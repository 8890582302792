import { SessionStorageEnum } from 'domain/repositories/session-storage/session-storage-enum';
import { setSessionStorage } from 'domain/repositories/session-storage/session_storage';
import { loginDev } from 'presentation/utils/login-development';
import { useEffect, useState } from 'react';

export const SessionPage: React.FC = () => {
  const [hasLogged, setHasLogged] = useState<boolean>(false);
  const timeoutToReceiveToken = 8000;

  function redirectToGenial() {
    window.location.href = process.env.REACT_APP_GENIAL_REDIRECT_URL ?? '';
  }

  function redirectToVexterHome() {
    window.location.href = process.env.REACT_APP_VEXTER_URL ?? '';
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      loginDev();
      setHasLogged(true);
    } else {
      const timer = setTimeout(() => {
        redirectToGenial();
      }, timeoutToReceiveToken);

      window.addEventListener('message', (event) => {
        if (event.origin === process.env.REACT_APP_GENIAL_REDIRECT_URL && !hasLogged) {
          const { token, account } = event.data;
          clearTimeout(timer);
          if (token && account) {
            setSessionStorage(SessionStorageEnum.account, account);
            setSessionStorage(SessionStorageEnum.token, token);
            setHasLogged(true);
          } else {
            redirectToGenial();
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (hasLogged) {
      redirectToVexterHome();
    }
  }, [hasLogged]);
  return <></>;
};

import { SimpleTooltip } from 'presentation/components/info-tooltip';
import { VXIcon, VXIconCustom, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { VxBodyLg, VxBodySm } from 'presentation/styleguide/typography/body/VxBody.stories';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useMediaQuery } from 'usehooks-ts';
import { VxBilletType } from '../..';
import { BilletTitle, BilletTitleIconContainer, TextAndTooltipContainer } from './style';

export type VxBilletTitleProps = {
  onClose?: () => void;
  onBack?: () => void;
  title: string;
  tooltip?: string;
  type?: VxBilletType;
  shouldCenterTitle?: boolean;
};

export const VxBilletTitle: React.FC<VxBilletTitleProps> = ({
  title,
  onClose,
  tooltip,
  onBack,
  type = 'modal',
  shouldCenterTitle = false,
}) => {
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  return (
    <BilletTitle type={type}>
      {onBack && (
        <BilletTitleIconContainer type={type} onClick={onBack}>
          <VXIconCustom icon={VXIconsEnum.arrowLeft} size="sm" color={vxAppColors.brand.primary.principal} />
        </BilletTitleIconContainer>
      )}
      <TextAndTooltipContainer shouldCenterTitle={shouldCenterTitle}>
        {tooltip ? (
          <>
            {isSmall ? (
              <VxBodySm title={title} fontWeight="light" color={vxAppColors.neutral.dark} />
            ) : (
              <H5 title={title} fontWeight="medium" color={vxAppColors.neutral.darkest} />
            )}
            {!isSmall && (
              <SimpleTooltip text={tooltip}>
                <VXIcon icon={VXIconsEnum.infos} size="sm" color={vxAppColors.neutral.dark} />
              </SimpleTooltip>
            )}
          </>
        ) : isSmall ? (
          <VxBodySm title={title} fontWeight="light" color={vxAppColors.neutral.dark} />
        ) : (
          <VxBodyLg title={title} fontWeight="medium" color={vxAppColors.neutral.darkest} />
        )}
      </TextAndTooltipContainer>
      {onClose && (
        <BilletTitleIconContainer type={type} onClick={onClose}>
          <VXIconCustom icon={VXIconsEnum.close} size="xsm" color={vxAppColors.brand.primary.principal} />
        </BilletTitleIconContainer>
      )}
    </BilletTitle>
  );
};

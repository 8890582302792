import { FormControl } from '@mui/material';
import { useState } from 'react';
import { VXIconProps } from '../vx-icon';
import { VxInputCore } from './components/vx-input-core';
import { InputContainer } from './style';

export type InputStatus = 'success' | 'error' | 'unknown';

export type InputType = 'text' | 'numeric' | 'currency';

export type InputFontSizeType = 'small' | 'regular';

export type VxInputProps = {
  id: string;
  value: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  placeHolder?: string;
  helperText?: string;
  disabled?: boolean;
  status?: InputStatus;
  endIcon?: React.ReactElement<VXIconProps>;
  helpIcon?: React.ReactElement<VXIconProps>;
  type?: InputType;
  className?: string;
  fontSize?: InputFontSizeType;
};

export const VxInput: React.FC<VxInputProps> = ({
  id,
  status = 'unknown',
  label,
  placeHolder,
  value,
  onFocus,
  onBlur,
  onChange,
  disabled = false,
  helperText = '',
  endIcon,
  helpIcon,
  type = 'text',
  fontSize = 'regular',
  className,
}) => {
  const [fullSize, setFullSize] = useState<boolean>(false);
  return (
    <InputContainer fontSize={fontSize} value={value} disabled={disabled} status={status} className={className}>
      <FormControl sx={{ width: fullSize ? '280px' : '279px' }} variant="standard">
        <VxInputCore
          onFocus={onFocus}
          onBlur={onBlur}
          fontSize={fontSize}
          id={id}
          type={type}
          label={label}
          placeHolder={placeHolder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          helperText={helperText}
          endIcon={endIcon}
          helpIcon={helpIcon}
          setFullSize={setFullSize}
        />
      </FormControl>
    </InputContainer>
  );
};

import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { CancelProductBoughtRepository } from 'domain/repositories/cancel-product-bought-usecase';

export interface CancelProductBoughtUsecase {
  execute(id: string): Promise<Either<Failure, void>>;
}

export class CancelProductBoughtUsecaseImp implements CancelProductBoughtUsecase {
  constructor(readonly repository: CancelProductBoughtRepository) {
    Object.freeze(this);
  }
  execute(id: string): Promise<Either<Failure, void>> {
    return this.repository.cancelBoughtProduct(id);
  }
}

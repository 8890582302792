import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';

import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import * as Styles from './styles';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: vxAppColors.neutral.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: vxAppColors.neutral.white,
    filter: 'drop-shadow(0px 4px 15px rgba(54, 77, 205, 0.2))',
    borderRadius: 4,
    minWidth: 360,
    padding: theme.spacing(0.5, 0.75),
  },
}));

type InfoTooltipProps = {
  text: string;
};

export function InfoTooltip(props: InfoTooltipProps) {
  return (
    <Styles.TooltipContainer>
      <CustomTooltip
        title={<VxBodySm title={props.text} fontWeight="regular" color={vxAppColors.neutral.dark} />}
        placement="bottom-start"
      >
        <button className="tooltip-button">
          <VXIcon icon={VXIconsEnum.infos} size="sm" color={vxAppColors.neutral.dark} />
        </button>
      </CustomTooltip>
    </Styles.TooltipContainer>
  );
}

import styled from 'styled-components';

export const EmptyOrdersContainer = styled.section`
  width: 100%;
  height: 231px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 48px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  h5:first-child {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 48px;
  }
`;

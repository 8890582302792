/* eslint-disable @typescript-eslint/no-unused-vars */
export type Either<L, R> = Left<L, R> | Right<L, R>;

export class Left<L, R> {
  constructor(readonly value: L) {
    Object.freeze(this);
  }

  isLeft(): this is Left<L, R> {
    return true;
  }

  isRight(): this is Right<L, R> {
    return false;
  }

  map<T>(_: (r: R) => T): Left<L, T> {
    return this as any;
  }

  mapLeft<T>(fn: (l: L) => T): Left<T, R> {
    return new Left<T, R>(fn(this.value));
  }

  fold<T>(fnL: (l: L) => T, _: (r: R) => T): T {
    return fnL(this.value);
  }
}

export class Right<L, R> {
  constructor(readonly value: R) {
    this.value = value;
    Object.freeze(this);
  }

  isLeft(): this is Left<L, R> {
    return false;
  }

  isRight(): this is Right<L, R> {
    return true;
  }

  map<T>(fn: (r: R) => T): Right<L, T> {
    return new Right(fn(this.value));
  }

  mapLeft<T>(_: (l: L) => T): Right<T, R> {
    return this as any;
  }

  fold<T>(_: (l: L) => T, fnR: (r: R) => T): T {
    return fnR(this.value);
  }
}

export const left = <L, R>(l: L): Either<L, R> => {
  return new Left<L, R>(l);
};

export const right = <L, R>(r: R): Either<L, R> => {
  return new Right<L, R>(r);
};

import { HttpDatasource } from 'data/datasources/http/http-datasource';
import { UrlDatasource } from 'data/datasources/url/url-datasource';
import { ExtractsResponseModel } from 'data/models/extracts-response-model';
import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { ExtractsRepository } from 'domain/repositories/extract-repository';
import { LoadExtractUsecaseParams } from 'domain/usecases/extract/load-extracts-usecase';
import { groupBy } from 'lodash';
import { ExtractProps } from 'presentation/pages/profile/subpages/extract/components/extract-table';
import { verifyAndFormatDateComplete } from 'data/utils/date-time-util';

class ExtractHttpRepository implements ExtractsRepository {
  constructor(readonly httpDatasource: HttpDatasource, readonly urlDatasource: UrlDatasource) {
    Object.freeze(this);
  }

  async loadExtracts(params: LoadExtractUsecaseParams): Promise<Either<Failure, Array<ExtractProps>>> {
    const error = left<Failure, ExtractProps[]>(new Failure(`Erro ao carregar extratos`));
    try {
      const response = await this.httpDatasource.request(
        this.urlDatasource.loadExtracts(params.startDate, params.endDate, params.releaseType)
      );
      if (!response.ok) {
        return error;
      }
      const extracts: ExtractsResponseModel = response.data;

      if (!extracts.success) {
        return error;
      }

      const extractsEntity = extracts.data.map((extract) => ({
        value: extract.value ?? 2,
        type: extract.type,
        createdAt: new Date(extract.createdAt ?? new Date()).toLocaleDateString(),
      }));

      extractsEntity.forEach((e) => (e.createdAt = verifyAndFormatDateComplete(e.createdAt)));

      const groupByDate = groupBy(extractsEntity, 'createdAt');

      const extractsProps = Object.keys(groupByDate).map((date) => {
        const model: ExtractProps = {
          date: date,
          operations: groupByDate[date].map((e) => ({
            text: e.type?.name ?? '--',
            value: e.value ?? 0,
          })),
        };
        return model;
      });
      return right(extractsProps);
    } catch (_error) {
      return error;
    }
  }
}

export { ExtractHttpRepository };

import styled from 'styled-components';

export const Nav = styled.nav`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  margin-top: 19px;

  button {
    cursor: pointer;
    border: none;
    background: transparent;
    display: flex;
    height: 37px;
    gap: 8px;
    align-items: center;
  }
`;

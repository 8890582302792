import { Checkbox } from '@mui/material';
import { formatCurrency } from 'data/utils/currency-util';
import { PaymentPeriodEnum } from 'domain/enums/payment-period-enum';
import { hasValue } from 'domain/util';
import { makeCancelProductBoughtUsecase } from 'main/factories/domain/cancel-product-bought-usecase-factory';
import { makeSaveBuyProductUsecase } from 'main/factories/domain/save-buy-product-usecase-factory';
import { vexterToastError, vexterToastSuccess } from 'presentation/components/micro-components/toast';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { VXTextSkeleton } from 'presentation/components/micro-components/vx-skeleton/text/styles';

import { VxChips } from 'presentation/components/micro-components/vx-chips';
import { Modal } from 'presentation/components/modal';
import { useAllProducts } from 'presentation/stores/hooks/use-all-products';
import { useProductsBought } from 'presentation/stores/hooks/use-products-bought';

import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { VxBodyLg, VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H4, H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { StringUtils } from 'presentation/utils/string-utils';
import { useCallback, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useMediaQuery } from 'usehooks-ts';
import { CardLoading, TitleLoading } from './components/loadings';
import * as S from './style';

export const PlatformsPage = () => {
  const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
  const [seletectedProductIndex, setSelectedProductIndex] = useState<number>(0);
  const [seletectedTierIndex, setSelectedTierIndex] = useState<number>(0);
  const { allProducts, isLoadingAllProducts } = useAllProducts();
  const {
    productsBoughtActive,
    isLoadingProductsBought,
    reloadBoughtProducts: realoadBoughtProducts,
  } = useProductsBought();

  const isLoading = isLoadingAllProducts || isLoadingProductsBought;
  const active = !isLoadingProductsBought && productsBoughtActive.length > 0;
  const selectedProduct = allProducts?.[seletectedProductIndex];
  const selectedProductTier = selectedProduct?.tiers?.[seletectedTierIndex];
  const isSmalletsScreen = useMediaQuery('(max-width: 1024px)');
  const isMediumScreen = useMediaQuery('(max-width: 1440px)');
  const isLargetsScreen = useMediaQuery('(min-width: 1441px)');
  const showExtraColumn = useMediaQuery('(min-width: 1280px) and (max-width: 1919px)');

  useEffect(() => {
    if (hasValue(allProducts) && hasValue(productsBoughtActive) && productsBoughtActive.length > 0) {
      const selectedProduct = allProducts.findIndex((product) =>
        product.tiers.some((tier) => tier.id === (productsBoughtActive?.[0]?.tier.id ?? ''))
      );
      if (selectedProduct !== -1) {
        const selectedTier = allProducts[selectedProduct].tiers.findIndex(
          (tier) => tier.id === productsBoughtActive[0].tier.id
        );
        if (selectedProduct !== -1) {
          setSelectedProductIndex(selectedProduct);
          setSelectedTierIndex(selectedTier);
        }
      }
    }
  }, [productsBoughtActive, allProducts]);
  const buyProduct = useCallback(() => {
    if (isLoadingAction || isLoading) return;
    setIsLoadingAction(true);
    makeSaveBuyProductUsecase()
      .execute({
        acceptedTerms: true,
        productId: allProducts[seletectedProductIndex].id,
        tierId: allProducts[seletectedProductIndex].tiers[seletectedTierIndex].id,
      })
      .then((savedOrError) => {
        setIsLoadingAction(false);
        savedOrError.fold(
          () => {
            vexterToastError({
              title: 'Erro ao contratar a plataforma.',
              message: 'Entre em contato com nosso Atendimento.',
              hasClose: true,
            });
          },
          () => {
            vexterToastSuccess({
              title: 'Plataforma contratada com sucesso',
              message: 'Você irá receber um e-mail com seus dados de acesso',
              hasClose: true,
            });
            realoadBoughtProducts();
          }
        );
      })
      .catch(console.error);
  }, [allProducts, seletectedProductIndex, seletectedTierIndex, isLoadingAction, isLoading]);
  const cancelProduct = useCallback(() => {
    if (isLoadingAction) return;
    setIsLoadingAction(true);
    makeCancelProductBoughtUsecase()
      .execute(productsBoughtActive[0].id)
      .then((canceledOrError) => {
        setIsLoadingAction(false);
        canceledOrError.fold(
          () => {
            vexterToastError({
              title: 'Erro ao cancelar a plataforma.',
              message: 'Entre em contato com nosso Atendimento.',
              hasClose: true,
            });
          },
          () => {
            vexterToastSuccess({
              title: 'Plano cancelado com sucesso',
              hasClose: true,
            });
            realoadBoughtProducts();
          }
        );
      })
      .catch(console.error);
  }, [productsBoughtActive, isLoadingAction]);
  const [showModalAccept, hideModalAccept] = useModal(
    ({ in: open }) => {
      const [confirm, setConfirm] = useState<boolean>(false);
      return (
        <Modal
          open={open}
          hideModalHookCallback={hideModalAccept}
          height={510}
          width={529}
          backgroundColor={vxAppColors.neutral.white}
        >
          <S.ConfirmationContainer>
            <S.ConfirmationCloseButtonContainer>
              <div>
                <VXIcon icon={VXIconsEnum.close} size="xsm" />
              </div>
            </S.ConfirmationCloseButtonContainer>
            <S.ConfirmationTitleContainer>
              <H4 title="Adesão" color={vxAppColors.brand.primary.principal} fontWeight="light" />
            </S.ConfirmationTitleContainer>
            <S.ConfirmationProductNameContainer>
              <H5 title={selectedProduct.name} color={vxAppColors.brand.primary.darkest} fontWeight="medium" />
            </S.ConfirmationProductNameContainer>
            <VxBodyLg
              title={selectedProductTier.name}
              color={vxAppColors.brand.primary.principal}
              fontWeight="medium"
            />
            <S.ConfirmationValueContainer isFree={selectedProductTier.isFree}>
              <VxBodySm title="Valor : " color={vxAppColors.brand.primary.darkest} fontWeight="light" />
              <VxBodyMd
                title={formatCurrency(selectedProductTier.price)}
                color={selectedProductTier.isFree ? vxAppColors.brand.primary.principal : vxAppColors.neutral.darkest}
                fontWeight="medium"
              />
              <VxBodyMd
                title={`${selectedProductTier.isFree ? 'Grátis' : ''}`}
                color={vxAppColors.feedback.success.default}
                fontWeight="medium"
              />
            </S.ConfirmationValueContainer>
            <VxBodyMd
              title={selectedProductTier.description}
              color={vxAppColors.brand.primary.darkest}
              fontWeight="regular"
            />
            <S.ConfirmationTermsReceivedContainer
              onClick={() => {
                setConfirm((state) => !state);
              }}
            >
              <Checkbox
                size="small"
                checked={confirm}
                style={{ color: vxAppColors.brand.primary.principal, padding: '0px' }}
                disableTouchRipple
                disableFocusRipple
              />
              <VxBodySm
                title="Declaro que recebi, li e estou de acordo com o termo de adesão e ciência de risco"
                color={vxAppColors.brand.primary.darkest}
                fontWeight="regular"
              />
            </S.ConfirmationTermsReceivedContainer>
            <VxBodySm
              title="Ao continuar, declaro que li e estou de acordo com o Termos e Condições para investir em Cripto."
              color={vxAppColors.brand.primary.darkest}
              fontWeight="regular"
            />
            <S.ConfirmationButtonContainer>
              <VxButton
                type="primary"
                disabled={!confirm}
                text="CONFIRMAR"
                onClick={() => {
                  hideModalAccept();
                  buyProduct();
                }}
                size="small"
              />
            </S.ConfirmationButtonContainer>
          </S.ConfirmationContainer>
        </Modal>
      );
    },
    [selectedProductTier]
  );
  const [showModalCancel, hideModalCancel] = useModal(
    ({ in: open }) => {
      return (
        <Modal
          open={open}
          hideModalHookCallback={hideModalCancel}
          height={273}
          width={463}
          backgroundColor={vxAppColors.neutral.white}
        >
          <S.CancelContainer>
            <S.CancelCloseIconContainer onClick={hideModalCancel}>
              <div>
                <VXIcon icon={VXIconsEnum.close} size="xsm" color={vxAppColors.brand.primary.principal} />
              </div>
            </S.CancelCloseIconContainer>
            <S.CancelTitleContainer>
              <VXIcon
                icon={VXIconsEnum.alert}
                invertStrokeWithFill={true}
                size="lg"
                color={vxAppColors.feedback.atention.default}
              />
              <H4 title="Cancelar Plano" color={vxAppColors.brand.primary.darkest} fontWeight="light" />
            </S.CancelTitleContainer>
            <VxBodySm
              title="Você tem certeza que deseja cancelar o seu plano da plataforma?"
              color={vxAppColors.brand.primary.darkest}
              fontWeight="light"
            />
            <S.CancelButtonContainerContainer>
              <VxButton
                type="primary"
                disabled={false}
                text="CONTINUAR COM O PLANO"
                onClick={() => {
                  hideModalCancel();
                }}
                size="small"
              />
              <VxButton
                type="primary"
                disabled={false}
                text="CANCELAR"
                onClick={() => {
                  hideModalCancel();
                  cancelProduct();
                }}
                size="small"
              />
            </S.CancelButtonContainerContainer>
          </S.CancelContainer>
        </Modal>
      );
    },
    [productsBoughtActive]
  );

  return (
    <>
      <VxGridContainer>
        <VxGridItem>
          <S.StyledPlatformPageTitleContainer>
            {isLargetsScreen ? (
              <H4 title="Plataforma" color={vxAppColors.brand.primary.darkest} fontWeight="light" />
            ) : (
              <VxBodyMd title="Plataforma" color={vxAppColors.brand.primary.darkest} fontWeight="light" />
            )}
            <VxBodySm
              title="Encontre as melhores plataformas para crescer suas operações e ter mais controle em tempo real."
              color={vxAppColors.brand.primary.darkest}
              fontWeight="regular"
            />
          </S.StyledPlatformPageTitleContainer>

          <S.StyledPlatformPageTabContainer>
            {isLoading ? (
              <VXTextSkeleton height={34} width={148} skeletonColor="light" />
            ) : (
              <VxChips
                activeIndex={seletectedTierIndex}
                items={allProducts.map((product) => product.name)}
                onClick={(caption) => {
                  window.open('https://www.vectorcrypto.com.br/pt/login', '_blank');
                  const newIndex = allProducts.findIndex((product) => product.name === caption);
                  if (newIndex !== -1) {
                    setSelectedProductIndex(newIndex);
                  }
                }}
              />
            )}
          </S.StyledPlatformPageTabContainer>
        </VxGridItem>
      </VxGridContainer>

      <VxGridContainer>
        {isLoading ? (
          <TitleLoading />
        ) : (
          <VxGridItem columns={3} xlg={8} lg={8} md={8}>
            <S.StyledPlatformPageDescriptionTitle>
              <S.StyledPlatformPageDescriptionTitleImage>
                <img src={`${process.env.REACT_APP_BUCKET}${allProducts?.[seletectedProductIndex]?.shortName}.png`} />
              </S.StyledPlatformPageDescriptionTitleImage>
              <S.StyledPlatformPageDescriptionTitleInfo>
                {active && (
                  <S.CurrentItemActiveBadgeContainer>
                    <div>
                      <VxBodyXs title="Ativado" color={vxAppColors.neutral.white} fontWeight="medium" />
                    </div>
                  </S.CurrentItemActiveBadgeContainer>
                )}
                <VxBodySm
                  title={allProducts?.[seletectedProductIndex]?.description ?? ''}
                  color={vxAppColors.brand.primary.darkest}
                  fontWeight="regular"
                />

                {!active && (
                  <S.StyledPlatformPageDescriptionButton>
                    <VxButton
                      disabled={false}
                      type="callToAction"
                      text="CONTRATAR"
                      onClick={showModalAccept}
                      width={isMediumScreen ? 94 : 180}
                      size={isMediumScreen ? 'extraSmall' : 'regular'}
                    />
                  </S.StyledPlatformPageDescriptionButton>
                )}
              </S.StyledPlatformPageDescriptionTitleInfo>
            </S.StyledPlatformPageDescriptionTitle>
          </VxGridItem>
        )}

        {showExtraColumn && (
          <VxGridItem columns={1}>
            <br />
          </VxGridItem>
        )}

        {!active && (
          <>
            {(allProducts?.[seletectedProductIndex]?.tiers ?? [{}, {}, {}]).map((tier, index) => {
              const selected = seletectedTierIndex === index;
              return isLoading ? (
                <CardLoading key={index} />
              ) : (
                <VxGridItem columns={3} xlg={4} lg={4} md={4} height="100%">
                  <S.StyledPlatformPageDescriptionItem key={tier.id}>
                    <S.StyledPlatformPageDescriptionItemTitleRow>
                      <S.StyledPlatformPageDescriptionItemSelected onClick={() => setSelectedTierIndex(index)}>
                        {selected && <S.StyledPlatformPageSeletectedDot />}
                      </S.StyledPlatformPageDescriptionItemSelected>
                      <VxBodySm
                        title={StringUtils.capitalizeFirstLetter(tier.name)}
                        color={vxAppColors.brand.primary.darkest}
                        fontWeight="regular"
                      />
                    </S.StyledPlatformPageDescriptionItemTitleRow>
                    <S.StyledPlatformPageDescriptionItemDescription>
                      <VxBodySm title={tier.description} color={vxAppColors.neutral.dark} fontWeight="light" />
                    </S.StyledPlatformPageDescriptionItemDescription>
                    <S.StyledPlatformPageDescriptionItemValue isFree={tier.isFree}>
                      <VxBodySm title="Valor :" color={vxAppColors.neutral.dark} fontWeight="light" />
                      <VxBodyMd
                        title={`${formatCurrency(tier.price)}${
                          tier.paymentPeriod === PaymentPeriodEnum.month ? '/mês' : ''
                        }`}
                        color={tier.isFree ? vxAppColors.brand.primary.principal : vxAppColors.brand.primary.darkest}
                        fontWeight="medium"
                      />
                      <VxBodyMd
                        title={`${tier.isFree ? 'Grátis' : ''}`}
                        color={vxAppColors.feedback.success.default}
                        fontWeight="medium"
                      />
                    </S.StyledPlatformPageDescriptionItemValue>
                    <S.StyledPlatformPageDescriptionItemDivider>
                      <hr />
                    </S.StyledPlatformPageDescriptionItemDivider>
                    <S.StyledPlatformPageDescriptionItemContainer>
                      {Array.from(Array(isSmalletsScreen ? Math.ceil(tier.benefits.length / 5) : 1).keys()).map(
                        (value) => {
                          const benefits = isSmalletsScreen
                            ? tier.benefits.slice(value * 5, value * 5 + 5)
                            : tier.benefits;
                          return (
                            <S.StyledPlatformPageDescriptionItemBenefitContainer key={value}>
                              {benefits.map((description) => (
                                <S.StyledPlatformPageDescriptionItemContent key={description}>
                                  <S.StyledPlatformPageDescriptionItemContentIcon>
                                    <VXIcon
                                      icon={VXIconsEnum.checkCircle}
                                      size="sm"
                                      invertStrokeWithFill={true}
                                      color={vxAppColors.brand.primary.principal}
                                    />
                                  </S.StyledPlatformPageDescriptionItemContentIcon>
                                  <VxBodySm title={description} color={vxAppColors.neutral.dark} fontWeight="light" />
                                </S.StyledPlatformPageDescriptionItemContent>
                              ))}
                            </S.StyledPlatformPageDescriptionItemBenefitContainer>
                          );
                        }
                      )}
                    </S.StyledPlatformPageDescriptionItemContainer>
                  </S.StyledPlatformPageDescriptionItem>
                </VxGridItem>
              );
            })}
          </>
        )}

        {active && (
          <S.CurrentItemContainer>
            <S.CurrentItemSessionContainer>
              <S.CurrentItemTitleContainer>
                <VxBodyLg title="Minha versão atual:" color={vxAppColors.brand.primary.darkest} fontWeight="medium" />
              </S.CurrentItemTitleContainer>
              <S.CurrentItemContentContainer>
                <VxBodyMd
                  title={productsBoughtActive[0].tier.name}
                  color={vxAppColors.brand.primary.darkest}
                  fontWeight="bold"
                />
                <S.CurrentItemContentDescriptionContainer>
                  <VxBodySm
                    title={productsBoughtActive[0].tier.description}
                    color={vxAppColors.brand.primary.darkest}
                    fontWeight="light"
                  />
                </S.CurrentItemContentDescriptionContainer>
                <S.CurrentItemValueContainer isFree={productsBoughtActive[0].tier.isFree}>
                  <VxBodySm title="Valor : " color={vxAppColors.neutral.dark} fontWeight="light" />
                  <VxBodyMd
                    title={`${formatCurrency(productsBoughtActive[0].tier.price)}${
                      productsBoughtActive[0].tier.paymentPeriod === PaymentPeriodEnum.month ? '/mês' : ''
                    }`}
                    color={
                      productsBoughtActive[0].tier.isFree
                        ? vxAppColors.brand.primary.principal
                        : vxAppColors.brand.primary.darkest
                    }
                    fontWeight="regular"
                  />
                  {productsBoughtActive[0].tier.isFree && (
                    <VxBodyMd title="Grátis*" color={vxAppColors.feedback.success.default} fontWeight="medium" />
                  )}
                </S.CurrentItemValueContainer>
              </S.CurrentItemContentContainer>
            </S.CurrentItemSessionContainer>
            <S.CurrentItemSessionContainer>
              <S.CurrentItemTitleContainer>
                <VxBodyLg
                  title="Deseja mudar sua versão?"
                  color={vxAppColors.brand.primary.darkest}
                  fontWeight="medium"
                />
              </S.CurrentItemTitleContainer>
              <S.CancelItemContentContainer>
                <VxBodySm
                  title="Para alterar a sua versão, primeiro você deve cancelar a versão atual e, logo após, contratar a versão desejado"
                  color={vxAppColors.brand.primary.darkest}
                  fontWeight="regular"
                />
              </S.CancelItemContentContainer>
              <S.CancelItemButtonContainer>
                <VxButton
                  disabled={false}
                  size="small"
                  type="secondary"
                  onClick={showModalCancel}
                  text="CANCELAR VERSÃO"
                />
              </S.CancelItemButtonContainer>
            </S.CurrentItemSessionContainer>
          </S.CurrentItemContainer>
        )}
      </VxGridContainer>
    </>
  );
};

import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { SaveBuyProductRepository } from 'domain/repositories/save-buy-product-repository';

export type SaveBuyProductUsecaseExecuteParams = {
  productId: string;
  tierId: string;
  acceptedTerms: boolean;
};

export interface SaveBuyProductUsecase {
  execute(params: SaveBuyProductUsecaseExecuteParams): Promise<Either<Failure, void>>;
}

export class SaveBuyProductUsecaseImp implements SaveBuyProductUsecase {
  constructor(readonly repository: SaveBuyProductRepository) {
    Object.freeze(this);
  }
  execute(params: SaveBuyProductUsecaseExecuteParams): Promise<Either<Failure, void>> {
    return this.repository.buyProduct(params);
  }
}

import { HttpDatasource, HttpMethodEnum } from 'data/datasources/http/http-datasource';
import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { SaveDepositRepository } from 'domain/repositories/save-deposit-repository';
import { SaveWithdrawRepository } from 'domain/repositories/save-withdraw-repository';

export class FinancialHttpRepository implements SaveWithdrawRepository, SaveDepositRepository {
  constructor(readonly baseUrl: string, readonly httpDatasource: HttpDatasource) {
    Object.freeze(this);
  }
  async saveWithdraw(amount: number): Promise<Either<Failure, void>> {
    const error = left<Failure, void>(new Failure('Erro ao realizar saque'));
    try {
      const response = await this.httpDatasource.request(`${this.baseUrl}/Financial/v1/withdraw?amount=${amount}`, {
        method: HttpMethodEnum.post,
      });
      return response.ok ? right(undefined) : error;
    } catch (_) {
      return error;
    }
  }
  async saveDeposit(amount: number): Promise<Either<Failure, void>> {
    const error = left<Failure, void>(new Failure('Erro ao realizar depósito'));
    try {
      const response = await this.httpDatasource.request(`${this.baseUrl}/Financial/v1/deposit`, {
        data: {
          amount: amount,
        },
        method: HttpMethodEnum.post,
      });
      return response.ok ? right(undefined) : error;
    } catch (_) {
      return error;
    }
  }
}

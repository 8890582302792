import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

type MenuButtonStyleType = {
  selected?: boolean;
  disabled?: boolean;
};

export const MenuButtonStyle = styled.button<MenuButtonStyleType>`
  height: 56px;
  min-width: 100%;
  border: none;
  background-color: ${(props) => {
    if (props.disabled) {
      return 'transparent';
    }
    return props.selected ? vxAppColors.brand.primary.light : 'transparent';
  }};
  transition: 100ms all;
  position: relative;
  padding: 0px 36px 0px 43px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  gap: 8px;
  :hover {
    color: ${vxAppColors.brand.primary.light};
    background-color: ${(props) => (props.disabled ? 'transparent' : vxAppColors.brand.primary.light)};
  }
  ::before {
    content: '';
    position: absolute;
    transition: 200ms all;
    left: 0px;
    top: 0px;
    width: 4px;
    height: 56px;
    border-radius: 8px;
    background-color: ${(props) => {
      if (props.disabled) {
        return 'transparent';
      }
      return props.selected ? vxAppColors.brand.secondary.principal : 'transparent';
    }};
  }

  @media (max-width: 1919px) {
    height: 52px;
    width: 72px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    i {
      margin-top: 7px;
    }
    ::before {
      height: 52px;
    }
  }
`;

import { VxBackTitle } from 'presentation/components/micro-components/vx-back-title';
import { SubHeader } from 'presentation/components/sub-header';
import { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { DepositContent } from './components/deposit';
import { WithdrawContent } from './components/withdraw';
import { BackButtonContainer, TransferContainer } from './styles';
export const DepositWithdrawPage: React.FunctionComponent = () => {
  const [currentTab, setCurrentTab] = useState('Depositar');
  const showBackButton = useMediaQuery('(min-width: 1025px)');
  return (
    <>
      <SubHeader itens={['Depositar', 'Retirar']} onClick={setCurrentTab} />
      {showBackButton && (
        <BackButtonContainer>
          <VxBackTitle />
        </BackButtonContainer>
      )}
      <TransferContainer>{currentTab === 'Depositar' ? <DepositContent /> : <WithdrawContent />}</TransferContainer>
    </>
  );
};

import { BannerDataEntity } from 'domain/entities/banner-data-entity';
import { makeLoadBannersUsecase } from 'main/factories/domain/load-banners-usecase-factory';
import { useEffect, useState } from 'react';

export function useBanners() {
  const [banners, setBanners] = useState<BannerDataEntity[]>([]);
  const [isLoadingBanners, setIsLoadingBanners] = useState<boolean>(true);
  useEffect(() => {
    setIsLoadingBanners(true);
    makeLoadBannersUsecase()
      .execute()
      .then((bannersOrError) => {
        if (bannersOrError.isRight()) {
          setBanners(bannersOrError.value);
          setIsLoadingBanners(false);
        }
      })
      .catch(console.error);
  }, []);
  return {
    banners,
    isLoadingBanners,
  };
}

import { HubConnectionBuilder } from '@microsoft/signalr';
import { CryptoQuotationWsRepository } from 'data/repositories/ws/crypto-quotation-ws-repository';
import { SignalRWsDatasource } from 'datasources/ws/signalr-ws-datasource';
import { Failure } from 'domain/failure/failure';
import { ListenQuotationBySymbolRepository } from 'domain/repositories/listen-quotation-by-symbol-repository';

let cryptoListenQuotationBySymbolRepository: ListenQuotationBySymbolRepository | null = null;

export const makeCryptoListenQuotationBySymbolRepository = (): ListenQuotationBySymbolRepository => {
  if (cryptoListenQuotationBySymbolRepository === null) {
    const socketUrl = process.env.REACT_APP_WS_CRYPTO ?? '';
    if (socketUrl === '') {
      throw new Failure('Falta definir variável de ambiente ("REACT_APP_WS_CRYPTO")');
    }
    const hubConnection = new HubConnectionBuilder().withUrl(socketUrl, {}).build();
    cryptoListenQuotationBySymbolRepository = new CryptoQuotationWsRepository(new SignalRWsDatasource(hubConnection));
  }
  return cryptoListenQuotationBySymbolRepository;
};

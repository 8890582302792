import { AssetImage } from 'presentation/components/asset-image';
import { SimpleTooltip } from 'presentation/components/info-tooltip';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { useAppDispatch, useAppSelector } from 'presentation/stores/hooks';
import { Currencys, setCurrency } from 'presentation/stores/slices/settings.store';
import { VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import * as S from './styles';

export function CurrencyInfo() {
  const dispatch = useAppDispatch();
  const { selectedCurrency } = useAppSelector((state) => state.settingsSlice);
  const isDolar = selectedCurrency.symbol === Currencys.dolar.symbol;

  return (
    <S.CurrencyContainer>
      <AssetImage symbol={selectedCurrency.image} size="xs2" />
      <S.CurrencySymbolAndNameContainer>
        <VxBodyXs
          title={selectedCurrency.symbol}
          fontWeight="bold"
          color={vxAppColors.brand.primary.darkest}
          htmlTag="span"
        />
        <VxBodyXs
          title={selectedCurrency.companyName}
          fontWeight="regular"
          color={vxAppColors.brand.primary.darkest}
          htmlTag="span"
        />
      </S.CurrencySymbolAndNameContainer>
      <SimpleTooltip text={isDolar ? 'Trocar para Real' : 'Trocar para Dólar'} placement="bottom-end">
        <button
          onClick={() => dispatch(setCurrency(selectedCurrency.symbol === 'BRL' ? Currencys.dolar : Currencys.real))}
        >
          <VXIcon icon={VXIconsEnum.exchange} size="sm" color={vxAppColors.brand.tertiary.principal} />
        </button>
      </SimpleTooltip>
    </S.CurrencyContainer>
  );
}

import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  cursor: pointer;
  width: fit-content;
  @media (min-width: 1081px) {
    gap: 12px;
  }
  div.title-button-container {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

import { Box, LinearProgress } from '@mui/material';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { ContentProgressContainer, ContentProgressItem } from './style';

export type VxMarginProgressProps = {
  usedMarginGreen: number;
  usedMarginYellow: number;
  usedMarginRed: number;
};

export const VxMarginProgress: React.FC<VxMarginProgressProps> = ({
  usedMarginGreen,
  usedMarginYellow,
  usedMarginRed,
}) => {
  return (
    <ContentProgressContainer>
      <Box sx={{ width: '50%' }}>
        <ContentProgressItem color={vxAppColors.feedback.success.default}>
          <LinearProgress variant="determinate" value={usedMarginGreen} />
        </ContentProgressItem>
      </Box>
      <Box sx={{ width: '30%' }}>
        <ContentProgressItem color={vxAppColors.feedback.atention.default}>
          <LinearProgress variant="determinate" value={usedMarginYellow} />
        </ContentProgressItem>
      </Box>
      <Box sx={{ width: '20%' }}>
        <ContentProgressItem color={vxAppColors.feedback.error.default}>
          <LinearProgress variant="determinate" value={usedMarginRed} />
        </ContentProgressItem>
      </Box>
    </ContentProgressContainer>
  );
};

import { formatDouble, formatNumbers } from 'data/utils/currency-util';
import { AssetImage } from 'presentation/components/asset-image';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';
import usePosition from 'presentation/stores/hooks/use-position';
import { useQuotation } from 'presentation/stores/hooks/use-quotation';

import { VxBodyLg, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { VxBodyMd, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody.stories';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { vxOscillationColor } from 'presentation/utils/color-utils';
import { useMediaQuery } from 'usehooks-ts';
import { VxBilletType } from '../..';

import * as Styles from './style';

export type VxBilletItemProps = {
  symbol: string;
  onClick?: () => void;
  shouldNavigate?: boolean;
  shouldShowPosition?: boolean;
  type?: VxBilletType;
};

export const VxBilletItem = ({
  symbol,
  onClick,
  shouldNavigate = false,
  shouldShowPosition = false,
  type = 'modal',
}: VxBilletItemProps) => {
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  const { companyName } = useCompanyName({ symbol });
  const { dailyOscillation, lastTrade } = useQuotation({ symbol });
  const formatOptions = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };
  const { positions } = usePosition();
  let firstValue = formatNumbers(lastTrade ?? 0, formatOptions);
  let lastValue = `${formatDouble(dailyOscillation, { alwaysShowSignal: true, minimumDecimalPlaces: 2 })}%`;
  let lastValueColor = vxOscillationColor(dailyOscillation);
  if (shouldShowPosition) {
    const position = positions.find((p) => p.symbol === symbol);
    firstValue = `${formatDouble(position?.totalNet, { alwaysShowSignal: true, minimumDecimalPlaces: 2 })}`;
    lastValue = formatNumbers(position?.positionValue ?? 0, formatOptions);
    lastValueColor = vxAppColors.neutral.darkest;
  }
  return (
    <Styles.BilletItemContainer type={type} shouldNavigate={onClick !== undefined} onClick={onClick}>
      <Styles.BilletItemIconAndSymbolAndCompanyNameContainer type={type} shouldNavigate={shouldNavigate}>
        <AssetImage symbol={symbol} size={isSmall ? 'xs2' : 'xs'} />
        <Styles.BilletItemSymbolAndCompanyName>
          {isSmall ? (
            <>
              <VxBodyXs title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} htmlTag="span" />
              <VxBodyXs
                title={companyName}
                fontWeight="regular"
                color={vxAppColors.brand.primary.darkest}
                htmlTag="span"
              />
            </>
          ) : (
            <>
              <VxBodyMd title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} htmlTag="span" />
              <VxBodySm
                title={companyName}
                fontWeight="light"
                color={vxAppColors.brand.primary.darkest}
                htmlTag="span"
              />
            </>
          )}
        </Styles.BilletItemSymbolAndCompanyName>
      </Styles.BilletItemIconAndSymbolAndCompanyNameContainer>
      <Styles.BilletItemLastTradeAndDailyOscillationAndNavigationContainer type={type} shouldNavigate={shouldNavigate}>
        <Styles.BilletItemLastTradeAndDailyOscillation>
          {isSmall ? (
            <>
              <VxBodyXs title={firstValue} fontWeight="medium" color={vxAppColors.neutral.darkest} htmlTag="span" />
              <VxBodyXs title={lastValue} fontWeight="regular" color={lastValueColor} htmlTag="span" />
            </>
          ) : (
            <>
              <VxBodyLg title={firstValue} fontWeight="medium" color={vxAppColors.neutral.darkest} htmlTag="span" />
              <VxBodyMd title={lastValue} fontWeight="regular" color={lastValueColor} htmlTag="span" />
            </>
          )}
        </Styles.BilletItemLastTradeAndDailyOscillation>
        {shouldNavigate && (
          <Styles.BilletItemIconContainer onClick={onClick}>
            <VXIcon color={vxAppColors.neutral.darkest} size="sm" icon={VXIconsEnum.arrowRight} />
          </Styles.BilletItemIconContainer>
        )}
      </Styles.BilletItemLastTradeAndDailyOscillationAndNavigationContainer>
    </Styles.BilletItemContainer>
  );
};

import { SessionStorageEnum } from 'domain/repositories/session-storage/session-storage-enum';
import { getSessionStorage } from 'domain/repositories/session-storage/session_storage';
import { HttpDatasource } from '../../../../data/datasources/http/http-datasource';
import { AxiosHttpDatasource } from '../../../../datasources/http/axios-http-datasource';
import { DynamicHeaderHttpDatasource } from '../../../../datasources/http/dynamic-header-http-datasource';
import { FixedHeaderHttpDatasource } from '../../../../datasources/http/fixed-header-http-datasource';

export const makeAxiosHttpDatasource = (): HttpDatasource => {
  return new AxiosHttpDatasource();
};

export const makeFixedHeaderHttpDatasource = (
  origin: HttpDatasource,
  fixedHeader: Record<string, string> = {}
): HttpDatasource => {
  return new FixedHeaderHttpDatasource(origin, fixedHeader);
};

export const makeDynamicHeaderHttpDatasource = (
  origin: HttpDatasource,
  dynamicHeader: () => Promise<Record<string, string>> = async () => ({})
): HttpDatasource => {
  return new DynamicHeaderHttpDatasource(origin, dynamicHeader);
};

export const makeCryptoHttpDatasource = (): HttpDatasource => {
  return new DynamicHeaderHttpDatasource(makeAxiosHttpDatasource(), async () => {
    return {
      content: 'application/json',
      authorization: `Bearer ${getSessionStorage(SessionStorageEnum.token) ?? 'NULL'}`,
    };
  });
};

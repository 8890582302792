import { HttpDatasource } from 'data/datasources/http/http-datasource';
import { UrlDatasource } from 'data/datasources/url/url-datasource';
import { cryptoMiniChartMapper } from 'data/utils/mini-chart-mapper-util';
import { AssetChartEntity } from 'domain/entities/asset-chart-point-entity';
import { AssetSymbolEntity } from 'domain/entities/asset-symbol-entity';
import { AssetChartPointData, ChartResponseModel } from 'domain/entities/chart-asset-data-entity';
import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadAssetChartRepository } from 'domain/repositories/load-chart-asset-data-repository';
import { LoadMiniChartRepository } from 'domain/repositories/load-mini-chart-repository';
import { LoadAssetChartUsecaseParams } from 'domain/usecases/load-asset-chart-usecase';
import { hasValue } from 'domain/util';

type AssetChartRepositoryParams = {
  httpDatasource: HttpDatasource;
  urlDatasource: UrlDatasource;
};

export class AssetChartRepository implements LoadMiniChartRepository, LoadAssetChartRepository {
  constructor(readonly params: AssetChartRepositoryParams) {
    Object.freeze(this);
  }
  async loadMiniChart(symbols: AssetSymbolEntity[]): Promise<Either<Failure, AssetChartEntity[]>> {
    const error = left<Failure, AssetChartEntity[]>(
      new Failure(`Erro ao carregar mini-gráfico (símbolos: "${symbols.map((symbol) => symbol.value).join(',')}")`)
    );
    try {
      const response = await this.params.httpDatasource.request(
        this.params.urlDatasource.miniChart(symbols.map((symbol) => symbol.value))
      );
      if (response.ok) {
        return right(cryptoMiniChartMapper(response.data));
      }
      return error;
    } catch (e) {
      return error;
    }
  }
  async assetChart({
    symbol,
    currency,
    interval,
  }: LoadAssetChartUsecaseParams): Promise<Either<Failure, AssetChartPointData[]>> {
    const error = left<Failure, AssetChartPointData[]>(new Failure(`Erro ao carregar gráfico do ativo ${symbol}`));
    try {
      let intervalValue = 5;
      switch (interval) {
        case '1m':
          intervalValue = 6;
          break;
        case '6m':
          intervalValue = 7;
          break;
        case '12m':
          intervalValue = 8;
          break;
      }
      const response = await this.params.httpDatasource.request(
        this.params.urlDatasource.assetChart(symbol, intervalValue, currency)
      );
      if (response.ok) {
        const chartModel: ChartResponseModel = response.data;
        return right(
          chartModel.data.history
            .filter((h) => hasValue(h.updatedAt) && hasValue(h.closePrice))
            .map((h) => ({
              price: h.closePrice ?? 0,
              when: new Date(h.updatedAt ?? ''),
            }))
        );
      }
      return error;
    } catch (e) {
      return error;
    }
  }
}

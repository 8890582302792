import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { checkProps, GRID_FHD_COLUMNS, GRID_MOBILE_COLUMNS, GRID_XS_COLUMNS } from '../helpers';
import { VxGridItemProps } from './vx-grid-props';

export const GridItem = styled.div<VxGridItemProps>`
  display: grid;
  ${(props) => `{grid-column: span ${props.columns ? props.columns : GRID_FHD_COLUMNS};}`}
  ${(props) => `{background-color:  ${props.debug ? vxAppColors.brand.secondary.dark : 'transparent'};}`}
  
  width: 100%;
  height: ${(props) => (props.height ? props.height : 'fit-content')};

  @media (min-width: 1440px) and (max-width: 1919px) {
    ${(props) =>
      `{grid-column: span ${
        checkProps(props.columns, props.xlg) ? checkProps(props.columns, props.xlg) : GRID_FHD_COLUMNS
      };}`}
  }

  @media (min-width: 1366px) and (max-width: 1439px) {
    ${(props) =>
      `{grid-column: span ${
        checkProps(props.columns, props.lg) ? checkProps(props.columns, props.lg) : GRID_FHD_COLUMNS
      };}`}
  }

  @media (min-width: 1280px) and (max-width: 1365px) {
    ${(props) =>
      `{grid-column: span ${
        checkProps(props.columns, props.md) ? checkProps(props.columns, props.md) : GRID_FHD_COLUMNS
      };}`}
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    ${(props) => `{grid-column: span ${props.sm ? props.sm : GRID_XS_COLUMNS};}`}
  }

  @media (max-width: 1023px) {
    ${(props) => `{grid-column: span ${props.xs ? props.xs : GRID_MOBILE_COLUMNS};}`}
  }
`;

import styled from 'styled-components';

export type PaginationProps = {
  isActive: boolean;
  color?: string;
  paginationInside: boolean;
};

export const Pagination = styled.div<PaginationProps>`
  width: ${(props) => (props.isActive ? 17 : 4)}px;
  height: 4px;
  border-radius: 100px;
  background: ${(props) => props.color};
  margin: 0 2px;
  transition: 0.2s;
  ${(props) => (props.paginationInside ? 'transform: translateY(-18px)' : '')};
  ${(props) => (props.paginationInside ? 'z-index: 100' : '')};
  &:hover {
    cursor: pointer;
  }
`;

export const PaginationArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
`;

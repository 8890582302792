import { SessionStorageEnum } from './session-storage-enum';

export const setSessionStorage = (key: SessionStorageEnum, value: string) => {
  const encodeItem = window.btoa(value);
  sessionStorage.setItem(key, encodeItem);
};
export const getSessionStorage = (key: SessionStorageEnum) => {
  var item = sessionStorage.getItem(key);
  if (item !== null) {
    const decode = window.atob(item);
    return decode;
  }
  return item;
};

export const isEmptystorage = (key: SessionStorageEnum): boolean => {
  return getSessionStorage(key) === null;
};
export const isNotEmptystorage = (key: SessionStorageEnum): boolean => {
  return getSessionStorage(key) !== null;
};

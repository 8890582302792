import { TableBody } from '../table/components/table-body';
import { TableHead } from '../table/components/table-head';
import { GuaranteeAssetListProps } from './guarantee-asset-list-props';
import { GuaranteeAssetListStyle } from './styles';

export const GuaranteeAssetList = ({ assetList }: GuaranteeAssetListProps) => {
  return (
    <GuaranteeAssetListStyle>
      <TableHead />
      <TableBody assetList={assetList} />
    </GuaranteeAssetListStyle>
  );
};

import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { VxBilletType } from '../..';

export type BillitItemProps = {
  shouldNavigate: boolean;
  type: VxBilletType;
};

export const BilletItemContainer = styled.div<BillitItemProps>`
  display: flex;
  height: ${(props) => (props.type === 'modal' ? 68 : 71)}px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.shouldNavigate ? 'pointer' : 'default')};
  border: 0.5px solid transparent;
  padding-inline: 8px;
  ${(props) =>
    props.shouldNavigate &&
    `:hover {
    background-color: ${vxAppColors.brand.secondary.lightest};
    border-radius: 8px;
    border: 0.5px solid ${vxAppColors.brand.secondary.principal};
    cursor: pointer;
  }`}
  @media (max-width: ${DESKTOP_XS}px) {
    height: 40px;
    padding-inline: 0px;
  }
`;

export const BilletItemIconAndSymbolAndCompanyNameContainer = styled.div<BillitItemProps>`
  display: flex;
  padding-top: ${(props) => (props.shouldNavigate ? 3 : 0)}px;
  padding-bottom: ${(props) => (props.shouldNavigate ? 3 : 0)}px;
  align-items: center;
`;

export const BilletItemSymbolAndCompanyName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8px;
  gap: 4px;
`;

export const BilletItemLastTradeAndDailyOscillationAndNavigationContainer = styled.div<BillitItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => (props.shouldNavigate ? 0 : 1.5)}px;
  padding-bottom: ${(props) => (props.shouldNavigate ? 0 : 1.5)}px;
`;

export const BilletItemLastTradeAndDailyOscillation = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
`;

export const BilletItemIconContainer = styled.div`
  margin-left: 32px;
  cursor: pointer;
  @media (max-width: ${DESKTOP_XS}px) {
    margin-left: 12px;
  }
`;

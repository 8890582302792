import { VXRoundedSkeleton } from 'presentation/components/micro-components/vx-skeleton/rounded/style';
import { VXTextSkeleton } from 'presentation/components/micro-components/vx-skeleton/text/styles';

import React from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { HomeAssetsSkeletonStyle } from './styles';

export const HomeAssetsSkeleton: React.FC = () => {
  const breakpoint = useMediaQuery('(min-width: 1550px)');
  return (
    <HomeAssetsSkeletonStyle>
      <thead>
        <tr>
          {(breakpoint ? Array.from(Array(5).keys()) : Array.from(Array(3).keys())).map((_, i) => (
            <th key={i}>
              <VXTextSkeleton height={24} width={70} skeletonColor="light" />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from(Array(7).keys()).map((_, i) => {
          return (
            <tr key={i}>
              <td>
                <div className="asset-info">
                  <VXRoundedSkeleton size={40} skeletonColor="light" />
                  <div>
                    <VXTextSkeleton height={24} width={80} skeletonColor="light" />
                    <VXTextSkeleton height={24} width={90} skeletonColor="light" />
                  </div>
                </div>
              </td>
              <td>
                <div className="quotation-info">
                  <VXTextSkeleton height={24} width={116} skeletonColor="light" />
                  {!breakpoint && <VXTextSkeleton height={24} width={116} skeletonColor="light" />}
                </div>
              </td>
              {breakpoint && (
                <td>
                  <div className="mini-chart-container">{/* TODO: CHART SKELETON AQUI */}</div>
                </td>
              )}

              {breakpoint && (
                <td>
                  <VXTextSkeleton height={24} width={116} skeletonColor="light" />
                </td>
              )}
              <td>
                <VXTextSkeleton height={40} width={100} skeletonColor="light" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </HomeAssetsSkeletonStyle>
  );
};

import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { ExtractsRepository } from 'domain/repositories/extract-repository';
import { ExtractProps } from 'presentation/pages/profile/subpages/extract/components/extract-table';

export interface LoadExtractUsecaseParams {
  startDate: string;
  endDate: string;
  releaseType: string;
}
export interface LoadExtractsUsecase {
  execute(params: LoadExtractUsecaseParams): Promise<Either<Failure, ExtractProps[]>>;
}

export class LoadExtractsUsecaseImpl implements LoadExtractsUsecase {
  constructor(readonly repository: ExtractsRepository) {
    Object.freeze(this);
  }
  async execute(params: LoadExtractUsecaseParams): Promise<Either<Failure, ExtractProps[]>> {
    return this.repository.loadExtracts(params);
  }
}

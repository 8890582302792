import styled from 'styled-components';

export const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 40px;
  > div > button {
    cursor: pointer;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    width: 40px;
    height: 40px;
    padding: 16px;

    @media (max-width: 1919px) {
      width: 32px;
      height: 32px;
    }
  }
  width: 116px;
`;

export const CurrencySymbolAndNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 28px;
`;

type AssetInfoResponseModelParams = {
  id?: string;
  symbol?: string;
  companyName?: string;
  assetProfile?: string;
  assetType?: string;
  assetMarket?: string;
  description?: string;
};

export class AssetInfoResponseModel {
  readonly id?: string;
  readonly symbol?: string;
  readonly companyName?: string;
  readonly assetProfile?: string;
  readonly assetType?: string;
  readonly assetMarket?: string;
  readonly description?: string;
  constructor(params: AssetInfoResponseModelParams) {
    let newSymbol = params.symbol;
    if ((newSymbol ?? '') !== '' && newSymbol?.includes('/')) {
      const slashIndex = newSymbol?.indexOf('/');
      newSymbol = newSymbol.substring(0, slashIndex);
    }
    this.id = params.id;
    this.symbol = newSymbol;
    this.companyName = params.companyName;
    this.assetProfile = params.assetProfile;
    this.assetType = params.assetType;
    this.assetMarket = params.assetMarket;
    this.description = params.description;
    Object.freeze(this);
  }
}

type AssetsInfoResponseModelParams = {
  success?: boolean;
  data?: AssetInfoResponseModel[];
};

export class AssetsInfoResponseModel {
  readonly success?: boolean;
  readonly data?: AssetInfoResponseModel[];
  constructor(params: AssetsInfoResponseModelParams) {
    this.success = params.success;
    this.data = params.data;
    Object.freeze(this);
  }
  static fromJson(json: any): AssetsInfoResponseModel {
    return new AssetsInfoResponseModel({
      success: json.success,
      data: json.data.map((d: any) => new AssetInfoResponseModel(d)),
    });
  }
}

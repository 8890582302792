import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import sourceSansProNormalWoff from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-300.woff';
import sourceSansProNormalWoff2 from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-300.woff2';
import sourceSansProBoldWoff from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-600.woff';
import sourceSansProBoldWoff2 from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-600.woff2';
import sourceSansPro700Woff from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff';
import sourceSansPro700Woff2 from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff2';
import sourceSansProRegularWoff from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff';
import sourceSansProRegularWoff2 from 'assets/fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff2';
import React from 'react';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodysm: true;
    bodyxs: true;
    placeholder: true;
    buttontypeMd: true;
    buttontypeLg: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  bodysm: React.CSSProperties;
  bodyxs: React.CSSProperties;
  placeholder: React.CSSProperties;
  buttontypeMd: React.CSSProperties;
  buttontypeLg: React.CSSProperties;
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
    h1: {
      fontSize: '3rem',
      lineHeight: '100%',
      letterSpacing: '-1.5%',
      fontWeight: undefined,
    },
    h2: {
      fontSize: '2.5rem',
      lineHeight: '120%',
      letterSpacing: '-1.5%',
      fontWeight: undefined,
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '120%',
      letterSpacing: '-1.5%',
      fontWeight: undefined,
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '120%',
      letterSpacing: '-1.5%',
      fontWeight: undefined,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: '120%',
      letterSpacing: '-1.5%',
      fontWeight: undefined,
    },
    body1: {
      fontSize: '1.125rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
    body2: {
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
    bodysm: {
      fontSize: '0.875rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
    bodyxs: {
      fontSize: '0.75rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
    placeholder: {
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
    buttontypeLg: {
      fontSize: '0.875rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
    buttontypeMd: {
      fontSize: '0.75rem',
      lineHeight: '150%',
      letterSpacing: '0%',
      fontWeight: undefined,
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 300;
          src: local(''),
              url(${sourceSansProNormalWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
              url(${sourceSansProNormalWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
        /* source-sans-pro-regular - latin */
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          src: local(''),
              url(${sourceSansProRegularWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
              url(${sourceSansProRegularWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
        /* source-sans-pro-600 - latin */
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 600;
          src: local(''),
              url(${sourceSansProBoldWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
              url(${sourceSansProBoldWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
        /* source-sans-pro-700 - latin */
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 700;
          src: local(''),
              url(${sourceSansPro700Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
              url(${sourceSansPro700Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
      `,
    },
  },
});

import { VxAppColor } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

interface ButtonGhostProps {
  width?: number;
  size: 'small' | 'regular';
  hoverColor: VxAppColor;
  prefixIconInvertStrokeWithFill: boolean;
  sufixIconInvertStrokeWithFill: boolean;
}

export const Button = styled.button<ButtonGhostProps>`
  all: unset;
  cursor: pointer;
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '34px';
      default:
        return '37px';
    }
  }};
  :hover {
    span {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;

export const Content = styled.div<ButtonGhostProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  gap: 8px;
  padding: ${({ width }) => (width ? 0 : 8)}px;
  width: 100%;
  height: 100%;
  :hover {
    div.prefix-icon-container {
      svg > path {
        ${({ prefixIconInvertStrokeWithFill, hoverColor }) => {
          if (prefixIconInvertStrokeWithFill) {
            return `stroke: ${hoverColor}`;
          }
          return `fill: ${hoverColor}`;
        }}
      }
    }
    div.sufix-icon-container {
      svg > path {
        ${({ sufixIconInvertStrokeWithFill, hoverColor }) => {
          if (sufixIconInvertStrokeWithFill) {
            return `stroke: ${hoverColor}`;
          }
          return `fill: ${hoverColor}`;
        }}
      }
    }
  }
`;

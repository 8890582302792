import { makeLoadCompanyNameBySymbolUsecase } from 'main/factories/domain/load-company-name-by-symbol-usecase-factory';
import { useEffect, useState } from 'react';
import { Currencys } from '../slices/settings.store';

export type UseCompanyNameParams = {
  symbol: string;
};

export function useCompanyName({ symbol }: UseCompanyNameParams) {
  const [companyName, setCompanyName] = useState<string>(symbol);
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (symbol === Currencys.real.symbol || symbol === Currencys.dolar.symbol) {
      setCompanyName(symbol === Currencys.real.symbol ? Currencys.real.companyName : Currencys.dolar.companyName);
    } else {
      setLoading(true);
      makeLoadCompanyNameBySymbolUsecase()
        .execute(symbol)
        .then((companyNameOrError) => {
          if (companyNameOrError.isRight()) {
            setCompanyName(companyNameOrError.value);
          }
        })
        .finally(() => setLoading(false));
    }
  }, []);
  return { companyName, isLoading };
}

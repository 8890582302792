import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const TransferContainer = styled.section`
  background: ${vxAppColors.background};
  width: 100%;
  height: 100%;
`;

export const BackButtonContainer = styled.div`
  margin-top: 49px;
  margin-left: 56px;
`;

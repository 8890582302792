import { FormHelperText, Input, InputAdornment, InputLabel, useFormControl } from '@mui/material';
import { formatDouble } from 'data/utils/currency-util';
import { ReactNode, useEffect } from 'react';
import { VxInputProps } from '..';
import { HelperContainer } from '../style';

type VxInputCoreProps = Omit<VxInputProps, 'status'> & {
  setFullSize: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VxInputCore: React.FC<VxInputCoreProps> = ({
  id,
  label,
  placeHolder,
  value,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  helperText = '',
  endIcon,
  helpIcon,
  setFullSize,
  type,
}) => {
  const { focused } = useFormControl() || {};
  useEffect(() => {
    setFullSize(focused || value !== '' || disabled);
  }, [focused, value, disabled]);
  let displayValue = value;
  if (type === 'currency') {
    displayValue = formatDouble(Number(value) ?? 0, {
      minimumDecimalPlaces: 2,
    });
  }
  return (
    <>
      <InputLabel htmlFor={id}>{focused || value !== '' ? label : placeHolder ?? label}</InputLabel>
      <Input
        id={id}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete="off"
        autoCapitalize="off"
        autoSave="off"
        value={disabled ? undefined : value !== '' && type === 'currency' ? displayValue : value}
        onChange={(event) => {
          if (!disabled) {
            if (isNaN(parseFloat(event.target.value)) && (type === 'currency' || type === 'numeric')) {
              return;
            }
            onChange?.(event);
          }
        }}
        endAdornment={endIcon as ReactNode}
        startAdornment={
          (type === 'currency' && focused) || (type === 'currency' && value !== '') ? (
            <InputAdornment position="start">R$</InputAdornment>
          ) : undefined
        }
        inputProps={{
          inputMode: type === 'text' ? undefined : 'numeric',
          pattern: type === 'text' ? undefined : '[0-9]*',
        }}
      />
      <FormHelperText id={id}>
        {helpIcon === undefined ? (
          helperText
        ) : (
          <HelperContainer>
            {helpIcon}
            {helperText}
          </HelperContainer>
        )}
      </FormHelperText>
    </>
  );
};

import { OrdersHistoryCacheRepository } from 'data/repositories/cache/orders-history-cache-repository';
import { LoadOrdersReportRepository } from 'domain/repositories/load-orders-report-repository';
import { SaveOrdersReportRepository } from 'domain/repositories/save-orders-report-repository';
import { makeLocalStorageCacheDatasource } from 'main/factories/datasources/cache/cache-datasource-factory';

let ordersHistoryCacheRepository: OrdersHistoryCacheRepository | null = null;

const makeOrdersHistoryCacheRepository = (): OrdersHistoryCacheRepository => {
  if (ordersHistoryCacheRepository === null) {
    ordersHistoryCacheRepository = new OrdersHistoryCacheRepository(makeLocalStorageCacheDatasource());
  }
  return ordersHistoryCacheRepository;
};

export const makeLoadOrdersReportRepository = (): LoadOrdersReportRepository => makeOrdersHistoryCacheRepository();

export const makeSaveOrdersReportRepository = (): SaveOrdersReportRepository => makeOrdersHistoryCacheRepository();

import { FinancialHttpRepository } from 'data/repositories/http/financial-http-repository';
import { Failure } from 'domain/failure/failure';
import { SaveDepositUsecase, SaveDepositUsecaseImp } from 'domain/usecases/save-deposit-usecase';
import { makeCryptoHttpDatasource } from '../datasources/http/http-datasource-factory';

let saveDepositUsecase: SaveDepositUsecase | null = null;

export const makeSaveDepositUsecase = (): SaveDepositUsecase => {
  if (saveDepositUsecase === null) {
    const baseUrl = process.env.REACT_APP_API_CRYPTO_GATEWAY ?? '';
    if (baseUrl === '') {
      throw new Failure('Variável de ambiente ("REACT_APP_API_CRYPTO_GATEWAY") não foi encontrada');
    }
    const repository = new FinancialHttpRepository(baseUrl, makeCryptoHttpDatasource());
    saveDepositUsecase = new SaveDepositUsecaseImp(repository);
  }
  return saveDepositUsecase;
};

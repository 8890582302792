import { useMediaQuery } from '@mui/material';
import { RoutesEnum } from 'config/routes/router-enum';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { useBillet } from 'presentation/stores/hooks/use-billet';
import { Link } from 'react-router-dom';

export function ResponsiveButtons() {
  const { showModal } = useBillet();
  const isMobile = useMediaQuery('(max-width: 1366px)');

  if (isMobile) {
    return (
      <>
        <VxButton
          text="COMPRAR/VENDER"
          type="primary"
          size="small"
          disabled={false}
          onClick={() => showModal()}
          width={114}
        />
        <Link to={RoutesEnum.depositWithdraw}>
          <VxButton
            text="DEPOSITAR/SACAR"
            type="secondary"
            size="small"
            disabled={false}
            onClick={() => null}
            width={114}
          />
        </Link>
      </>
    );
  }

  return (
    <>
      <VxButton
        text="COMPRAR/VENDER"
        type="primary"
        size="small"
        disabled={false}
        onClick={() => showModal()}
        width={180}
      />
      <Link to={RoutesEnum.depositWithdraw}>
        <VxButton
          text="DEPOSITAR/SACAR"
          type="secondary"
          size="small"
          disabled={false}
          onClick={() => null}
          width={180}
        />
      </Link>
    </>
  );
}

import {
  ListenQuotationBySymbolUsecase,
  ListenQuotationBySymbolUsecaseImp,
} from 'domain/usecases/listen-quotation-by-symbol-usecase';
import { makeCryptoListenQuotationBySymbolRepository } from '../repositories/ws/quotation-ws-repository-factory';

let cryptoListenQuotationBySymbolUsecase: ListenQuotationBySymbolUsecase | null = null;

export const makeCryptoListenQuotationBySymbolUsecase = (): ListenQuotationBySymbolUsecase => {
  if (cryptoListenQuotationBySymbolUsecase === null) {
    cryptoListenQuotationBySymbolUsecase = new ListenQuotationBySymbolUsecaseImp(
      makeCryptoListenQuotationBySymbolRepository()
    );
  }
  return cryptoListenQuotationBySymbolUsecase;
};

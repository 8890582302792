import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

interface ButtonProps {
  btnType: 'primary' | 'secondary' | 'tertiary' | 'callToAction';
  disabled: boolean;
  width?: number;
  size: 'extraSmall' | 'small' | 'regular' | 'large';
}

export const Button = styled.button<ButtonProps>`
  all: unset;
  cursor: pointer;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${({ size }) => {
    switch (size) {
      case 'extraSmall':
        return '40px';
      case 'small':
        return '40px';
      case 'regular':
        return '40px';
      case 'large':
        return '100px';
      default:
        return '40px';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'extraSmall':
        return '34px';
      case 'small':
        return '40px';
      case 'regular':
        return '48px';
      case 'large':
        return '56px';
      default:
        return '40px';
    }
  }};
  width: ${({ size, width }) => {
    if (width) return `${width}px`;
    switch (size) {
      case 'extraSmall':
        return '72px';
      case 'small':
        return '160px';
      case 'regular':
        return '180px';
      case 'large':
        return '180px';
      default:
        return '160px';
    }
  }};
  background-color: ${({ btnType }) => {
    switch (btnType) {
      case 'primary':
        return vxAppColors.brand.tertiary.principal;
      case 'secondary':
        return vxAppColors.neutral.white;
      case 'callToAction':
        return vxAppColors.feedback.success.default;
      case 'tertiary':
        return vxAppColors.brand.primary.principal;
    }
  }};
  border: ${({ btnType }) => (btnType === 'tertiary' ? `1px solid ${vxAppColors.brand.secondary.principal}` : 'none')};
  :disabled {
    cursor: default;
    opacity: 0.3;
  }
  :hover {
    background-color: ${({ btnType }) => {
      switch (btnType) {
        case 'secondary':
          return vxAppColors.neutral.lightest;
        case 'callToAction':
          return vxAppColors.button.hover.callToAction;
        default:
          return vxAppColors.brand.primary.principal;
      }
    }};
  }
`;

export const Content = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  gap: 16px;
  border-radius: ${({ size }) => {
    switch (size) {
      case 'small':
        return '40px';
      case 'regular':
        return '40px';
      case 'large':
        return '100px';
      default:
        return '40px';
    }
  }};
  padding: ${({ size, width }) => {
    if (width) return '0px';
    switch (size) {
      case 'extraSmall':
        return '8px';
      case 'small':
        return '11px 16px';
      case 'regular':
        return '16px';
      case 'large':
        return '19px 16px';
      default:
        return '40px';
    }
  }};
  width: 100%;
  height: 100%;
`;

import { Either } from 'domain/entities/either';
import { ProductBoughtEntity } from 'domain/entities/product-bought-entity';
import { Failure } from 'domain/failure/failure';
import { LoadProductsBoughtRepository } from 'domain/repositories/load-products-bought-usecase';

export interface LoadProductsBoughtUsecase {
  execute(): Promise<Either<Failure, ProductBoughtEntity[]>>;
}

export class LoadProductsBoughtUsecaseImp implements LoadProductsBoughtUsecase {
  constructor(readonly repository: LoadProductsBoughtRepository) {
    Object.freeze(this);
  }
  execute(): Promise<Either<Failure, ProductBoughtEntity[]>> {
    return this.repository.boughtProducts();
  }
}

import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { PositionWsRepository } from 'data/repositories/ws/position-ws-repository';
import { SignalRWsDatasource } from 'datasources/ws/signalr-ws-datasource';

import { Failure } from 'domain/failure/failure';

let repositoryCrypto: PositionWsRepository | null = null;
export const makeCryptoPositionRepository = (): PositionWsRepository => {
  if (repositoryCrypto) {
    return repositoryCrypto;
  }
  const socketUrl = process.env.REACT_APP_WS_POSITIONS_CRYPTO ?? '';
  if (socketUrl === '') {
    throw new Failure('Falta definir variável de ambiente ("REACT_APP_WS_POSITIONS_CRYPTO")');
  }
  const hubConnection = new HubConnectionBuilder()
    .withUrl(socketUrl, {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
    })
    .build();

  repositoryCrypto = new PositionWsRepository(new SignalRWsDatasource(hubConnection));
  return repositoryCrypto;
};

import { RoutesEnum } from 'config/routes/router-enum';

import React from 'react';
import { isMobile } from 'react-device-detect';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute: React.FunctionComponent<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  if (isMobile) {
    return <Navigate to={RoutesEnum.mobile} />;
  } else if (isAuthenticated) {
    return <Outlet />;
  }
  window.location.href = process.env.REACT_APP_GENIAL_REDIRECT_URL as string;
  return null;
};

export { PrivateRoute };

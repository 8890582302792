import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody.stories';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { TableHead } from '../../table/components/table-head';
import { AsideButton } from './components/aside-button';
import { AsideText } from './components/aside-text';
import { AsideTitle } from './components/aside-title';

import * as S from './styles';

const MarginFeedbackEmpty = () => {
  return (
    <VxGridContainer>
      <VxGridItem columns={8} sm={5}>
        <S.Container>
          <H4 title="Ativos em garantia" color={vxAppColors.brand.primary.darkest} fontWeight="light" />
          <S.EmptyTable>
            <TableHead />
          </S.EmptyTable>

          <S.ErrorMessage>
            <H4
              title="Você ainda não possui ativos alocados"
              color={vxAppColors.brand.primary.darkest}
              fontWeight="medium"
            />
            <VxBodySm
              title="Quando fizer, ela aparecerá aqui"
              color={vxAppColors.brand.primary.darkest}
              fontWeight="regular"
            />
          </S.ErrorMessage>
        </S.Container>
      </VxGridItem>

      <VxGridItem columns={4} sm={3}>
        <S.Container>
          <S.AsideCard>
            <div>
              <AsideTitle />
            </div>

            <AsideText />

            <AsideButton />
          </S.AsideCard>
        </S.Container>
      </VxGridItem>
    </VxGridContainer>
  );
};

export { MarginFeedbackEmpty };

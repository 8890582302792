import { AssetChartPointData } from 'domain/entities/chart-asset-data-entity';
import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadAssetChartRepository } from 'domain/repositories/load-chart-asset-data-repository';
import { ChartInterval, Currency } from 'domain/types';

export type LoadAssetChartUsecaseParams = {
  symbol: string;
  interval: ChartInterval;
  currency: Currency;
};

export interface LoadAssetChartUsecase {
  execute(params: LoadAssetChartUsecaseParams): Promise<Either<Failure, AssetChartPointData[]>>;
}

export class LoadAssetChartUsecaseImp implements LoadAssetChartUsecase {
  constructor(readonly repository: LoadAssetChartRepository) {
    Object.freeze(this);
  }
  async execute(params: LoadAssetChartUsecaseParams): Promise<Either<Failure, AssetChartPointData[]>> {
    return this.repository.assetChart(params);
  }
}

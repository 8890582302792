import { ThemeProvider } from '@mui/material';
import { initializeFirebase } from 'datasources/firebase/firebase';
import { rootStore } from 'presentation/stores';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ModalProvider } from 'react-modal-hook';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TransitionGroup } from 'react-transition-group';
import { theme } from 'styles/global';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
initializeFirebase();
document.title = process.env.REACT_APP_TITLE as string;
root.render(
  <React.StrictMode>
    <Provider store={rootStore}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ModalProvider rootComponent={TransitionGroup}>
            <App />
          </ModalProvider>
          <ToastContainer />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

class StringUtils {
  static capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  static formatRentability({ isCrypto = false, value = '' }): string {
    const stringToArray = value.split(',');
    const numberOfDecimalPlaces = isCrypto ? 8 : 2;

    const decimalPlaces = stringToArray.length > 1 ? stringToArray[1].slice(0, numberOfDecimalPlaces) : false;

    const newValue = decimalPlaces ? stringToArray[0].concat(',', decimalPlaces) : value;

    return newValue;
  }
}

export { StringUtils };

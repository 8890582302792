import { useMediaQuery } from '@mui/material';
import { removeSlashFromSymbol } from 'data/utils/symbol-util';
import { OrderDataEntity } from 'domain/entities/order-entity';
import json2mq from 'json2mq';
import { makeLoadRecentOrdersUsecase } from 'main/factories/domain/load-recent-orders-factory';
import { vexterToastWarning } from 'presentation/components/micro-components/toast';

import { VxBodySm, VxButtonTypeLg } from 'presentation/styleguide/typography/body/VxBody';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useEffect, useState } from 'react';
import OrderDataEntityError from './components/error';
import { OrderItem } from './components/order-item';
import { OrderDataEntitySkeleton } from './components/recent-orders-skeleton';
import * as Styles from './styles';

export function OrderDataComponent() {
  const [orderData, setOrderData] = useState<OrderDataEntity[]>([]);
  const [loadOrderData, setLoadOrderDat] = useState(true);
  const [loadRecentError, setLoadRecentError] = useState(false);
  const isExceptionalForMobileAndSmallDesktop = useMediaQuery(json2mq({ minWidth: 300, maxWidth: 1440 }));
  useEffect(() => {
    setLoadOrderDat(true);
    makeLoadRecentOrdersUsecase()
      .execute({
        startAt: new Date(Date.now()),
        endAt: new Date(Date.now()),
        sides: [],
        statuses: [],
      })
      .then((ordersError) => {
        if (ordersError.isRight()) {
          const orderResult = ordersError.value;
          orderResult.forEach((order) => (order.symbol = removeSlashFromSymbol(order.symbol)));
          setOrderData(orderResult);
          setLoadRecentError(false);
          setLoadOrderDat(false);
        } else {
          setLoadRecentError(true);
          setLoadOrderDat(false);
        }
      })
      .catch(() => {
        setLoadRecentError(true);
      });

    return () => {
      setOrderData([]);
    };
  }, []);
  const ordersByDate = orderData
    .sort((objA, objB) => new Date(objA.updatedAt).getTime() - new Date(objB.updatedAt).getTime())
    .slice(0, 5);

  const showErrorMessage = (loadRecentError && !loadOrderData) || (!ordersByDate.length && !loadOrderData);

  return (
    <Styles.Container>
      <div className="head-info">
        {isExceptionalForMobileAndSmallDesktop ? (
          <VxBodySm title="Ordens recentes" fontWeight="light" color={vxAppColors.brand.primary.darkest} />
        ) : (
          <H4 title="Ordens recentes" fontWeight="light" color={vxAppColors.brand.primary.darkest} />
        )}
        {!showErrorMessage && (
          <Styles.Button onClick={() => vexterToastWarning({ title: 'Em breve' })}>
            <VxButtonTypeLg title="VER TUDO" fontWeight="medium" color={vxAppColors.brand.tertiary.principal} />
          </Styles.Button>
        )}
      </div>

      {loadOrderData && <OrderDataEntitySkeleton />}

      {showErrorMessage && <OrderDataEntityError />}

      {!loadRecentError &&
        !loadOrderData &&
        orderData &&
        ordersByDate.map((order, index) => {
          const previousIndex = index ? index - 1 : index;
          const previousDate = ordersByDate[previousIndex].updatedAt;
          const showDateExeception = index === 0;

          return (
            <OrderItem
              key={index}
              symbol={order.symbol}
              updatedAt={new Date(order.updatedAt)}
              previousDate={new Date(previousDate)}
              showDateExeception={showDateExeception}
              status={order.status}
              isExceptionalForMobileAndSmallDesktop={isExceptionalForMobileAndSmallDesktop}
            />
          );
        })}
    </Styles.Container>
  );
}

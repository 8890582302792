import { Typography } from '@mui/material';
import { VxAppColor } from 'presentation/styleguide/vx-colors-style';

type typographProps = {
  title: string;
  color: VxAppColor;
  /**
   * light = 300;
   *
   * regular = 400;
   *
   * medium = 600;
   *
   * bold = 700;
   */
  fontWeight: 'light' | 'regular' | 'medium' | 'bold';
};

const _getFontWeight = (fontWeight: string) => {
  switch (fontWeight) {
    case 'light':
      return 300;
    case 'regular':
      return 400;
    case 'medium':
      return 600;
    case 'bold':
      return 700;
  }
};

export function H1(props: typographProps) {
  return (
    <Typography variant="h1" color={props.color} fontWeight={_getFontWeight(props.fontWeight)}>
      {props.title}
    </Typography>
  );
}

export function H2(props: typographProps) {
  return (
    <Typography variant="h2" color={props.color} fontWeight={_getFontWeight(props.fontWeight)}>
      {props.title}
    </Typography>
  );
}

export function H3(props: typographProps) {
  return (
    <Typography variant="h3" color={props.color} fontWeight={_getFontWeight(props.fontWeight)}>
      {props.title}
    </Typography>
  );
}

export function H4(props: typographProps) {
  return (
    <Typography variant="h4" color={props.color} fontWeight={_getFontWeight(props.fontWeight)}>
      {props.title}
    </Typography>
  );
}

export function H5(props: typographProps) {
  return (
    <Typography variant="h5" color={props.color} fontWeight={_getFontWeight(props.fontWeight)}>
      {props.title}
    </Typography>
  );
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const AssetItemStyle = styled.tr`
  height: 80px;
  :last-child {
    border-bottom: none;
  }
  td {
    vertical-align: middle;
    text-align: left;
    padding-left: 8px;
    :nth-child(1) {
      padding-left: 16px;
    }
    :nth-child(3) {
      position: relative;
    }
    @media (max-width: 1550px) {
      :nth-child(2) {
        text-align: end;
      }
    }
    @media (max-width: 1180px) {
      :nth-child(2) {
        padding-right: 50px;
      }
    }
    :last-child {
      text-align: right;
    }
    .asset-info {
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .asset-margin-info {
        display: flex;
        gap: 4px;
        align-items: center;
        .asset-margin-dot {
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: ${vxAppColors.brand.primary.principal};
          margin: 2px;
        }
      }
    }
    .mini-chart-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      position: absolute;
      top: 68%;
      transform: translateY(-50%);
    }
  }
`;

import { formatCurrency, formatDouble } from 'data/utils/currency-util';
import { AssetImage } from 'presentation/components/asset-image';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';

import { VxBodyLg, VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useMediaQuery } from 'usehooks-ts';
import { VxBilletSide, VxBilletType } from '../..';
import { BilletContainer, ButtonContainer } from '../../style';
import { VxBilletConfirmationTitle } from './components/vx-billet-confirmation-title';
import {
  AssetContainer,
  AssetIconAndSymbolAndCompanyNameContainer,
  AssetQuantityContainer,
  AssetSymbolAndCompanyNameContainer,
  BilletConfirmationDivider,
  ButtonErrorBackground,
  CancelAndConfirmContainer,
  TitleContainer,
  ValueContainer,
} from './style';

export type VxBilletConfirmationProps = {
  symbol: string;
  quantity: number;
  value: number;
  side: VxBilletSide;
  type: VxBilletType;
  onClose?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  onEdit: () => void;
};

export const VxBilletConfirmation: React.FC<VxBilletConfirmationProps> = ({
  onClose,
  symbol,
  quantity,
  value,
  side,
  type,
  onEdit,
  onCancel,
  onConfirm,
}) => {
  const { companyName } = useCompanyName({ symbol });
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  let titleInfo = '';
  if (type === 'margin') {
    titleInfo = side === 'buyOrBlock' ? 'a alocação?' : 'o desbloqueio?';
  } else {
    titleInfo = `a ordem de ${side === 'buyOrBlock' ? 'compra' : 'venda'} abaixo?`;
  }
  return (
    <BilletContainer>
      <VxBilletConfirmationTitle
        type={type}
        title={type === 'modal' ? '' : `Você confirma ${titleInfo}`}
        onClose={onClose}
      />
      {type === 'modal' && (
        <TitleContainer>
          {isSmall ? (
            <VxBodySm title={`Você confirma ${titleInfo}`} fontWeight="medium" color={vxAppColors.neutral.darkest} />
          ) : (
            <VxBodyLg title={`Você confirma ${titleInfo}`} fontWeight="medium" color={vxAppColors.neutral.darkest} />
          )}
        </TitleContainer>
      )}
      <AssetContainer type={type}>
        <AssetIconAndSymbolAndCompanyNameContainer>
          <AssetImage symbol={symbol} size={isSmall ? 'xs2' : 'xs'} />
          {isSmall ? (
            <AssetSymbolAndCompanyNameContainer>
              <VxBodyXs title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
              <VxBodyXs title={companyName} fontWeight="light" color={vxAppColors.neutral.darkest} />
            </AssetSymbolAndCompanyNameContainer>
          ) : (
            <AssetSymbolAndCompanyNameContainer>
              <VxBodyMd title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
              <VxBodySm title={companyName} fontWeight="light" color={vxAppColors.neutral.darkest} />
            </AssetSymbolAndCompanyNameContainer>
          )}
        </AssetIconAndSymbolAndCompanyNameContainer>
        <AssetQuantityContainer>
          {isSmall ? (
            <>
              <VxBodyXs title="Quantidade" fontWeight="medium" color={vxAppColors.neutral.darkest} />
              <VxBodyXs
                title={formatDouble(quantity, { minimumDecimalPlaces: 0 })}
                fontWeight={type === 'margin' ? 'regular' : 'light'}
                color={vxAppColors.neutral.darkest}
              />
            </>
          ) : (
            <>
              <VxBodyLg title="Quantidade" fontWeight="medium" color={vxAppColors.neutral.darkest} />
              <VxBodyMd
                title={formatDouble(quantity, { minimumDecimalPlaces: 0 })}
                fontWeight={type === 'margin' ? 'regular' : 'light'}
                color={vxAppColors.neutral.darkest}
              />
            </>
          )}
        </AssetQuantityContainer>
      </AssetContainer>
      <BilletConfirmationDivider />
      <ValueContainer>
        {isSmall ? (
          <VxBodyXs
            title={`Total estimado: ${formatCurrency(value)}`}
            fontWeight="medium"
            color={vxAppColors.neutral.darkest}
          />
        ) : (
          <VxBodyMd
            title={`Total estimado: ${formatCurrency(value)}`}
            fontWeight="medium"
            color={vxAppColors.neutral.darkest}
          />
        )}
        <div onClick={onEdit}>
          <VXIcon icon={VXIconsEnum.edit} size="sm" color={vxAppColors.neutral.dark} />
        </div>
      </ValueContainer>
      <CancelAndConfirmContainer type={type}>
        <ButtonErrorBackground>
          <ButtonContainer width={isSmall ? 84 : 100}>
            <VxButton
              text="CANCELAR"
              type="primary"
              size={isSmall ? 'extraSmall' : 'small'}
              disabled={false}
              onClick={onCancel}
            />
          </ButtonContainer>
        </ButtonErrorBackground>
        <ButtonContainer width={isSmall ? 93 : 109}>
          <VxButton
            text="CONFIRMAR"
            type="primary"
            size={isSmall ? 'extraSmall' : 'small'}
            disabled={false}
            onClick={onConfirm}
          />
        </ButtonContainer>
      </CancelAndConfirmContainer>
    </BilletContainer>
  );
};

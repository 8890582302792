import { HttpDatasource, HttpMethodEnum } from 'data/datasources/http/http-datasource';
import { UrlDatasource } from 'data/datasources/url/url-datasource';
import { OrderDataResponseModel } from 'data/models/recent-orders-response-model';
import dayjs from 'dayjs';
import { orderSideIdDict, orderStatusIdDict } from 'domain/dicts';
import { OrderDataEntity } from 'domain/entities';
import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { CancelOrderRepository } from 'domain/repositories/cancel-order-repository';
import {
  LoadRecentOrdersRepository,
  LoadRecentOrdersRepositoryParams,
} from 'domain/repositories/load-recent-orders-repository';
import { SendOrderRepository, SendOrderRepositoryParams } from 'domain/repositories/send-order-repository';

export type OrdersHttpRepositoryParams = {
  urlDatasource: UrlDatasource;
  httpDatasource: HttpDatasource;
  pnpAccountLoader?: () => string;
};

export class OrdersHttpRepository implements SendOrderRepository, CancelOrderRepository, LoadRecentOrdersRepository {
  readonly urlDatasource: UrlDatasource;
  readonly httpDatasource: HttpDatasource;
  constructor(params: OrdersHttpRepositoryParams) {
    this.urlDatasource = params.urlDatasource;
    this.httpDatasource = params.httpDatasource;
    Object.freeze(this);
  }
  async sendOrder(params: SendOrderRepositoryParams): Promise<Either<Failure, void>> {
    try {
      let data: any = {
        price: params.price,
        quantity: params.quantity,
        typeId: 3,
        sideId: Number(params.side),
        symbol: params.symbol,
      };

      data = { ...data, lavarage: params.isLavarage };
      await this.httpDatasource.request(this.urlDatasource.sendOrder(), {
        method: HttpMethodEnum.post,
        data,
      });
      return right(undefined);
    } catch (e) {
      return left(new Failure('Erro ao enviar pela internet a ordem'));
    }
  }
  async cancelOrder(orderId: string): Promise<Either<Failure, void>> {
    const error = left<Failure, void>(new Failure(`Erro ao cancelar a ordem ${orderId}`));
    try {
      const response = await this.httpDatasource.request(this.urlDatasource.cancelOrder(orderId), {
        method: HttpMethodEnum.put,
      });
      if (!response.ok) {
        return error;
      }
      return right<Failure, void>(undefined);
    } catch (e) {
      return error;
    }
  }
  async recentOrders({
    sides,
    statuses,
    startAt,
    endAt,
  }: LoadRecentOrdersRepositoryParams): Promise<Either<Failure, OrderDataEntity[]>> {
    if (sides.length === 0 || statuses.length === 0) return right([]);
    const error = left<Failure, OrderDataEntity[]>(new Failure('Erro ao cancelar a ordem buscar ordens recentes'));
    try {
      const sideIdFilter =
        sides.length === Object.keys(orderSideIdDict).length
          ? ''
          : sides.map((side) => `sideIdFilter=${orderSideIdDict[side]}`).join('&');
      const statusIdFilter =
        statuses.length === Object.keys(orderStatusIdDict).length
          ? ''
          : statuses.map((status) => `statusFilters=${orderStatusIdDict[status]}`).join('&');
      const startAtDayJs = dayjs(startAt);
      const endAtDayJs = dayjs(endAt);
      const response = await this.httpDatasource.request(
        this.urlDatasource.orderDataEntity(
          sideIdFilter,
          statusIdFilter,
          startAtDayJs.format('MM-DD-YYYY'),
          endAtDayJs.format('MM-DD-YYYY')
        )
      );
      if (!response.ok) {
        return error;
      }
      const orders: OrderDataResponseModel = response.data;
      if (!orders.success) {
        return error;
      }
      return right(orders.data as any);
    } catch (_error) {
      return error;
    }
  }
}

import styled from 'styled-components';

export const ChartWithLegend = styled.div`
  display: inline-flex;
  gap: 24px;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  width: 100%;
  @media (min-width: 1025px) {
    gap: 64px;
  }
`;

export const WalletChartSubtitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 16px;

  @media (min-width: 1024px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const marginSubtitle = { margin: '4px' };

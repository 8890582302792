import styled from 'styled-components';
interface CloseButtonProps {
  isBlackContent: boolean;
}
export const CloseButton = styled.div<CloseButtonProps>`
  position: absolute;
  right: -4px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: ${(props) => (props.isBlackContent ? 'rgba(28, 28, 28, 0.4);' : 'none')};
`;

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { Area, AreaChart, XAxis, YAxis } from 'recharts';
interface AssetChartResponse {
  assetPrice: number;
  date: Date;
}

interface AssetChartProps {
  chartPositions: AssetChartResponse[];
  variation?: number;
  height?: number;
}

const formatData = (chartData: AssetChartResponse[]) =>
  chartData.map((data) => ({ date: data.date.toISOString(), assetPrice: data.assetPrice }));

const FakeArrayData = [...Array(3).keys()].map(() => ({ date: new Date().toISOString(), assetPrice: 1 }));

const AssetChart = (props: AssetChartProps) => {
  const formatedData = formatData(props.chartPositions);
  const isValidData = formatedData.length > 0;
  const data = isValidData ? formatData(props.chartPositions) : FakeArrayData;
  const maxAssetPrice = Math.max(...formatedData.map((data) => data.assetPrice));
  const minAssetPrice = Math.min(...formatedData.map((data) => data.assetPrice));

  return (
    <AreaChart
      width={160}
      height={props.height ?? 66}
      data={isValidData ? data : FakeArrayData}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorAsset" x1="0" y1="0" x2="0" y2="1">
          <stop offset="-1.17%" stopColor={vxAppColors.graph.bench1} stopOpacity={0.15} />
          <stop offset="90.5%" stopColor={vxAppColors.graph.bench1} stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        layout="horizontal"
        dataKey="assetPrice"
        stroke={vxAppColors.graph.bench1}
        fillOpacity={1}
        fill="url(#colorAsset)"
        strokeWidth={1.5}
      />
      <XAxis dataKey="date" axisLine={false} tick={false} />
      <YAxis axisLine={false} tick={false} domain={[minAssetPrice, maxAssetPrice]} />
    </AreaChart>
  );
};

export default AssetChart;

import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadCompanyNameBySymbolRepository } from 'domain/repositories/load-company-name-by-symbol-repository';
import { SaveCompanyNameBySymbolRepository } from 'domain/repositories/save-company-name-by-symbol-repository';

type LoadAssetInfoFromHttpWithCacheFallbackCompositeRepositoryParams = {
  httpLoadRepository: LoadCompanyNameBySymbolRepository;
  cacheLoadRepository: LoadCompanyNameBySymbolRepository;
  cacheSaveRepository: SaveCompanyNameBySymbolRepository;
};

export class LoadAssetInfoFromHttpWithCacheFallbackCompositeRepository implements LoadCompanyNameBySymbolRepository {
  readonly httpLoadRepository: LoadCompanyNameBySymbolRepository;
  readonly cacheLoadRepository: LoadCompanyNameBySymbolRepository;
  readonly cacheSaveRepository: SaveCompanyNameBySymbolRepository;
  constructor(params: LoadAssetInfoFromHttpWithCacheFallbackCompositeRepositoryParams) {
    this.httpLoadRepository = params.httpLoadRepository;
    this.cacheLoadRepository = params.cacheLoadRepository;
    this.cacheSaveRepository = params.cacheSaveRepository;
    Object.freeze(this);
  }
  async loadCompanyNameBySymbol(symbol: string): Promise<Either<Failure, string>> {
    let loadOrError = await this.cacheLoadRepository.loadCompanyNameBySymbol(symbol);
    if (loadOrError.isRight()) {
      return right(loadOrError.value);
    }
    loadOrError = await this.httpLoadRepository.loadCompanyNameBySymbol(symbol);
    if (loadOrError.isLeft()) {
      return loadOrError;
    }
    const saveOrError = await this.cacheSaveRepository.saveCompanyNameBySymbol(symbol, loadOrError.value);
    if (saveOrError.isLeft()) {
      return left(saveOrError.value);
    }
    return right(loadOrError.value);
  }
}

import { HubConnectionBuilder } from '@microsoft/signalr';
import { ListenPatrimonyRepositoryImp } from 'data/repositories/ws/listen-patrimony-repository';
import { SignalRWsDatasource } from 'datasources/ws/signalr-ws-datasource';
import { PatrimonyEntity } from 'domain/entities/patrimony-entity';
import { ListenPatrimonyRepository } from 'domain/repositories/listen-patrimony-repository';

export const makeWsListenPatrimonyRepository = (params: {
  url: string;
  wsResponseMapper: (data: any) => PatrimonyEntity | undefined;
  loadPnpAccount: () => any;
}): ListenPatrimonyRepository => {
  const hubConnection = new HubConnectionBuilder()
    .withUrl(params.url, {
      // logMessageContent: true,
      // logger: { log: (level, msg) => console.log(`${level} ==> ${msg}`) },
    })
    .build();
  const wsDatasource = new SignalRWsDatasource(hubConnection);
  return new ListenPatrimonyRepositoryImp(wsDatasource, params.wsResponseMapper, params.loadPnpAccount);
};

export function symbolFromFractional(value: string): string {
  if (value.toUpperCase().endsWith('F')) {
    return value.substring(0, -1);
  }
  return value;
}

export function removeSlashFromSymbol(value: string): string {
  if (value !== '' && value.includes('/')) {
    const slashIndex = value.indexOf('/');
    return value.substring(0, slashIndex);
  }
  return value;
}

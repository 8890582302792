import { UrlDatasource } from '../../data/datasources/url/url-datasource';

export class CryptoUrlDatasource implements UrlDatasource {
  constructor(readonly baseUrl: string, readonly baseUrlOrders: string, readonly baseUrlPositions: string) {
    Object.freeze(this);
  }
  addFavorite(id: string): string {
    return `${this.baseUrl}/Assets/v1/favorite/symbols/${id}`;
  }
  removeFavorite(id: string): string {
    return `${this.baseUrl}/Assets/v1/favorite/symbols/${id}`;
  }
  loadFavorites(): string {
    return `${this.baseUrl}/Assets/v1/favorites`;
  }
  loadHighests(): string {
    return `${this.baseUrl}/Quotes/v1/highest/BRL`;
  }
  loadMostTraded(): string {
    return `${this.baseUrl}/Quotes/v1/most-traded/BRL`;
  }
  assetInfoBySymbol(symbol: string): string {
    return `${this.baseUrl}/Assets/v1/info?symbols=${symbol}/BRL`;
  }
  companyName(symbol: string): string {
    return `${this.baseUrl}/Assets/v1/info?symbols=${symbol}/BRL`;
  }
  miniChart(symbols: string[]): string {
    return `${this.baseUrl}/Charts/v1/history/aggregate?${symbols
      .map((symbol, index) => `symbols[${index}].Symbol=${symbol}&symbols[${index}].CurrencyId=BRL`)
      .join('&')}`;
  }
  searchAssets(term: string): string {
    return `${this.baseUrl}/Assets/v1?searchTerm=${term}`;
  }
  orderDataEntity(sideIdFilter: string, statusFilters: string, startAt: string, endAt: string): string {
    return `${this.baseUrl}/Order/v1?minDate=${startAt}&maxDate=${endAt}${
      sideIdFilter !== '' ? `&${sideIdFilter}` : ''
    }${statusFilters !== '' ? `&${statusFilters}` : ''}`;
  }
  loadPositions(): string {
    return `${this.baseUrlPositions}/simulator/Patrimony/v1/positions`;
  }
  loadExtracts(startDate: string, endDate: string, releaseType: string): string {
    return `${this.baseUrl}/Financial/v1/statement/${startDate}/${endDate}?${releaseType}`;
  }
  sendOrder(): string {
    return `${this.baseUrl}/Order/v1`;
  }
  assetChart(symbol: string, interval: number, currency: string): string {
    return `${this.baseUrl}/Charts/v1/history?symbol=${symbol}&currencyId=${currency}&interval=${interval}`;
  }
  cancelOrder(orderId: string): string {
    return `${this.baseUrl}/Order/v1/${orderId}/cancel`;
  }
}

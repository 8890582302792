import { OrdersReport } from 'domain/entities';
import { makeLoadOrdersReportUsecase } from 'main/factories/domain/load-orders-report-usecase-factory';
import { vexterToastError } from 'presentation/components/micro-components/toast';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VxFilterButton } from 'presentation/components/micro-components/vx-filter-button';
import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import useOrders from 'presentation/stores/hooks/use-orders';
import { filterByDaysIntervalValues } from 'presentation/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { EmptyOrders } from './components/empty-orders';
import { OrdersExport } from './components/export';
import { OrderFilterDate } from './components/filter/date';
import { OrderFilterPeriod } from './components/filter/period';
import { OrderFilterStatus } from './components/filter/status';
import { OrderFilterType } from './components/filter/type';
import { OrdersContent } from './components/order-content';
import { OrderSkeleton } from './components/order-skeleton';
import * as S from './style';

export const OrderPage: React.FunctionComponent = () => {
  const isDesktop = useMediaQuery('(min-width: 1281px)');
  const isSmall = useMediaQuery('(max-width: 1280px)');

  const [showFilter, setShowFilter] = useState(false);
  const {
    orders,
    isLoadingOrders,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    clean,
    loadOrders,
    daysInterval,
    setDaysInterval,
    sides,
    setSides,
    statuses,
    setStatuses,
  } = useOrders();
  const hasOrders = orders.length > 0;
  const [orderReportHistory, setOrderReportHistory] = useState<OrdersReport[]>([]);
  const loadOrdersReport = useCallback(() => {
    makeLoadOrdersReportUsecase()
      .execute()
      .then((reportHistoryOrError) => {
        reportHistoryOrError.fold(
          () => {
            vexterToastError({ title: 'Opa! Algo parece errado.', message: 'Por favor, tente novamente mais tarde!' });
          },
          (reportHistory) => {
            setOrderReportHistory(reportHistory);
          }
        );
      });
  }, []);

  useEffect(() => {
    loadOrdersReport();
  }, []);

  return (
    <S.OrderContentPage>
      {!showFilter && (
        <section>
          <VxGridContainer>
            <VxGridItem>
              <div className="hide-filters">
                <VxFilterButton isOpen={showFilter} onFilterClick={() => setShowFilter(!showFilter)} />
              </div>
            </VxGridItem>
          </VxGridContainer>
        </section>
      )}

      {showFilter && (
        <S.FilterContainer>
          <VxGridContainer>
            <VxGridItem>
              {isSmall && (
                <div className="close-button-mobile">
                  <VxFilterButton isOpen={showFilter} onFilterClick={() => setShowFilter(!showFilter)} />
                </div>
              )}

              <div className="show-filters">
                <div className="filter-container__date">
                  <OrderFilterDate
                    startAt={startAt}
                    endAt={endAt}
                    onChange={(newValue) => {
                      if (newValue?.[0]) {
                        setStartAt(newValue[0]);
                      }
                      if (newValue?.[1]) {
                        setEndAt(newValue[1]);
                      }
                    }}
                  />
                  <OrderFilterPeriod
                    active={daysInterval}
                    onClick={(item: any) => {
                      setDaysInterval(filterByDaysIntervalValues.indexOf(item) === -1 ? undefined : item);
                    }}
                  />

                  {isDesktop && (
                    <div className="hide-filter-mobile">
                      <VxFilterButton isOpen={showFilter} onFilterClick={() => setShowFilter(!showFilter)} />
                    </div>
                  )}
                </div>

                <div className="filter-container">
                  <OrderFilterStatus statuses={statuses} onCheck={(items) => setStatuses([...items])} />
                  <OrderFilterType sides={[...sides]} onCheck={(items) => setSides(items)} />
                  {isDesktop && (
                    <div className="actions-buttons">
                      <div className="btn-filter">
                        <VxButton
                          text="LIMPAR"
                          disabled={false}
                          onClick={clean}
                          size="small"
                          type="secondary"
                          width={80}
                        />
                        <VxButton
                          text="FILTRAR"
                          disabled={false}
                          onClick={loadOrders}
                          size="small"
                          type="primary"
                          width={80}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {isSmall && (
                  <div className="actions-buttons">
                    <div className="btn-filter">
                      <VxButton
                        text="LIMPAR"
                        disabled={false}
                        onClick={clean}
                        size="small"
                        type="secondary"
                        width={80}
                      />
                      <VxButton
                        text="FILTRAR"
                        disabled={false}
                        onClick={loadOrders}
                        size="small"
                        type="primary"
                        width={80}
                      />
                    </div>
                  </div>
                )}
              </div>
            </VxGridItem>
          </VxGridContainer>
        </S.FilterContainer>
      )}

      <VxGridContainer>
        <VxGridItem columns={8}>
          {isLoadingOrders ? <OrderSkeleton /> : hasOrders ? <OrdersContent orderList={orders} /> : <EmptyOrders />}
        </VxGridItem>
        <VxGridItem columns={4}>
          <aside>
            <OrdersExport
              reloadOrdersReport={loadOrdersReport}
              startAt={startAt}
              endAt={endAt}
              orderHistoryList={orderReportHistory}
              orderList={orders}
            />
          </aside>
        </VxGridItem>
      </VxGridContainer>
    </S.OrderContentPage>
  );
};

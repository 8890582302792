import { useMediaQuery } from '@mui/material';
import { RoutesEnum } from 'config/routes/router-enum';
import json2mq from 'json2mq';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { Link } from 'react-router-dom';

export function AsideButton() {
  const isMobile = useMediaQuery(json2mq({ minWidth: 1024, maxWidth: 1440 }));

  if (isMobile) {
    return (
      <Link to={RoutesEnum.depositWithdraw}>
        <VxButton
          text="DEPOSITAR"
          onClick={() => null}
          type="primary"
          disabled={false}
          size="extraSmall"
          width={75}
          buttonTextType="md"
        />
      </Link>
    );
  }

  return (
    <Link to={RoutesEnum.depositWithdraw}>
      <VxButton text="DEPOSITAR" onClick={() => null} type="primary" disabled={false} size="regular" width={180} />
    </Link>
  );
}

import { DESKTOP_MD, DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import styled from 'styled-components';

export const PatrimonyAndHideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const PatrimonyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 58px;
  width: 192px;

  .info-container {
    display: flex;
    align-items: center;

    &--icon {
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  @media (max-width: ${DESKTOP_MD}px) {
    width: 115px;
  }

  @media (max-width: ${DESKTOP_XS}px) {
    height: 46px;
  }
`;

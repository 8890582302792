import { AssetInfoCacheRepository } from 'data/repositories/cache/asset-info-cache-repository';
import { LoadCompanyNameBySymbolRepository } from 'domain/repositories/load-company-name-by-symbol-repository';
import { SaveCompanyNameBySymbolRepository } from 'domain/repositories/save-company-name-by-symbol-repository';
import { makeLocalStorageCacheDatasource } from 'main/factories/datasources/cache/cache-datasource-factory';

const makeAssetInfoCacheRepository = (): AssetInfoCacheRepository => {
  return new AssetInfoCacheRepository(makeLocalStorageCacheDatasource());
};

export const makeCacheLoadCompanyNameBySymbolRepository = (): LoadCompanyNameBySymbolRepository => {
  return makeAssetInfoCacheRepository();
};

export const makeCacheSaveCompanyNameBySymbolRepository = (): SaveCompanyNameBySymbolRepository => {
  return makeAssetInfoCacheRepository();
};

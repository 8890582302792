import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 384px;
  background-color: ${vxAppColors.brand.primary.principal};
  border-radius: 8px;
`;

export const TextIconButton = styled.button`
  background-color: ${vxAppColors.brand.primary.darkest};
  border: none;
  border-radius: 40px;
  display: flex;
  align-items: center;
  height: 44px;
  min-width: 128px;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  i {
    display: inline-block;
    width: 23.4336px;
    transform: translate(1.75px, -1px);
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

export const ContentContainer = styled.div`
  padding: 32px 24px;
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin: 30px 0 0 30px;
  }
`;

export const UlElement = styled.ol`
  list-style: none;
  counter-reset: counter;

  li {
    counter-increment: counter;
    color: ${vxAppColors.brand.primary.lightest};
    max-width: 360px;
    height: 68px;
    display: flex;
    align-items: center;

    &:nth-child(2) {
      margin-left: 30px;
    }

    ::before {
      content: counter(counter);
      border: 1px solid ${vxAppColors.brand.primary.light};
      border-radius: 50%;
      height: 40px;
      min-width: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }
  }
`;

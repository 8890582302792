import { DateRange } from '@material-ui/pickers';
import { VxDateRange } from 'presentation/components/micro-components/vx-date-range';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { OrderFilterDateStyle } from './style';

export type OrderFilterDateProps = {
  startAt?: Date;
  endAt?: Date;
  onChange: (date: DateRange<Date | null>, keyboardInputValue?: string | undefined) => void;
};

export const OrderFilterDate: React.FC<OrderFilterDateProps> = ({ startAt, endAt, onChange }) => {
  return (
    <OrderFilterDateStyle>
      <VxBodyMd title="Filtre por data" color={vxAppColors.neutral.dark} fontWeight="medium" />
      <div className="date-picker-row">
        <VxDateRange startDate={startAt} endDate={endAt} onChange={onChange} />
      </div>
    </OrderFilterDateStyle>
  );
};

import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 1279px) {
    margin-top: 24px;
  }
`;

import { useMediaQuery } from '@mui/material';
import { formatNumbers } from 'data/utils/currency-util';
import { Dots } from 'presentation/components/micro-components/dots';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { useAppDispatch, useAppSelector } from 'presentation/stores/hooks/redux-types';
import { useVexterPatrimony } from 'presentation/stores/hooks/use-vexter-patrimony';
import { Currencys, toggleShowInfo } from 'presentation/stores/slices/settings.store';
import { VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { HideButton } from '../hide-button';

import * as S from './styles';

export function PatrimonyInfo() {
  const { vexterPatrimony } = useVexterPatrimony();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const dispatch = useAppDispatch();
  const { showInfo, selectedCurrency, environment } = useAppSelector((state) => state.settingsSlice);
  const isSpot = environment === 'spot';
  const isDolar = selectedCurrency.symbol === Currencys.dolar.symbol;
  const formatOptionsEstimatedValue = {
    style: 'currency',
    currency: isDolar ? Currencys.dolar.symbol : Currencys.real.symbol,
    minimumFractionDigits: 2,
  };
  const patrimonyText = isSpot ? 'Saldo em conta' : 'Margem disponível';

  return (
    <S.PatrimonyAndHideContainer>
      <S.PatrimonyContainer>
        {isMobile ? (
          <div className="info-container">
            <VxBodyXs title={patrimonyText} fontWeight="medium" color={vxAppColors.brand.primary.dark} htmlTag="span" />
            <div className="info-container--icon" onClick={() => dispatch(toggleShowInfo())}>
              <VXIcon
                icon={showInfo ? VXIconsEnum.olho : VXIconsEnum.olhoFechado}
                size="sm"
                color={vxAppColors.graph.principal}
              />
            </div>
          </div>
        ) : (
          <div className="info-container">
            <VxBodySm title={patrimonyText} fontWeight="medium" color={vxAppColors.brand.primary.dark} htmlTag="span" />
          </div>
        )}

        {showInfo ? (
          isMobile ? (
            <VxBodySm
              title={formatNumbers(vexterPatrimony?.saldoCliente, formatOptionsEstimatedValue, isDolar)}
              fontWeight="medium"
              color={vxAppColors.brand.primary.dark}
            />
          ) : (
            <H4
              title={formatNumbers(vexterPatrimony?.saldoCliente, formatOptionsEstimatedValue, isDolar)}
              fontWeight="light"
              color={vxAppColors.brand.primary.dark}
            />
          )
        ) : (
          <Dots />
        )}
      </S.PatrimonyContainer>
      {!isMobile && <HideButton />}
    </S.PatrimonyAndHideContainer>
  );
}

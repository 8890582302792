import { AssetImage } from 'presentation/components/asset-image';

import { useMemo } from 'react';

import { formatDate } from 'data/utils/date-time-util';
import { OrderDescriptionEntity } from 'domain/entities/order-entity';
import { vexterToastWarning } from 'presentation/components/micro-components/toast';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';
import { VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { OrderStatus } from '../order-status';
import * as Styles from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { goToAssetPage } from 'presentation/utils/navigate-utils';

type OrderItemProps = {
  symbol: string;
  updatedAt: Date;
  previousDate: Date;
  showDateExeception: boolean;
  status: OrderDescriptionEntity;
  isExceptionalForMobileAndSmallDesktop: boolean;
};

export function OrderItem({
  symbol,
  updatedAt,
  previousDate,
  showDateExeception,
  status,
  isExceptionalForMobileAndSmallDesktop,
}: OrderItemProps) {
  const showDateInfo = useMemo(
    () => previousDate.getDate() !== updatedAt.getDate() || showDateExeception,
    [previousDate, updatedAt]
  );
  const showDateDivider = useMemo(() => !showDateExeception, [showDateInfo]);

  const { companyName } = useCompanyName({ symbol });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Styles.OrderDataEntityContainer onClick={() => vexterToastWarning({ title: 'Em breve' })}>
      {showDateInfo && (
        <>
          {showDateDivider && <Styles.Divider />}
          {isExceptionalForMobileAndSmallDesktop ? (
            <VxBodyXs
              title={formatDate(new Date(updatedAt), { showYear: false })}
              fontWeight="light"
              color={vxAppColors.brand.primary.darkest}
            />
          ) : (
            <VxBodyMd
              title={formatDate(new Date(updatedAt), { showYear: false })}
              fontWeight="light"
              color={vxAppColors.brand.primary.darkest}
            />
          )}
        </>
      )}

      <div className="recent-orders__info">
        <div className="recent-orders__info--container" onClick={() => goToAssetPage(symbol, navigate, dispatch)}>
          <AssetImage symbol={symbol} size="xs" />
          <div className="recent-orders__info--text">
            {isExceptionalForMobileAndSmallDesktop ? (
              <>
                <VxBodyXs title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
                <VxBodyXs title={companyName} fontWeight="light" color={vxAppColors.brand.primary.darkest} />
              </>
            ) : (
              <>
                <VxBodySm title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
                <VxBodyXs title={companyName} fontWeight="light" color={vxAppColors.brand.primary.darkest} />
              </>
            )}
          </div>
        </div>

        <OrderStatus
          id={status?.id}
          name={status.name}
          isExceptionalForMobileAndSmallDesktop={isExceptionalForMobileAndSmallDesktop}
        />
      </div>
    </Styles.OrderDataEntityContainer>
  );
}

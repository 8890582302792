import { useMediaQuery } from '@mui/material';
import { VxBodyMd, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';

export function AsideText() {
  const isMobile = useMediaQuery('(max-width: 1440px)');

  if (isMobile) {
    return (
      <VxBodyXs
        title="Para movimentar sua carteira Cripto você pode utilizar do seu saldo na Genial apenas fazendo um deposito rápido e facil."
        color={vxAppColors.brand.primary.darkest}
        fontWeight="light"
      />
    );
  }

  return (
    <VxBodyMd
      title="Para movimentar sua carteira Cripto você pode utilizar do seu saldo na Genial apenas fazendo um deposito rápido e facil."
      color={vxAppColors.brand.primary.darkest}
      fontWeight="light"
    />
  );
}

import { Spinner } from 'presentation/components/micro-components/spinner';
import { LobbyPageStyle } from './style';

interface LobbyPageProps {
  children?: React.ReactNode;
}
export const LobbyPage: React.FunctionComponent<LobbyPageProps> = ({ children }) => {
  return (
    <LobbyPageStyle>
      {children}
      <Spinner size={60} />
    </LobbyPageStyle>
  );
};

import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const ContentProgressContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  .MuiLinearProgress-root {
    height: 8px;
    border-radius: 12px;
    background-color: ${vxAppColors.neutral.lightest};
    color: ${vxAppColors.neutral.darkest};
  }
`;

export type ContentProgressItemProps = {
  color: VxAppColor;
};

export const ContentProgressItem = styled.div<ContentProgressItemProps>`
  .MuiLinearProgress-bar {
    background-color: ${(props) => props.color};
  }
`;

import { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

export function useChartPositionHeight() {
  const { width } = useWindowSize();
  const [height, setHeight] = useState<number>(102);
  const maxWindowsSize = 1920;
  const minWindowsSize = 1024;
  const maxChartSize = 162;
  const minChartSize = 102;
  useEffect(() => {
    const calculateHeight =
      width *
      (maxChartSize / maxWindowsSize +
        ((minChartSize / minWindowsSize - maxChartSize / maxWindowsSize) / (maxWindowsSize - minWindowsSize)) *
          (maxWindowsSize - width));
    if (calculateHeight > 162) {
      setHeight(162);
    } else if (calculateHeight < 102) {
      setHeight(102);
    } else {
      setHeight(calculateHeight);
    }
  }, [width]);
  return height;
}

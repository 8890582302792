import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const ChartSubtitleBody = styled.div`
  display: flex;
  border: 1px solid ${vxAppColors.brand.secondary.lightest};
  border-radius: 8px;
  padding: 8px;
  height: 40px;
  flex-wrap: wrap;
`;

type PointerStyleType = {
  backgroundColor: string;
  isMobile: boolean;
};

export const Container = styled.div`
  display: flex;
  align-items: center;

  & p:nth-child(2) {
    margin-right: 8px;
  }
`;

export const Pointer = styled.div<PointerStyleType>`
  height: 4px;
  width: ${(props) => (props.isMobile ? '12.8px' : '16px')};
  border-radius: 60px;
  background-color: ${(props) => props.backgroundColor};
`;

export const PointerArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

import { ProductHttpRepository } from 'data/repositories/http/product-http-repository';
import { CancelProductBoughtRepository } from 'domain/repositories/cancel-product-bought-usecase';
import { LoadAllProductsRepository } from 'domain/repositories/load-all-products-repository';
import { LoadProductsBoughtRepository } from 'domain/repositories/load-products-bought-usecase';
import { SaveBuyProductRepository } from 'domain/repositories/save-buy-product-repository';
import { makeCryptoHttpDatasource } from 'main/factories/datasources/http/http-datasource-factory';

const makeProductHttpRepository = (): ProductHttpRepository => {
  return new ProductHttpRepository(makeCryptoHttpDatasource(), process.env.REACT_APP_API_CRYPTO_GATEWAY as string);
};

export const makeHttpLoadAllProductsRepository = (): LoadAllProductsRepository => {
  return makeProductHttpRepository();
};

export const makeHttpLoadProductsBoughtRepository = (): LoadProductsBoughtRepository => {
  return makeProductHttpRepository();
};

export const makeHttpCancelProductBoughtRepository = (): CancelProductBoughtRepository => {
  return makeProductHttpRepository();
};

export const makeHttpSaveBuyProductRepository = (): SaveBuyProductRepository => {
  return makeProductHttpRepository();
};

import { UrlDatasource } from 'data/datasources/url/url-datasource';
import { CryptoUrlDatasource } from 'datasources/url/crypto-url-datasource';
import { Failure } from 'domain/failure/failure';

export const makeCryptoUrlDatasource = (): UrlDatasource => {
  const baseUrl = process.env.REACT_APP_API_CRYPTO_GATEWAY ?? '';
  if (baseUrl === '') {
    throw new Failure('Variável de ambiente ("REACT_APP_API_CRYPTO_GATEWAY") não foi encontrada');
  }

  const baseUrlOrders = process.env.REACT_APP_API_SIMULATOR_BFF ?? '';
  if (baseUrl === '') {
    throw new Failure('Variável de ambiente ("REACT_APP_API_SIMULATOR_BFF") não foi encontrada');
  }

  const baseUrlPositions = process.env.REACT_APP_WS_POSITIONS_CRYPTO ?? '';
  if (baseUrlPositions === '') {
    throw new Failure('Variável de ambiente ("REACT_APP_API_SIMULATOR_BFF") não foi encontrada');
  }

  return new CryptoUrlDatasource(baseUrl, baseUrlOrders, baseUrlPositions);
};

export const makeGenialAnalistaDatasource = (): string => {
  const baseUrl = process.env.REACT_APP_API_GENIAL_ANALISA ?? '';
  if (baseUrl === '') {
    throw new Failure('Variável de ambiente ("REACT_APP_API_SIMULATOR_BFF") não foi encontrada');
  }
  return `${baseUrl}/_search`;
};
export const makeAssetChartDatasource = (symbol: string, interval: string): string => {
  const baseUrlCrypto = process.env.REACT_APP_API_CRYPTO_GATEWAY;

  return `${baseUrlCrypto}/Charts/v1/history?symbol=${symbol}&currencyId=BRL&interval=${interval}`;
};
export const makeOrderDataEntityUrlDatasource = (): string => {
  const baseUrl = process.env.REACT_APP_API_TLORDERS ?? '';

  if (baseUrl === '') {
    throw new Failure('Variável de ambiente ("process.env.REACT_APP_API_TLORDERS") não foi encontrada');
  }
  return `${baseUrl}`;
};

import { VxAppColor } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

type VXIconStyleProps = {
  size: number;
  color?: VxAppColor;
  invertStrokeWithFill?: boolean;
};

export const VXIconStyled = styled.i<VXIconStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  svg > path {
    ${(props) => {
      if (props.invertStrokeWithFill ?? false) {
        return `stroke: ${props.color}`;
      }
      return `fill: ${props.color}`;
    }}
  }
`;

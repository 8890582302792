import { RoutesEnum } from 'config/routes/router-enum';
import { MenuButton } from 'presentation/components/micro-components/button-menu';
import { RootState } from 'presentation/stores';
import { setCurrentRoute } from 'presentation/stores/slices/settings.store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import packageJson from '../../../../package.json';
import { ReactComponent as GenialLogo } from '../../../assets/images/genial-logo.svg';
import { ReactComponent as PoweredByVexterMobile } from '../../../assets/images/powered-by-vexter-mobile.svg';
import { ReactComponent as PoweredByVexter } from '../../../assets/images/powered-by-vexter.svg';
import { SimpleTooltip } from '../info-tooltip';
import { VXIconsEnum } from '../micro-components/vx-icon';
import { SideBarStyle } from './style';

const SideBar: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const sideBarRoute = useSelector((state: RootState) => state.settingsSlice.sideBarRoute);
  return (
    <SideBarStyle>
      <Link to={RoutesEnum.home}>
        <GenialLogo
          className="show-desk"
          width={106.57}
          height={106.57}
          onClick={() => dispatch(setCurrentRoute(RoutesEnum.home))}
        />
        <GenialLogo
          className="show-mobile"
          width={60}
          height={60}
          onClick={() => dispatch(setCurrentRoute(RoutesEnum.home))}
        />
      </Link>
      <div className="navigation-buttons">
        <Link to={RoutesEnum.home}>
          <MenuButton
            text="Início"
            icon={RoutesEnum.home === sideBarRoute ? VXIconsEnum.homeFilled : VXIconsEnum.home}
            onClick={() => dispatch(setCurrentRoute(RoutesEnum.home))}
            selected={RoutesEnum.home === sideBarRoute}
          />
        </Link>
        <Link to={`${RoutesEnum.wallet}`}>
          <MenuButton
            text="Carteira"
            icon={RoutesEnum.wallet === sideBarRoute ? VXIconsEnum.walletFilled : VXIconsEnum.wallet}
            onClick={() => dispatch(setCurrentRoute(RoutesEnum.wallet))}
            selected={RoutesEnum.wallet === sideBarRoute}
          />
        </Link>
        <Link to={`${RoutesEnum.platform}`}>
          <MenuButton
            text="Plataformas"
            icon={RoutesEnum.platform === sideBarRoute ? VXIconsEnum.platformsFilled : VXIconsEnum.platforms}
            onClick={() => dispatch(setCurrentRoute(RoutesEnum.platform))}
            selected={RoutesEnum.platform === sideBarRoute}
          />
        </Link>
        <Link to={`${RoutesEnum.marginAlocation}`}>
          <MenuButton
            text="Alocação"
            icon={RoutesEnum.marginAlocation === sideBarRoute ? VXIconsEnum.allocationFilled : VXIconsEnum.allocation}
            onClick={() => dispatch(setCurrentRoute(RoutesEnum.marginAlocation))}
            selected={RoutesEnum.marginAlocation === sideBarRoute}
          />
        </Link>
        <Link to={RoutesEnum.extract}>
          <MenuButton
            text="Extrato"
            icon={RoutesEnum.extract === sideBarRoute ? VXIconsEnum.coinFilled : VXIconsEnum.coin}
            onClick={() => dispatch(setCurrentRoute(RoutesEnum.extract))}
            selected={RoutesEnum.extract === sideBarRoute}
          />
        </Link>
        <Link to={`${RoutesEnum.orders}`}>
          <MenuButton
            text="Ordens"
            icon={RoutesEnum.orders === sideBarRoute ? VXIconsEnum.orderFilled : VXIconsEnum.order}
            onClick={() => dispatch(setCurrentRoute(RoutesEnum.orders))}
            selected={RoutesEnum.orders === sideBarRoute}
          />
        </Link>
      </div>
      <SimpleTooltip text={`Versão: ${packageJson.version}`}>
        <PoweredByVexter
          className="show-desk"
          width={110}
          height={32}
          onClick={() => dispatch(setCurrentRoute(RoutesEnum.home))}
        />
      </SimpleTooltip>

      <PoweredByVexterMobile
        className="show-mobile logo-mobile-vexter"
        height={29.5}
        onClick={() => dispatch(setCurrentRoute(RoutesEnum.home))}
      />
    </SideBarStyle>
  );
};

export { SideBar };

import { VxBodyMd, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';

export function BeginningToInvestMobile() {
  return (
    <>
      <li>
        <p>
          <VxBodySm
            title="Invista em cripto com "
            fontWeight="light"
            color={vxAppColors.brand.primary.lightest}
            htmlTag="span"
          />
          <VxBodySm title="facilidade" fontWeight="medium" color={vxAppColors.brand.primary.lightest} htmlTag="span" />
        </p>
      </li>
      <li>
        <p>
          <VxBodySm
            title="Plataforma profissional "
            fontWeight="light"
            color={vxAppColors.brand.primary.lightest}
            htmlTag="span"
          />
          <VxBodySm title="sem custo" fontWeight="medium" color={vxAppColors.brand.primary.lightest} htmlTag="span" />
          <VxBodySm
            title=" ao operar cripto
                  ao menos uma vez no mês"
            fontWeight="light"
            color={vxAppColors.brand.primary.lightest}
            htmlTag="span"
          />
        </p>
      </li>
      <li>
        <p>
          <VxBodyMd title="Taxa zero " fontWeight="medium" color={vxAppColors.brand.primary.lightest} htmlTag="span" />
          <VxBodyMd
            title="para todas as operações"
            fontWeight="light"
            color={vxAppColors.brand.primary.lightest}
            htmlTag="span"
          />
        </p>
      </li>
    </>
  );
}

import { AssetChartEntity } from 'domain/entities/asset-chart-point-entity';
import { makeLoadMiniChartUsecase } from 'main/factories/domain/load-mini-chart-usecase-factory';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { allSymbols } from 'presentation/utils/all-symbols-utils';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { AssetItem } from '../../../../components/asset-item';
import { HomeAssetsSkeleton } from './home-assets-skeleton';
import { HomeAssetsStyle } from './styled';
import { DESKTOP_LG } from 'presentation/components/vx-grid/helpers';
import { VxBodyXs } from 'presentation/styleguide/typography/body/VxBody.stories';

export const HomeAssets: React.FC = () => {
  const breakpoint = useMediaQuery('(min-width: 1550px)');
  const isMobile = useMediaQuery(`(min-width: ${DESKTOP_LG}px)`);
  useEffect(() => {
    const loader = makeLoadMiniChartUsecase();
    loader.execute(allSymbols.map((e) => ({ value: e }))).then((miniChartDataOrError) => {
      if (miniChartDataOrError.isRight()) {
        setMiniChartData(miniChartDataOrError.value);
        setIsLoadingMiniChartData(false);
      }
    });
  }, []);
  const [isLoadingMiniChartData, setIsLoadingMiniChartData] = useState<boolean>(true);
  const [miniChartData, setMiniChartData] = useState<AssetChartEntity[]>([]);
  const isLoading = isLoadingMiniChartData;
  return (
    <HomeAssetsStyle>
      {isMobile ? (
        <H5 title="Ativos" color={vxAppColors.neutral.dark} fontWeight="medium" />
      ) : (
        <VxBodyMd title="Ativos" color={vxAppColors.neutral.dark} fontWeight="medium" />
      )}

      {isLoading ? (
        <HomeAssetsSkeleton />
      ) : (
        <table>
          <thead>
            <tr>
              {(breakpoint ? ['Nome', 'Valor', '', 'Variação %', ''] : ['Nome', 'Valor e Variação', '']).map(
                (title, i) => (
                  <th key={i}>
                    {isMobile ? (
                      <VxBodyMd title={title} color={vxAppColors.brand.primary.dark} fontWeight="regular" />
                    ) : (
                      <VxBodyXs title={title} color={vxAppColors.brand.primary.dark} fontWeight="regular" />
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {allSymbols.map((asset, i) => {
              return (
                <AssetItem
                  key={i}
                  symbol={asset}
                  miniChartData={miniChartData?.find((data) => data.symbol === asset)?.data ?? []}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </HomeAssetsStyle>
  );
};

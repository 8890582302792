import styled from 'styled-components';

export const HomeAssetsSkeletonStyle = styled.table`
  border-collapse: collapse;
  border-radius: 8px;
  width: 100%;
  table-layout: fixed;
  min-width: 536px;
  thead {
    height: 40px;
    tr {
      th {
        vertical-align: middle;
        text-align: left;
        padding-left: 8px;
        :nth-child(1) {
          padding-left: 16px;
        }
        @media (max-width: 1550px) {
          :nth-child(2) {
            text-align: end;
          }
        }
        @media (max-width: 1180px) {
          :nth-child(2) {
            padding-right: 50px;
          }
        }
      }
    }
  }
  tbody {
    tr {
      height: 80px;
      :last-child {
        border-bottom: none;
      }
      td {
        vertical-align: middle;
        text-align: left;
        padding-left: 8px;
        :nth-child(1) {
          padding-left: 16px;
        }
        @media (max-width: 1550px) {
          :nth-child(2) {
            text-align: end;
          }
        }
        @media (max-width: 1180px) {
          :nth-child(2) {
            padding-right: 50px;
          }
        }
        :last-child {
          text-align: right;
        }
        .asset-info {
          display: flex;
          gap: 8px;
          align-items: center;
          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
        .quotation-info {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        .mini-chart-container {
          width: 72px;
        }
      }
    }
  }
`;

import { Either } from 'domain/entities/either';
import { Position } from 'domain/entities/positions';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { Failure } from 'domain/failure/failure';
import { LoadPositionsRepository } from 'domain/repositories/load-positions-repository';

export interface LoadPositionsUsecase {
  execute(callback: (positions: Position[]) => void): Promise<Either<Failure, SubscriptionCancelEntity>>;
}

export class LoadPositionsImp implements LoadPositionsUsecase {
  constructor(readonly repository: LoadPositionsRepository) {
    Object.freeze(this);
  }

  execute(callback: (positions: Position[]) => void): Promise<Either<Failure, SubscriptionCancelEntity>> {
    return this.repository.loadPositions(callback);
  }
}

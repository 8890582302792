import { useMediaQuery } from '@mui/material';
import { VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';

type ButtonProps = {
  isActive: boolean;
  item: string;
};

export function Button({ isActive, item }: ButtonProps) {
  const isSmall = useMediaQuery('(max-width: 1365px)');

  if (isSmall) {
    return (
      <button>
        {isActive ? (
          <VxBodyXs
            title={item}
            color={isActive ? vxAppColors.neutral.white : vxAppColors.brand.primary.darkest}
            fontWeight="bold"
          />
        ) : (
          <VxBodyXs
            title={item}
            color={isActive ? vxAppColors.neutral.white : vxAppColors.brand.primary.darkest}
            fontWeight="regular"
          />
        )}
      </button>
    );
  }

  return (
    <button>
      {isActive ? (
        <VxBodyMd
          title={item}
          color={isActive ? vxAppColors.neutral.white : vxAppColors.brand.primary.darkest}
          fontWeight="medium"
        />
      ) : (
        <VxBodySm
          title={item}
          color={isActive ? vxAppColors.neutral.white : vxAppColors.brand.primary.darkest}
          fontWeight="medium"
        />
      )}
    </button>
  );
}

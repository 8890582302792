import { Checkbox } from '@mui/material';
import { VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody.stories';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useEffect, useState } from 'react';
import { VXIcon, VXIconsEnum } from '../vx-icon';
import { VxDropdownFilterEmptyStyle, VxDropdownFilterStyle } from './style';

export type VxDropdownItemsType = {
  label: string;
  checked: boolean;
};

type vxFilterBoxType = {
  onChangeItem: (list: VxDropdownItemsType[], item?: VxDropdownItemsType) => void;
  items: VxDropdownItemsType[];
  showSelectAllOptions?: boolean;
  emptyTextPlaceholder: string;
};

export const VxDropdownFilter: React.FunctionComponent<vxFilterBoxType> = ({
  onChangeItem,
  items,
  showSelectAllOptions,
  emptyTextPlaceholder,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [optionDropdown, setOptionsDropdown] = useState<VxDropdownItemsType[]>([...items]);
  const [optionsContainer, setOptionsContainer] = useState<VxDropdownItemsType[]>([...items]);
  const [selectedAllOptions, setSelectedAllOptions] = useState(true);
  const [dropdownMargin, setDropdownMargin] = useState(18);

  useEffect(() => {
    setOptionsDropdown([...items]);
    setOptionsContainer([...items]);
    setSelectedAllOptions([...items].some((s) => s.checked));
  }, [...items]);

  useEffect(() => {
    setDropdownMargin(document.getElementById(emptyTextPlaceholder)?.clientHeight ?? 18);
  }, [optionsContainer]);

  function handleSelectAll(shouldSelectAll: boolean) {
    setSelectedAllOptions(shouldSelectAll);
    optionDropdown.forEach((e) => (e.checked = shouldSelectAll));
    setOptionsDropdown([...optionDropdown]);
    setOptionsContainer(!shouldSelectAll ? [] : optionDropdown);
    onChangeItem(optionDropdown);
  }

  function selectItemContainer(item: VxDropdownItemsType) {
    setOptionsContainer(optionsContainer.filter((f) => f.label !== item.label));
    optionDropdown.forEach((f) => {
      if (item.label === f.label) {
        f.checked = false;
      }
    });
    setOptionsDropdown([...optionDropdown]);
    setSelectedAllOptions(!optionDropdown.some((f) => f.checked === false));
    onChangeItem(optionDropdown, item);
  }

  function selectItemDropdown(option: VxDropdownItemsType) {
    const index = optionDropdown.findIndex((f) => f.label === option.label);
    if (index !== -1) {
      optionDropdown[index].checked = !option.checked;
      setOptionsDropdown([...optionDropdown]);
    }
    setSelectedAllOptions(!optionDropdown.some((f) => f.checked === false));

    setOptionsContainer(
      option.checked ? [...optionsContainer, option] : optionsContainer.filter((f) => f.label !== option.label)
    );
    onChangeItem(optionDropdown, option);
  }

  const dropdownList = (
    <div
      className="dropdown"
      style={{ marginTop: `${dropdownMargin - 20}px` }}
      onMouseLeave={() =>
        setTimeout(() => {
          setShowDropdown(false);
        }, 1000)
      }
    >
      <div className="list-container">
        {showSelectAllOptions && (
          <div className="item-dropdown">
            <Checkbox
              size="small"
              checked={selectedAllOptions}
              onChange={() => handleSelectAll(!selectedAllOptions)}
              style={{ color: vxAppColors.brand.primary.principal, padding: '0px' }}
              disableTouchRipple
            />
            <VxBodySm title="Todos" color={vxAppColors.brand.primary.darkest} fontWeight="regular" />
          </div>
        )}
        {optionDropdown.map((option, i) => (
          <div className="item-dropdown" key={i}>
            <Checkbox
              size="small"
              checked={option.checked}
              onChange={() => selectItemDropdown(option)}
              style={{ color: vxAppColors.brand.primary.principal, padding: '0px' }}
              disableTouchRipple
            />
            <VxBodySm title={option.label} color={vxAppColors.brand.primary.darkest} fontWeight="regular" />
          </div>
        ))}
      </div>
    </div>
  );

  if (!optionsContainer.length) {
    return (
      <VxDropdownFilterEmptyStyle>
        <div className="empty-button" onClick={() => setShowDropdown(!showDropdown)} id={emptyTextPlaceholder}>
          <VxBodySm title={emptyTextPlaceholder} fontWeight="regular" color={vxAppColors.neutral.dark} />
          <VXIcon icon={VXIconsEnum.arrowDown} color={vxAppColors.neutral.darkest} size="sm" />
        </div>
        {showDropdown && dropdownList}
      </VxDropdownFilterEmptyStyle>
    );
  }

  return (
    <VxDropdownFilterStyle selectedFilters={items}>
      <div className="filter-box" id={emptyTextPlaceholder}>
        {optionsContainer.map((item, key) => (
          <div className="item-label" key={key} onClick={() => selectItemContainer(item)}>
            <VxBodyXs title={item.label} color={vxAppColors.brand.primary.darkest} fontWeight="regular" />
            <VXIcon icon={VXIconsEnum.close} size="sm"></VXIcon>
          </div>
        ))}
      </div>

      <div onClick={() => setShowDropdown(!showDropdown)}>
        <VXIcon icon={VXIconsEnum.arrowDown} size="sm"></VXIcon>
      </div>

      {showDropdown && dropdownList}
    </VxDropdownFilterStyle>
  );
};

import { CacheDatasource } from 'data/datasources/cache/cache-datasource';
import { EnvironmentLocalStorageCacheDatasource } from 'datasources/cache/environment-local-storage-cache-datasource copy';
import { LocalStorageCacheDatasource } from 'datasources/cache/local-storage-cache-datasource';

export const makeEnvironmentLocalStorageCacheDatasource = (): CacheDatasource => {
  return new EnvironmentLocalStorageCacheDatasource();
};

export const makeLocalStorageCacheDatasource = (): CacheDatasource => {
  return new LocalStorageCacheDatasource();
};

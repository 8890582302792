import { VxBillet } from 'presentation/components/vx-billet';
import { VxMargin } from 'presentation/components/vx-margin';
import { RootState } from 'presentation/stores';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import { GuaranteeAssetList } from './components/guarantee-asset-list';
import { GuaranteeAssetListItemProps } from './components/guarantee-asset-list/guarantee-asset-list-props';
import { MarginFeedbackEmpty } from './components/margin-feedbacks/margin-feedback-empty';
import * as S from './styles';

export const MarginAlocationPage: React.FunctionComponent = () => {
  const isSmall = useMediaQuery('(max-width: 1024px)');
  const [data, setData] = React.useState<GuaranteeAssetListItemProps[]>([]);
  const settingsSlice = useSelector((state: RootState) => state.settingsSlice);
  const PLACEHOLDER_DATA: GuaranteeAssetListItemProps[] = [
    {
      symbol: settingsSlice.selectedCurrency.symbol,
      quantity: 31.1245,
      positionValue: 0.32,
    },
    {
      symbol: 'DASH',
      quantity: 0.0000034,
      positionValue: 0.3232,
    },
    {
      symbol: 'ALGO',
      quantity: 0.3123,
      positionValue: 0.531424555,
    },
    {
      symbol: 'BTC',
      quantity: 1.3231,
      positionValue: 4.4311142,
    },
  ];

  if (data.length === 0) {
    return (
      <>
        <button style={{ width: 200 }} onClick={() => setData(PLACEHOLDER_DATA)}>
          Ativar dados de testes
        </button>

        <MarginFeedbackEmpty />
      </>
    );
  }

  return (
    <S.MarginAllocation>
      <section>
        <button style={{ width: 200, marginBottom: '24px' }} onClick={() => setData([])}>
          Desativar dados de testes
        </button>
        <S.MarginContainer>
          <VxMargin shouldRedirect={false} />
        </S.MarginContainer>
        {isSmall ? (
          <VxBodyMd title="Ativos" color={vxAppColors.neutral.darkest} fontWeight="light" />
        ) : (
          <H5 title="Ativos em garantia" color={vxAppColors.brand.primary.darkest} fontWeight="medium" />
        )}
        <GuaranteeAssetList assetList={data} />
      </section>
      <aside>
        <VxBillet
          phase="editing"
          type="margin"
          side="buyOrBlock"
          symbol={data.length > 1 ? data[1].symbol : data[0].symbol}
        />
      </aside>
    </S.MarginAllocation>
  );
};

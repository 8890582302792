import { DESKTOP_MD } from 'presentation/components/vx-grid/helpers';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const HideContainer = styled.div`
  display: flex;
  height: 38px;
  width: 158.61px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 100px;
  background-color: ${vxAppColors.neutral.superLight};
  cursor: pointer;
  filter: drop-shadow(0px 4px 48px rgba(54, 77, 205, 0.15));

  @media (max-width: ${DESKTOP_MD}px) {
    width: 118px;
    height: 20px;
    padding: 1px 8px;
  }
`;

export type HideIconContainerProps = {
  showing: boolean;
};

export const HideIconContainer = styled.div<HideIconContainerProps>`
  padding: 1px;
  border-radius: 100px;
  background-color: ${(props) => (props.showing ? vxAppColors.neutral.lightest : vxAppColors.brand.tertiary.principal)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

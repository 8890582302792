export const jsonLowerFistLetter = (json: any): any => {
  return JSON.parse(
    typeof json === 'string' ? json : JSON.stringify(json),
    function reviver(key: string, value: string) {
      const lower = key.substring(0, 1).toLowerCase() + key.substring(1);
      if (key === lower) return value;
      this[lower] = value;
    }
  );
};

import { AssetInfoHttpRepository } from 'data/repositories/http/asset-info-http-repository';
import { LoadAssetIdBySymbolRepository } from 'domain/repositories/load-asset-id-by-symbol-repository';
import { LoadAssetInfoRepository } from 'domain/repositories/load-asset-info-repository';
import { LoadCompanyNameBySymbolRepository } from 'domain/repositories/load-company-name-by-symbol-repository';
import { makeCryptoHttpDatasource } from 'main/factories/datasources/http/http-datasource-factory';
import { makeCryptoUrlDatasource } from 'main/factories/datasources/url/url-datasource-factory';

let assetInfoHttpRepository: AssetInfoHttpRepository | null = null;

const makeAssetInfoHttpRepository = (): AssetInfoHttpRepository => {
  if (assetInfoHttpRepository === null) {
    assetInfoHttpRepository = new AssetInfoHttpRepository({
      httpDatasource: makeCryptoHttpDatasource(),
      urlDatasource: makeCryptoUrlDatasource(),
    });
  }
  return assetInfoHttpRepository;
};

export const makeLoadCompanyNameBySymbolHttpRepository = (): LoadCompanyNameBySymbolRepository => {
  return makeAssetInfoHttpRepository();
};

export const makeLoadAssetIdBySymbolHttpRepository = (): LoadAssetIdBySymbolRepository => {
  return makeAssetInfoHttpRepository();
};

export const makeLoadAssetInfoHttpRepository = (): LoadAssetInfoRepository => {
  return makeAssetInfoHttpRepository();
};

import { useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';
import usePosition from 'presentation/stores/hooks/use-position';
import { BeginningToInvestCrypto } from '../banners/beginning-to-invest-crypto';
import { InvestedAmount } from '../Invested-amount';
import { TablePosition } from '../table-position';
import Title from './components/title';
import * as Styles from './styles';

export default function PositionAndValue() {
  const { positions, isLoading, hasError, hasPositions, rentabilitySum } = usePosition();
  const isSmallDesktop = useMediaQuery(json2mq({ minWidth: 1024, maxWidth: 1440 }));
  const isMobile = useMediaQuery(json2mq({ minWidth: 300, maxWidth: 1023 }));
  const isExceptionalForMobileAndSmallDesktop = useMediaQuery(json2mq({ minWidth: 300, maxWidth: 1440 }));

  return (
    <Styles.ContentContainer>
      {hasPositions && (
        <Title
          isMobile={isMobile}
          smallDesktop={isSmallDesktop}
          seeOrders={true}
          tooltipText="Este valor representa o total investido em ativos de cripto
              com a rentabilidade em valor em reais e em percentual"
          titleText="Valor investido"
        />
      )}
      <InvestedAmount
        positions={positions}
        isLoading={isLoading}
        hasPositions={hasPositions}
        rentabilitySum={rentabilitySum}
        emptyPositionComponent={<BeginningToInvestCrypto />}
        smallDesktop={isSmallDesktop}
        isMobile={isMobile}
      />
      <Styles.CardContainer>
        <Styles.TitleContainer>
          <div className="title-container__with-tooltip">
            {hasPositions && (
              <Title
                isMobile={isMobile}
                smallDesktop={isSmallDesktop}
                seeOrders={false}
                tooltipText="Lista de posições que o usuário possui na carteira
            demonstrando a quantia, valor que pagou e rentabilidade
            com valor em reais absoluto e percentual"
                titleText="Minha posição"
              />
            )}
          </div>
        </Styles.TitleContainer>
        <TablePosition
          positions={positions}
          isLoading={isLoading}
          hasError={hasError}
          hasPositions={hasPositions}
          isExceptionalForMobileAndSmallDesktop={isExceptionalForMobileAndSmallDesktop}
        />
      </Styles.CardContainer>
    </Styles.ContentContainer>
  );
}

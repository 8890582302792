import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

interface ModalProps {
  width?: number;
  height?: number;
  backgroundColor?: VxAppColor | string;
  padding?: number;
}

export const ModalStyle = styled.div<ModalProps>`
  width: ${(props) => props.width ?? 464}px;
  height: ${(props) => props.height ?? 586}px;
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor ?? vxAppColors.neutral.white};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
`;

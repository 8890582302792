import styled from 'styled-components';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  div.value-container {
    display: flex;
    gap: 4px;
    div.icon-container {
      position: absolute;
      left: -10px;
      top: 22px;
      width: 12px;
      height: 12px;
    }
    div.text-container {
      padding-left: 16px;
    }
  }
`;

export type ChartContainerProps = {
  height: number;
};

export const ChartContainer = styled.div<ChartContainerProps>`
  display: flex;
  width: 100%;
  height: ${(props) => props.height}px;
  justify-content: center;
  align-items: center;
`;

import styled from 'styled-components';

export const LoadingTitleSkeleton = styled.div`
  display: flex;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  & .loading {
    width: 100%;
    display: flex;

    &__image {
      width: 58%;
      > div {
        @media (max-width: 1919px) {
          height: 100%;
          width: 100%;
        }
      }
    }

    &__content {
      padding: 0 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      > div {
        width: 96%;
        margin: 20px auto;
        @media (max-width: 1919px) {
          margin: 15px auto;
        }
      }

      &--button {
        display: flex;
        margin-bottom: 20px;

        div {
          margin-left: auto;
        }
      }
    }

    @media (min-width: 1920px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1919px) {
    margin-bottom: 24px;
  }
`;

export const LoadingContentSkeleton = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    margin-bottom: 20px;
  }
`;

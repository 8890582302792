import { AssetsInfoResponseModel } from 'data/models/assets-info-response-model';
import { AssetIdEntity } from 'domain/entities/asset-id-entity';
import { AssetInfoEntity } from 'domain/entities/asset-info-entity';
import { AssetSymbolEntity } from 'domain/entities/asset-symbol-entity';
import { LoadAssetIdBySymbolRepository } from 'domain/repositories/load-asset-id-by-symbol-repository';
import { LoadAssetInfoRepository } from 'domain/repositories/load-asset-info-repository';
import { Either, left, right } from '../../../domain/entities/either';
import { Failure } from '../../../domain/failure/failure';
import { LoadCompanyNameBySymbolRepository } from '../../../domain/repositories/load-company-name-by-symbol-repository';
import { HttpDatasource } from '../../datasources/http/http-datasource';
import { UrlDatasource } from '../../datasources/url/url-datasource';

type AssetInfoHttpRepositoryParams = {
  httpDatasource: HttpDatasource;
  urlDatasource: UrlDatasource;
};

export class AssetInfoHttpRepository
  implements LoadCompanyNameBySymbolRepository, LoadAssetIdBySymbolRepository, LoadAssetInfoRepository
{
  readonly httpDatasource: HttpDatasource;
  readonly urlDatasource: UrlDatasource;
  constructor(params: AssetInfoHttpRepositoryParams) {
    this.httpDatasource = params.httpDatasource;
    this.urlDatasource = params.urlDatasource;
    Object.freeze(this);
  }
  async loadCompanyNameBySymbol(symbol: string): Promise<Either<Failure, string>> {
    return this.assetInfo(symbol).then((assetInfoOrError) => {
      return assetInfoOrError.fold(
        () => {
          return left<Failure, string>(
            new Failure(`Não encontrado ao carregar da internet o "companyName" do símbolo: "${symbol}"`)
          );
        },
        (assetInfo) => {
          return right(assetInfo.companyName);
        }
      );
    });
  }
  async assetIdBySymbol(symbol: AssetSymbolEntity): Promise<Either<Failure, AssetIdEntity>> {
    return this.assetInfo(symbol.value).then((assetInfoOrError) => {
      return assetInfoOrError.fold(
        () => {
          return left<Failure, AssetIdEntity>(new Failure(`Erro ao carregar ID (símbolo: ${symbol.value})`));
        },
        (assetInfo) => {
          return right(new AssetIdEntity(assetInfo.id));
        }
      );
    });
  }
  async assetInfo(symbol: string): Promise<Either<Failure, AssetInfoEntity>> {
    const error = left<Failure, AssetInfoEntity>(
      new Failure(`Erro ao carregar da internet as informações do símbolo: "${symbol}"`)
    );
    try {
      const response = await this.httpDatasource.request(this.urlDatasource.companyName(symbol));
      if (!response.ok) {
        return error;
      }
      const assetInfoResponse = AssetsInfoResponseModel.fromJson(response.data);
      return right({
        id: assetInfoResponse.data?.[0]?.id ?? '',
        companyName: assetInfoResponse.data?.[0]?.companyName ?? '',
        assetMarket: assetInfoResponse.data?.[0]?.assetMarket ?? '',
        assetProfile: assetInfoResponse.data?.[0]?.assetProfile ?? '',
        assetType: assetInfoResponse.data?.[0]?.assetType ?? '',
        description: assetInfoResponse.data?.[0]?.description ?? '',
        symbol,
      });
    } catch (_) {
      return error;
    }
  }
}

import { AssetChartEntity } from 'domain/entities/asset-chart-point-entity';
import { AssetSymbolEntity } from 'domain/entities/asset-symbol-entity';
import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadMiniChartRepository } from 'domain/repositories/load-mini-chart-repository';

export interface LoadMiniChartUsecase {
  execute(symbols: AssetSymbolEntity[]): Promise<Either<Failure, AssetChartEntity[]>>;
}

export class LoadMiniChartUsecaseImp implements LoadMiniChartUsecase {
  constructor(readonly repository: LoadMiniChartRepository) {
    Object.freeze(this);
  }
  execute(symbols: AssetSymbolEntity[]): Promise<Either<Failure, AssetChartEntity[]>> {
    return this.repository.loadMiniChart(symbols);
  }
}

import { useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';
import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';

export const AsideTitle = () => {
  const isMobile = useMediaQuery(json2mq({ minWidth: 1024, maxWidth: 1440 }));

  if (isMobile) {
    return (
      <VxBodySm
        color={vxAppColors.brand.primary.darkest}
        fontWeight="medium"
        title="Deposite da sua conta Genial 
                para carteira Cripto"
        htmlTag="h5"
      />
    );
  }

  return (
    <H5
      title="Deposite da sua conta Genial para carteira Cripto"
      color={vxAppColors.brand.primary.darkest}
      fontWeight="medium"
    />
  );
};

import React from 'react';
import brokenChain from '../../../../assets/images/broken-chain.png';
import pointingCoins from '../../../../assets/images/pointing-coins.png';

export enum VexterImagesEnum {
  brokenChain,
  pointingCoins,
}
interface VexterImageProps {
  image: VexterImagesEnum;
  width?: number;
  height?: number;
}
const VexterImage: React.FunctionComponent<VexterImageProps> = (props) => {
  let image = '';

  switch (props.image) {
    case VexterImagesEnum.brokenChain:
      image = brokenChain;
      break;

    case VexterImagesEnum.pointingCoins:
      image = pointingCoins;
      break;
    default:
      break;
  }

  return <img src={image} width={props.width} height={props.height} />;
};

export default VexterImage;

import { Mutex } from 'async-mutex';
import { WsDatasource } from 'data/datasources/ws/ws-datasource';
import { Either, left, right } from 'domain/entities/either';
import { PatrimonyEntity } from 'domain/entities/patrimony-entity';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { Failure } from 'domain/failure/failure';
import { ListenPatrimonyRepository } from 'domain/repositories/listen-patrimony-repository';

export class ListenPatrimonyRepositoryImp implements ListenPatrimonyRepository {
  private subscribers: Array<(patrimony: PatrimonyEntity | undefined) => void> = [];
  private lastResponse: { value: any } = { value: null };
  private readonly activityMutex = new Mutex();
  constructor(
    readonly wsDatasource: WsDatasource,
    readonly wsResponseMapper: (data: any) => PatrimonyEntity | undefined,
    readonly loadPnpAccount: () => any
  ) {
    this.init();
    Object.freeze(this);
  }
  async init() {
    this.wsDatasource.onMessage({
      message: 'SendOperation',
      callback: this.listen.bind(this),
    });
  }
  async listen(data: any) {
    this.lastResponse.value = data;
    this.subscribers.forEach((subscriber) => {
      try {
        subscriber(this.wsResponseMapper(this.lastResponse?.value));
      } catch (_) {
        subscriber(undefined);
      }
    });
  }
  async listenPatrimony(
    callback: (patrimony: PatrimonyEntity | undefined) => void
  ): Promise<Either<Failure, SubscriptionCancelEntity>> {
    try {
      await this.activityMutex.runExclusive(async () => {
        if (this.subscribers.length === 0) {
          this.lastResponse.value = await this.wsDatasource.send({
            message: 'subscribe',
            data: this.loadPnpAccount(),
          });
        }
      });
      if (!this.subscribers.includes(callback)) {
        this.subscribers.push(callback);
        callback(this.wsResponseMapper(this.lastResponse?.value));
      }
      return right(
        new SubscriptionCancelEntity(async () => {
          await this.activityMutex.runExclusive(async () => {
            const index = this.subscribers.indexOf(callback);
            if (index !== -1) {
              this.subscribers.splice(index, 1);
            }
            if (this.subscribers.length === 0) {
              this.wsDatasource.send({
                message: 'Unsubscribe',
                data: this.loadPnpAccount(),
              });
            }
          });
        })
      );
    } catch (e) {
      return left(new Failure('Erro ao inscrever para receber o patrimônio da Vexter'));
    }
  }
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const HomeSubheader = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 61px;
  box-shadow: 0px 4px 8px rgba(54, 77, 205, 0.15);
  width: 100%;
  background-color: ${vxAppColors.brand.primary.principal};
`;

import { CssBaseline } from '@mui/material';
import { SessionStorageEnum } from 'domain/repositories/session-storage/session-storage-enum';
import { isEmptystorage, isNotEmptystorage } from 'domain/repositories/session-storage/session_storage';
import { AppWrapper } from 'presentation/components/app-wrapper';
import { LobbyPage } from 'presentation/pages/lobby';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { loginDev } from 'presentation/utils/login-development';
import React from 'react';
import { VexterRouter } from './config/routes/router';

function App() {
  window.Buffer;
  document.body.style.cssText = `background: ${vxAppColors.background};`;

  if (process.env.NODE_ENV === 'development') {
    loginDev();
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {isNotEmptystorage(SessionStorageEnum.token) && (
        <AppWrapper>
          <VexterRouter />
        </AppWrapper>
      )}
      {isEmptystorage(SessionStorageEnum.token) && (
        <LobbyPage>
          <VexterRouter />
        </LobbyPage>
      )}
    </React.Fragment>
  );
}

export default App;

export enum RoutesEnum {
  home = '/',
  access = '/access',
  platform = '/plataformas',
  assets = '/ativos',
  profile = '/perfil',
  wallet = '/carteira',
  assetProfile = '/ativo',
  orders = '/orders',
  extract = '/extract',
  depositWithdraw = '/deposit-withdraw',
  mobile = '/mobile',
  marginAlocation = '/alocar-margem',
}

export enum SideBarRoutesEnum {
  home = '/',
  platform = '/plataformas',
  wallet = '/carteira',
  orders = '/orders',
  extract = '/extract',
  marginAlocation = '/alocar-margem',
}

export const sideBarRoutesNames = ['Início', 'Plataformas', 'Carteira', 'Ordens', 'Extrato', 'Alocação'];

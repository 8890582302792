import { HttpDatasource, HttpDatasourceParams, HttpMethodEnum } from '../../data/datasources/http/http-datasource';
import { HttpResponse } from '../../data/datasources/http/http-response';

export class DynamicHeaderHttpDatasource implements HttpDatasource {
  constructor(
    public readonly origin: HttpDatasource,
    public readonly dynamicHeader: () => Promise<Record<string, string>> = async () => ({})
  ) {
    Object.freeze(this);
  }
  async request(
    url: string,
    { headers = {}, data = null, method = HttpMethodEnum.get }: HttpDatasourceParams = {}
  ): Promise<HttpResponse> {
    const newHeaders = await this.dynamicHeader();
    return this.origin.request(url, { data, method, headers: { ...headers, ...newHeaders } });
  }
}

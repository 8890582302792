import * as Styles from './styles';
import { VxGridItemProps } from './vx-grid-props';

export function VxGridItem(props: VxGridItemProps) {
  return (
    <Styles.GridItem
      columns={props.columns}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xlg={props.xlg}
      debug={props.debug}
      height={props.height}
    >
      {props.children}
    </Styles.GridItem>
  );
}

import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { formatNumbers } from 'data/utils/currency-util';
import { makeSaveWithdrawUsecase } from 'main/factories/domain/save-withdraw-usecase-factory';
import { VexterCard } from 'presentation/components/card';
import { Dot } from 'presentation/components/micro-components/dots/styles';
import { vexterToastError, vexterToastSuccess } from 'presentation/components/micro-components/toast';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VxMoneyInput } from 'presentation/components/micro-components/vx-money-input';
import { useVexterPatrimony } from 'presentation/stores/hooks/use-vexter-patrimony';

import { VxBodyLg } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useCallback, useState } from 'react';
import { OperationForm, ValueItensAlignment } from '../styles';

export const WithdrawContent: React.FunctionComponent = () => {
  const formatOptionsEstimatedValue = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };
  const { vexterPatrimony, isLoadingVexterPatrimony } = useVexterPatrimony();
  const [amount, setAmount] = useState<number>(0);
  const [checked, setChecked] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const handleWithdrawConfirmation = useCallback(() => {
    if (!amount) return;
    makeSaveWithdrawUsecase()
      .execute(amount)
      .then((savedOrError) => {
        savedOrError.fold(
          () => {
            vexterToastError({
              title: 'Opa, alguma coisa deu errado',
              message: 'Por favor, tente novamente mais tarde!',
              hasClose: true,
            });
          },
          () => {
            setAmount(0);
            vexterToastSuccess({
              title: 'Transferência realizada com sucesso',
              hasClose: true,
            });
          }
        );
      })
      .catch(console.error);
  }, [amount]);

  const widrawAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setAmount(vexterPatrimony?.saldoCliente || 0) : setAmount(0);
    setChecked(event.target.checked);
    setDisableInput(event.target.checked);
  };

  return (
    <OperationForm>
      <Box>
        <Typography fontWeight="bold">Saque da sua carteira cripto para sua conta Genial</Typography>
        <Typography paddingTop="1rem" fontWeight="regular">
          É muito facíl e seguro enviar seu saldo em carteira cripto para sua conta Genial.
        </Typography>
      </Box>
      <ValueItensAlignment>
        <VexterCard>
          <Box padding="2rem">
            <VxBodyLg title="Disponível para sacar:" fontWeight="bold" color={vxAppColors.neutral.darkest} />
            {!isLoadingVexterPatrimony ? (
              <VxBodyLg
                title={formatNumbers(vexterPatrimony?.saldoCliente, formatOptionsEstimatedValue)}
                fontWeight="medium"
                color={vxAppColors.neutral.darkest}
              />
            ) : (
              <Dot />
            )}
          </Box>
        </VexterCard>
      </ValueItensAlignment>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
        <VxMoneyInput
          value={amount}
          onChange={(value) => setAmount(value ?? 0)}
          label={'Valor que deseja transferir'}
          disable={disableInput}
          maximum={vexterPatrimony?.saldoCliente}
          setHasError={setHasError}
        />
        <FormControlLabel
          sx={{ padding: '1rem' }}
          control={<Checkbox color="default" onChange={widrawAll} checked={checked} />}
          label="Realizar saque total"
        />
      </FormGroup>
      <VxButton
        text="Confirmar"
        type="primary"
        onClick={handleWithdrawConfirmation}
        disabled={amount <= 0 || hasError}
        size="large"
      />
    </OperationForm>
  );
};

import { Failure } from 'domain/failure/failure';

export class AssetSymbolEntity {
  readonly value: string;
  constructor(value?: string) {
    if (!value) {
      throw new Failure('Símbolo deve ser informado');
    }
    this.value = value;
    Object.freeze(this);
  }
}

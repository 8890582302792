import hexRgb from 'hex-rgb';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { VxDropdownItemsType } from '.';
import { VXIconStyled } from '../vx-icon/style';

type VxDropdownFilterStyleProps = {
  selectedFilters: VxDropdownItemsType[];
};

export const VxDropdownFilterStyle = styled.div<VxDropdownFilterStyleProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${VXIconStyled} {
    cursor: pointer;
  }
  position: relative;

  .filter-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    background: ${vxAppColors.product.previdencia};
    border-radius: ${(porps) => (porps.selectedFilters.length > 4 ? 8 : 100)}px;
    padding: 8px;
    max-width: 519px;
    z-index: 1;
    flex-wrap: wrap;
    .item-label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      text-transform: capitalize;
      cursor: pointer;
      ${VXIconStyled} {
        background: rgba(
          ${() => {
            const { red, green, blue } = hexRgb(vxAppColors.neutral.light);
            return [red, green, blue].join(',');
          }},
          0.3
        );
        border-radius: 100px;
        padding: 4px;
      }
    }
  }
  @keyframes dropdownExpand {
    0% {
      max-height: 0px;
      overflow: hidden;
    }
    100% {
      max-height: 178px;
      overflow: auto;
    }
  }

  .dropdown {
    background-color: ${vxAppColors.neutral.white};
    animation: dropdownExpand 150ms linear;
    padding-top: 34px;
    padding-bottom: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    min-width: 128px;
    max-height: 178px;
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    top: 0;
    transition: margin-top 100ms linear;
    box-shadow: 0px 4px 16px
      rgba(
        ${() => {
          const { red, green, blue } = hexRgb(vxAppColors.brand.primary.darkest);
          return [red, green, blue].join(',');
        }},
        0.08
      );
    .list-container {
      overflow: auto;
    }
    .item-dropdown {
      text-transform: capitalize;
      display: flex;
      gap: 8px;
      padding-inline: 16px;
      padding-block: 4px;
      align-items: center;
      cursor: pointer;
      :hover {
        background-color: ${vxAppColors.brand.secondary.lightest};
      }
    }
  }
`;

export const VxDropdownFilterEmptyStyle = styled.div`
  position: relative;
  .empty-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 15px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
    background-color: ${vxAppColors.neutral.superLight};
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  .dropdown {
    background-color: ${vxAppColors.neutral.white};
    padding-top: 34px;
    padding-bottom: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    min-width: 128px;
    transition: margin-top 100ms linear;
    max-height: 178px;
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    top: 0;
    box-shadow: 0px 4px 16px
      rgba(
        ${() => {
          const { red, green, blue } = hexRgb(vxAppColors.brand.primary.darkest);
          return [red, green, blue].join(',');
        }},
        0.08
      );
    .list-container {
      overflow: auto;
    }
    .item-dropdown {
      text-transform: capitalize;
      display: flex;
      gap: 8px;
      padding-inline: 16px;
      padding-block: 4px;
      align-items: center;
      cursor: pointer;
      :hover {
        background-color: ${vxAppColors.brand.secondary.lightest};
      }
    }
  }
`;

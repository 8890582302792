import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

interface CircularContainerProps {
  symbol: string;
  size: number;
  isLoading: boolean;
}
export const CircularContainer = styled.div<CircularContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  overflow: hidden;
  background-color: ${vxAppColors.background};

  img {
    color: ${vxAppColors.background};
    display: flex;
    align-self: center;
    background-color: ${vxAppColors.background};
    overflow: hidden;
    object-fit: cover;
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    display: ${(props) => (props.isLoading === true ? 'none' : 'block')};
  }

  span {
    font-size: 0.6em;
  }
`;

export const Image = styled.img``;

import { VxChips } from 'presentation/components/micro-components/vx-chips';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { FilterByDaysIntervalType, filterByDaysIntervalValues } from 'presentation/types';
import React from 'react';
import { OrderFilterPeriodStyle } from './style';

export type OrderFilterPeriodProps = {
  active: FilterByDaysIntervalType | undefined;
  onClick: (item: string) => void;
};

export const OrderFilterPeriod: React.FC<OrderFilterPeriodProps> = ({ active, onClick }) => {
  return (
    <OrderFilterPeriodStyle>
      <VxBodyMd title="Filtre por período" color={vxAppColors.neutral.dark} fontWeight="medium" />
      <div className="period-row">
        <VxChips
          items={filterByDaysIntervalValues.map((s) => s)}
          activeIndex={active === undefined ? -1 : filterByDaysIntervalValues.indexOf(active)}
          onClick={onClick}
        />
      </div>
    </OrderFilterPeriodStyle>
  );
};

import { AssetQuotationEntity } from 'domain/entities/asset-quotation-entity';
import { Either } from 'domain/entities/either';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { Failure } from 'domain/failure/failure';
import { ListenQuotationBySymbolRepository } from 'domain/repositories/listen-quotation-by-symbol-repository';

export interface ListenQuotationBySymbolUsecaseParams {
  symbol: string;
  callback(quotation: AssetQuotationEntity): void;
}

export interface ListenQuotationBySymbolUsecase {
  execute(params: ListenQuotationBySymbolUsecaseParams): Promise<Either<Failure, SubscriptionCancelEntity>>;
}

export class ListenQuotationBySymbolUsecaseImp implements ListenQuotationBySymbolUsecase {
  constructor(readonly repository: ListenQuotationBySymbolRepository) {
    Object.freeze(this);
  }
  execute(params: ListenQuotationBySymbolUsecaseParams): Promise<Either<Failure, SubscriptionCancelEntity>> {
    return this.repository.quotationBySymbol(params);
  }
}

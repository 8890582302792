const monthName = (month: number): string => {
  switch (month) {
    case 0:
      return 'Jan';
    case 1:
      return 'Fev';
    case 2:
      return 'Mar';
    case 3:
      return 'Abr';
    case 4:
      return 'Mai';
    case 5:
      return 'Jun';
    case 6:
      return 'Jul';
    case 7:
      return 'Ago';
    case 8:
      return 'Set';
    case 9:
      return 'Out';
    case 10:
      return 'Nov';
    case 11:
      return 'Dez';
    default:
      return '';
  }
};

const weekDayName = (weekDay: number): string => {
  switch (weekDay) {
    case 0:
      return 'Dom';
    case 1:
      return 'Seg';
    case 2:
      return 'Ter';
    case 3:
      return 'Qua';
    case 4:
      return 'Qui';
    case 5:
      return 'Sex';
    case 6:
      return 'Sáb';
    default:
      return '';
  }
};

type FormatDateParams = {
  captalizeFirstLetter?: boolean;
  showYear?: boolean;
  showHourAndMinute?: boolean;
  showWeekDay?: boolean;
  withDot?: boolean;
};

const formatDate = (
  value: Date | null = null,
  {
    captalizeFirstLetter = true,
    showWeekDay = false,
    withDot = false,
    showYear = true,
    showHourAndMinute = false,
  }: FormatDateParams = {}
): string => {
  if (value === null) {
    return '';
  }
  const hour = value.getHours().toString().padStart(2, '0');
  const minute = value.getMinutes().toString().padStart(2, '0');
  const day = value.getDate().toString().padStart(2, '0');
  const weekDay = weekDayName(value.getDay());
  const month = monthName(value.getMonth());
  const year = value.getFullYear().toString();
  let result = '';
  if (showWeekDay) {
    result = weekDay + ', ';
  }
  result = result + `${day} ${month}`;
  if (withDot) {
    result = result + (month === 'Mai' ? 'o' : '.');
  }
  if (showYear) {
    result = result + `${showWeekDay ? ' de' : ','} ${year}`;
  }
  if (showHourAndMinute) {
    result = result + ` as ${hour}:${minute}h`;
  }
  return captalizeFirstLetter ? result : result.toLowerCase();
};

const sendingTimeStrToDateTime = (sendingTimeStr: string): Date | undefined => {
  if (sendingTimeStr.length < 14) return undefined;
  const year = Number(sendingTimeStr.substring(0, 4));
  const month = Number(sendingTimeStr.substring(4, 6));
  const day = Number(sendingTimeStr.substring(6, 8));
  const hour = Number(sendingTimeStr.substring(8, 10));
  const minute = Number(sendingTimeStr.substring(10, 12));
  const second = Number(sendingTimeStr.substring(12, 14));
  const millisecond = Number(sendingTimeStr.substring(14).padStart(3, '0'));
  return new Date(year, month, day, hour, minute, second, millisecond);
};
function adicionaZero(numero: number) {
  if (numero <= 9) return '0' + numero;
  return numero;
}
const verifyAndFormatDateComplete = (date?: string): string => {
  if (!date) return '--/--/----';
  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
  if (date.match(dateRegex)) return date;
  return formatDateComplete(new Date(date));
};
const formatDateComplete = (date?: Date): string => {
  if (!date || isNaN(date.getDate())) return '--/--/----';
  const dataFormatada =
    adicionaZero(date.getDate()) + '/' + adicionaZero(date.getMonth() + 1) + '/' + date.getFullYear();
  return dataFormatada;
};
export { formatDate, sendingTimeStrToDateTime, monthName, formatDateComplete, verifyAndFormatDateComplete };

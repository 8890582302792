import { Position } from 'domain/entities/positions';

export const createChartData = (positions: Position[]) => {
  const createChartData = positions
    .map((position, index) => {
      const percentage = Math.abs((position.positionValue / position.totalHeritageGross) * 100).toFixed(2);
      let anotherValue = 0;
      const percentageBiggerThanHundred = Number(percentage) > 100.0;

      if (index >= 5) {
        return {
          totalNet: (anotherValue += Number(percentage)),
          symbol: 'Outros',
        };
      }
      return {
        totalNet: percentageBiggerThanHundred ? 100.0 : Number(percentage),
        symbol: position.symbol,
      };
    })
    .sort(function (a, b) {
      return b.totalNet - a.totalNet;
    });

  const filteredData = createChartData.filter((position) => position.symbol !== 'Outros');
  const filteredOthers = createChartData.filter((position) => position.symbol === 'Outros');

  const chartData = filteredOthers.length ? filteredData.concat(filteredOthers[0]) : filteredData;

  return chartData;
};

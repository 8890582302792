import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { makeCryptoListenQuotationBySymbolUsecase } from 'main/factories/domain/listen-quotation-by-symbol-usecase-factory';
import { useEffect, useState } from 'react';

export type UseQuotationParams = {
  symbol: string;
};

export function useQuotation({ symbol }: UseQuotationParams) {
  const [lastTrade, setLastTrade] = useState<number>();
  const [dailyOscillation, setDailyOscillation] = useState<number>();
  const [marketStatus, setMarketStatus] = useState<string>();
  const [marginValueSell, setMarginValueSell] = useState<number>();
  const [unsubscribe, setUnsubscribe] = useState<SubscriptionCancelEntity>();
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const loader = makeCryptoListenQuotationBySymbolUsecase();
    loader
      .execute({
        symbol,
        callback: (quotation) => {
          setLastTrade(quotation.lastTrade);
          setDailyOscillation(quotation.dailyOscillation);
          setMarketStatus(quotation.status);
          setMarginValueSell(quotation.marginValueSell);
        },
      })
      .then((listenOrError) => {
        if (listenOrError.isRight()) {
          setUnsubscribe(listenOrError.value);
        }
      })
      .catch(console.error);
    setLoading(false);
    return () => {
      if (unsubscribe) {
        unsubscribe.cancel();
      }
    };
  }, []);
  return {
    lastTrade,
    dailyOscillation,
    isLoading,
    marketStatus,
    marginValueSell,
  };
}

import dayjs from 'dayjs';
import useExtracts from 'presentation/stores/hooks/use-extract';

import { ExtractHistoryCache } from 'domain/entities/extract-entity';
import { makeLoadExtractsHistoryUsecase } from 'main/factories/domain/load-extract-history-usecase-factory';
import { vexterToastError } from 'presentation/components/micro-components/toast';
import React, { useEffect, useState } from 'react';

import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { EmptyExtract } from './components/empty-extract';
import { ExtractInfos } from './components/extract-infos';
import { ExtractTable } from './components/extract-table';
import { ExtractFilter } from './components/filters';
import { ExtractTableSkeleton } from './components/skeletons/profile-extract-table';
import * as S from './styles';
import { VxDropdownItemsType } from 'presentation/components/micro-components/vx-dropdown-filter';

const ProfileExtractPage: React.FunctionComponent = () => {
  const periods = ['7 dias', '15 dias', '30 dias', '60 dias', '90 dias'];
  const [userIsFiltering, setUserIsFiltering] = useState(false);
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(dayjs(endDate).add(-7, 'day').toISOString());
  const [releaseType, setReleaseType] = useState('typeFilter=1&typeFilter=2&typeFilter=3&typeFilter=4');
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const { extractsList, loadExtracts, extractsError } = useExtracts({
    startDate,
    endDate,
    release: releaseType,
    selectedPeriod: selectedPeriod,
  });
  const emptyExtract = extractsList.length === 0;

  const dropdownOptions: VxDropdownItemsType[] = [
    { checked: true, label: 'Entrada' },
    { checked: true, label: 'Saída' },
  ];
  const [selectedReleaseType, setSelectedReleaseType] = useState(dropdownOptions);
  const [exportedExtractsHistory, setExportedExtractsHistory] = useState<ExtractHistoryCache[]>([]);
  function clearFilter() {
    setEndDate(new Date().toISOString());
    setStartDate(dayjs(endDate).add(-7, 'day').toISOString());
    setReleaseType('typeFilter=1&typeFilter=2&typeFilter=3&typeFilter=4');
    setUserIsFiltering(false);
    setSelectedPeriod(0);
    setSelectedReleaseType(dropdownOptions);
  }
  useEffect(() => {
    makeLoadExtractsHistoryUsecase()
      .execute()
      .then((historyOrError) => {
        if (historyOrError.isRight()) {
          setExportedExtractsHistory(historyOrError.value);
        } else {
          vexterToastError({ title: 'Opa! Algo parece errado.', message: 'Por favor, tente novamente mais tarde!' });
        }
      });
  }, []);
  useEffect(() => {
    const today = dayjs(Date.now());
    if (today.diff(endDate, 'day') === 0) {
      let newSelectedPeriodTab = -1;
      const daysToEnd = today.diff(startDate, 'day');
      switch (daysToEnd) {
        case 7:
          newSelectedPeriodTab = 0;
          break;
        case 15:
          newSelectedPeriodTab = 1;
          break;
        case 30:
          newSelectedPeriodTab = 2;
          break;
        case 60:
          newSelectedPeriodTab = 3;
          break;
        case 90:
          newSelectedPeriodTab = 4;
          break;
      }
      setSelectedPeriod(newSelectedPeriodTab);
    }
  }, [startDate, endDate]);
  return (
    <>
      <ExtractFilter
        selectedPeriod={selectedPeriod}
        selectedReleaseType={selectedReleaseType}
        periods={periods}
        onSelectPeriod={(e) => {
          setSelectedPeriod(periods.findIndex((i) => i === e));
        }}
        onFilter={(filters) => {
          setStartDate(filters.startDate);
          setEndDate(filters.endDate);
          setReleaseType(filters.release);
          setUserIsFiltering(true);
        }}
        onFilterUpdate={(filters) => {
          setStartDate(filters.startDate);
          setEndDate(filters.endDate);
          setReleaseType(filters.release);
          setSelectedPeriod(filters.selectedPeriod);
        }}
        onClearFilter={clearFilter}
      />

      <VxGridContainer>
        <VxGridItem columns={8}>
          <S.ExtractPageStyle>
            <section className={`extract-list${emptyExtract ? '-empty' : ''}`}>
              {loadExtracts || extractsError ? (
                <ExtractTableSkeleton />
              ) : emptyExtract ? (
                <EmptyExtract userIsFiltering={userIsFiltering} clearFilter={clearFilter} />
              ) : (
                <ExtractTable extractList={extractsList} />
              )}
            </section>
          </S.ExtractPageStyle>
        </VxGridItem>

        <VxGridItem columns={4}>
          <ExtractInfos
            userIsFiltering={userIsFiltering}
            endDate={endDate}
            extractsList={extractsList}
            startDate={startDate}
            exportedExtractsHistory={exportedExtractsHistory}
            setExportedExtractsHistory={setExportedExtractsHistory}
          />
        </VxGridItem>
      </VxGridContainer>
    </>
  );
};

export { ProfileExtractPage };

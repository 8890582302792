import { formatDouble, formatNumbers, getPositionPercentage, getRentability } from 'data/utils/currency-util';
import { OrderSideIdEnum } from 'domain/enums/orders';
import { hasValue } from 'domain/util';
import { CloePositionConfirmation } from 'presentation/components/close-position-confirmation';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { Modal } from 'presentation/components/modal';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';
import usePosition from 'presentation/stores/hooks/use-position';

import { VxBodyLg, VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { vxOscillationColor } from 'presentation/utils/color-utils';
import React from 'react';
import { useModal } from 'react-modal-hook';
import { useMediaQuery } from 'usehooks-ts';
import { CardContainer } from './style';

export type AssetProfilePositionProps = {
  symbol: string;
};

export const AssetProfilePosition: React.FC<AssetProfilePositionProps> = ({ symbol }) => {
  const { companyName } = useCompanyName({ symbol });
  const isLowerSize = useMediaQuery('(max-width: 1024px)');
  const formatOptions = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };
  const { positions } = usePosition();
  const position = positions.find((p) => p.symbol === symbol);
  const quantity = `Qtd ${position?.totalNet ? Math.abs(position?.totalNet) : '--'}`;
  const avgPrice = formatNumbers(position?.avgPrice, formatOptions);
  const value = formatNumbers(position?.positionValue, formatOptions);
  const rentabilityValue =
    hasValue(position?.totalNet) && hasValue(position?.avgPositionValue) && hasValue(position?.totalNet)
      ? getRentability(position?.totalNet ?? 0, position?.avgPositionValue ?? 0, position?.positionValue ?? 0)
      : undefined;
  const rentabilityValueText = formatNumbers(rentabilityValue, formatOptions);
  const rentabilityPercentage = `${formatDouble(
    hasValue(position?.totalNet) && hasValue(position?.avgPositionValue) && hasValue(position?.totalNet)
      ? getPositionPercentage(position?.totalNet ?? 0, position?.avgPositionValue ?? 0, position?.positionValue ?? 0)
      : undefined,
    { alwaysShowSignal: true, minimumDecimalPlaces: 2, maximumFractionDigits: 2 }
  )}%`;
  const bloquedValue = 10000;
  const bloquedQuantity = formatNumbers(bloquedValue, formatOptions);
  const [showModalConfirm, hideModalConfirm] = useModal(({ in: open }) => {
    if (!position) {
      return <></>;
    }
    return (
      <Modal
        open={open}
        hideModalHookCallback={hideModalConfirm}
        width={460}
        height={213}
        backgroundColor={vxAppColors.neutral.white}
      >
        <CloePositionConfirmation
          symbol={position.symbol}
          quantity={position.totalNet}
          side={position.totalNet > 0 ? OrderSideIdEnum.sell : OrderSideIdEnum.buy}
          onCloseModal={hideModalConfirm}
        ></CloePositionConfirmation>
      </Modal>
    );
  });
  return (
    <CardContainer>
      <div className="title-container">
        {isLowerSize ? (
          <VxBodyMd title="Minha Posição" fontWeight="light" color={vxAppColors.neutral.dark} />
        ) : (
          <H5 title="Minha Posição" fontWeight="medium" color={vxAppColors.neutral.dark} />
        )}
      </div>
      <div className="asset-container">
        <div className="asset-item-name-container">
          {isLowerSize ? (
            <>
              <VxBodyMd title={symbol} fontWeight="bold" color={vxAppColors.neutral.dark} />
              <VxBodySm title={companyName} fontWeight="regular" color={vxAppColors.neutral.dark} />
            </>
          ) : (
            <>
              <VxBodyMd title={symbol} fontWeight="bold" color={vxAppColors.neutral.dark} />
              <VxBodySm title={companyName} fontWeight="light" color={vxAppColors.neutral.dark} />
            </>
          )}
        </div>
        <div className="asset-item-value-container">
          {isLowerSize ? (
            <>
              <VxBodyXs
                title={rentabilityPercentage}
                fontWeight="medium"
                color={vxOscillationColor(rentabilityValue)}
              />
              <VxBodyXs title={quantity} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          ) : (
            <>
              <VxBodyLg
                title={rentabilityPercentage}
                fontWeight="medium"
                color={vxOscillationColor(rentabilityValue)}
              />
              <VxBodyMd title={quantity} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          )}
        </div>
      </div>

      <div className="position-container">
        <div className="position-item-container">
          {isLowerSize ? (
            <>
              <VxBodyXs title="Posição atual" fontWeight="regular" color={vxAppColors.neutral.dark} />
              <VxBodyXs title={value} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          ) : (
            <>
              <VxBodyMd title="Posição atual" fontWeight="regular" color={vxAppColors.neutral.dark} />
              <VxBodyMd title={value} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          )}
        </div>
        <hr className="asset-item-separator" />
        <div className="position-item-container">
          {isLowerSize ? (
            <>
              <VxBodyXs title="Preço médio" fontWeight="regular" color={vxAppColors.neutral.dark} />
              <VxBodyXs title={avgPrice} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          ) : (
            <>
              <VxBodyMd title="Preço médio" fontWeight="regular" color={vxAppColors.neutral.dark} />
              <VxBodyMd title={avgPrice} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          )}
        </div>
        <hr className="asset-item-separator" />
        <div className="position-item-container">
          {isLowerSize ? (
            <>
              <VxBodyXs title="Rentabilidade" fontWeight="regular" color={vxAppColors.neutral.dark} />
              <VxBodyXs title={rentabilityValueText} fontWeight="medium" color={vxOscillationColor(rentabilityValue)} />
            </>
          ) : (
            <>
              <VxBodyMd title="Rentabilidade" fontWeight="regular" color={vxAppColors.neutral.dark} />
              <VxBodyMd title={rentabilityValueText} fontWeight="medium" color={vxOscillationColor(rentabilityValue)} />
            </>
          )}
        </div>
        <hr className="asset-item-separator" />
        <div className="position-item-container">
          {isLowerSize ? (
            <>
              <VxBodyXs title="Quantidade bloqueada" fontWeight="light" color={vxAppColors.neutral.dark} />
              <VxBodyXs title={bloquedQuantity} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          ) : (
            <>
              <VxBodyMd title="Quantidade bloqueada" fontWeight="light" color={vxAppColors.neutral.dark} />
              <VxBodyMd title={bloquedQuantity} fontWeight="medium" color={vxAppColors.neutral.dark} />
            </>
          )}
        </div>
      </div>
      <div className="button-container">
        <VxButton type="primary" text="ENCERRAR AGORA" size="small" disabled={false} onClick={showModalConfirm} />
      </div>
    </CardContainer>
  );
};

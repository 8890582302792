import { Typography } from '@mui/material';
import { VxAppColor } from 'presentation/styleguide/vx-colors-style';
import { ElementType } from 'react';

type RegularTypographProps = {
  title: string;
  color: VxAppColor;
  /**
   * light = 300;
   *
   * regular = 400;
   *
   * medium = 600;
   *
   * bold = 700;
   */
  fontWeight: 'light' | 'regular' | 'medium' | 'bold';
  htmlTag?: ElementType;
};

const _getFontWeight = (fontWeight: string) => {
  switch (fontWeight) {
    case 'light':
      return 300;
    case 'regular':
      return 400;
    case 'medium':
      return 600;
    case 'bold':
      return 700;
  }
};

export function VxBodyLg(props: RegularTypographProps) {
  return (
    <Typography variant="body1" color={props.color} fontWeight={_getFontWeight(props.fontWeight)}>
      {props.title}
    </Typography>
  );
}

export function VxBodyMd(props: RegularTypographProps) {
  return (
    <Typography
      variant="body2"
      color={props.color}
      fontWeight={_getFontWeight(props.fontWeight)}
      component={props.htmlTag ? props.htmlTag : 'p'}
    >
      {props.title}
    </Typography>
  );
}

export function VxBodySm(props: RegularTypographProps) {
  return (
    <Typography
      variant="bodysm"
      color={props.color}
      fontWeight={_getFontWeight(props.fontWeight)}
      component={props.htmlTag ? props.htmlTag : 'p'}
    >
      {props.title}
    </Typography>
  );
}

export function VxBodyXs(props: RegularTypographProps) {
  return (
    <Typography
      variant="bodyxs"
      color={props.color}
      fontWeight={_getFontWeight(props.fontWeight)}
      component={props.htmlTag ? props.htmlTag : 'p'}
    >
      {props.title}
    </Typography>
  );
}

export function VxPlaceholder(props: RegularTypographProps) {
  return (
    <Typography
      variant="placeholder"
      color={props.color}
      fontWeight={_getFontWeight(props.fontWeight)}
      component={props.htmlTag ? props.htmlTag : 'span'}
    >
      {props.title}
    </Typography>
  );
}

export function VxButtonTypeLg(props: RegularTypographProps) {
  return (
    <Typography
      variant="buttontypeLg"
      color={props.color}
      fontWeight={_getFontWeight(props.fontWeight)}
      component={props.htmlTag ? props.htmlTag : 'span'}
    >
      {props.title}
    </Typography>
  );
}

export function VxButtonTypeMd(props: RegularTypographProps) {
  return (
    <Typography
      variant="buttontypeMd"
      color={props.color}
      fontWeight={_getFontWeight(props.fontWeight)}
      component={props.htmlTag ? props.htmlTag : 'span'}
    >
      {props.title}
    </Typography>
  );
}

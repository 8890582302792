import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  height: 24px;
  width: 100%;
  gap: 12px;

  .active {
    border-bottom: 2px solid ${vxAppColors.neutral.superLight};
  }
`;

interface TabItemProps {
  width?: number;
  height?: number;
}
export const TabItem = styled.li<TabItemProps>`
  width: ${(props) => (props.width !== null ? props.width : '100%')}px;
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  height: 29px;

  text-align: center;

  span {
    display: inline-block;
    margin-bottom: 4px;
    padding: 0 16px;
  }
`;

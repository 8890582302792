import { VXTextSkeleton } from 'presentation/components/micro-components/vx-skeleton/text/styles';

import React from 'react';
import * as Styles from './styles';

export const ExtractTableSkeleton: React.FunctionComponent = () => {
  return (
    <Styles.ExtractTableSkeletonStyle>
      <thead>
        <tr>
          <th>
            <VXTextSkeleton height={21} width={60} skeletonColor="light" />
          </th>
          <th>
            <VXTextSkeleton height={21} width={80} skeletonColor="light" />
          </th>
          <th>
            <VXTextSkeleton height={21} width={70} skeletonColor="light" />
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.from(Array(10).keys()).map((_, i) => {
          return (
            <tr key={i}>
              <td>
                <VXTextSkeleton height={21} width={70} skeletonColor="light" />
              </td>
              <td>
                <VXTextSkeleton height={21} width={100} skeletonColor="light" />
              </td>
              <td>
                <VXTextSkeleton height={21} width={70} skeletonColor="light" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Styles.ExtractTableSkeletonStyle>
  );
};

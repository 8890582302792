import { formatNumbers } from 'data/utils/currency-util';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AssetChartPointData } from 'domain/entities/chart-asset-data-entity';
import { ChartInterval } from 'domain/types';
import { RootState } from 'presentation/stores';
import { Currencys } from 'presentation/stores/slices/settings.store';
import { VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChartContainer, TooltipContainer } from './style';

const CustomizedDot = (props: any) => {
  const { cx, cy } = props;
  return (
    <svg
      x={cx ? cx - 23 : undefined}
      y={cy ? cy - 23 : undefined}
      width="46"
      height="47"
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_604_3927)">
        <circle cx="23" cy="23.5" r="6" fill="white" />
      </g>
      <g filter="url(#filter1_dd_604_3927)">
        <circle cx="23" cy="23.5" r="4" fill="#050922" />
      </g>
      <defs>
        <filter
          id="filter0_i_604_3927"
          x="17"
          y="17.5"
          width="12"
          height="12"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_604_3927" />
        </filter>
        <filter
          id="filter1_dd_604_3927"
          x="0"
          y="0.5"
          width="46"
          height="46"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_604_3927" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_604_3927" result="effect2_dropShadow_604_3927" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_604_3927" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

const CustomTooltip = (props: any) => {
  const { selectedCurrency } = useSelector((state: RootState) => state.settingsSlice);
  const isDolar = selectedCurrency.symbol === Currencys.dolar.symbol;
  const formatOptionsEstimatedValue = {
    style: 'currency',
    currency: isDolar ? 'USD' : 'BRL',
    minimumFractionDigits: 2,
  };
  if (props.active && props.payload && props.payload.length) {
    const now = new Date(Date.now());
    const chartDate = new Date(Number(props.label));
    return (
      <TooltipContainer>
        <VxBodyXs
          title={dayjs(chartDate).format(
            now.getFullYear() === chartDate.getFullYear() ? 'HH[h]MM, DD MMM' : 'HH[h]MM, DD MMM [de] YYYY'
          )}
          color={vxAppColors.brand.primary.darkest}
          fontWeight="regular"
        />
        <div className="value-container">
          <div className="icon-container">
            <CustomizedDot />
          </div>
          <div className="text-container">
            <VxBodySm
              title={formatNumbers(Number(props.payload[0].value), formatOptionsEstimatedValue)}
              color={vxAppColors.brand.primary.darkest}
              fontWeight="medium"
            />
          </div>
        </div>
      </TooltipContainer>
    );
  }

  return null;
};

function whenMaxGapByInterval(interval: '1d' | '1m'): number {
  const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
  return interval === '1d' ? twoHoursInMilliseconds : 24 * twoHoursInMilliseconds;
}

function tickFormatter(value: number, interval: ChartInterval) {
  const date = dayjs(value);
  const now = dayjs(Date.now());
  if (interval === '1d') {
    return date.format('H[h]');
  } else if (interval === '1m') {
    return date.format(date.date() < 4 ? 'D MMM' : 'D');
  }
  return date.format(date.year() !== now.year() ? 'MMM YYYY' : 'MMM');
}

export type VxPriceChartProps = {
  data: AssetChartPointData[];
  interval?: ChartInterval;
  height?: number;
};

export const VxPriceChart: React.FC<VxPriceChartProps> = ({ data, interval = '1d', height = 221 }) => {
  const chartData: Array<{ price: number; when: number }> = data
    .map((d) => ({
      price: d.price,
      when: d.when.getTime(),
    }))
    .filter((item, pos, self) => {
      return pos === self.findIndex((s) => s.when === item.when);
    });
  const priceMaxValue = Math.max(...chartData.map((d) => d.price));
  const priceMinValue = Math.min(...chartData.map((d) => d.price));

  const whenTicks = chartData
    .map((d, index, self) => {
      const lastValue = self[self.length - 1].when;
      const firstValue = self[0].when;
      if (d.when <= firstValue || d.when >= lastValue) {
        return d.when;
      }
      const date = new Date(d.when);
      if (interval === '6m' || interval === '12m') {
        date.setDate(1);
      } else if (interval === '1m') {
        if (date.getDate() % 2 === 1) {
          date.setDate(date.getDate() + 1);
        }
      }
      if (interval !== '1d') {
        date.setHours(0);
      } else {
        if (date.getHours() % 2 === 1) {
          date.setHours(date.getHours() + 1);
        }
      }
      date.setSeconds(0);
      date.setMilliseconds(0);
      date.setMinutes(0);
      const result = date.getTime();
      if (result < firstValue) {
        return firstValue;
      } else if (result > lastValue) {
        return lastValue;
      }
      return result;
    })
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    });
  const whenTicksHelper = [...whenTicks];
  if (interval === '1d' || interval === '1m') {
    for (let index = 0; index < whenTicksHelper.length - 1; index++) {
      const element = whenTicksHelper[index];
      const elementNext = whenTicksHelper[index + 1];
      const startInsertIndex = whenTicks.indexOf(element);
      const whenMaxGap = whenMaxGapByInterval(interval);
      if (elementNext - element > whenMaxGap) {
        Array.from(
          { length: Math.floor((elementNext - element) / whenMaxGap) },
          (value, index) => element + index * whenMaxGap
        ).forEach((v, index) => {
          whenTicks.splice(startInsertIndex + index, 0, v);
        });
      }
    }
  }
  return (
    <ChartContainer height={height}>
      {chartData.length === 0 ? (
        <H5 title="Este período não possui gráfico" fontWeight="bold" color={vxAppColors.neutral.dark} />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData} margin={{ top: 0, left: 6, right: 6, bottom: 0 }}>
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis
              padding={{
                left: 0,
                right: 0,
              }}
              dataKey="when"
              type="number"
              scale="linear"
              domain={whenTicks}
              ticks={whenTicks.slice(1)}
              tickCount={whenTicks.length}
              interval={0}
              tickMargin={0}
              minTickGap={0}
              tickFormatter={(value) => tickFormatter(value, interval)}
              style={{
                fontFamily: 'Source Sans Pro',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400',
                color: vxAppColors.brand.primary.darkest,
              }}
            />
            <YAxis
              padding={{
                bottom: 0,
                top: 0,
              }}
              dataKey="price"
              type="number"
              scale="log"
              tickMargin={0}
              width={1}
              tick={false}
              domain={[priceMinValue * 0.99, priceMaxValue * 1.01]}
            />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{ backgroundColor: vxAppColors.neutral.white, outline: 'none' }}
            />
            <Line
              type="monotone"
              dataKey="price"
              width={2}
              stroke={vxAppColors.graph.principal}
              dot={<CustomizedDot />}
              activeDot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </ChartContainer>
  );
};

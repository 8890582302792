import { VXRoundedSkeleton } from 'presentation/components/micro-components/vx-skeleton/rounded/style';
import { VXSquareSkeleton } from 'presentation/components/micro-components/vx-skeleton/square/style';

import * as Styles from './styles';

export default function InvestedAmountSkeleton() {
  return (
    <Styles.InvestedAmountContainer>
      <VXSquareSkeleton width={220} height={56} skeletonColor="light" />
      <div className="invested-amount">
        <div className="invested-amount__numbers">
          <VXSquareSkeleton width={220} height={56} skeletonColor="light" />
          <div className="invested-amount__numbers--info">
            <VXSquareSkeleton width={158} height={22} skeletonColor="light" />
          </div>
        </div>

        <div className="invested-amount__image">
          <VXRoundedSkeleton size={142} skeletonColor="light" />
          <div className="invested-amount__image--info">
            <div>
              <VXSquareSkeleton width={114} height={34} skeletonColor="light" />
              <VXSquareSkeleton width={137} height={34} skeletonColor="light" />
              <VXSquareSkeleton width={124} height={34} skeletonColor="light" />
            </div>

            <div>
              <VXSquareSkeleton width={114} height={34} skeletonColor="light" />
              <VXSquareSkeleton width={137} height={34} skeletonColor="light" />
              <VXSquareSkeleton width={124} height={34} skeletonColor="light" />
            </div>
          </div>
        </div>
      </div>
    </Styles.InvestedAmountContainer>
  );
}

import { OrderSideIdEnum } from 'domain/enums/orders';
import { makeCryptoSendOrderUsecase } from 'main/factories/domain/send-order-usecase-factory';
import { RootState } from 'presentation/stores';
import { useQuotation } from 'presentation/stores/hooks/use-quotation';

import { H3 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useSelector } from 'react-redux';
import { VxButton } from '../micro-components/vx-button';
import { VXIcon, VXIconsEnum } from '../micro-components/vx-icon';
import { CloePositionConfirmationStyle } from './style';

type CloePositionConfirmationProps = {
  symbol: string;
  quantity: number;
  side: OrderSideIdEnum;
  onCloseModal: () => void;
};

export const CloePositionConfirmation: React.FC<CloePositionConfirmationProps> = ({
  symbol,
  quantity,
  side,
  onCloseModal,
}) => {
  const settingsSlice = useSelector((state: RootState) => state.settingsSlice);
  const { lastTrade } = useQuotation({ symbol });
  return (
    <CloePositionConfirmationStyle>
      <button className="icon-close" onClick={() => onCloseModal()}>
        <VXIcon icon={VXIconsEnum.close} size="xsm" color={vxAppColors.brand.primary.darkest} />
      </button>
      <div>
        <H3 title="Deseja encerrar esta posição?" fontWeight="light" color={vxAppColors.brand.primary.darkest} />
        <div>
          <VxButton
            type="secondary"
            disabled={false}
            size="small"
            width={105}
            onClick={() => onCloseModal()}
            text="CANCELAR"
          />
          <VxButton
            type="primary"
            disabled={false}
            size="small"
            width={105}
            onClick={() => {
              makeCryptoSendOrderUsecase().execute({
                symbol: symbol,
                price: lastTrade ?? 0.0,
                quantity: quantity,
                side: side,
                isLavarage: settingsSlice.environment === 'margin',
              });
            }}
            text="ENCERRAR"
          />
        </div>
      </div>
    </CloePositionConfirmationStyle>
  );
};

import { CryptoMiniChartResponseModel } from 'data/models/crypto-mini-chart-response-model';
import { AssetChartEntity, AssetChartPointEntity } from 'domain/entities/asset-chart-point-entity';
import { hasValue } from 'domain/util';

export const cryptoMiniChartMapper = (response: any): AssetChartEntity[] => {
  const data: CryptoMiniChartResponseModel = response;
  const result = (data?.data?.history ?? [])
    .filter((asset) => hasValue(asset.symbol))
    .map<AssetChartEntity>((asset) => {
      return {
        symbol: asset.symbol ?? '',
        data: (
          asset.collection?.filter((c) => hasValue(c.closePrice) && hasValue(c.updatedAt)) ?? []
        ).map<AssetChartPointEntity>((h) => {
          return {
            value: h.closePrice ?? 0,
            when: new Date(h.updatedAt ?? ''),
          };
        }),
      };
    });
  return result;
};

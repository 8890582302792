import { HttpDatasource, HttpMethodEnum } from 'data/datasources/http/http-datasource';
import { ProductBoughtResponseModel, ProductResponseModel } from 'data/models/product-response-model';
import { Either, left, right } from 'domain/entities/either';
import { ProductBoughtEntity } from 'domain/entities/product-bought-entity';
import { ProductEntity } from 'domain/entities/product-entity';
import { ProductTierEntity } from 'domain/entities/product-tier-entity';
import { Failure } from 'domain/failure/failure';
import { CancelProductBoughtRepository } from 'domain/repositories/cancel-product-bought-usecase';
import { LoadAllProductsRepository } from 'domain/repositories/load-all-products-repository';
import { LoadProductsBoughtRepository } from 'domain/repositories/load-products-bought-usecase';
import {
  SaveBuyProductRepository,
  SaveBuyProductRepositoryBuyProductParams,
} from 'domain/repositories/save-buy-product-repository';

export class ProductHttpRepository
  implements
    LoadAllProductsRepository,
    LoadProductsBoughtRepository,
    SaveBuyProductRepository,
    CancelProductBoughtRepository
{
  constructor(readonly httpDatasource: HttpDatasource, readonly baseUrl: string) {
    Object.freeze(this);
  }
  async allProducts(): Promise<Either<Failure, ProductEntity[]>> {
    const error = left<Failure, ProductEntity[]>(new Failure('Erro ao carregar da internet os contratos disponíveis'));
    try {
      const response = await this.httpDatasource.request(`${this.baseUrl}/Product/v1`);
      if (!response.ok) {
        return error;
      }
      const contracts: ProductResponseModel = response.data;
      if (!contracts.success) {
        return error;
      }
      return right<Failure, ProductEntity[]>(
        contracts.data.map((c) => ({
          id: c.id,
          name: c.fullName,
          shortName: c.shortName,
          description: c.description,
          tiers: c.tiers.map<ProductTierEntity>((t) => ({
            id: t.id,
            name: t.name,
            isFree: t.isFree,
            description: t.description,
            benefits: t.benefits,
            price: t.price,
            paymentPeriod: Number(t.paymentPeriod.id),
          })),
        }))
      );
    } catch (e) {
      return error;
    }
  }
  async cancelBoughtProduct(id: string): Promise<Either<Failure, void>> {
    const error = left<Failure, void>(new Failure(`Erro ao cancelar na internet o contrato (ID: "${id}")`));
    try {
      const response = await this.httpDatasource.request(`${this.baseUrl}/Product/v1/contract/${id}/cancel`, {
        method: HttpMethodEnum.put,
      });
      if (!response.ok) {
        return error;
      }
      return right<Failure, void>(undefined);
    } catch (e) {
      return error;
    }
  }
  async buyProduct(params: SaveBuyProductRepositoryBuyProductParams): Promise<Either<Failure, void>> {
    const error = left<Failure, void>(new Failure(`Erro ao contratar (ID:  "${params.productId}") na internet`));
    try {
      const response = await this.httpDatasource.request(`${this.baseUrl}/Product/v1/contract`, {
        method: HttpMethodEnum.post,
        data: params,
      });
      if (!response.ok) {
        return error;
      }
      return right<Failure, void>(undefined);
    } catch (e) {
      return error;
    }
  }
  async boughtProducts(): Promise<Either<Failure, ProductBoughtEntity[]>> {
    const error = left<Failure, ProductBoughtEntity[]>(
      new Failure('Erro ao carregar da internet os contratos disponíveis')
    );
    try {
      const response = await this.httpDatasource.request(`${this.baseUrl}/Product/v1/contract`);
      if (!response.ok) {
        return error;
      }
      const products: ProductBoughtResponseModel = response.data;
      if (!products.success) {
        return error;
      }
      return right<Failure, ProductBoughtEntity[]>(
        products.data.map((c) => ({
          id: c.id,
          name: c.name,
          updatedAt: new Date(c.updatedAt),
          status: Number(c.status.id),
          acceptedTerms: c.acceptedTerms,
          tier: {
            benefits: c.tier.benefits,
            id: c.tier.id,
            description: c.tier.description,
            isFree: c.tier.isFree,
            name: c.tier.name,
            price: c.tier.price,
            paymentPeriod: Number(c.tier.paymentPeriod.id),
          },
        }))
      );
    } catch (e) {
      return error;
    }
  }
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const GuaranteeAssetListStyle = styled.table`
  margin-top: 16px;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  background: ${vxAppColors.background};
  min-width: 536px;
`;

import { VXSquareSkeleton } from 'presentation/components/micro-components/vx-skeleton/square/style';
import { VXTextSkeleton } from 'presentation/components/micro-components/vx-skeleton/text/styles';
import { VxGridItem } from 'presentation/components/vx-grid';
import * as S from './styles';

export const TitleLoading = () => (
  <VxGridItem columns={3} xlg={8} lg={8} md={8}>
    <S.LoadingTitleSkeleton>
      <div className="loading">
        <div className="loading__image">
          <VXSquareSkeleton height={120} width={375} skeletonColor="light" />
        </div>
        <div className="loading__content">
          <VXTextSkeleton height={18} width={350} skeletonColor="light" />
          <VXTextSkeleton height={18} width={350} skeletonColor="light" />
          <VXTextSkeleton height={18} width={350} skeletonColor="light" />
          <VXTextSkeleton height={18} width={350} skeletonColor="light" />
          <div className="loading__content--button">
            <VXSquareSkeleton height={48} width={180} skeletonColor="light" />
          </div>
        </div>
      </div>
    </S.LoadingTitleSkeleton>
  </VxGridItem>
);

export const CardLoading = () => (
  <VxGridItem columns={3} xlg={4} lg={4} md={4} height="100%">
    <S.LoadingContentSkeleton>
      <VXSquareSkeleton height={644} width={371} skeletonColor="light" />
    </S.LoadingContentSkeleton>
  </VxGridItem>
);

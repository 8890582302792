import { createChartData } from 'data/utils/chart-util';
import WalletChart from 'presentation/components/charts/wallet_charts';
import { Dots } from 'presentation/components/micro-components/dots';
import { useAppSelector } from 'presentation/stores/hooks';
import { Infos } from './components/infos';
import InvestedAmountSkeleton from './components/invested-amount-skeleton';
import { InvestedAmountProps } from './invested-amount-props';
import * as Styles from './styles';

export function InvestedAmount({
  positions,
  isLoading,
  hasPositions,
  rentabilitySum,
  emptyPositionComponent,
  smallDesktop,
  isMobile,
}: InvestedAmountProps) {
  const { showInfo } = useAppSelector((state) => state.settingsSlice);

  if (isLoading) return <InvestedAmountSkeleton />;

  if (!hasPositions && !isLoading) {
    return emptyPositionComponent;
  }

  const chartData = createChartData(positions);

  return (
    <Styles.InvestedAmountContainer>
      <div className="invested-amount">
        <div className="invested-amount__numbers">
          {showInfo ? (
            <Infos
              positions={positions}
              rentabilitySum={rentabilitySum}
              isMobile={isMobile}
              smallDesktop={smallDesktop}
            />
          ) : (
            <div className="dots-container">
              <Dots />
              <Dots />
            </div>
          )}
        </div>
        <WalletChart listPosistion={chartData} />
      </div>
    </Styles.InvestedAmountContainer>
  );
}

import { OrderSideIdKey, OrderStatusIdKey } from 'domain/dicts';
import { Either } from 'domain/entities/either';
import { OrderDataEntity } from 'domain/entities/order-entity';
import { Failure } from 'domain/failure/failure';
import { LoadRecentOrdersRepository } from 'domain/repositories/load-recent-orders-repository';

export type LoadRecentOrdersUsecaseParams = {
  sides: OrderSideIdKey[];
  statuses: OrderStatusIdKey[];
  startAt: Date;
  endAt: Date;
};

export interface LoadRecentOrdersUsecase {
  execute(params: LoadRecentOrdersUsecaseParams): Promise<Either<Failure, OrderDataEntity[]>>;
}

export class LoadRecentOrdersUsecaseImp implements LoadRecentOrdersUsecase {
  constructor(readonly repository: LoadRecentOrdersRepository) {
    Object.freeze(this);
  }
  execute(params: LoadRecentOrdersUsecaseParams): Promise<Either<Failure, OrderDataEntity[]>> {
    return this.repository.recentOrders(params);
  }
}

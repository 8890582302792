import { removeSlashFromSymbol } from 'data/utils/symbol-util';
import { Either, left } from 'domain/entities/either';
import { OrderSideIdEnum } from 'domain/enums/orders';
import { Failure } from 'domain/failure/failure';
import { SendOrderRepository } from 'domain/repositories/send-order-repository';

export type SendOrderUsecaseParams = {
  symbol: string;
  quantity: number;
  price: number;
  side: OrderSideIdEnum;
  isLavarage: boolean;
};

export interface SendOrderUsecase {
  execute(params: SendOrderUsecaseParams): Promise<Either<Failure, void>>;
}

export class SendOrderUsecaseImp implements SendOrderUsecase {
  constructor(readonly repository: SendOrderRepository) {
    Object.freeze(this);
  }
  async execute(params: SendOrderUsecaseParams): Promise<Either<Failure, void>> {
    try {
      const symbolWithUSD = removeSlashFromSymbol(params.symbol) + '/USD';
      params.symbol = symbolWithUSD;
      return this.repository.sendOrder(params);
    } catch (e) {
      return left(new Failure('Erro ao enviar ordem'));
    }
  }
}

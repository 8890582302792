import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { VxButtonTypeLg, VxButtonTypeMd } from '../../../styleguide/typography/body/VxBody';
import { Spinner } from '../spinner';
import { VXIcon, VXIconsEnum } from '../vx-icon';
import * as S from './styles';

export interface VxButtonProps {
  /**
   * primary = vxAppColors.brand.tertiary.principal;
   *
   * secondary = vxAppColors.neutral.white;
   *
   * tertiary = vxAppColors.brand.primary.principal;
   *
   * callToAction = vxAppColors.feedback.success.default;
   */
  type: 'primary' | 'secondary' | 'tertiary' | 'callToAction';
  disabled: boolean;
  size: 'extraSmall' | 'small' | 'regular' | 'large';
  onClick: () => void;
  isLoading?: boolean;
  prefixIcon?: VXIconsEnum;
  sufixIcon?: VXIconsEnum;
  /**
   * xsm = 8px;
   *
   * sm = 16px;
   *
   * md = 24px;
   *
   * lg = 32px;
   *
   * xlg = 40px;
   *
   * xxlg = 48px;
   */
  prefixIconSize?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
  /**
   * xsm = 8px;
   *
   * sm = 16px;
   *
   * md = 24px;
   *
   * lg = 32px;
   *
   * xlg = 40px;
   *
   * xxlg = 48px;
   */
  sufixIconSize?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
  prefixIconColor?: VxAppColor;
  sufixIconColor?: VxAppColor;
  text: string;
  width?: number;
  /**
   * md = 12px;
   * lg = 14px;
   */
  buttonTextType?: 'md' | 'lg';
}

const VxButton: React.FunctionComponent<VxButtonProps> = ({
  type,
  disabled,
  onClick,
  size,
  prefixIcon,
  sufixIcon,
  prefixIconSize,
  sufixIconSize,
  prefixIconColor,
  sufixIconColor,
  isLoading,
  text,
  width,
  buttonTextType,
}) => {
  const ButtonTextType = () => {
    if (buttonTextType === 'lg') {
      return (
        <VxButtonTypeLg
          title={text}
          color={type === 'secondary' ? vxAppColors.neutral.darkest : vxAppColors.neutral.white}
          fontWeight="medium"
        />
      );
    }

    return (
      <VxButtonTypeMd
        title={text}
        color={type === 'secondary' ? vxAppColors.neutral.darkest : vxAppColors.neutral.white}
        fontWeight="medium"
      />
    );
  };
  return (
    <S.Button btnType={type} disabled={disabled} size={size} onClick={onClick} width={width}>
      <S.Content btnType={type} disabled={disabled} size={size} width={width}>
        {isLoading ? (
          <Spinner size={18} />
        ) : (
          <>
            {prefixIcon && prefixIconSize && prefixIconColor && (
              <VXIcon icon={prefixIcon} size={prefixIconSize} color={prefixIconColor} />
            )}
            <ButtonTextType />
            {sufixIcon && sufixIconSize && sufixIconColor && (
              <VXIcon icon={sufixIcon} size={sufixIconSize} color={sufixIconColor} />
            )}
          </>
        )}
      </S.Content>
    </S.Button>
  );
};
export { VxButton };

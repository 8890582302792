import { HttpResponse } from '../../data/datasources/http/http-response';

export class HttpResponseImp implements HttpResponse {
  constructor(public readonly statusCode: number, public readonly data: unknown = null) {
    Object.freeze(this);
  }
  get ok(): boolean {
    return this.statusCode >= 200 && this.statusCode < 300;
  }
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const ExtractCardWrapper = styled.div`
  @media (max-width: 1193px) {
    margin-left: 76px;
  }
`;
export const ExtractPageStyle = styled.article`
  background-color: ${vxAppColors.neutral.white};
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  section.extract-list {
    &-empty {
      display: flex;
      flex: 1;
      @media (max-width: 1193px) {
        width: 100%;
      }
      .empty-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 24px;
        gap: 24px;
        height: 263px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        background-color: ${vxAppColors.neutral.white};
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;
          margin-bottom: 64px;
        }
        button.clear-filter-btn {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px;
          gap: 10px;
          border: none;
          background: transparent;
          cursor: pointer;
        }
      }
    }
  }
`;

import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { formatNumbers } from 'data/utils/currency-util';
import { makeSaveDepositUsecase } from 'main/factories/domain/save-deposit-usecase-factory';
import { VexterCard } from 'presentation/components/card';
import { Dot } from 'presentation/components/micro-components/dots/styles';
import { vexterToastError, vexterToastSuccess } from 'presentation/components/micro-components/toast';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VxMoneyInput } from 'presentation/components/micro-components/vx-money-input';
import { useGenialPatrimony } from 'presentation/stores/hooks/use-genial-patrimony';

import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { VxBodyLg } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useCallback, useState } from 'react';
import { OperationForm, ValueItensAlignment } from '../styles';

export const DepositContent: React.FunctionComponent = () => {
  const { genialPatrimony, isLoadingGenialPatrimony } = useGenialPatrimony();
  const formatOptionsEstimatedValue = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };
  const [amount, setAmount] = useState<number>(0);
  const [checked, setChecked] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const handleDepositConfirmation = useCallback(() => {
    if (!amount) return;
    makeSaveDepositUsecase()
      .execute(amount)
      .then((savedOrError) => {
        savedOrError.fold(
          () => {
            vexterToastError({
              title: 'Opa, alguma coisa deu errado',
              message: 'Por favor, tente novamente mais tarde!',
              hasClose: true,
            });
          },
          () => {
            setAmount(0);
            vexterToastSuccess({
              title: 'Deposito realizado com sucesso',
              hasClose: true,
            });
          }
        );
      })
      .catch(console.error);
  }, [amount]);

  const depositAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setAmount(genialPatrimony?.saldoCliente || 0) : setAmount(0);
    setChecked(event.target.checked);
    setDisableInput(event.target.checked);
  };

  return (
    <VxGridContainer>
      <VxGridItem columns={8}>
        <OperationForm>
          <VxBodyLg
            title="Deposite da sua conta Genial para carteira Cripto"
            fontWeight="bold"
            color={vxAppColors.neutral.darkest}
          />
          <VxBodyLg
            title="Para movimentar sua carteira Cripto você pode utilizar do seu saldo na Genial apenas fazendo um deposito rápido e facil."
            fontWeight="light"
            color={vxAppColors.neutral.darkest}
          />
          <ValueItensAlignment>
            <VexterCard>
              <Box padding="2rem">
                <VxBodyLg title="Disponivel para Depositar:" fontWeight="bold" color={vxAppColors.neutral.darkest} />
                {!isLoadingGenialPatrimony ? (
                  <VxBodyLg
                    title={formatNumbers(genialPatrimony?.saldoCliente, formatOptionsEstimatedValue)}
                    fontWeight="medium"
                    color={vxAppColors.neutral.darkest}
                  />
                ) : (
                  <Dot />
                )}
              </Box>
            </VexterCard>
          </ValueItensAlignment>
          <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
            <VxMoneyInput
              value={amount}
              onChange={(value) => setAmount(value ?? 0)}
              label={'Valor que deseja transferir'}
              disable={disableInput}
              maximum={genialPatrimony?.saldoCliente}
              setHasError={setHasError}
            />
            <FormControlLabel
              sx={{ padding: '1rem' }}
              control={<Checkbox color="default" onChange={depositAll} checked={checked} />}
              label="Realizar depósito total"
            />
          </FormGroup>
          <VxButton
            text="Confirmar"
            type="primary"
            onClick={handleDepositConfirmation}
            disabled={amount <= 0 || hasError}
            size="large"
          />
        </OperationForm>
      </VxGridItem>
    </VxGridContainer>
  );
};

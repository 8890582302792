import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const StyledPlatformPageTitleContainer = styled.div`
  margin-top: 32px;
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1440px) {
    height: 61px;
  }
`;

export const StyledPlatformPageTabContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: start;
  margin: 40px 0px;
  button {
    width: fit-content;
    white-space: nowrap;
    p {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
`;

export const CardContainer = styled.div`
  height: fit-content;
`;

export const StyledPlatformPageDescriptionTitle = styled.div`
  display: flex;
  justify-content: start;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media (min-width: 1920px) {
    flex-direction: column;
  }

  @media (max-width: 1919px) {
    margin-bottom: 24px;
  }
`;

export const StyledPlatformPageDescriptionButton = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: end;
  margin-top: 74px;
  margin-bottom: 32px;
  @media (max-width: 1440px) {
    height: 34px;
    margin-bottom: 24px;
  }
`;

export const StyledPlatformPageDescriptionTitleImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 24px;
  background-color: #181f24;
  height: 120px;

  img {
    width: 232px;
    height: 56px;
    object-fit: contain;
    border-radius: 8px;
  }
  @media (max-width: 1024px) {
    height: 100%;
    border-radius: 8px;
  }
  @media (min-width: 1025px) and (max-width: 1439px) {
    width: 424px;
    height: 100%;
    border-radius: 8px;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    height: 120px;
    height: 100%;
    border-radius: 8px;
  }
`;

export const StyledPlatformPageDescriptionTitleInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  white-space: pre-wrap;
  margin-top: 3px;
  padding-right: 24px;
  padding-left: 21px;

  @media (max-width: 1919px) {
    padding-top: 24px;
    padding-left: 48px;
  }
  @media (min-width: 1441px) {
    padding-left: 24px;
  }
  @media (min-width: 1920px) {
    padding-left: 24px;
    flex-direction: column;
  }
`;

export const StyledPlatformPageDescriptionTitleInfoSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  white-space: pre-wrap;
  margin-top: 27px;
  div {
    margin-bottom: 8px;
  }
`;

export const StyledPlatformPageDescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 24px 24px 8px 24px;
  height: 100%;

  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const StyledPlatformPageItemContainer = styled.div`
  display: flex;
  @media (max-width: 1440px) {
    gap: 24px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const StyledPlatformPageDescriptionItemSkeleton = styled.div`
  margin: 0px 12px;
`;

export const CancelContainer = styled.div`
  padding: 32px;
`;

export const CancelCloseIconContainer = styled.div`
  height: 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  div {
    height: 24px;
    width: 24px;
    border: 1px solid ${vxAppColors.brand.primary.principal};
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CancelTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const CancelButtonContainerContainer = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  button {
    span {
      font-size: 14px;
      line-height: 17.6px;
    }
  }
  button:first-child {
    background-color: ${vxAppColors.neutral.white};
    width: 203px;
    span {
      color: ${vxAppColors.neutral.darkest};
    }
  }
  button:last-child {
    background-color: ${vxAppColors.feedback.error.default};
    width: 100px;
  }
`;

export const ConfirmationContainer = styled.div`
  padding: 24px 32px;
`;

export const StyledPlatformPageDescriptionItemTitleRow = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledPlatformPageDescriptionItemSelected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  border: 0.5px solid ${vxAppColors.neutral.light};
  margin-right: 8px;
  cursor: pointer;
`;

export const StyledPlatformPageSeletectedDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${vxAppColors.brand.primary.principal};
`;

type StyledPlatformPageDescriptionItemValueProps = {
  isFree: boolean;
};

export const StyledPlatformPageDescriptionItemValue = styled.div<StyledPlatformPageDescriptionItemValueProps>`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
  span {
    margin-right: 8px;
    :nth-child(2) {
      text-decoration: ${(props) => (props.isFree ? 'line-through' : 'none')};
    }
  }
`;

export const StyledPlatformPageDescriptionItemDivider = styled.div`
  margin-bottom: 16px;
  padding: 0px 4px;
  hr {
    color: ${vxAppColors.neutral.lightest};
  }
`;
export const StyledPlatformPageDescriptionItemContainer = styled.div`
  display: flex;
  @media (min-width: 1025px) {
    flex-direction: column;
  }
`;

export const StyledPlatformPageDescriptionItemBenefitContainer = styled.div`
  @media (max-width: 1024px) {
    width: 50%;
  }
`;

export const StyledPlatformPageDescriptionItemContent = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  height: 34px;
`;

export const StyledPlatformPageDescriptionItemContentIcon = styled.div`
  margin-right: 8px;
`;

export const CurrentItemContainer = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CurrentItemSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 1024px) {
    max-width: 624px;
    min-width: 624px;
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    max-width: 424px;
    min-width: 424px;
  }
  @media (min-width: 1441px) {
    max-width: 371px;
    min-width: 371px;
    margin-left: 36px;
    margin-right: 12px;
  }
`;

export const CurrentItemTitleContainer = styled.div`
  margin-bottom: 16px;
`;

export const CurrentItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  @media (max-width: 1024px) {
    max-height: 149px;
    min-height: 149px;
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    max-height: 170px;
    min-height: 170px;
  }
`;

export const CurrentItemContentDescriptionContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const CurrentItemActiveBadgeContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 21px;
  div {
    display: flex;
    border-radius: 21px;
    justify-content: center;
    align-items: center;
    width: 61px;
    height: 26px;
    background-color: ${vxAppColors.feedback.success.default};
  }
`;

type CurrentItemValueContainerProps = {
  isFree: boolean;
};

export const CurrentItemValueContainer = styled.div<CurrentItemValueContainerProps>`
  display: flex;
  align-items: center;
  span {
    :nth-child(2) {
      text-decoration: ${(props) => (props.isFree ? 'line-through' : 'none')};
    }
  }
`;

export const CancelItemContentContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const StyledPlatformPageDescriptionItemDescription = styled.div`
  margin-bottom: 16px;
`;

export const CancelItemButtonContainer = styled.div`
  span {
    color: ${vxAppColors.brand.primary.darkest};
  }
  button {
    background-color: ${vxAppColors.neutral.white};
  }
`;

export const ConfirmationCloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 24px;
  width: 100%;
  cursor: pointer;
  div {
    height: 24px;
    width: 24px;
    border: 1px solid ${vxAppColors.brand.primary.principal};
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ConfirmationTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29px;
  margin-bottom: 24px;
`;

export const ConfirmationProductNameContainer = styled.div`
  height: 29px;
  margin-bottom: 16px;
`;

type ConfirmationValueContainerProps = {
  isFree: boolean;
};

export const ConfirmationValueContainer = styled.div<ConfirmationValueContainerProps>`
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    :first-child {
      margin-right: 14px;
    }
    :last-child {
      margin-left: ${(props) => (props.isFree ? 14 : 0)}px;
    }
  }
`;

export const ConfirmationTermsReceivedContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 4px;
  cursor: pointer;
`;

export const ConfirmationButtonContainer = styled.div`
  margin-top: 33px;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: start;
`;

export const StyledPlatformModalTermsAcceptTitleDivider = styled.hr`
  border-color: lightblue;
  border-width: 0.5px;
  width: 464px;
  margin: 0px;
  transform: translateX(-25px);
`;

export const StyledPlatformModalTermsAcceptValueDivider = styled.hr`
  border-color: lightblue;
  border-width: 0.5px;
  width: 416px;
  margin-top: 20px;
  margin-bottom: 28px;
`;

export const StyledPlatformModalTermsAcceptProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 68px;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const StyledPlatformModalTermsAcceptTermReadConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 48px;
`;

export const StyledPlatformModalCancelIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  :hover {
    cursor: pointer;
  }
`;

export const StyledPlatformModalCancelInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  span {
    text-align: center;
  }
`;

import { useMediaQuery } from '@mui/material';
import { VxBodyMd, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';

import { VXIcon, VXIconsEnum } from '../vx-icon';
import { MenuButtonStyle } from './style';

type MenuButtonType = {
  text?: string;
  icon: VXIconsEnum;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
  disabled?: boolean;
};

const MenuButton: React.FunctionComponent<MenuButtonType> = (props) => {
  const isMobile = useMediaQuery('(max-width: 1919px)');

  return (
    <MenuButtonStyle disabled={props.disabled} selected={props.selected} onClick={props.onClick}>
      {isMobile ? (
        <VXIcon color={vxAppColors.brand.secondary.principal} size="sm" icon={props.icon} />
      ) : (
        <VXIcon color={vxAppColors.brand.secondary.principal} size="md" icon={props.icon} />
      )}

      {props.text && (
        <>
          {isMobile ? (
            <VxBodyXs title={props.text ?? ''} fontWeight="regular" color={vxAppColors.neutral.white} />
          ) : (
            <VxBodyMd title={props.text ?? ''} fontWeight="medium" color={vxAppColors.neutral.white} />
          )}
        </>
      )}
    </MenuButtonStyle>
  );
};

export { MenuButton };

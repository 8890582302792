import { CacheDatasource } from 'data/datasources/cache/cache-datasource';
import { CacheStorageEnum } from 'data/datasources/entities/cache-storage-enum';
import { Either, left, OrdersReport, right } from 'domain/entities';
import { Failure } from 'domain/failure/failure';
import { LoadOrdersReportRepository } from 'domain/repositories/load-orders-report-repository';
import { SaveOrdersReportRepository } from 'domain/repositories/save-orders-report-repository';

export class OrdersHistoryCacheRepository implements LoadOrdersReportRepository, SaveOrdersReportRepository {
  constructor(readonly datasource: CacheDatasource) {
    Object.freeze(this);
  }
  async loadOrdersReport(): Promise<Either<Failure, OrdersReport[]>> {
    try {
      const ordersReport: any[] | undefined = await this.datasource.read(CacheStorageEnum.orderHistory);
      return right(
        ordersReport?.map<OrdersReport>((report) => ({
          endAt: new Date(report.endAt),
          startAt: new Date(report.startAt),
          generatedAt: new Date(report.generatedAt),
          orders: report.orders,
        })) ?? []
      );
    } catch (_) {
      return left(new Failure('Erro ao salvar os relatórios de ordens do Local Storage'));
    }
  }
  async saveOrdersReport(params: OrdersReport): Promise<Either<Failure, void>> {
    try {
      const oldReports = await this.loadOrdersReport().then((reportsOrError) =>
        reportsOrError.fold(
          () => [],
          (reports) => reports
        )
      );
      if (oldReports.length > 5) {
        oldReports.slice(4, 1);
      }
      await this.datasource.write(CacheStorageEnum.orderHistory, [...oldReports, params]);
      return right(undefined);
    } catch (_) {
      return left(new Failure('Erro ao carregar os relatórios de ordens do Local Storage'));
    }
  }
}

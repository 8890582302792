import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const PatrimonyBlocked = styled.div`
  background-color: ${vxAppColors.neutral.white};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  gap: 16px;

  height: 77px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  margin-bottom: 24px;
`;

export const Aside = styled.aside`
  @media (max-width: 1280px) {
    margin-top: 24px;
  }
`;

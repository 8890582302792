import styled from 'styled-components';

export const CloePositionConfirmationStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  gap: 24px;
  > button.icon-close {
    margin-left: auto;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    background: none;
    border: 0.5px solid #050922;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    > div {
      display: flex;
      gap: 16px;
    }
  }
`;

import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import styled from 'styled-components';

export const MarginAllocation = styled.main`
  padding-top: 24px;
  padding-bottom: 24px;
  padding-inline: 40px;
  display: flex;
  gap: 24px;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  section {
    display: flex;
    flex-direction: column;
  }
  aside {
    min-width: 504px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    height: fit-content;
    @media (max-width: ${DESKTOP_XS}px) {
      min-width: 312px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 56px;
  padding-top: 32px;
  padding-right: 48px;
  .right-content {
    display: flex;
    max-width: 504px;
  }
`;

export const Content = styled.div`
  width: 65%;
  margin-right: 32px;

  h4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: start;

  th {
    text-align: start;
  }
  tr {
    width: 100%;
  }
  tr {
    width: 100%;
  }
`;

export const MarginContainer = styled.div`
  max-width: 504px;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;

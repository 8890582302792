import styled from 'styled-components';

export const Thead = styled.thead`
  height: 40px;
  width: 100%;
  @media (max-width: 1024px) {
    height: 34px;
  }
  tr {
    th {
      vertical-align: middle;
      text-align: left;
      padding-left: 8px;
    }
  }
`;

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import {
  DESKTOP_FHD,
  DESKTOP_FHD_GRID_WIDTH,
  DESKTOP_FHD_USABLE_WIDTH,
  DESKTOP_LG,
  DESKTOP_LG_GRID_WIDTH,
  DESKTOP_LG_USABLE_WIDTH,
  DESKTOP_MD,
  DESKTOP_MD_GRID_WIDTH,
  DESKTOP_MD_USABLE_WIDTH,
  DESKTOP_SM,
  DESKTOP_SM_GRID_WIDTH,
  DESKTOP_SM_USABLE_WIDTH,
  DESKTOP_XS,
  DESKTOP_XS_GRID_WIDTH,
  DESKTOP_XS_USABLE_WIDTH,
  getGridPercentage,
  getGridVw,
  GRID_FHD_COLUMNS,
  GRID_GAP,
  GRID_MOBILE_COLUMNS,
  GRID_XS_COLUMNS,
  MARGIN_LG_DESKTOP,
  MARGIN_MOBILE,
  MARGIN_XL_DESKTOP,
  MOBILE,
  MOBILE_GRID_WIDTH,
  MOBILE_USABLE_WIDTH,
} from '../helpers';

type GridContainerProps = {
  debug?: boolean;
};

export const GridContainer = styled.section<GridContainerProps>`
  display: grid;
  width: ${getGridVw(DESKTOP_FHD_USABLE_WIDTH, DESKTOP_FHD)};
  grid-template-columns: repeat(
    ${GRID_FHD_COLUMNS},
    ${getGridPercentage(DESKTOP_FHD_GRID_WIDTH, DESKTOP_FHD_USABLE_WIDTH)}
  );

  ${(props) => `{background-color:  ${props.debug ? vxAppColors.brand.secondary.darkest : 'transparent'};}`}

  grid-column-gap: ${getGridPercentage(GRID_GAP, DESKTOP_FHD_USABLE_WIDTH)};
  height: fit-content;
  margin-left: ${getGridVw(MARGIN_XL_DESKTOP, DESKTOP_FHD)};

  @media (min-width: 1440px) and (max-width: 1919px) {
    width: ${getGridVw(DESKTOP_LG_USABLE_WIDTH, DESKTOP_LG)};

    grid-template-columns: repeat(
      ${GRID_FHD_COLUMNS},
      ${getGridPercentage(DESKTOP_LG_GRID_WIDTH, DESKTOP_LG_USABLE_WIDTH)}
    );
    grid-column-gap: ${getGridPercentage(GRID_GAP, DESKTOP_LG_USABLE_WIDTH)};

    margin-left: ${getGridVw(MARGIN_LG_DESKTOP, DESKTOP_LG)};
  }

  @media (min-width: 1366px) and (max-width: 1439px) {
    width: ${getGridVw(DESKTOP_MD_USABLE_WIDTH, DESKTOP_MD)};

    grid-template-columns: repeat(
      ${GRID_FHD_COLUMNS},
      ${getGridPercentage(DESKTOP_MD_GRID_WIDTH, DESKTOP_MD_USABLE_WIDTH)}
    );
    grid-column-gap: ${getGridPercentage(GRID_GAP, DESKTOP_MD_USABLE_WIDTH)};

    margin-left: ${getGridVw(MARGIN_LG_DESKTOP, DESKTOP_MD)};
  }

  @media (min-width: 1280px) and (max-width: 1365px) {
    width: ${getGridVw(DESKTOP_SM_USABLE_WIDTH, DESKTOP_SM)};

    grid-template-columns: repeat(
      ${GRID_FHD_COLUMNS},
      ${getGridPercentage(DESKTOP_SM_GRID_WIDTH, DESKTOP_SM_USABLE_WIDTH)}
    );
    grid-column-gap: ${getGridPercentage(GRID_GAP, DESKTOP_SM_USABLE_WIDTH)};

    margin-left: ${getGridVw(MARGIN_LG_DESKTOP, DESKTOP_SM)};
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    width: ${getGridVw(DESKTOP_XS_USABLE_WIDTH, DESKTOP_XS)};

    grid-template-columns: repeat(
      ${GRID_XS_COLUMNS},
      ${getGridPercentage(DESKTOP_XS_GRID_WIDTH, DESKTOP_XS_USABLE_WIDTH)}
    );
    grid-column-gap: ${getGridPercentage(GRID_GAP, DESKTOP_XS_USABLE_WIDTH)};

    margin-left: ${getGridVw(MARGIN_LG_DESKTOP, DESKTOP_XS)};
  }

  @media (max-width: 1023px) {
    width: ${getGridVw(MOBILE_USABLE_WIDTH, MOBILE)};

    grid-template-columns: repeat(${GRID_MOBILE_COLUMNS}, ${getGridPercentage(MOBILE_GRID_WIDTH, MOBILE_USABLE_WIDTH)});
    grid-column-gap: ${getGridPercentage(GRID_GAP, MOBILE_USABLE_WIDTH)};

    margin-left: ${getGridVw(MARGIN_MOBILE, MOBILE)};
  }
`;

import { GenialPatrimonyResponseModel } from 'data/models/genial-patrimony-response-model';
import { VexterPatrimonyResponseModel } from 'data/models/vexter-patrimony-response-model';
import { PatrimonyEntity } from 'domain/entities/patrimony-entity';
import { Failure } from 'domain/failure/failure';
import { SessionStorageEnum } from 'domain/repositories/session-storage/session-storage-enum';
import { getSessionStorage } from 'domain/repositories/session-storage/session_storage';
import { ListenPatrimonyUsecase, ListenPatrimonyUsecaseImp } from 'domain/usecases/listen-patrimony-usecase';
import { makeWsListenPatrimonyRepository } from '../repositories/ws/patrimony-ws-repository-factory';

const vexterListenPatrimonyUsecase: ListenPatrimonyUsecase | null = null;

export const makeVexterListenPatrimonyUsecase = (): ListenPatrimonyUsecase => {
  if (vexterListenPatrimonyUsecase === null) {
    const socketUrl = process.env.REACT_APP_WS_PATRIMONY_CRYPTO ?? '';
    if (socketUrl === '') {
      throw new Failure('Falta definir variável de ambiente ("REACT_APP_WS_PATRIMONY_CRYPTO")');
    }
    const repository = makeWsListenPatrimonyRepository({
      url: socketUrl,
      wsResponseMapper: (data: VexterPatrimonyResponseModel) => {
        const response: PatrimonyEntity = {
          saldoCliente: data?.saldoCliente,
          saldoBloqueado: data?.saldoBloqueado,
        };
        return response;
      },
      loadPnpAccount: () => Number(getSessionStorage(SessionStorageEnum.account)),
    });
    return new ListenPatrimonyUsecaseImp(repository);
  }
  return vexterListenPatrimonyUsecase;
};

const genialListenPatrimonyUsecase: ListenPatrimonyUsecase | null = null;

export const makeGenialListenPatrimonyUsecase = (): ListenPatrimonyUsecase => {
  if (genialListenPatrimonyUsecase === null) {
    const socketUrl = process.env.REACT_APP_WS_PATRIMONY_STOCK ?? '';
    if (socketUrl === '') {
      throw new Failure('Falta definir variável de ambiente ("REACT_APP_WS_PATRIMONY_STOCK")');
    }
    const repository = makeWsListenPatrimonyRepository({
      url: socketUrl,
      wsResponseMapper: (data: GenialPatrimonyResponseModel) => {
        const response: PatrimonyEntity = {
          saldoCliente: data?.valorDisponivelSwing,
        };
        return response;
      },
      loadPnpAccount: () => getSessionStorage(SessionStorageEnum.account),
    });
    return new ListenPatrimonyUsecaseImp(repository);
  }
  return genialListenPatrimonyUsecase;
};

import { Either } from 'domain/entities/either';
import { PatrimonyEntity } from 'domain/entities/patrimony-entity';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { Failure } from 'domain/failure/failure';
import { ListenPatrimonyRepository } from 'domain/repositories/listen-patrimony-repository';

export interface ListenPatrimonyUsecase {
  execute(
    callback: (patrimony: PatrimonyEntity | undefined) => void
  ): Promise<Either<Failure, SubscriptionCancelEntity>>;
}

export class ListenPatrimonyUsecaseImp implements ListenPatrimonyUsecase {
  constructor(readonly repository: ListenPatrimonyRepository) {
    Object.freeze(this);
  }
  execute(
    callback: (patrimony: PatrimonyEntity | undefined) => void
  ): Promise<Either<Failure, SubscriptionCancelEntity>> {
    return this.repository.listenPatrimony(callback);
  }
}

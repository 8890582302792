import { SideBarRoutesEnum, sideBarRoutesNames } from 'config/routes/router-enum';
import { RootState } from 'presentation/stores';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VXIcon, VXIconsEnum } from '../vx-icon';
import { TitleContainer } from './style';

export const VxBackTitle: React.FC = () => {
  const sideBarRoute = useSelector((state: RootState) => state.settingsSlice.sideBarRoute);
  const previousRouteName = sideBarRoute
    ? ` para ${sideBarRoutesNames[Object.values<string>(SideBarRoutesEnum).indexOf(sideBarRoute)]}`
    : '';
  const navigate = useNavigate();
  return (
    <TitleContainer onClick={() => navigate(-1)}>
      <div className="title-button-container">
        <VXIcon icon={VXIconsEnum.arrowLeft} size="sm" color={vxAppColors.neutral.darkest} />
      </div>
      <H5 title={`Voltar${previousRouteName}`} fontWeight="regular" color={vxAppColors.neutral.dark} />
    </TitleContainer>
  );
};

import { Either, OrdersReport } from 'domain/entities';
import { Failure } from 'domain/failure/failure';
import { LoadOrdersReportRepository } from 'domain/repositories/load-orders-report-repository';

export interface LoadOrdersReportUsecase {
  execute: () => Promise<Either<Failure, OrdersReport[]>>;
}

export class LoadOrdersReportUsecaseImp implements LoadOrdersReportUsecase {
  constructor(readonly repository: LoadOrdersReportRepository) {
    Object.freeze(this);
  }
  execute(): Promise<Either<Failure, OrdersReport[]>> {
    return this.repository.loadOrdersReport().then((reportsOrError) =>
      reportsOrError.map((reports) => {
        reports.sort((a, b) => b.generatedAt.getTime() - a.generatedAt.getTime());
        return reports;
      })
    );
  }
}

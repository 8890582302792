import { OrdersHttpRepository } from 'data/repositories/http/orders-http-repository';
import { CancelOrderRepository } from 'domain/repositories/cancel-order-repository';
import { LoadRecentOrdersRepository } from 'domain/repositories/load-recent-orders-repository';
import { SendOrderRepository } from 'domain/repositories/send-order-repository';
import { makeCryptoHttpDatasource } from 'main/factories/datasources/http/http-datasource-factory';
import { makeCryptoUrlDatasource } from 'main/factories/datasources/url/url-datasource-factory';

let ordersHttpRepository: OrdersHttpRepository | null = null;

const makeOrdersHttpRepository = (): OrdersHttpRepository => {
  if (ordersHttpRepository === null) {
    ordersHttpRepository = new OrdersHttpRepository({
      httpDatasource: makeCryptoHttpDatasource(),
      urlDatasource: makeCryptoUrlDatasource(),
    });
  }
  return ordersHttpRepository;
};

export const makeSendOrderHttpRepository = (): SendOrderRepository => {
  return makeOrdersHttpRepository();
};

export const makeCancelOrderHttpRepository = (): CancelOrderRepository => {
  return makeOrdersHttpRepository();
};

export const makeLoadRecentOrdersRepository = (): LoadRecentOrdersRepository => {
  return makeOrdersHttpRepository();
};

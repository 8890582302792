import styled from 'styled-components';

export const HomeAssetsStyle = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > table {
    border-collapse: collapse;
    border-radius: 8px;
    width: 100%;
    table-layout: fixed;
    min-width: 536px;
    thead {
      height: 40px;
      tr {
        th {
          vertical-align: middle;
          text-align: left;
          padding-left: 8px;
          :nth-child(1) {
            padding-left: 16px;
          }
          @media (max-width: 1550px) {
            :nth-child(2) {
              text-align: end;
            }
          }
          @media (max-width: 1180px) {
            :nth-child(2) {
              padding-right: 50px;
            }
          }
        }
      }
    }
  }
`;

import { formatDate } from 'data/utils/date-time-util';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H4, H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { ExportCardStyle } from './styles';

dayjs.locale('pt-br');
dayjs.extend(relativeTime);

export interface ExportCardListItem {
  data: any[];
  startDate: string;
  endDate: string;
  generatedAt?: string;
}

type ExtractExportProps = {
  title: string;
  subTitle: string;
  createRecordButtonText: string;
  onDownload: (e: any) => void;
  onCreateRecord: () => void;
  userExportedList: ExportCardListItem[];
  filterResult: ExportCardListItem[];
};

export const ExportCard: React.FunctionComponent<ExtractExportProps> = ({
  title,
  subTitle,
  createRecordButtonText: exportButtonText,
  onDownload,
  onCreateRecord,
  userExportedList,
  filterResult,
}) => {
  return (
    <ExportCardStyle>
      <H4 title={title} color={vxAppColors.neutral.dark} fontWeight="light" />
      <VxBodyMd title={subTitle} fontWeight="light" color={vxAppColors.brand.primary.darkest} />
      <div className="container-export-button">
        <VxButton
          text={exportButtonText}
          disabled={filterResult.length === 0}
          onClick={onCreateRecord}
          size="small"
          type="primary"
        />
      </div>
      <div className="extract-list-container">
        <H4 title="Relatórios gerados" color={vxAppColors.neutral.dark} fontWeight="light" />
        <div className="extract-list">
          {userExportedList.length === 0 && (
            <div className="empty-list">
              <H5
                title="Você ainda não possui relatórios disponíveis"
                fontWeight="medium"
                color={vxAppColors.neutral.dark}
              />

              <VxBodySm
                title="Quando tiver, eles aparecerão aqui"
                fontWeight="regular"
                color={vxAppColors.neutral.darkest}
              />
            </div>
          )}
          {userExportedList.length > 0 &&
            userExportedList.map((extract, i) => (
              <div className="extract-container" key={i}>
                <div className="extract-item">
                  <VxBodyXs
                    title={dayjs(extract.generatedAt ?? Date.now()).toNow()}
                    fontWeight="regular"
                    color={vxAppColors.neutral.dark}
                  />
                  <div className="extract-item-date">
                    <VxBodySm
                      title={formatDate(new Date(extract.startDate))}
                      fontWeight="regular"
                      color={vxAppColors.neutral.darkest}
                    />
                    <div className="extract-item-dot" />
                    <VxBodySm
                      title={formatDate(new Date(extract.endDate))}
                      fontWeight="regular"
                      color={vxAppColors.neutral.darkest}
                    />
                  </div>
                </div>
                <div onClick={() => onDownload(extract.data)}>
                  <VXIcon icon={VXIconsEnum.download} size="sm" color={vxAppColors.brand.primary.darkest} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </ExportCardStyle>
  );
};

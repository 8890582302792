import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

interface CarouselButton {
  showButton: boolean;
}

export const ButtonPrev = styled.button<CarouselButton>`
  position: absolute;
  height: 40px;
  width: 40px;
  z-index: 2;
  background: ${vxAppColors.neutral.white};
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  cursor: pointer;
  border: none;
  display: ${(props) => (props.showButton ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  transform: translateX(-20px) translateY(90px);
  @media (max-width: 1180px) {
    transform: none;
    position: absolute;
    left: auto;
    transform: translateY(90px) translateX(-22px);
  }
`;
export const ButtonNext = styled.button<CarouselButton>`
  transform: translateY(90px) translateX(-18px);
  position: absolute;
  left: 100%;
  height: 40px;
  width: 40px;
  z-index: 2;
  background: ${vxAppColors.neutral.white};
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  cursor: pointer;
  border: none;
  display: ${(props) => (props.showButton ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  @media (max-width: 1180px) {
    transform: none;
    position: absolute;
    left: 100%;
    transform: translateY(90px) translateX(-22px);
  }
`;

import { formatDouble, formatNumbers, unmaskMoney } from 'data/utils/currency-util';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { InputStatus, VxInput } from 'presentation/components/micro-components/vx-input';
import { DESKTOP_XS, MOBILE } from 'presentation/components/vx-grid/helpers';
import usePosition from 'presentation/stores/hooks/use-position';
import { useQuotation } from 'presentation/stores/hooks/use-quotation';
import { useVexterPatrimony } from 'presentation/stores/hooks/use-vexter-patrimony';

import { VxBodyMd, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody.stories';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { VxBilletSide, VxBilletType } from '../..';
import { BilletContainer, ButtonContainer } from '../../style';
import { VxBilletItem } from '../vx-billet-item';
import { VxBilletTitle } from '../vx-billet-title';
import { VxBilletEditingCheckbox } from './components/vx-billet-editing-checkbox';
import {
  BodyContainer,
  BuyAndSellContainer,
  ItemContainer,
  ValueAndQuantityContainer,
  ValueOptionsContainer,
  WalletAndPatrimonyContainer,
  WalletAndPatrimonySectionContainer,
} from './style';

export type VxBilletEditingProps = {
  onClose?: () => void;
  symbol: string;
  quantity?: string;
  setQuantity: React.Dispatch<React.SetStateAction<string | undefined>>;
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  onBuy: () => void;
  onSell: () => void;
  isTypingValue: boolean;
  setIsTypingValue: React.Dispatch<React.SetStateAction<boolean>>;
  type?: VxBilletType;
  side?: VxBilletSide;
  onItemClick?: () => void;
};

export const VxBilletEditing: React.FC<VxBilletEditingProps> = ({
  onClose,
  symbol,
  quantity,
  value,
  setQuantity,
  setValue,
  onBuy,
  onSell,
  isTypingValue,
  setIsTypingValue,
  type = 'modal',
  side,
  onItemClick,
}) => {
  const { vexterPatrimony } = useVexterPatrimony();
  const { positions } = usePosition();
  const position = positions.find((p) => p.symbol === symbol)?.totalNet ?? 0;
  const { lastTrade } = useQuotation({ symbol });
  const [useAll, setUseAll] = useState<boolean>(false);
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  const isExtraSmall = useMediaQuery(`(max-width: ${MOBILE}px)`);
  useEffect(() => {
    if (isTypingValue) {
      setQuantity(((isNaN(Number(value)) ? 0 : Number(value)) / (lastTrade ?? 1)).toString());
    } else {
      setValue(((isNaN(Number(quantity)) ? 0 : Number(quantity)) * (lastTrade ?? 1)).toString());
    }
  }, [lastTrade, value, quantity, isTypingValue]);
  useEffect(() => {
    setUseAll(
      value ===
        formatDouble(vexterPatrimony?.saldoCliente ?? 0, {
          decimalSeparator: '.',
          thousandSeparator: '',
          minimumDecimalPlaces: 2,
          maximumFractionDigits: 2,
        })
    );
  }, [value, vexterPatrimony]);
  const moneyValue = unmaskMoney(value ?? '');
  let helperMessageValue = ' ';
  let status: InputStatus = 'unknown';
  if ((value ?? '') !== '') {
    if (moneyValue < 5) {
      helperMessageValue = 'Valor inválido, abaixo da movimentação miníma';
      status = 'error';
    } else if (moneyValue > (vexterPatrimony?.saldoCliente ?? 0)) {
      status = 'error';
      helperMessageValue = 'O valor da sua ordem pode estar acima do disponível';
    } else if ((value ?? '') !== '') {
      status = 'success';
    }
  }
  const formatOptions = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };
  let title = 'Você está investindo em:';
  let tooltip = '';
  if (type === 'margin') {
    if (side === 'buyOrBlock') {
      title = 'Selecione uma quantidade para alocar';
      tooltip =
        'Alocar ativo é movimentá-lo para servir como garantia para suas operações de alocação e também bloqueá-lo para venda em Spot';
    } else {
      title = 'Você está desbloqueando';
      tooltip = 'Ao desbloquear um ativo, você o retira como garantia de valor para as operações de alocação';
    }
  }
  return (
    <BilletContainer>
      <VxBilletTitle type={type} tooltip={tooltip} title={title} onClose={onClose} />
      <BodyContainer>
        <ItemContainer type={type}>
          <VxBilletItem
            shouldShowPosition={type === 'margin'}
            symbol={symbol}
            shouldNavigate={onItemClick !== undefined}
            onClick={onItemClick}
            type={type}
          />
        </ItemContainer>
        {type !== 'margin' && (
          <WalletAndPatrimonyContainer>
            <WalletAndPatrimonySectionContainer>
              {isSmall ? (
                <>
                  <VxBodyXs title="Em carteira:" fontWeight="light" color={vxAppColors.neutral.dark} />
                  <VxBodyXs
                    title={formatDouble(position, { minimumDecimalPlaces: 0 })}
                    fontWeight="medium"
                    color={vxAppColors.neutral.dark}
                  />
                </>
              ) : (
                <>
                  <VxBodyMd title="Em carteira:" fontWeight="light" color={vxAppColors.neutral.dark} />
                  <VxBodyMd
                    title={formatDouble(position, { minimumDecimalPlaces: 0 })}
                    fontWeight="medium"
                    color={vxAppColors.neutral.dark}
                  />
                </>
              )}
            </WalletAndPatrimonySectionContainer>
            <WalletAndPatrimonySectionContainer>
              {isSmall ? (
                <>
                  <VxBodyXs title="Saldo em conta:" fontWeight="light" color={vxAppColors.neutral.dark} />
                  <VxBodyXs
                    title={formatNumbers(vexterPatrimony?.saldoCliente ?? 0, formatOptions)}
                    fontWeight="medium"
                    color={vxAppColors.neutral.dark}
                  />
                </>
              ) : (
                <>
                  <VxBodyMd title="Saldo em conta:" fontWeight="light" color={vxAppColors.neutral.dark} />
                  <VxBodyMd
                    title={formatNumbers(vexterPatrimony?.saldoCliente ?? 0, formatOptions)}
                    fontWeight="medium"
                    color={vxAppColors.neutral.dark}
                  />
                </>
              )}
            </WalletAndPatrimonySectionContainer>
          </WalletAndPatrimonyContainer>
        )}
        <ValueAndQuantityContainer type={type}>
          <ValueOptionsContainer>
            <VxInput
              fontSize={isSmall ? 'small' : 'regular'}
              id="billet-editing-value"
              label="Valor"
              placeHolder="Digite o valor do investimento"
              value={value ?? ''}
              onChange={(input) =>
                setValue(
                  formatDouble(unmaskMoney(input.target.value), {
                    decimalSeparator: '.',
                    thousandSeparator: '',
                    minimumDecimalPlaces: 2,
                  })
                )
              }
              helperText={helperMessageValue}
              type="currency"
              onFocus={() => setIsTypingValue(true)}
              status={status}
            />
            {type === 'margin' && !isExtraSmall && (
              <VxBilletEditingCheckbox
                useAll={useAll}
                side={side}
                onClick={() => {
                  if (!useAll) {
                    setValue(
                      formatDouble(vexterPatrimony?.saldoCliente ?? 0, {
                        decimalSeparator: '.',
                        thousandSeparator: '',
                        minimumDecimalPlaces: 2,
                        maximumFractionDigits: 2,
                      })
                    );
                  }
                  setUseAll((state) => !state);
                }}
              />
            )}
          </ValueOptionsContainer>
          <VxInput
            fontSize={isSmall ? 'small' : 'regular'}
            id="billet-editing-quantity"
            label="Quantidade"
            placeHolder="Digite a quantidade"
            value={quantity?.replaceAll('.', ',') ?? ''}
            onChange={(input) => setQuantity(input.target.value.replaceAll(',', '.'))}
            helperText=" "
            type="numeric"
            status={status}
            onFocus={() => setIsTypingValue(false)}
          />
          {type === 'margin' && isExtraSmall && (
            <VxBilletEditingCheckbox
              useAll={useAll}
              side={side}
              onClick={() => {
                if (!useAll) {
                  setValue(
                    formatDouble(vexterPatrimony?.saldoCliente ?? 0, {
                      decimalSeparator: '.',
                      thousandSeparator: '',
                      minimumDecimalPlaces: 2,
                      maximumFractionDigits: 2,
                    })
                  );
                }
                setUseAll((state) => !state);
              }}
            />
          )}
        </ValueAndQuantityContainer>
        <BuyAndSellContainer type={type}>
          {type !== 'margin' ? (
            <>
              <ButtonContainer width={96}>
                <VxButton text="COMPRAR" type="primary" size="small" disabled={status !== 'success'} onClick={onBuy} />
              </ButtonContainer>
              <ButtonContainer width={84}>
                <VxButton text="VENDER" type="tertiary" size="small" disabled={status !== 'success'} onClick={onSell} />
              </ButtonContainer>
            </>
          ) : side === 'buyOrBlock' ? (
            <VxButton
              text="ALOCAR"
              type="callToAction"
              size={isSmall ? 'extraSmall' : 'small'}
              disabled={status !== 'success'}
              width={isSmall ? 68 : 138}
              onClick={onBuy}
            />
          ) : (
            <VxButton
              text="DESBLOQUEAR"
              type="primary"
              size={isSmall ? 'extraSmall' : 'small'}
              disabled={status !== 'success'}
              width={isSmall ? 94 : 180}
              onClick={onSell}
            />
          )}
        </BuyAndSellContainer>
      </BodyContainer>
    </BilletContainer>
  );
};

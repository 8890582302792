import { GuaranteeAssetItem } from '../../../guarantee-asset-item';
import { GuaranteeAssetListProps } from '../../../guarantee-asset-list/guarantee-asset-list-props';
import * as Styles from './styles';

export function TableBody({ assetList }: GuaranteeAssetListProps) {
  return (
    <Styles.Tbody>
      {assetList.map((asset, i) => (
        <GuaranteeAssetItem
          key={i}
          symbol={asset.symbol}
          quantity={asset.quantity}
          positionValue={asset.positionValue}
        />
      ))}
    </Styles.Tbody>
  );
}

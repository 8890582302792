import styled from 'styled-components';

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .tooltip-button {
    background: transparent;
    border: none;
    padding: 0;
    margin-left: 12px;
    margin-top: 2px;

    i {
      padding: 0;
    }
  }
`;

import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

type VXTextSkeletonStyleType = {
  height: number;
  width: number;
  skeletonColor: 'white' | 'principal' | 'lightest' | 'light';
};

const setColor = (color: string): VxAppColor => {
  if (color === 'lightest') return vxAppColors.brand.tertiary.lightest;
  if (color === 'light') return vxAppColors.brand.primary.lightest;
  if (color === 'principal') return vxAppColors.brand.tertiary.principal;
  return vxAppColors.neutral.white;
};
const setAnimationColor = (color: string): VxAppColor => {
  if (color === 'lightest') return vxAppColors.brand.primary.lightest;
  if (color === 'light') return vxAppColors.brand.tertiary.lightest;
  if (color === 'principal') return vxAppColors.brand.tertiary.light;
  return vxAppColors.neutral.lightest;
};
export const VXTextSkeleton = styled.div<VXTextSkeletonStyleType>`
  @keyframes shimmer {
    from {
      left: -${(props) => props.width}px;
    }
    to {
      left: 100%;
    }
  }

  border-radius: ${(props) => props.height}px;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => setColor(props.skeletonColor)};
  ::before {
    content: '';
    position: absolute;
    left: -${(props) => props.width}px;
    top: 0;
    height: 100%;
    width: ${(props) => props.width / 2}px;
    background: linear-gradient(
      to right,
      transparent 0%,
      ${(props) => setAnimationColor(props.skeletonColor)} 50%,
      transparent 100%
    );
    animation: shimmer 2s linear infinite;
  }
`;

import { formatNumbers } from 'data/utils/currency-util';
import { ExtractHistoryCache } from 'domain/entities/extract-entity';
import { makeSaveExtractsHistoryUsecase } from 'main/factories/domain/save-extract-history-usecase-factory';
import { ExportCard } from 'presentation/components/export-card';
import { InfoTooltip } from 'presentation/components/info-tooltip';
import { vexterToastError } from 'presentation/components/micro-components/toast';
import { useVexterPatrimony } from 'presentation/stores/hooks/use-vexter-patrimony';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { ExtractProps } from '../extract-table';
import * as S from './styles';

type ExtractInfosProps = {
  userIsFiltering: boolean;
  extractsList: ExtractProps[];
  startDate: string;
  endDate: string;
  exportedExtractsHistory: ExtractHistoryCache[];
  setExportedExtractsHistory: (history: ExtractHistoryCache[]) => void;
};

export function ExtractInfos(props: ExtractInfosProps) {
  const { vexterPatrimony } = useVexterPatrimony();
  const formatOptions = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };

  function downloadCsvFile(extract: ExtractProps[]) {
    let csv = 'data,operacao,valor\n';
    extract.forEach((e) => {
      csv += e.operations.map((o) => `${e.date},${o.text},${o.value}\n`).join('');
    });
    const element = document.createElement('a');
    const file = new Blob([csv], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'Extrato Vexter.csv';
    document.body.appendChild(element);
    element.click();
  }

  function saveExtract() {
    makeSaveExtractsHistoryUsecase()
      .execute(props.extractsList, props.startDate, props.endDate, new Date(Date.now()).toISOString())
      .then((historyOrError) => {
        if (historyOrError.isRight()) {
          props.setExportedExtractsHistory(historyOrError.value);
        } else {
          vexterToastError({ title: 'Opa! Algo parece errado.', message: 'Por favor, tente novamente mais tarde!' });
        }
      });
  }
  return (
    <S.Aside>
      <S.PatrimonyBlocked className="patrimony-blocked">
        <div>
          <VxBodyMd title="Saldo bloqueado" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />

          <InfoTooltip text="Esse saldo representa a quantia que você alocou como garantia para suas operações alavancadas" />
        </div>
        <H4
          title={formatNumbers(vexterPatrimony?.saldoBloqueado, formatOptions)}
          color={vxAppColors.brand.primary.darkest}
          fontWeight="light"
        />
      </S.PatrimonyBlocked>

      <ExportCard
        title="Exportar extrato"
        subTitle="Serão exportadas todas as ordens do período filtrado"
        createRecordButtonText="CRIAR RELATÓRIO"
        onCreateRecord={saveExtract}
        onDownload={downloadCsvFile}
        userExportedList={props.exportedExtractsHistory.map((e) => ({
          data: e.extract,
          startDate: e.filterStartDate,
          endDate: e.filterEndDate,
          generatedAt: e.generatedAtDate,
        }))}
        filterResult={
          props.userIsFiltering
            ? props.extractsList.map((e) => ({
                startDate: props.startDate,
                endDate: props.endDate,
                data: e.operations,
              }))
            : []
        }
      />
    </S.Aside>
  );
}

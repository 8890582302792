import { CacheDatasource } from 'data/datasources/cache/cache-datasource';
import { CacheStorageEnum } from 'data/datasources/entities/cache-storage-enum';
import { hasValue } from 'domain/util/value-util';

export class LocalStorageCacheDatasource implements CacheDatasource {
  async read(key: CacheStorageEnum): Promise<any> {
    const value = localStorage.getItem(key);
    if (!hasValue(value)) {
      return value;
    }
    return JSON.parse(window.atob(value as any));
  }
  async write(key: CacheStorageEnum, value: any): Promise<void> {
    if (!hasValue(value)) {
      localStorage.removeItem(key);
    }
    localStorage.setItem(key, window.btoa(JSON.stringify(value)));
  }
}

import dayjs from 'dayjs';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VxChips } from 'presentation/components/micro-components/vx-chips';
import { VxDateRange } from 'presentation/components/micro-components/vx-date-range';
import { VxDropdownFilter, VxDropdownItemsType } from 'presentation/components/micro-components/vx-dropdown-filter';
import { VxFilterButton } from 'presentation/components/micro-components/vx-filter-button';
import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { ExtractFilterStyle } from './style';

export interface OnFilterExtractParams {
  startDate: string;
  endDate: string;
  release: string;
  selectedPeriod: number;
}

type ExtractFilterProps = {
  onFilter: (filters: OnFilterExtractParams) => void;
  onFilterUpdate: (filters: OnFilterExtractParams) => void;
  onClearFilter: () => void;
  onSelectPeriod: (item: string) => void;
  selectedPeriod: number;
  selectedReleaseType: VxDropdownItemsType[];
  periods: string[];
};

export const ExtractFilter: React.FunctionComponent<ExtractFilterProps> = ({
  onFilter,
  onFilterUpdate,
  onClearFilter,
  selectedPeriod,
  selectedReleaseType,
  periods,
  onSelectPeriod,
}) => {
  const showResponsiveFilter = useMediaQuery('(max-width: 1840px)');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedPeriodTab, setSelectedPeriodTab] = useState(0);
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [startDate, setStartDate] = useState(dayjs(endDate).add(-7, 'day').toISOString());
  const [releaseType, setReleaseType] = useState('typeFilter=1&typeFilter=2&typeFilter=3&typeFilter=4');
  const filterByPeriod = useCallback((periodString: string) => {
    const day = Number.parseInt(periodString.split(' ')[0]);
    setSelectedPeriodTab(day);
    const today = dayjs(new Date());
    const todayLessPeriod = today.add(-day, 'day');
    setEndDate(new Date().toISOString());
    setStartDate(todayLessPeriod.toISOString());
  }, []);
  const filterByReleaseType = useCallback((dropDownValues: VxDropdownItemsType[]) => {
    let query = 'typeFilter=1&typeFilter=2&typeFilter=3&typeFilter=4';
    const allSelected = dropDownValues.every((e) => e.checked);
    const allUnselected = dropDownValues.every((e) => !e.checked);
    if (allSelected) {
      setReleaseType(query);
    } else if (allUnselected) {
      setReleaseType(query);
    } else if (dropDownValues[0].checked) {
      query = 'typeFilter=2&typeFilter=3&typeFilter=4';
    } else if (dropDownValues[1].checked) {
      query = 'typeFilter=1';
    }
    setReleaseType(query);
  }, []);
  useEffect(() => {
    const today = dayjs(Date.now());
    let newSelectedPeriodTab = -1;
    if (today.diff(endDate, 'day') === 0) {
      const daysToEnd = today.diff(startDate, 'day');
      switch (daysToEnd) {
        case 7:
          newSelectedPeriodTab = 0;
          break;
        case 15:
          newSelectedPeriodTab = 1;
          break;
        case 30:
          newSelectedPeriodTab = 2;
          break;
        case 60:
          newSelectedPeriodTab = 3;
          break;
        case 90:
          newSelectedPeriodTab = 4;
          break;
      }
    }
    setSelectedPeriodTab(newSelectedPeriodTab);
  }, [startDate, endDate]);
  useEffect(() => {
    onFilterUpdate({
      startDate: startDate,
      endDate: endDate,
      release: releaseType,
      selectedPeriod: selectedPeriodTab,
    });
  }, [startDate, endDate, releaseType, selectedPeriodTab]);
  return (
    <ExtractFilterStyle>
      {!showFilters && (
        <VxGridContainer>
          <VxGridItem>
            <div className="filter-hide">
              <VxFilterButton isOpen={showFilters} onFilterClick={() => setShowFilters(!showFilters)} />
            </div>
          </VxGridItem>
        </VxGridContainer>
      )}
      {showFilters &&
        (showResponsiveFilter ? (
          <div className="filter-show-responsive">
            <div className="container-left">
              <VxFilterButton isOpen={showFilters} onFilterClick={() => setShowFilters(!showFilters)} />
            </div>
            <div className="date-period-container">
              <div className="date-container">
                <VxBodyMd title="Filtrar por data" color={vxAppColors.neutral.dark} fontWeight="medium" />
                <div>
                  <VxDateRange
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    onChange={(newValue) => {
                      if (newValue?.[0]) {
                        setStartDate(newValue[0].toISOString());
                      }
                      if (newValue?.[1]) {
                        setStartDate(newValue[1].toISOString());
                      }
                    }}
                  />
                </div>
              </div>
              <div className="period-container">
                <VxBodyMd title="Filtrar por período" color={vxAppColors.neutral.dark} fontWeight="medium" />
                <VxChips
                  activeIndex={selectedPeriod}
                  items={periods}
                  onClick={(e) => {
                    onSelectPeriod(e);
                    filterByPeriod(e);
                  }}
                />
              </div>
            </div>
            <div className="release-container">
              <VxBodyMd title="Filtrar por lançamento" color={vxAppColors.neutral.dark} fontWeight="medium" />
              <VxDropdownFilter
                emptyTextPlaceholder="Selecione o tipo de lançamento"
                showSelectAllOptions={true}
                items={[...selectedReleaseType]}
                onChangeItem={(list) => filterByReleaseType(list)}
              />
            </div>
            <div className="container-left">
              <VxButton
                width={84}
                text="LIMPAR"
                size="small"
                onClick={onClearFilter}
                type="secondary"
                disabled={false}
              />
              <VxButton
                width={84}
                text="FILTRAR"
                size="small"
                onClick={() =>
                  onFilter({
                    startDate: startDate,
                    endDate: endDate,
                    release: releaseType,
                    selectedPeriod: selectedPeriodTab,
                  })
                }
                type="primary"
                disabled={false}
              />
            </div>
          </div>
        ) : (
          <div className="filter-show-desk">
            <div className="date-container">
              <VxBodyMd title="Filtrar por data" color={vxAppColors.neutral.dark} fontWeight="medium" />
              <div>
                <VxDateRange
                  onChange={(newValue) => {
                    if (newValue?.[0]) {
                      setStartDate(newValue[0].toISOString());
                    }
                    if (newValue?.[1]) {
                      setStartDate(newValue[1].toISOString());
                    }
                  }}
                />
              </div>
            </div>
            <div className="period-container">
              <VxBodyMd title="Filtrar por período" color={vxAppColors.neutral.dark} fontWeight="medium" />
              <VxChips
                activeIndex={selectedPeriod}
                items={periods}
                onClick={(e) => {
                  onSelectPeriod(e);
                  filterByPeriod(e);
                }}
              />
            </div>
            <div className="release-container">
              <VxBodyMd title="Filtrar por lançamento" color={vxAppColors.neutral.dark} fontWeight="medium" />
              <VxDropdownFilter
                emptyTextPlaceholder="Selecione o tipo de status"
                showSelectAllOptions={true}
                items={[...selectedReleaseType]}
                onChangeItem={(list) => filterByReleaseType(list)}
              />
            </div>
            <div className="filters-btn-container">
              <VxFilterButton isOpen={showFilters} onFilterClick={() => setShowFilters(!showFilters)} />
              <div>
                <VxButton
                  width={84}
                  text="LIMPAR"
                  size="small"
                  onClick={onClearFilter}
                  type="secondary"
                  disabled={false}
                />
                <VxButton
                  width={84}
                  text="FILTRAR"
                  size="small"
                  onClick={() =>
                    onFilter({
                      startDate: startDate,
                      endDate: endDate,
                      release: releaseType,
                      selectedPeriod: selectedPeriodTab,
                    })
                  }
                  type="primary"
                  disabled={false}
                />
              </div>
            </div>
          </div>
        ))}
    </ExtractFilterStyle>
  );
};

import VexterCarousel from 'presentation/components/carousel';
import { VXSquareSkeleton } from 'presentation/components/micro-components/vx-skeleton/square/style';

import { useBanners } from 'presentation/stores/hooks/use-banners';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import {
  StyledHomeBannerContainer,
  StyledHomeBannerItem,
  StyledHomeBannerItemText,
  StyledHomeBannerSkeleton,
} from './styled';

export const HomeBanners: React.FC = () => {
  const { banners, isLoadingBanners } = useBanners();
  return (
    <StyledHomeBannerContainer>
      {isLoadingBanners ? (
        <StyledHomeBannerSkeleton>
          <VXSquareSkeleton height={254} width={800} skeletonColor="light" />
        </StyledHomeBannerSkeleton>
      ) : (
        <VexterCarousel
          paginationInside={true}
          slidesPerView={1}
          showPagination={true}
          backgroundColor={vxAppColors.neutral.superLight}
          componets={banners.map((b) => (
            <StyledHomeBannerItem key={b.url} url={b.url}>
              <StyledHomeBannerItemText>
                <H4 title={b.title} color={vxAppColors.neutral.superLight} fontWeight="medium" />
                <VxBodyMd title={b.subtitle} color={vxAppColors.neutral.superLight} fontWeight="regular" />
              </StyledHomeBannerItemText>
            </StyledHomeBannerItem>
          ))}
        />
      )}
    </StyledHomeBannerContainer>
  );
};

import { ProductBoughtEntity } from 'domain/entities/product-bought-entity';
import { ProductBoughtStatusEnum } from 'domain/enums/product-bought-status-enum';
import { makeLoadProductsBoughtUsecase } from 'main/factories/domain/load-products-bought-usecase-factory';
import { useEffect, useState } from 'react';

export function useProductsBought() {
  const [count, setCount] = useState<number>(0);
  const [productsBought, setProductsBought] = useState<ProductBoughtEntity[]>([]);
  const [productsBoughtActive, setProductsBoughtActive] = useState<ProductBoughtEntity[]>([]);
  const [isLoadingProductsBought, setIsLoadingProductsBought] = useState<boolean>(true);
  useEffect(() => {
    setIsLoadingProductsBought(true);
    makeLoadProductsBoughtUsecase()
      .execute()
      .then((productsBoughtOrError) => {
        if (productsBoughtOrError.isRight()) {
          setProductsBought(productsBoughtOrError.value);
          setProductsBoughtActive(
            productsBoughtOrError.value.filter((p) => p.status === ProductBoughtStatusEnum.contracted)
          );
          setIsLoadingProductsBought(false);
        }
      })
      .catch(console.error);
  }, [count]);
  return {
    productsBought,
    productsBoughtActive,
    isLoadingProductsBought,
    reloadBoughtProducts: () => setCount((state) => state + 1),
  };
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { DESKTOP_MD, DESKTOP_XS } from '../vx-grid/helpers';

export const HeaderContainer = styled.header`
  background-color: ${vxAppColors.background};
  border: 1px solid ${vxAppColors.neutral.lightest};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  min-height: 74px;
  display: flex;
  align-items: center;

  @media (max-width: ${DESKTOP_MD}px) {
    height: 62px;
  }

  @media (max-width: ${DESKTOP_XS - 1}px) {
    height: 63.35px;
  }
`;

export const ButtonsAndCurrencyContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  button:nth-child(2) {
    span {
      color: ${vxAppColors.brand.primary.darkest};
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import React, { useEffect, useRef, useState } from 'react';
import { formatNumbers, unmaskMoney } from 'data/utils/currency-util';
import { Box, InputAdornment, TextField } from '@mui/material';

export type InputMoneyProps = {
  value?: number;
  disable?: boolean;
  setHasError?: (param: boolean) => void;
  label?: string;
  maximum?: number;
  onChange: (value?: number) => void;
};

export const VxMoneyInput = ({
  value = 0,
  onChange,
  disable = false,
  label = '',
  maximum,
  setHasError,
}: InputMoneyProps) => {
  const [invalidValue, setInvalidValue] = useState(false);

  const [moneyValue, setMoneyValue] = useState(
    formatNumbers(Number(0), { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2 })
  );

  const previousValues = useRef({ value });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let _value = event.currentTarget.value;
    _value = _value.slice(0, 10);
    setNewValue(unmaskMoney(_value));
  };

  const setNewValue = (newValue: number) => {
    if (maximum && newValue > maximum) {
      setInvalidValue(true);
      setHasError && setHasError(true);
    } else {
      onChange(Number(newValue));
      setInvalidValue(false);
      setHasError && setHasError(false);
    }
    setMoneyValue(formatNumbers(Number(newValue), { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2 }));
  };

  useEffect(() => {
    if (previousValues.current.value !== value) {
      setNewValue(value);
      previousValues.current = { value };
    }
  }, [value]);

  return (
    <>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        overflow={'hidden'}
      >
        <TextField
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          label={label}
          variant="standard"
          value={moneyValue}
          disabled={disable}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          helperText={invalidValue ? 'Valor inválido.' : ''}
          error={invalidValue}
        />
      </Box>
    </>
  );
};

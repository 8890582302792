import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const ListContainer = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  li {
    background: ${vxAppColors.neutral.white};
    max-width: fit-content;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 40px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    &:not(:last-child) {
      margin-right: 8px;
    }
    button {
      background-color: transparent;
      width: 100%;
      border: none;
      cursor: pointer;
      :focus {
        outline: none;
      }
    }

    @media (max-width: 1280px) {
      min-width: 64px;
      height: 34px;
      text-align: center;
      padding: 0;
    }
  }
  li.active {
    padding: 4px 17px;
    border: none;
    background: ${vxAppColors.brand.primary.principal};
    transition: background 0.3s ease-in;
    @media (max-width: 1280px) {
      padding: 0;
    }
  }

  :focus {
    outline: none;
  }
`;

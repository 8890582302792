import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const OrderSkeletonStyle = styled.div`
  margin-left: 56px;
  margin-right: 24px;
  margin-top: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  .table-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${vxAppColors.brand.secondary.lightest};
    padding: 0px 16px;
    width: 100%;
    display: flex;
    gap: 24px;
    .table-titles {
      padding: 8px 0px;
      width: 100%;
      text-align: start;

      :nth-child(3) {
        text-align: start;
      }
    }
  }
  table {
    border-collapse: collapse;
    border-radius: 8px;
    width: 100%;
    table-layout: fixed;

    thead {
      tr {
        th {
          vertical-align: middle;
          text-align: left;
          padding-left: 8px;
          :nth-child(1) {
            padding-left: 16px;
          }
          @media (max-width: 1550px) {
            :nth-child(2) {
              text-align: end;
            }
          }
          @media (max-width: 1180px) {
            :nth-child(2) {
              padding-right: 50px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        height: 80px;
        :last-child {
          border-bottom: none;
        }
        td {
          vertical-align: middle;
          text-align: left;
          padding-left: 8px;
          :nth-child(1) {
            padding-left: 16px;
          }
          @media (max-width: 1550px) {
            :nth-child(2) {
              text-align: end;
            }
          }
          @media (max-width: 1180px) {
            :nth-child(2) {
              padding-right: 50px;
            }
          }
          :last-child {
            text-align: right;
          }
          .asset-info {
            display: flex;
            gap: 8px;
            align-items: center;
            > div {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }
          }
          .quotation-info {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
          .mini-chart-container {
            width: 72px;
          }
        }
      }
    }
  }
`;

import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import styled from 'styled-components';
import { VxBilletType } from '../..';

export type BilletSelectingAssetsList = {
  type: VxBilletType;
};

export const BilletSelectingAssetsList = styled.div<BilletSelectingAssetsList>`
  display: flex;
  flex-direction: column;
  height: 471px;
  overflow-y: scroll;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  gap: ${({ type }) => (type !== 'modal' ? 8 : 0)}px;
  @media (max-width: ${DESKTOP_XS}px) {
    gap: 12px;
  }
`;

import { VxAppColor } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import ReactModal from 'react-modal';
import { ModalStyle } from './style';

interface ModalProps {
  hideModalHookCallback: any;
  open: boolean;
  height?: number;
  width?: number;
  backgroundColor?: VxAppColor | string;
  children?: React.ReactNode;
  onAfterClose?: () => void;
}

const modalConfigStyle = {
  overlay: {
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
  },
  content: {
    borderRadius: 0,
    zIndex: 999,
    inset: 'unset',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
  },
};
ReactModal.setAppElement('#root');
export const Modal: React.FunctionComponent<ModalProps> = ({
  height,
  width,
  children,
  backgroundColor,
  open: showModal,
  hideModalHookCallback: hideModal,
  onAfterClose,
}) => {
  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      isOpen={showModal}
      style={modalConfigStyle}
      onAfterClose={() => onAfterClose?.()}
      onRequestClose={hideModal}
    >
      <ModalStyle height={height} width={width} backgroundColor={backgroundColor}>
        {children}
      </ModalStyle>
    </ReactModal>
  );
};

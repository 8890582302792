import {
  calculatePercentageValue,
  formatCurrency,
  formatNumbers,
  formatPercentage,
  isNegative,
} from 'data/utils/currency-util';
import { Position } from 'domain/entities';

import { VxBodyLg, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H2, H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { StringUtils } from 'presentation/utils/string-utils';

import * as S from './styles';

type InfoProps = {
  positions: Position[];
  smallDesktop: boolean;
  isMobile: boolean;
  rentabilitySum: number;
};

export function Infos({ positions, smallDesktop, isMobile, rentabilitySum }: InfoProps) {
  const percentageOptions = { showGainSymbol: true, maximumFractionDigits: 2 };

  const getFontColor = isNegative(rentabilitySum)
    ? vxAppColors.feedback.error.default
    : vxAppColors.feedback.success.default;

  if (isMobile) {
    return (
      <S.Container>
        <H4
          title={formatNumbers(positions[0].totalHeritageGross, {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          })}
          fontWeight="bold"
          color={vxAppColors.brand.primary.principal}
        />

        <div className="invested-amount__numbers--info">
          <>
            <VxBodyXs
              title={StringUtils.formatRentability({
                isCrypto: false,
                value: formatCurrency(rentabilitySum, { alwaysShowSignal: true, showAsInteger: false }),
              })}
              fontWeight="bold"
              color={getFontColor}
              htmlTag="span"
            />
            <VxBodyXs
              title={formatPercentage(calculatePercentageValue(positions), percentageOptions)}
              fontWeight="bold"
              color={getFontColor}
              htmlTag="span"
            />
          </>
        </div>
      </S.Container>
    );
  }

  if (smallDesktop) {
    return (
      <S.Container>
        <H4
          title={formatNumbers(positions[0].totalHeritageGross, {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          })}
          fontWeight="bold"
          color={vxAppColors.brand.primary.principal}
        />

        <div className="invested-amount__numbers--info">
          <>
            <VxBodySm
              title={StringUtils.formatRentability({
                isCrypto: false,
                value: formatCurrency(rentabilitySum, { alwaysShowSignal: true, showAsInteger: false }),
              })}
              fontWeight="bold"
              color={getFontColor}
              htmlTag="span"
            />
            <VxBodySm
              title={formatPercentage(calculatePercentageValue(positions), percentageOptions)}
              fontWeight="bold"
              color={getFontColor}
              htmlTag="span"
            />
          </>
        </div>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <H2
        title={formatNumbers(positions[0].totalHeritageGross, {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        })}
        fontWeight="bold"
        color={vxAppColors.brand.primary.principal}
      />

      <div className="invested-amount__numbers--info">
        <>
          <VxBodyLg
            title={StringUtils.formatRentability({
              isCrypto: false,
              value: formatCurrency(rentabilitySum, { alwaysShowSignal: true, showAsInteger: false }),
            })}
            fontWeight="bold"
            color={getFontColor}
            htmlTag="span"
          />
          <VxBodyLg
            title={formatPercentage(calculatePercentageValue(positions), percentageOptions)}
            fontWeight="bold"
            color={getFontColor}
            htmlTag="span"
          />
        </>
      </div>
    </S.Container>
  );
}

import { Either } from 'domain/entities/either';
import { ProductEntity } from 'domain/entities/product-entity';
import { Failure } from 'domain/failure/failure';
import { LoadAllProductsRepository } from 'domain/repositories/load-all-products-repository';

export interface LoadAllProductsUsecase {
  execute(): Promise<Either<Failure, ProductEntity[]>>;
}

export class LoadAllProductsUsecaseImp implements LoadAllProductsUsecase {
  constructor(readonly repository: LoadAllProductsRepository) {
    Object.freeze(this);
  }
  execute(): Promise<Either<Failure, ProductEntity[]>> {
    return this.repository.allProducts();
  }
}

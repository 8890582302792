import { PatrimonyEntity } from 'domain/entities/patrimony-entity';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { makeVexterListenPatrimonyUsecase } from 'main/factories/domain/listen-patrimony-usecase-factory';
import { useEffect, useState } from 'react';

export const useVexterPatrimony = () => {
  const [isLoadingVexterPatrimony, setIsLoadingVexterPatrimony] = useState<boolean>(true);
  const [vexterPatrimony, setVexterPatrimony] = useState<PatrimonyEntity>();
  const [unsubscribe, setUnsubscribe] = useState<SubscriptionCancelEntity>();
  useEffect(() => {
    setIsLoadingVexterPatrimony(true);
    makeVexterListenPatrimonyUsecase()
      .execute((patrimony) => {
        setVexterPatrimony(patrimony);
      })
      .then((listenOrError) => {
        if (listenOrError.isRight()) {
          setUnsubscribe(listenOrError.value);
          setIsLoadingVexterPatrimony(false);
        }
      });
  }, []);
  useEffect(() => {
    return () => {
      unsubscribe?.cancel();
    };
  }, [unsubscribe]);
  return { vexterPatrimony, isLoadingVexterPatrimony };
};

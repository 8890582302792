import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  div.title-container {
    display: flex;
    align-items: center;
    height: 40px;
    @media (min-width: 1081px) {
      height: 77px;
    }
  }
  div.asset-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    @media (min-width: 1081px) {
      height: 87px;
    }
    div.asset-item-name-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    div.asset-item-value-container {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 4px;
    }
  }
  div.position-container {
    display: flex;
    flex-direction: column;
    div.position-item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 18px;
      @media (min-width: 1081px) {
        height: 24px;
      }
    }
    hr.asset-item-separator {
      width: 100%;
      border: 0.25px solid ${vxAppColors.neutral.lightest};
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  div.button-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 46px;
    @media (min-width: 1081px) {
      align-items: center;
      height: 72px;
    }
    button {
      @media (max-width: 1080px) {
        height: 32px;
      }
    }
  }
`;

import styled from 'styled-components';

export const InvestedAmountContainer = styled.div`
  display: flex;
  flex-direction: column;

  .invested-amount {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &__numbers {
      margin-right: 6.8%;
      &--info {
        display: flex;
        > :first-child {
          margin-right: 20px;
        }
      }

      .dots-container {
        min-width: 90px;
        margin-top: 40px;
        & > div {
          margin-bottom: 39px;
        }
      }
    }
  }
`;

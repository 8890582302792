import styled from 'styled-components';

type ContainerProps = {
  color: string;
};

export const Container = styled.div<ContainerProps>`
  padding: 4px 8px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    background: ${(props) => props.color};
    width: 8px;
    height: 8px;
    margin-right: 10px;
  }
`;

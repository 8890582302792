import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-top: 48px;

  & > span {
    display: inline-block;
    margin-bottom: 32px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .title-container {
    &__with-tooltip {
      display: flex;
    }
  }
`;

export const ContentContainer = styled.div`
  margin-top: 24px;
`;

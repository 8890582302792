import styled from 'styled-components';

export const ToastContent = styled.div`
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: block;
  width: 360px;
`;

interface LabelToastProps {
  color: string;
}
export const LabelToast = styled.div<LabelToastProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border: 1px solid ${(props) => props.color};
  background-color: transparent;
  border-radius: 40px;
  cursor: pointer;
`;

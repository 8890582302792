import { formatCurrency, formatDouble } from 'data/utils/currency-util';
import { AssetImage } from 'presentation/components/asset-image';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { useBillet } from 'presentation/stores/hooks/use-billet';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';

import { VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody.stories';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { goToAssetPage } from 'presentation/utils/navigate-utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { GuaranteeAssetListItemProps } from '../guarantee-asset-list/guarantee-asset-list-props';

export const GuaranteeAssetItem: React.FunctionComponent<GuaranteeAssetListItemProps> = (asset) => {
  const symbol = asset.symbol;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showModal } = useBillet({ symbol: symbol, type: 'margin', side: 'sellOrRelease' });
  const { companyName } = useCompanyName({ symbol: symbol });
  const isSmall = useMediaQuery('(max-width: 1024px)');
  return (
    <tr>
      <td>
        <div
          className="symbol-info"
          onClick={() => {
            if (symbol !== 'BRL' && symbol !== 'USD') {
              goToAssetPage(symbol, navigate, dispatch);
            }
          }}
        >
          <AssetImage symbol={symbol} size="xs" />
          <div>
            <VxBodyXs title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
            <VxBodyXs title={companyName} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
          </div>
        </div>
      </td>
      <td>
        {isSmall ? (
          <VxBodyXs
            title={formatDouble(asset.quantity)}
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
          />
        ) : (
          <VxBodyMd
            title={formatDouble(asset.quantity)}
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
          />
        )}
      </td>
      <td>
        {isSmall ? (
          <VxBodyXs
            title={formatCurrency(asset.positionValue)}
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
          />
        ) : (
          <VxBodyMd
            title={formatCurrency(asset.positionValue)}
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
          />
        )}
      </td>
      <td>
        <VxButton
          buttonTextType={isSmall ? 'md' : 'lg'}
          type="primary"
          disabled={false}
          size="small"
          width={isSmall ? 94 : 160}
          onClick={showModal}
          text="DESBLOQUEAR"
        />
      </td>
    </tr>
  );
};

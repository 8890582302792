import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 32px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: ${vxAppColors.neutral.superLight};
  height: 250px;
  margin-top: 32px;
`;

export const EmptyTable = styled.table`
  width: 75%;
  th {
    &:nth-child(2) {
      min-width: 373px;
      text-align: center;
    }
  }
`;

export const AsideCard = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background: ${vxAppColors.neutral.superLight};
  min-height: 300px;

  h5 {
    text-align: center;

    @media (min-width: 1024px) and (max-width: 1180px) {
      max-width: 246px;
    }
  }

  p {
    margin-top: 12px;
    margin-bottom: 74px;
    padding: 0 24px;
    text-align: center;
    max-width: 456px;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    min-height: 206px;

    p {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 1441px) and (max-width: 1600px) {
    min-height: 270px;

    p {
      margin-bottom: 30px;
    }
  }
`;

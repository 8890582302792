import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const OperationForm = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 32px;
  background: ${vxAppColors.neutral.superLight};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 24px;
  width: 100%;
`;

export const ValueItensAlignment = styled.div`
  align-items: flex-start;
`;

export enum OrderStatusEnumMessage {
  notConfirmed = 'Não Confirmada',
  openOrder = 'Em aberto',
  filled = 'Executada',
  cancelled = 'Cancelada',
  rejected = 'Rejeitada',
  pending = 'Pendente',
  calculed = 'Calculada',
}

export enum OrdersStatusEnumId {
  notConfirmed = '0',
  openOrder = '1',
  cancelled = '5',
  pending = '10',
  rejected = '8',
  filled = '3',
  calculed = '5',
}

import { useMediaQuery } from 'usehooks-ts';
import { VxButtonGhost } from '../vx-button-ghost';
import { VXIconsEnum } from '../vx-icon';

type VxFilterButtonType = {
  onFilterClick: () => void;
  isOpen: boolean;
};

export const VxFilterButton: React.FunctionComponent<VxFilterButtonType> = ({ onFilterClick, isOpen }) => {
  const isSmall = useMediaQuery('(max-width: 1024px)');
  return (
    <VxButtonGhost
      onClick={onFilterClick}
      text={isOpen ? 'FECHAR FILTRO' : 'ABRIR FILTROS'}
      size={isSmall ? 'small' : 'regular'}
      prefixIcon={VXIconsEnum.filtro}
      prefixIconSize="sm"
      prefixIconInvertStrokeWithFill={true}
    />
  );
};

import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .title-container {
    &__with-tooltip {
      display: flex;
    }
  }
`;

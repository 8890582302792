import { VxDropdownFilter, VxDropdownItemsType } from 'presentation/components/micro-components/vx-dropdown-filter';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { OrderFilterTypeStyle } from './style';

export type OrderFilterTypeProps = {
  sides: VxDropdownItemsType[];
  onCheck: (items: VxDropdownItemsType[]) => void;
};

export const OrderFilterType: React.FC<OrderFilterTypeProps> = ({ sides, onCheck }) => {
  return (
    <OrderFilterTypeStyle>
      <VxBodyMd title="Filtrar por tipo" color={vxAppColors.neutral.dark} fontWeight="medium" />
      <VxDropdownFilter
        emptyTextPlaceholder="Selecione o tipo de lançamento"
        showSelectAllOptions={true}
        items={[...sides]}
        onChangeItem={(list) => onCheck(list)}
      />
    </OrderFilterTypeStyle>
  );
};

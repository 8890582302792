import { CacheDatasource } from 'data/datasources/cache/cache-datasource';
import { CacheStorageEnum } from 'data/datasources/entities/cache-storage-enum';
import { Either, left, right } from 'domain/entities/either';
import { ExtractHistoryCache } from 'domain/entities/extract-entity';
import { Failure } from 'domain/failure/failure';
import { LoadExtractHistoryRepository } from 'domain/repositories/load-extract-history-repository';
import { SaveExtractHistoryRepository } from 'domain/repositories/save-extract-history-repository';
import { ExtractProps } from 'presentation/pages/profile/subpages/extract/components/extract-table';

export class ExtractCacheRepository implements LoadExtractHistoryRepository, SaveExtractHistoryRepository {
  constructor(readonly datasource: CacheDatasource) {
    Object.freeze(this);
  }
  async loadHistory(): Promise<Either<Failure, ExtractHistoryCache[]>> {
    try {
      const existsKeyOnLocalStorage = await this.datasource.read(CacheStorageEnum.extractHistory);
      if (!existsKeyOnLocalStorage) {
        return right([]);
      }
      return right(existsKeyOnLocalStorage);
    } catch (error) {
      return left(new Failure(`Erro ao carregar do cache o historico de extratos exportados`));
    }
  }
  async saveHistory(
    extract: ExtractProps[],
    filterStartDate: string,
    filterEndDate: string,
    generatedAtDate: string
  ): Promise<Either<Failure, ExtractHistoryCache[]>> {
    const existsKeyOnLocalStorage = await this.datasource.read(CacheStorageEnum.extractHistory);
    if (!existsKeyOnLocalStorage) {
      await this.datasource.write(CacheStorageEnum.extractHistory, []);
    }
    const localData = await this.loadHistory();
    if (localData.isRight()) {
      const newEntry = [{ filterStartDate, filterEndDate, extract, generatedAtDate }, ...localData.value];
      await this.datasource.write(CacheStorageEnum.extractHistory, newEntry);
      return right(newEntry);
    }
    return left(new Failure(`Erro ao carregar salvar o extrato no local Storage`));
  }
}

import { CacheDatasource } from 'data/datasources/cache/cache-datasource';
import { CacheStorageEnum } from 'data/datasources/entities/cache-storage-enum';
import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadCompanyNameBySymbolRepository } from 'domain/repositories/load-company-name-by-symbol-repository';
import { SaveCompanyNameBySymbolRepository } from 'domain/repositories/save-company-name-by-symbol-repository';
import { hasValue } from 'domain/util/value-util';

export class AssetInfoCacheRepository implements LoadCompanyNameBySymbolRepository, SaveCompanyNameBySymbolRepository {
  constructor(readonly datasource: CacheDatasource) {
    Object.freeze(this);
  }
  async loadCompanyNameBySymbol(symbol: string): Promise<Either<Failure, string>> {
    try {
      const cache = await this.datasource.read(CacheStorageEnum.companyName);
      const result = cache[symbol];
      if (!hasValue(result)) {
        return left(new Failure(`Não encontrado cache "companyName" para o símbolo: "${symbol}"`));
      }
      const expiresAt = new Date(Date.now());
      expiresAt.setDate(expiresAt.getDate() + 15);
      const loadedAt = new Date(result.loadedAt);
      if (loadedAt < expiresAt) {
        return right(result.companyName);
      }
      return left(new Failure(`Cache do "companyName" (símbolo: "${symbol}") é inválido`));
    } catch (error) {
      return left(new Failure(`Erro ao carregar do cache o "companyName" para o símbolo: "${symbol}"`));
    }
  }
  async saveCompanyNameBySymbol(symbol: string, companyName: string): Promise<Either<Failure, void>> {
    try {
      let cache = await this.datasource.read(CacheStorageEnum.companyName);
      if (!hasValue(cache)) {
        cache = {};
      }
      cache = { ...cache, [symbol]: { companyName, loadedAt: new Date(Date.now()) } };
      await this.datasource.write(CacheStorageEnum.companyName, cache);
      return right(undefined);
    } catch (error) {
      return left(new Failure(`Erro ao salvar no cache o "companyName" para o símbolo: "${symbol}"`));
    }
  }
}

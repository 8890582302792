import { SubHeader } from 'presentation/components/sub-header';
import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { RootState } from 'presentation/stores';
import { setEnvironment } from 'presentation/stores/slices/settings.store';
import { useDispatch, useSelector } from 'react-redux';
import PositionAndValue from './components/position-and-value';
import { OrderDataComponent } from './components/recent-orders';

const WalletPage = () => {
  const settingsSlice = useSelector((state: RootState) => state.settingsSlice);
  const dispatch = useDispatch();
  return (
    <>
      <SubHeader
        itens={['Spot', 'Margem']}
        onClick={(value) => {
          dispatch(setEnvironment(value === 'Spot' ? 'spot' : 'margin'));
        }}
        defaultActiveItem={settingsSlice.environment === 'spot' ? 0 : 1}
      />
      <VxGridContainer>
        <VxGridItem columns={8}>
          <PositionAndValue />
        </VxGridItem>

        <VxGridItem columns={4}>
          <OrderDataComponent />
        </VxGridItem>
      </VxGridContainer>
    </>
  );
};

export { WalletPage };

import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

type SpinnerSize = {
  size: number;
  color?: VxAppColor;
};

export const Spinner = styled.div<SpinnerSize>`
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1px solid ${(props) => props.color ?? vxAppColors.brand.secondary.principal};
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
`;

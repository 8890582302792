import { useMediaQuery } from '@mui/material';
import { RoutesEnum } from 'config/routes/router-enum';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VxButtonGhost } from 'presentation/components/micro-components/vx-button-ghost';
import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { Link } from 'react-router-dom';

type EmptyExtractProps = {
  userIsFiltering: boolean;
  clearFilter: () => void;
};

export function EmptyExtract(props: EmptyExtractProps) {
  const isSmall = useMediaQuery('(max-width: 1024px)');

  return (
    <div className="empty-list">
      <div>
        <H5
          title="Você ainda não fez nenhuma movimentação"
          color={vxAppColors.brand.primary.darkest}
          fontWeight="medium"
        />

        <VxBodySm
          title="Quando fizer, ela aparecerá aqui"
          color={vxAppColors.brand.primary.darkest}
          fontWeight="regular"
        />
      </div>
      {props.userIsFiltering ? (
        <VxButtonGhost
          onClick={props.clearFilter}
          text="LIMPAR FILTROS"
          size={isSmall ? 'small' : 'regular'}
          type="danger"
        />
      ) : (
        <Link to={RoutesEnum.depositWithdraw}>
          <VxButton
            text="Depositar"
            disabled={false}
            onClick={() => null}
            size={'regular'}
            type="primary"
            width={106}
          />
        </Link>
      )}
    </div>
  );
}

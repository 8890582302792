import { formatDouble, formatNumbers } from 'data/utils/currency-util';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ChartInterval, chartIntervalValues } from 'domain/types';
import { hasValue } from 'domain/util';
import { AssetImage } from 'presentation/components/asset-image';
import { VxBackTitle } from 'presentation/components/micro-components/vx-back-title';
import { VxChips } from 'presentation/components/micro-components/vx-chips';
import { VxPriceChart } from 'presentation/components/micro-components/vx-price-chart';
import { VXSquareSkeleton } from 'presentation/components/micro-components/vx-skeleton/square/style';
import { VxBillet } from 'presentation/components/vx-billet';
import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { useAssetChart } from 'presentation/stores/hooks/use-asset-chart';
import { useAssetInfo } from 'presentation/stores/hooks/use-asset-info';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';
import usePosition from 'presentation/stores/hooks/use-position';
import { useQuotation } from 'presentation/stores/hooks/use-quotation';
import { VxBodyMd, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { H4, H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { allSymbols } from 'presentation/utils/all-symbols-utils';
import { vxOscillationColor } from 'presentation/utils/color-utils';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';
import { AssetProfilePosition } from './components/asset-profile-position';
import { BilletAndPositionContainer, ChartAndInfoContainer } from './style';

dayjs.locale('pt-br');

export const AssetProfilePage: React.FC = () => {
  const { pathname } = useLocation();
  const symbol = pathname.replace('/ativo/', '');
  if (!allSymbols.find((f) => f === symbol)) {
    window.location.href = process.env.REACT_APP_GENIAL_REDIRECT_URL ?? '';
  }
  const [activeInterval, setActiveInterval] = useState<ChartInterval>('1d');
  const { description, isLoadingAssetInfo } = useAssetInfo(symbol);
  const [aboutRef, { width: widthAbout }] = useElementSize();
  const [chartRef, { width: widthChart }] = useElementSize();
  const intervalNames = ['1 dia', '1 mês', '6 meses', '12 meses'];
  const { companyName } = useCompanyName({ symbol });
  const { dailyOscillation, lastTrade } = useQuotation({ symbol });
  const { positions } = usePosition();
  const position = positions.find((p) => p.symbol === symbol);
  const { chartData, isLoadingChartData } = useAssetChart({
    symbol,
    currency: 'BRL',
    interval: activeInterval,
  });
  return (
    <VxGridContainer>
      <VxGridItem columns={8}>
        <ChartAndInfoContainer>
          <VxBackTitle />
          <div className="asset-container">
            <div className="asset-identity-container">
              <AssetImage size="sm" symbol={symbol} />
              <div className="asset-name-container">
                <VxBodyMd title={symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
                <VxBodySm title={companyName} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              </div>
            </div>
            <div className="asset-values-container">
              <H4
                title={formatNumbers(lastTrade ?? 0, { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}
                fontWeight="regular"
                color={vxAppColors.neutral.dark}
              />
              <VxBodyMd
                title={`${formatDouble(dailyOscillation, { alwaysShowSignal: true, minimumDecimalPlaces: 2 })}%`}
                fontWeight="medium"
                color={vxOscillationColor(dailyOscillation)}
              />
            </div>
          </div>
          <div className="chart-container">
            <VxChips
              activeIndex={chartIntervalValues.indexOf(activeInterval)}
              items={intervalNames}
              onClick={(item) => setActiveInterval(chartIntervalValues[intervalNames.indexOf(item)])}
            />
            <div className="chart-data-container" ref={chartRef}>
              {isLoadingChartData ? (
                <VXSquareSkeleton height={200} width={widthChart} skeletonColor="light" />
              ) : (
                <VxPriceChart data={chartData} interval={activeInterval} />
              )}
            </div>
          </div>
          <div className="about-container" ref={aboutRef}>
            <H5 title="Sobre" fontWeight="medium" color={vxAppColors.neutral.dark} />
            {isLoadingAssetInfo ? (
              <VXSquareSkeleton height={96} width={widthAbout} skeletonColor="light" />
            ) : (
              <VxBodyMd title={description ?? ''} fontWeight="regular" color={vxAppColors.neutral.darkest} />
            )}
          </div>
        </ChartAndInfoContainer>
      </VxGridItem>
      <VxGridItem columns={4}>
        <BilletAndPositionContainer>
          <div className="billet-container">
            <VxBillet setHeight={() => null} phase="editing" symbol={symbol} type="assetProfile" />
          </div>
          {hasValue(position?.totalNet) && position?.totalNet !== 0 && (
            <div className="position-container">
              <AssetProfilePosition symbol={symbol} />
            </div>
          )}
        </BilletAndPositionContainer>
      </VxGridItem>
    </VxGridContainer>
  );
};

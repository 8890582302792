import { PatrimonyEntity as PatrimonyEntity } from 'domain/entities/patrimony-entity';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { makeGenialListenPatrimonyUsecase } from 'main/factories/domain/listen-patrimony-usecase-factory';
import { useEffect, useState } from 'react';

export const useGenialPatrimony = () => {
  const [isLoadingGenialPatrimony, setIsLoadingGenialPatrimony] = useState<boolean>(true);
  const [genialPatrimony, setGenialPatrimony] = useState<PatrimonyEntity>();
  const [unsubscribe, setUnsubscribe] = useState<SubscriptionCancelEntity>();
  useEffect(() => {
    setIsLoadingGenialPatrimony(true);
    makeGenialListenPatrimonyUsecase()
      .execute((patrimony) => {
        setGenialPatrimony(patrimony);
      })
      .then((listenOrError) => {
        if (listenOrError.isRight()) {
          setUnsubscribe(listenOrError.value);
          setIsLoadingGenialPatrimony(false);
        }
      })
      .catch(console.error);
    return () => {
      if (unsubscribe) {
        unsubscribe.cancel();
      }
    };
  }, []);
  return {
    genialPatrimony,
    isLoadingGenialPatrimony,
  };
};

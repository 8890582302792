import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const MarginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: ${vxAppColors.neutral.white};
  width: 100%;
  @media (max-width: 1180px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    padding: 12px 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  gap: 16px;
`;

export const ContentPercentAndProgressAndValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const ContentPercentAndProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 1180px) {
    max-width: 282px;
    width: 100%;
    margin-right: 16px;
  }
`;

export const ContentValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ContentMarginUsedValueContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ContentMessageContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
  align-items: flex-start;
`;

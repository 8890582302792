import styled from 'styled-components';

export const ChartAndInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  div.asset-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    @media (min-width: 1081px) {
      margin-top: 48px;
    }
    div.asset-identity-container {
      display: flex;
      align-items: center;
      gap: 8px;
      div.asset-name-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
      }
    }
    div.asset-values-container {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
  }
  div.chart-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 32px;
    @media (min-width: 1081px) {
      gap: 38px;
      margin-top: 48px;
    }
    div.chart-data-container {
      height: 221px;
    }
  }
  div.about-container {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (min-width: 1081px) {
      gap: 24px;
    }
  }
`;

export const BilletAndPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 24px;
  @media (max-width: 1080px) {
    margin-top: 24px;
  }

  div.billet-container {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;

    margin-bottom: 24px;
  }
  div.position-container {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    @media (min-width: 1081px) {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
      padding-bottom: 0px;
      max-width: 504px;
    }
  }
`;

import {
  formatCurrency,
  formatNumbers,
  formatPercentage,
  getPositionPercentage,
  getRentability,
  isNegative,
} from 'data/utils/currency-util';
import { OrderSideIdEnum } from 'domain/enums/orders';
import { AssetImage } from 'presentation/components/asset-image';
import { CloePositionConfirmation } from 'presentation/components/close-position-confirmation';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { Modal } from 'presentation/components/modal';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';

import { VxBodyMd, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useModal } from 'react-modal-hook';
import { goToAssetPage } from 'presentation/utils/navigate-utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Position } from '../../../../table-position-props';
import { StringUtils } from 'presentation/utils/string-utils';

type TableRowDesktopProps = {
  position: Position;
};

export function TableRowDesktop({ position }: TableRowDesktopProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModalConfirm, hideModalConfirm] = useModal(({ in: open }) => {
    return (
      <Modal
        open={open}
        hideModalHookCallback={hideModalConfirm}
        width={460}
        height={213}
        backgroundColor={vxAppColors.neutral.white}
      >
        <CloePositionConfirmation
          symbol={position.symbol}
          quantity={position.totalNet}
          side={position.totalNet > 0 ? OrderSideIdEnum.sell : OrderSideIdEnum.buy}
          onCloseModal={hideModalConfirm}
        ></CloePositionConfirmation>
      </Modal>
    );
  });
  const { companyName } = useCompanyName({ symbol: position.symbol });
  const percentageOptions = { showGainSymbol: true, maximumFractionDigits: 2 };
  const percentageValue = getPositionPercentage(position.totalNet, position.avgPositionValue, position.positionValue);
  const rentabilityValue = getRentability(position.totalNet, position.avgPositionValue, position.positionValue);
  const percentageColor = isNegative(percentageValue)
    ? vxAppColors.feedback.error.default
    : vxAppColors.feedback.success.default;
  const rentabilityColor = isNegative(rentabilityValue)
    ? vxAppColors.feedback.error.default
    : vxAppColors.feedback.success.default;
  return (
    <>
      <tr>
        <td onClick={() => goToAssetPage(position.symbol, navigate, dispatch)}>
          <div className="table__body--first-info">
            <AssetImage symbol={position.symbol} size="xs" />
            <div>
              <VxBodyMd title={position.symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
              <VxBodySm title={companyName} fontWeight="light" color={vxAppColors.brand.primary.darkest} />
            </div>
          </div>
        </td>
        <td className="align-center" onClick={() => goToAssetPage(position.symbol, navigate, dispatch)}>
          <VxBodyMd title={String(position.totalNet)} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
        </td>
        <td className="align-center">
          <VxBodyMd
            title={formatNumbers(position.positionValue, {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            })}
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
          />
        </td>
        <td>
          <div className="table__body--numbers-info">
            <div className="table__body--numbers" onClick={() => goToAssetPage(position.symbol, navigate, dispatch)}>
              <VxBodyMd
                title={
                  Number.isNaN(rentabilityValue)
                    ? '--'
                    : StringUtils.formatRentability({
                        isCrypto: true,
                        value: formatCurrency(rentabilityValue, { alwaysShowSignal: true, showAsInteger: false }),
                      })
                }
                fontWeight="medium"
                color={rentabilityColor}
              />
              <VxBodyMd
                title={`${Number.isNaN(percentageValue) ? '--' : formatPercentage(percentageValue, percentageOptions)}`}
                fontWeight="medium"
                color={percentageColor}
              />
            </div>

            <VxButton type="primary" disabled={false} size="small" onClick={showModalConfirm} text="ENCERRAR" />
          </div>
        </td>
      </tr>
      <tr className="tr-spacer"></tr>
    </>
  );
}

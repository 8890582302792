import styled from 'styled-components';

export const BilletContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

export type ButtonContainerProps = {
  width: number;
};

export const ButtonContainer = styled.div<ButtonContainerProps>`
  button {
    width: ${(props) => props.width}px;
  }
`;

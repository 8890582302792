import * as Styles from './styles';

export function Dots() {
  return (
    <Styles.DotsContainer>
      {[...Array(5).keys()].map((_, index) => (
        <Styles.Dot key={index} />
      ))}
    </Styles.DotsContainer>
  );
}

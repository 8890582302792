import { ThemeProvider } from '@material-ui/core';
import { DateRange, DateRangeDelimiter, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { theme } from 'styles/global';
import { VxInput } from '../vx-input';
import { DateInputContainer } from './style';
import './style.css';

export type VxDateRangeProps = {
  startDate?: Date;
  endDate?: Date;
  onChange: (date: DateRange<Date | null>, keyboardInputValue?: string | undefined) => void;
  startLabel?: string;
  endLabel?: string;
};

dayjs.locale('pt-br');

class SelfAdapter extends AdapterDayjs {
  private dayJs = new AdapterDayjs();
  formatByString = (date: dayjs.Dayjs, formatString: string) => {
    if (formatString === 'MMMM YYYY') {
      const result = this.dayJs.formatByString(date, 'MMM YYYY');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    if (formatString === 'dd') {
      const result = this.dayJs.formatByString(date, 'ddd');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    return this.dayJs.formatByString(date, formatString);
  };
}

export const VxDateRange: React.FC<VxDateRangeProps> = ({
  startDate = null,
  endDate = null,
  onChange,
  startLabel = 'De',
  endLabel = 'Até',
}) => {
  const [value, setValue] = useState<DateRange<Date>>([startDate, endDate]);
  useEffect(() => {
    setValue([startDate, endDate]);
  }, [startDate, endDate]);
  return (
    <LocalizationProvider dateAdapter={SelfAdapter}>
      <ThemeProvider theme={theme}>
        <DateRangePicker
          calendars={1}
          className="calendar"
          startText={startLabel}
          endText={endLabel}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
          renderInput={(startProps, endProps) => {
            return (
              <>
                <DateInputContainer>
                  <VxInput
                    onFocus={startProps.inputProps?.onFocus}
                    onBlur={startProps.inputProps?.onBlur}
                    onChange={endProps.inputProps?.onChange}
                    id={startProps.label as string}
                    label={startProps.label as string}
                    value={startProps.inputProps?.value ?? ''}
                  />
                </DateInputContainer>
                <DateRangeDelimiter></DateRangeDelimiter>
                <DateInputContainer>
                  <VxInput
                    onFocus={endProps.inputProps?.onFocus}
                    onBlur={endProps.inputProps?.onBlur}
                    onChange={endProps.inputProps?.onChange}
                    id={endProps.label as string}
                    label={endProps.label as string}
                    value={endProps.inputProps?.value ?? ''}
                  />
                </DateInputContainer>
              </>
            );
          }}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

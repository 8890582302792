import { getRentabilitySum } from 'data/utils/currency-util';
import { Position } from 'domain/entities/positions';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { LoadPositionsUsecase } from 'domain/usecases/load-positions-usecase';
import { makeCryptoLoadPositions } from 'main/factories/domain/load-positions-usecase-factory';
import { useEffect, useMemo, useState } from 'react';

export default function usePosition() {
  const [positions, setPositions] = useState<Position[]>([]);
  const [loadPositions, setLoadPositions] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState<SubscriptionCancelEntity>();
  useEffect(() => {
    return () => {
      unsubscribe?.cancel();
    };
  }, [unsubscribe]);
  useEffect(() => {
    const loadPositionsUsecase = (): LoadPositionsUsecase => makeCryptoLoadPositions();
    setLoadPositions(true);
    setApiError(false);
    loadPositionsUsecase()
      .execute((positions) => {
        const sortedPositions = positions.sort(
          (positionA, positionB) => positionB.positionValue - positionA.positionValue
        );
        setPositions(sortedPositions);
      })
      .then((listenOrError) => {
        if (listenOrError.isRight()) {
          setApiError(false);
          setUnsubscribe(listenOrError.value);
          setLoadPositions(false);
        } else {
          setApiError(true);
        }
      });
  }, []);
  const hasPositions = useMemo(() => positions.length > 0, [positions]);
  const rentabilitySum = useMemo(() => getRentabilitySum(positions), [positions]);
  return {
    positions,
    isLoading: loadPositions,
    hasError: apiError,
    hasPositions,
    rentabilitySum,
  };
}

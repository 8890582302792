import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const Container = styled.section`
  background: ${vxAppColors.neutral.white};
  height: fit-content;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 24px;
  padding: 24px 32px;

  .head-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 78px;
  height: 37px;

  background: none;
  border: none;

  cursor: pointer;
`;

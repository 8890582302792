import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const OrderDataEntityContainer = styled.div`
  margin-top: 34px;
  cursor: pointer;

  & > span {
    margin-bottom: 10px;
    display: inline-block;

    &:first-child {
      margin-top: -30px;
    }
  }

  .recent-orders {
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      height: 56px;

      &--container {
        display: flex;

        & > div {
          margin-right: 8px;
        }
      }
      &--text {
        display: flex;
        flex-direction: column;

        span {
          &:first-child {
            margin-bottom: 4px;
            display: inline-block;
          }

          &:last-child {
            text-transform: lowercase;

            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .hide {
    display: none;
  }
`;

export const Divider = styled.div`
  height: 0.1px;
  background-color: ${vxAppColors.neutral.lightest};
  width: 104%;
  margin: -22px 1% 12px -2%;
`;

import { VxAppColor } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { ReactComponent as Alert } from '../../../../assets/icons/alert.svg';
import { ReactComponent as Allocation } from '../../../../assets/icons/allocation.svg';
import { ReactComponent as AllocationFilled } from '../../../../assets/icons/allocationFilled.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrowRight.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/arrowUp.svg';
import { ReactComponent as Check } from '../../../../assets/icons/check.svg';
import { ReactComponent as CheckCircle } from '../../../../assets/icons/checkCircle.svg';
import { ReactComponent as CircleCheck } from '../../../../assets/icons/circleCheck.svg';
import { ReactComponent as CircleClose } from '../../../../assets/icons/circleClose.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as Coin } from '../../../../assets/icons/coin.svg';
import { ReactComponent as CoinFilled } from '../../../../assets/icons/coinFilled.svg';
import { ReactComponent as Deposit } from '../../../../assets/icons/deposit.svg';
import { ReactComponent as Download } from '../../../../assets/icons/download.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Exchange } from '../../../../assets/icons/exchange.svg';
import { ReactComponent as Exclamation } from '../../../../assets/icons/exclamation.svg';
import { ReactComponent as Filtro } from '../../../../assets/icons/filtro.svg';
import { ReactComponent as Home } from '../../../../assets/icons/home.svg';
import { ReactComponent as HomeFilled } from '../../../../assets/icons/homeFilled.svg';
import { ReactComponent as Infos } from '../../../../assets/icons/infos.svg';
import { ReactComponent as Olho } from '../../../../assets/icons/olho.svg';
import { ReactComponent as OlhoFechado } from '../../../../assets/icons/olhoFechado.svg';
import { ReactComponent as Order } from '../../../../assets/icons/order.svg';
import { ReactComponent as OrderFilled } from '../../../../assets/icons/orderFilled.svg';
import { ReactComponent as Platforms } from '../../../../assets/icons/platforms.svg';
import { ReactComponent as PlatformsFilled } from '../../../../assets/icons/platformsFilled.svg';
import { ReactComponent as Tax } from '../../../../assets/icons/tax.svg';
import { ReactComponent as Wallet } from '../../../../assets/icons/wallet.svg';
import { ReactComponent as WalletFilled } from '../../../../assets/icons/walletFilled.svg';
import { ReactComponent as Withdraw } from '../../../../assets/icons/withdraw.svg';

import { VXIconStyled } from './style';

const iconSizes: iconSizesType = {
  xsm: 8,
  msm: 12,
  sm: 16,
  md: 24,
  lg: 32,
  xlg: 40,
  xxlg: 48,
};

type iconSizesType = { [size in sizeKeys]: sizeValue };

type sizeKeys = 'xsm' | 'msm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
type sizeValue = 8 | 12 | 16 | 24 | 32 | 40 | 48;

export enum VXIconsEnum {
  check,
  checkCircle,
  close,
  tax,
  download,
  deposit,
  filtro,
  home,
  olhoFechado,
  olho,
  infos,
  arrowDown,
  circleClose,
  circleCheck,
  alert,
  arrowLeft,
  arrowRight,
  coin,
  coinFilled,
  order,
  orderFilled,
  platforms,
  platformsFilled,
  wallet,
  walletFilled,
  homeFilled,
  edit,
  exclamation,
  allocationFilled,
  allocation,
  arrowUp,
  withdraw,
  exchange,
}

type VXSvgIconProps = {
  /**
   * xsm = 8px;
   *
   * msm = 12px;
   *
   * sm = 16px;
   *
   * md = 24px;
   *
   * lg = 32px;
   *
   * xlg = 40px;
   *
   * xxlg = 48px;
   */
  size: sizeKeys;
  icon: VXIconsEnum;
  invertStrokeWithFill?: boolean;
};

const VXSvgIcon: React.FC<VXSvgIconProps> = ({ size, icon }) => {
  switch (icon) {
    case VXIconsEnum.check:
      return <Check height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.checkCircle:
      return <CheckCircle height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.close:
      return <Close height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.tax:
      return <Tax height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.download:
      return <Download height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.deposit:
      return <Deposit height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.filtro:
      return <Filtro height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.home:
      return <Home height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.olhoFechado:
      return <OlhoFechado height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.olho:
      return <Olho height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.infos:
      return <Infos height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.arrowDown:
      return <ArrowDown height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.circleClose:
      return <CircleClose height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.circleCheck:
      return <CircleCheck height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.alert:
      return <Alert height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.arrowLeft:
      return <ArrowLeft height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.arrowRight:
      return <ArrowRight height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.coin:
      return <Coin height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.coinFilled:
      return <CoinFilled height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.order:
      return <Order height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.orderFilled:
      return <OrderFilled height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.platforms:
      return <Platforms height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.platformsFilled:
      return <PlatformsFilled height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.wallet:
      return <Wallet height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.walletFilled:
      return <WalletFilled height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.homeFilled:
      return <HomeFilled height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.edit:
      return <Edit height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.exclamation:
      return <Exclamation height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.allocationFilled:
      return <AllocationFilled height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.allocation:
      return <Allocation height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.arrowUp:
      return <ArrowUp height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.withdraw:
      return <Withdraw height={iconSizes[size]} width={iconSizes[size]} />;
    case VXIconsEnum.exchange:
      return <Exchange height={iconSizes[size]} width={iconSizes[size]} />;
  }
};

export type VXIconProps = VXSvgIconProps & {
  color?: VxAppColor;
  className?: string;
  invertStrokeWithFill?: boolean;
};

export const VXIcon = ({
  size,
  icon,
  color,
  className,
  invertStrokeWithFill,
}: VXIconProps): React.ReactElement<VXIconProps> => {
  return (
    <VXIconStyled
      color={color}
      size={iconSizes[size]}
      className={className ? className : ''}
      invertStrokeWithFill={invertStrokeWithFill}
    >
      <VXSvgIcon icon={icon} size={size} />
    </VXIconStyled>
  );
};

export type VXIconCustomProps = {
  size: number;
  icon: VXIconsEnum;
  color?: VxAppColor;
  invertStrokeWithFill?: boolean;
};
export const VXIconCustom = ({
  size,
  icon,
  color,
  invertStrokeWithFill,
}: VXIconProps): React.ReactElement<VXIconProps> => {
  return (
    <VXIconStyled color={color} size={iconSizes[size]} invertStrokeWithFill={invertStrokeWithFill}>
      <VXSvgIcon icon={icon} size={size} />
    </VXIconStyled>
  );
};

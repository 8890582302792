import { jsonLowerFistLetter } from 'data/utils/json-utils';
import { Failure } from 'domain/failure/failure';
import { hasValue } from 'domain/util/value-util';

class CryptoQuotationOrderBook {
  constructor(readonly size?: number, readonly price?: number, readonly type?: number) {
    Object.freeze(this);
  }
  static fromJson(json: any): CryptoQuotationOrderBook {
    if (!hasValue(json)) {
      return new CryptoQuotationOrderBook();
    }
    json = jsonLowerFistLetter(json);
    return new CryptoQuotationOrderBook(json.size, json.price, json.type);
  }
}

class CryptoQuotationBookInfo {
  constructor(readonly topOfBook?: number, readonly orderBook?: CryptoQuotationOrderBook[]) {
    Object.freeze(this);
  }
  static fromJson(json: any): CryptoQuotationBookInfo {
    if (!hasValue(json)) {
      return new CryptoQuotationBookInfo();
    }
    json = jsonLowerFistLetter(json);
    return new CryptoQuotationBookInfo(
      json.topOfBook,
      (json.orderBook ?? []).map((o: any) => CryptoQuotationOrderBook.fromJson(o))
    );
  }
}

export class CryptoQuotation {
  constructor(
    readonly symbol: string,
    readonly timeExchange?: Date,
    readonly status?: string,
    readonly currencyId?: string,
    readonly lastTrade?: number,
    readonly tickIncrement?: number,
    readonly dailyOscillation?: number,
    readonly takerSide?: string,
    readonly marginType?: number,
    readonly marginValueBuy?: number,
    readonly marginValueSell?: number,
    readonly minQuantity?: number,
    readonly tradingStatus?: number,
    readonly bookInfoBuy?: CryptoQuotationBookInfo,
    readonly bookInfoSell?: CryptoQuotationBookInfo
  ) {
    Object.freeze(this);
  }
  static fromJson(json: any): CryptoQuotation {
    if (!hasValue(json)) {
      throw new Failure('Propriedade "symbol" é obrigatória para a classe "CryptoQuotation"');
    }
    json = jsonLowerFistLetter(json);
    return new CryptoQuotation(
      json.symbol,
      new Date(json.timeExchange),
      json.status,
      json.currencyId,
      json.lastTrade,
      json.tickIncrement,
      json.dailyOscillation,
      json.takerSide,
      json.marginType,
      json.marginValueBuy,
      json.marginValueSell,
      json.minQuantity,
      json.tradingStatus,
      new CryptoQuotationBookInfo(json.bookInfoBuy.topOfBook, json.bookInfoBuy.orderBook),
      new CryptoQuotationBookInfo(json.bookInfoSell.topOfBook, json.bookInfoSell.orderBook)
    );
  }
}

const brandPrimaryDarkest = '#000000';
const brandPrimaryDark = '#030412';
const brandPrimaryPrincipal = '#050922';
const brandPrimaryLight = '#2d314a';
const brandPrimaryLightest = '#CBD0F0';

const brandSecondaryDarkest = '#121B4D';
const brandSecondaryDark = '#0075C9';
const brandSecondaryPrincipal = '#3DA5FD';
const brandSecondaryLight = '#7ED4FF';
const brandSecondaryLightest = '#E5EFF8';

const brandTertiaryDarkest = '#0a0e28';
const brandTertiaryDark = '#002095';
const brandTertiaryPrincipal = '#3047C7';
const brandTertiaryLight = '#6F72FB';
const brandTertiaryLightest = '#E8EAFA';

const productRV = '#000D50';
const productFundos = '#3D46F2';
const productRF = '#0099FF';
const productPrevidencia = '#DDE4EB';
const productTesouro = '#37DCF6';
const productEstruturados = '#254499';
const productClubes = '#1A4E6B';

const riskConservador = '#7CA1C0';
const riskModerado = '#28388F';
const riskAgressivo = '#3C62FB';
const riskArrojado = '#0C0B38';

const graphBench1 = '#0A23B2';
const graphBench2 = '#34D1E7';
const graphBench3 = '#4068A3';
const graphBench4 = '#0099FF';
const graphPrincipal = '#050922';

const feedbackSuccessDefault = '#53BA21';
const feedbackSuccessLightest = '#CCEDBB';
const feedbackErrorDefault = '#E23535';
const feedbackErrorLightest = '#F2C7C7';
const feedbackAtentionDefault = '#FECB0E';
const feedbackAtentionLightest = '#FEE1B2';
const feedbackGenialDefault = '#233C62';
const feedbackGenialLightest = '#DBEAFF';

const neutralDarkest = '#262626';
const neutralDark = '#575756';
const neutralMedium = '#878787';
const neutralLight = '#B2B2B2';
const neutralLightest = '#DADADA';
const neutralWhite = '#FFFFFF';
const neutralSuperLight = '#FFFFFF';

const background = '#F9F9F9';

const buttonHoverCallToAction = '#50CF12';

export type VxAppColor =
  | typeof brandPrimaryDarkest
  | typeof brandPrimaryDark
  | typeof brandPrimaryPrincipal
  | typeof brandPrimaryLight
  | typeof brandPrimaryLightest
  | typeof brandSecondaryDarkest
  | typeof brandSecondaryDark
  | typeof brandSecondaryPrincipal
  | typeof brandSecondaryLight
  | typeof brandSecondaryLightest
  | typeof brandTertiaryDarkest
  | typeof brandTertiaryDark
  | typeof brandTertiaryPrincipal
  | typeof brandTertiaryLight
  | typeof brandTertiaryLightest
  | typeof productRV
  | typeof productFundos
  | typeof productRF
  | typeof productPrevidencia
  | typeof productTesouro
  | typeof productEstruturados
  | typeof productClubes
  | typeof riskConservador
  | typeof riskModerado
  | typeof riskAgressivo
  | typeof riskArrojado
  | typeof graphBench1
  | typeof graphBench2
  | typeof graphBench3
  | typeof graphBench4
  | typeof graphPrincipal
  | typeof feedbackSuccessDefault
  | typeof feedbackSuccessLightest
  | typeof feedbackErrorDefault
  | typeof feedbackErrorLightest
  | typeof feedbackAtentionDefault
  | typeof feedbackAtentionLightest
  | typeof feedbackGenialDefault
  | typeof feedbackGenialLightest
  | typeof neutralDarkest
  | typeof neutralDark
  | typeof neutralMedium
  | typeof neutralLight
  | typeof neutralLightest
  | typeof neutralWhite
  | typeof neutralSuperLight
  | typeof background
  | typeof buttonHoverCallToAction;

const gradient: VxAppColor[] = [brandTertiaryDarkest, brandTertiaryPrincipal];

type BrandColors = {
  darkest: VxAppColor;
  dark: VxAppColor;
  principal: VxAppColor;
  light: VxAppColor;
  lightest: VxAppColor;
};

type FeedbackColors = {
  default: VxAppColor;
  lightest: VxAppColor;
};

export type VxAppColorStyleguide = {
  brand: {
    primary: BrandColors;
    secondary: BrandColors;
    tertiary: BrandColors;
  };
  product: {
    rv: VxAppColor;
    fundos: VxAppColor;
    rf: VxAppColor;
    previdencia: VxAppColor;
    tesouro: VxAppColor;
    estruturados: VxAppColor;
    clubes: VxAppColor;
  };
  risk: {
    conservador: VxAppColor;
    moderado: VxAppColor;
    agressivo: VxAppColor;
    arrojado: VxAppColor;
  };
  graph: {
    bench1: VxAppColor;
    bench2: VxAppColor;
    bench3: VxAppColor;
    bench4: VxAppColor;
    principal: VxAppColor;
  };
  feedback: {
    success: FeedbackColors;
    error: FeedbackColors;
    atention: FeedbackColors;
    genial: FeedbackColors;
  };
  neutral: {
    darkest: VxAppColor;
    dark: VxAppColor;
    medium: VxAppColor;
    light: VxAppColor;
    lightest: VxAppColor;
    white: VxAppColor;
    superLight: VxAppColor;
  };
  button: {
    hover: {
      callToAction: VxAppColor;
    };
  };
  gradient: VxAppColor[];
  background: VxAppColor;
};

export const vxAppColors: VxAppColorStyleguide = {
  brand: {
    primary: {
      darkest: brandPrimaryDarkest,
      dark: brandPrimaryDark,
      principal: brandPrimaryPrincipal,
      light: brandPrimaryLight,
      lightest: brandPrimaryLightest,
    },
    secondary: {
      darkest: brandSecondaryDarkest,
      dark: brandSecondaryDark,
      principal: brandSecondaryPrincipal,
      light: brandSecondaryLight,
      lightest: brandSecondaryLightest,
    },
    tertiary: {
      darkest: brandTertiaryDarkest,
      dark: brandTertiaryDark,
      principal: brandTertiaryPrincipal,
      light: brandTertiaryLight,
      lightest: brandTertiaryLightest,
    },
  },
  product: {
    rv: productRV,
    fundos: productFundos,
    rf: productRF,
    previdencia: productPrevidencia,
    tesouro: productTesouro,
    estruturados: productEstruturados,
    clubes: productClubes,
  },
  risk: {
    conservador: riskConservador,
    moderado: riskModerado,
    agressivo: riskAgressivo,
    arrojado: riskArrojado,
  },
  graph: {
    bench1: graphBench1,
    bench2: graphBench2,
    bench3: graphBench3,
    bench4: graphBench4,
    principal: graphPrincipal,
  },
  feedback: {
    success: {
      default: feedbackSuccessDefault,
      lightest: feedbackSuccessLightest,
    },
    error: {
      default: feedbackErrorDefault,
      lightest: feedbackErrorLightest,
    },
    atention: {
      default: feedbackAtentionDefault,
      lightest: feedbackAtentionLightest,
    },
    genial: {
      default: feedbackGenialDefault,
      lightest: feedbackGenialLightest,
    },
  },
  neutral: {
    darkest: neutralDarkest,
    dark: neutralDark,
    medium: neutralMedium,
    light: neutralLight,
    lightest: neutralLightest,
    white: neutralWhite,
    superLight: neutralSuperLight,
  },
  button: {
    hover: {
      callToAction: buttonHoverCallToAction,
    },
  },
  gradient,
  background,
};

import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';

import { Container, TabItem } from './style';

interface TabsProps {
  items: Array<string>;
  spaceBetweenTextAndBar?: number;
  tabWidth?: number;
  defaultActiveItem?: number;
  itemClickCallback: (index: number, text: string) => void;
}

const VxTabs = ({ items, tabWidth, itemClickCallback, defaultActiveItem = 0 }: TabsProps) => {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(defaultActiveItem);

  return (
    <Container>
      {items.map((item, index) => {
        var isActiveItem = selectedItemIndex === index ? true : false;
        return (
          <TabItem
            key={index}
            onClick={() => {
              itemClickCallback(index, item);
              return setSelectedItemIndex(index);
            }}
            width={tabWidth}
            className={isActiveItem ? 'active' : 'inactive'}
          >
            <VxBodySm title={item} fontWeight="medium" color={vxAppColors.neutral.superLight} htmlTag="span" />
          </TabItem>
        );
      })}
    </Container>
  );
};

export { VxTabs };

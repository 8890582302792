import { AssetChartRepository } from 'data/repositories/http/asset-chart-http-repository';
import { LoadAssetChartRepository } from 'domain/repositories/load-chart-asset-data-repository';
import { LoadMiniChartRepository } from 'domain/repositories/load-mini-chart-repository';
import { makeCryptoHttpDatasource } from 'main/factories/datasources/http/http-datasource-factory';
import { makeCryptoUrlDatasource } from 'main/factories/datasources/url/url-datasource-factory';

let repository: AssetChartRepository | null = null;

const makeAssetChartRepository = (): AssetChartRepository => {
  if (repository === null) {
    repository = new AssetChartRepository({
      httpDatasource: makeCryptoHttpDatasource(),
      urlDatasource: makeCryptoUrlDatasource(),
    });
  }
  return repository;
};

export const makeLoadMiniChartHttpRepository = (): LoadMiniChartRepository => {
  return makeAssetChartRepository();
};

export const makeLoadAssetChartHttpRepository = (): LoadAssetChartRepository => {
  return makeAssetChartRepository();
};

import { Either } from 'domain/entities/either';
import { ExtractHistoryCache } from 'domain/entities/extract-entity';
import { Failure } from 'domain/failure/failure';
import { LoadExtractHistoryRepository } from 'domain/repositories/load-extract-history-repository';

export interface LoadExtractHistoryCacheUsecase {
  execute(): Promise<Either<Failure, ExtractHistoryCache[]>>;
}

export class LoadExtractHistoryCacheUsecaseImpl implements LoadExtractHistoryCacheUsecase {
  constructor(readonly repository: LoadExtractHistoryRepository) {
    Object.freeze(this);
  }
  async execute(): Promise<Either<Failure, ExtractHistoryCache[]>> {
    return this.repository.loadHistory();
  }
}

import { makeLoadAssetInfoUsecase } from 'main/factories/domain/load-asset-info-usecase-factory';
import { useEffect, useState } from 'react';

export function useAssetInfo(symbol: string) {
  const [isLoadingAssetInfo, setIsLoadingAssetInfo] = useState<boolean>(true);
  const [id, setId] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [assetProfile, setAssetProfile] = useState<string>();
  const [assetType, setAssetType] = useState<string>();
  const [assetMarket, setAssetMarket] = useState<string>();
  const [description, setDescription] = useState<string>();
  useEffect(() => {
    setIsLoadingAssetInfo(true);
    makeLoadAssetInfoUsecase()
      .execute(symbol)
      .then((assetInfoOrError) => {
        if (assetInfoOrError.isRight()) {
          setId(assetInfoOrError.value.id);
          setCompanyName(assetInfoOrError.value.companyName);
          setAssetMarket(assetInfoOrError.value.assetMarket);
          setAssetProfile(assetInfoOrError.value.assetProfile);
          setAssetType(assetInfoOrError.value.assetType);
          setDescription(assetInfoOrError.value.description);
        }
      })
      .finally(() => setIsLoadingAssetInfo(false));
  }, [symbol]);
  return {
    isLoadingAssetInfo,
    id,
    symbol,
    companyName,
    assetProfile,
    assetMarket,
    assetType,
    description,
  };
}

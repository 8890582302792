import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SideBarRoutesEnum } from 'config/routes/router-enum';
import { ChartIntervalEnum, getChartIntervalNumber } from 'domain/enums/profile-chart-interval-enum';

type SettingsEnvironment = 'spot' | 'margin';

export const Currencys = {
  real: { symbol: 'BRL', companyName: 'Real', image: 'real' },
  dolar: { symbol: 'USD', companyName: 'Dólar', image: 'dollar' },
};

const SettingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: {
    environment: 'spot',
    showInfo: true,
    chartInterval: ChartIntervalEnum.one_day,
    chartIntervalNumber: getChartIntervalNumber(ChartIntervalEnum.one_day),
    currentRoute: location.pathname,
    sideBarRoute: location.pathname,
    selectedCurrency: Currencys.real,
  },
  reducers: {
    setEnvironment: (state, action: PayloadAction<SettingsEnvironment>) => {
      state.environment = action.payload;
    },
    toggleShowInfo: (state) => {
      state.showInfo = !state.showInfo;
    },
    setChartInterval: (state, action: PayloadAction<ChartIntervalEnum>) => {
      state.chartInterval = action.payload;
      state.chartIntervalNumber = getChartIntervalNumber(action.payload);
    },
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload;
      if (Object.values(SideBarRoutesEnum).some((s) => s === action.payload)) {
        state.sideBarRoute = action.payload;
      }
    },
    setCurrency: (state, action: PayloadAction<any>) => {
      state.selectedCurrency = action.payload;
    },
  },
});

export const { setEnvironment, toggleShowInfo, setChartInterval, setCurrentRoute, setCurrency } = SettingsSlice.actions;
export default SettingsSlice.reducer;

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const Tbody = styled.tbody`
  tr {
    margin-top: 16px;
    height: 80px;
    border-bottom: 16px solid ${vxAppColors.background};
    :last-child {
      border-bottom: none;
    }
    :first-child {
      border-top: 16px solid ${vxAppColors.background};
    }
    @media (max-width: 1024px) {
      height: 48px;
      gap: 8px;
    }
    td {
      vertical-align: middle;
      text-align: left;
      padding-left: 8px;
      :last-child {
        text-align: right;
      }
      .symbol-info {
        display: flex;
        align-items: center;
        gap: 8px;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          :last-child {
            align-items: start;
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  .header-skeleton {
    display: flex;
    width: 824px;

    & > div {
      margin-right: 24px;
    }
  }

  .content-skeleton {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 35px;

    &--first {
      display: flex;
      align-items: center;

      div:first-child {
        margin-bottom: 4px;
        margin-right: 10px;
      }
    }

    &--second {
      margin-left: 12.01%;
      margin-right: 7.84%;
    }

    &--third {
      margin-right: 7.75%;
    }

    &--fourth {
      margin-right: 7.75%;
    }

    &--fifth {
      display: flex;
      align-items: center;

      & div:first-child {
        margin-right: 24px;
      }
    }
  }
`;

import { VXIconCustom, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { VxBilletType } from 'presentation/components/vx-billet';
import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { VxBodyLg, VxBodySm } from 'presentation/styleguide/typography/body/VxBody.stories';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useMediaQuery } from 'usehooks-ts';
import { BilletTitle, BilletTitleIconContainer } from './style';

export type VxBilletTitleProps = {
  onClose?: () => void;
  onBack?: () => void;
  title: string;
  tooltip?: string;
  type?: VxBilletType;
};

export const VxBilletConfirmationTitle: React.FC<VxBilletTitleProps> = ({ title, onClose, type = 'modal' }) => {
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  return (
    <BilletTitle type={type}>
      {isSmall ? (
        <VxBodySm title={title} fontWeight="light" color={vxAppColors.neutral.dark} />
      ) : type === 'assetProfile' ? (
        <VxBodyMd title={title} fontWeight="light" color={vxAppColors.neutral.dark} />
      ) : (
        <VxBodyLg title={title} fontWeight="medium" color={vxAppColors.neutral.darkest} />
      )}
      {onClose && (
        <BilletTitleIconContainer onClick={onClose}>
          <VXIconCustom icon={VXIconsEnum.close} size="xsm" color={vxAppColors.brand.primary.principal} />
        </BilletTitleIconContainer>
      )}
    </BilletTitle>
  );
};

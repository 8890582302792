import { OrderSideIdEnum } from 'domain/enums/orders';
import { makeCryptoSendOrderUsecase } from 'main/factories/domain/send-order-usecase-factory';
import { RootState } from 'presentation/stores';
import usePosition from 'presentation/stores/hooks/use-position';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import { DESKTOP_XS } from '../vx-grid/helpers';
import { VxBilletConfirmation } from './components/vx-billet-confirmation';
import { VxBilletEditing } from './components/vx-billet-editing';
import { VxBilletSelecting } from './components/vx-billet-selecting';

export type VxBilletPhase = 'selecting' | 'editing' | 'confirmation';

export type VxBilletSide = 'buyOrBlock' | 'sellOrRelease';

export type VxBilletType = 'modal' | 'assetProfile' | 'margin';

export type VxBilletProps = {
  hideModal?: () => void;
  phase: VxBilletPhase;
  type?: VxBilletType;
  symbol?: string;
  side?: VxBilletSide;
  setHeight?: React.Dispatch<React.SetStateAction<number>>;
};

export const VxBillet: React.FC<VxBilletProps> = (props) => {
  const [symbol, setSymbol] = useState<string>(props.symbol ?? '');
  const [value, setValue] = useState<string>();
  const [isTypingValue, setIsTypingValue] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<string>();
  const [phase, setPhase] = useState<VxBilletPhase>(props.phase);
  const [side, setSide] = useState<VxBilletSide>(props.side ?? 'buyOrBlock');
  const settingsSlice = useSelector((state: RootState) => state.settingsSlice);
  const { positions } = usePosition();
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  switch (phase) {
    case 'editing':
      props.setHeight?.(
        props.type === 'margin' && side === 'sellOrRelease' ? (isSmall ? 376 : 451) : isSmall ? 418 : 527
      );
      return (
        <VxBilletEditing
          symbol={symbol ?? ''}
          onClose={props.hideModal}
          quantity={quantity}
          value={value}
          setQuantity={setQuantity}
          setValue={setValue}
          onBuy={() => {
            setSide('buyOrBlock');
            setPhase('confirmation');
          }}
          onSell={() => {
            setSide('sellOrRelease');
            setPhase('confirmation');
          }}
          type={props.type}
          side={side}
          isTypingValue={isTypingValue}
          setIsTypingValue={setIsTypingValue}
          onItemClick={
            props.type === 'assetProfile' ||
            (positions.length === 0 && props.type === 'margin' && side === 'sellOrRelease')
              ? undefined
              : () => setPhase('selecting')
          }
        />
      );
    case 'confirmation':
      props.setHeight?.(props.type === 'modal' ? (isSmall ? 284 : 366) : isSmall ? 241 : 332);
      return (
        <VxBilletConfirmation
          quantity={Number(quantity) ?? 0}
          value={Number(value) ?? 0}
          side={side}
          type={props.type ?? 'modal'}
          symbol={symbol ?? ''}
          onClose={props.hideModal}
          onCancel={
            props.hideModal
              ? props.hideModal
              : () => {
                  setIsTypingValue(true);
                  setQuantity('0.00');
                  setValue('0.00');
                  setPhase('editing');
                }
          }
          onConfirm={() => {
            if (props.type !== 'margin') {
              makeCryptoSendOrderUsecase().execute({
                symbol: symbol,
                price: Number.parseFloat(value ?? '0'),
                quantity: Number.parseFloat(quantity ?? '0'),
                side: side === 'buyOrBlock' ? OrderSideIdEnum.buy : OrderSideIdEnum.sell,
                isLavarage: settingsSlice.environment === 'margin',
              });
            }
            props.hideModal?.();
          }}
          onEdit={() => setPhase('editing')}
        />
      );
    default:
      props.setHeight?.(535);
      return (
        <VxBilletSelecting
          type={props.type}
          showOnlyWithPosition={props.type === 'margin'}
          onClose={props.type === 'margin' ? () => setPhase('editing') : props.hideModal}
          onSelect={(symbol) => {
            setSymbol(symbol);
            setPhase('editing');
          }}
        />
      );
  }
};

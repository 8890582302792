import { ProductEntity } from 'domain/entities/product-entity';
import { makeLoadAllProductsUsecase } from 'main/factories/domain/load-all-products-usecase-factory';
import { useEffect, useState } from 'react';

export function useAllProducts() {
  const [allProducts, setAllProducts] = useState<ProductEntity[]>([]);
  const [isLoadingAllProducts, setIsLoadingAllProducts] = useState<boolean>(true);
  useEffect(() => {
    setIsLoadingAllProducts(true);
    makeLoadAllProductsUsecase()
      .execute()
      .then((allProductsOrError) => {
        if (allProductsOrError.isRight()) {
          setAllProducts(allProductsOrError.value);
          setIsLoadingAllProducts(false);
        }
      })
      .catch(console.error);
  }, []);
  return {
    allProducts,
    isLoadingAllProducts,
  };
}

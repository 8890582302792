import styled from 'styled-components';

export const OrderDataEntityContainer = styled.div`
  margin-top: 34px;
  & > span {
    margin-bottom: 10px;
    display: inline-block;

    &:first-child {
      margin-top: -30px;
    }
  }

  .recent-orders {
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--container {
        display: flex;

        & > div {
          margin-right: 8px;
        }
      }
      &--text {
        display: flex;
        flex-direction: column;

        div {
          margin-bottom: 10px;
        }
      }

      &--status {
      }
    }
  }
`;

import { SessionStorageEnum } from 'domain/repositories/session-storage/session-storage-enum';
import { isNotEmptystorage } from 'domain/repositories/session-storage/session_storage';
import { AssetProfilePage } from 'presentation/pages/asset-profile';
import HomePage from 'presentation/pages/home';
import { NotFoundPage } from 'presentation/pages/not-found';
import { ProfileExtractPage } from 'presentation/pages/profile/subpages/extract';
import { OrderPage } from 'presentation/pages/profile/subpages/orders';
import { SessionPage } from 'presentation/pages/session';
import { DepositWithdrawPage } from 'presentation/pages/deposit-withdraw';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutesEnum } from './router-enum';
import { PlatformsPage } from '../../presentation/pages/platforms';
import { WalletPage } from '../../presentation/pages/wallet';
import { PrivateRoute } from './private-route';
import { MarginAlocationPage } from 'presentation/pages/margin-alocation';

const VexterRouter: React.FunctionComponent = () => {
  return (
    <Routes>
      {/* Unprotected routes */}
      <Route path="*" element={<NotFoundPage />} />
      <Route element={<SessionPage />} path={RoutesEnum.access} />
      {/* Protected routes */}
      <Route element={<PrivateRoute isAuthenticated={isNotEmptystorage(SessionStorageEnum.token)} />}>
        <Route element={<HomePage />} path={RoutesEnum.home} />
        <Route element={<PlatformsPage />} path={RoutesEnum.platform} />
        <Route element={<OrderPage />} path={RoutesEnum.orders} />
        <Route element={<WalletPage />} path={RoutesEnum.wallet} />
        <Route element={<ProfileExtractPage />} path={RoutesEnum.extract} />
        <Route element={<AssetProfilePage />} path={`${RoutesEnum.assetProfile}/:symbol`} />
        <Route element={<DepositWithdrawPage />} path={RoutesEnum.depositWithdraw} />
        <Route element={<MarginAlocationPage />} path={RoutesEnum.marginAlocation} />
      </Route>
    </Routes>
  );
};

export { VexterRouter };

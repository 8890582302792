import styled from 'styled-components';
export const InvestedAmountContainer = styled.div`
  display: flex;
  flex-direction: column;

  .invested-amount {
    display: flex;
    align-items: center;
    width: 81.97%;

    &__numbers {
      margin-right: 6.8%;
      &--info {
        margin-top: 8px;
      }
    }

    &__image {
      display: flex;
      align-items: center;

      &--info {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        div {
          display: flex;
          margin-bottom: 8px;

          & > div {
            margin-right: 8px;
          }
        }
      }
    }
  }
`;

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { VXIcon, VXIconsEnum } from '../micro-components/vx-icon';
import CarouselPagination from './components/carousel_pagination/index';
import { ButtonNext, ButtonPrev } from './components/slide_buttons';

type VexterCarouselProps = {
  componets: any[];
  spaceBetween?: number;
  slidesPerView?: number;
  showPagination?: boolean;
  paginationInside?: boolean;
  autoplayDelay?: number;
  backgroundColor?: string;
  onSlideChange?: (props: object) => void;
};

type ButtonProps = {
  isBeginning: boolean;
  IsEnd: boolean;
};

// TODO: refazer o component
const SlideButtons: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  return (
    <div>
      <ButtonPrev onClick={() => document.getElementById('voltar')?.click()} showButton={props.isBeginning}>
        {<VXIcon size="sm" color={vxAppColors.brand.primary.principal} icon={VXIconsEnum.arrowLeft} />}
      </ButtonPrev>
      <ButtonNext onClick={() => document.getElementById('ir')?.click()} showButton={props.IsEnd}>
        {<VXIcon size="sm" color={vxAppColors.brand.primary.principal} icon={VXIconsEnum.arrowRight} />}
      </ButtonNext>
    </div>
  );
};
const HideButtons: React.FunctionComponent = () => {
  const swiper: SwiperCore = useSwiper();
  return (
    <div style={{ display: 'none' }}>
      <button id={'ir'} onClick={() => swiper.slideNext()}>
        IR
      </button>
      <button id="voltar" onClick={() => swiper.slidePrev()}>
        Voltar
      </button>
    </div>
  );
};
const VexterCarousel: React.FunctionComponent<VexterCarouselProps> = (props: VexterCarouselProps) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [isBegin, setisBegin] = React.useState(false);
  const [isEnd, setIsEnd] = React.useState(true);

  if (props.autoplayDelay !== undefined) SwiperCore.use([Autoplay]);
  return (
    <div style={{ position: 'relative' }}>
      {props.componets.length > (props.slidesPerView ?? 0) && <SlideButtons isBeginning={isBegin} IsEnd={isEnd} />}

      <Swiper
        spaceBetween={props.spaceBetween ?? 40}
        slidesPerView={props.slidesPerView ?? 2}
        onSlideChange={(swiper) => {
          if (props.onSlideChange !== undefined) {
            props.onSlideChange(swiper);
          }
          setisBegin(!swiper.isBeginning);
          setIsEnd(!swiper.isEnd);
          setActiveIndex(swiper.realIndex);
        }}
        autoplay={{ delay: props.autoplayDelay }}
      >
        {props.componets?.map((comp) => (
          <SwiperSlide key={Math.random() * Date.now()}>{comp}</SwiperSlide>
        ))}
        {props.showPagination && (
          <CarouselPagination
            paginationInside={props.paginationInside ?? false}
            backgroundColor={props.backgroundColor}
            activeIndex={activeIndex}
            paginationLength={
              props.slidesPerView === 1
                ? props.componets.length
                : props.componets.length - (props.slidesPerView ?? 0 - 1)
            }
          />
        )}
        <HideButtons />
      </Swiper>
    </div>
  );
};

export default VexterCarousel;

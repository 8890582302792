import { FirebaseFirestoreDatasource } from 'data/datasources/firebase/firestore-datasource';
import { BannerDataEntity } from 'domain/entities/banner-data-entity';
import { Either, left, right } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadBannersRepository } from 'domain/repositories/load-banners-repository';
import { hasValue } from 'domain/util/value-util';

export type MarketingResponseDataParams = {
  codigo?: number;
  podeArmazenar?: boolean;
  urlImagem?: string;
  subtitulo?: string;
  titulo?: string;
  expiraEm?: Date;
};

export class MarketingResponseData {
  constructor(
    readonly codigo?: number,
    readonly podeArmazenar?: boolean,
    readonly urlImagem?: string,
    readonly subtitulo?: string,
    readonly titulo?: string,
    readonly expiraEm?: Date
  ) {
    Object.freeze(this);
  }

  static fromJson(json: any): MarketingResponseData {
    return new MarketingResponseData(
      json.codigo,
      json.pode_armazenar,
      json.url_imagem,
      json.subtitulo,
      json.titulo,
      new Date(json.expira_em.seconds * 1000)
    );
  }
}

export class LoadBannersFirestoreRepository implements LoadBannersRepository {
  constructor(readonly firestoreDatasource: FirebaseFirestoreDatasource) {
    Object.freeze(this);
  }
  async loadBanners(): Promise<Either<Failure, BannerDataEntity[]>> {
    const error = left<Failure, BannerDataEntity[]>(new Failure('Erro ao carregar da internet os banners'));
    try {
      const marketingAllData = await this.firestoreDatasource.getCollection('marketing');
      const bannersAllData = marketingAllData.docs[0].data().data;
      let result: BannerDataEntity[] = [];
      const now = new Date(Date.now());
      if (Array.isArray(bannersAllData)) {
        result = bannersAllData
          .map((b) => MarketingResponseData.fromJson(b))
          .filter((b) => hasValue(b.subtitulo) && hasValue(b.titulo) && hasValue(b.urlImagem))
          .filter((b) => (b.expiraEm ?? now) >= now)
          .map((b) => ({
            subtitle: b.subtitulo,
            title: b.titulo,
            url: b.urlImagem,
          })) as any;
      }
      return right(result);
    } catch (e) {
      return error;
    }
  }
}

import { Either } from 'domain/entities';
import { AssetInfoEntity } from 'domain/entities/asset-info-entity';
import { Failure } from 'domain/failure/failure';
import { LoadAssetInfoRepository } from 'domain/repositories/load-asset-info-repository';

export interface LoadAssetInfoUsecase {
  execute: (symbol: string) => Promise<Either<Failure, AssetInfoEntity>>;
}

export class LoadAssetInfoUsecaseImp implements LoadAssetInfoUsecase {
  constructor(readonly repository: LoadAssetInfoRepository) {
    Object.freeze(this);
  }
  execute(symbol: string): Promise<Either<Failure, AssetInfoEntity>> {
    return this.repository.assetInfo(symbol);
  }
}

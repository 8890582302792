import { VxDropdownFilter, VxDropdownItemsType } from 'presentation/components/micro-components/vx-dropdown-filter';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { OrderFilterStatusStyle } from './style';

export type OrderFilterStatusProps = {
  statuses: VxDropdownItemsType[];
  onCheck: (items: VxDropdownItemsType[]) => void;
};

export const OrderFilterStatus: React.FC<OrderFilterStatusProps> = ({ statuses, onCheck }) => {
  return (
    <OrderFilterStatusStyle>
      <VxBodyMd title="Filtrar por status" color={vxAppColors.neutral.dark} fontWeight="medium" />
      <VxDropdownFilter
        showSelectAllOptions={true}
        items={statuses}
        onChangeItem={(list) => onCheck(list)}
        emptyTextPlaceholder="Selecione o tipo de status"
      />
    </OrderFilterStatusStyle>
  );
};

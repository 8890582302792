import { zipObject } from 'lodash';

export const getEnumKeys = (actualEnum: any) => Object.keys(actualEnum).filter((x) => !(parseInt(x) >= 0));

export const getEnumValues = <T extends { [key: number]: string | number }>(actualEnum: T): T[keyof T][] => {
  return getEnumKeys(actualEnum).map((x) => {
    return actualEnum[x as keyof typeof actualEnum] as T[keyof T];
  }) as T[keyof T][];
};

export function getEnumKeyByEnumValue<TEnumKey extends string, TEnumVal extends string | number>(
  myEnum: { [key in TEnumKey]: TEnumVal },
  enumValue: TEnumVal
): string {
  const keys = (Object.keys(myEnum) as TEnumKey[]).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
}

export const generateKeyValuePair = (RouteMap: Record<string, string | number>) => {
  return zipObject(
    getEnumKeys(RouteMap).map((key) => key),
    getEnumValues(RouteMap).map((key) => key)
  );
};

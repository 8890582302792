import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import styled from 'styled-components';
import { VxBilletType } from '../..';

export const BodyContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

export type ItemContainerProps = {
  type: VxBilletType;
};

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.type === 'modal' ? 95 : 103)}px;
  justify-content: center;
  @media (max-width: 1024px) {
    height: ${(props) => (props.type === 'modal' ? 64 : 68)}px;
  }
`;

export const WalletAndPatrimonyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 88px;
  gap: 8px;
  justify-content: center;
  @media (max-width: 1024px) {
    height: 60px;
  }
`;

export const WalletAndPatrimonySectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ValueAndQuantityContainer = styled.div<ItemContainerProps>`
  display: flex;
  flex-direction: column;
  height: 208px;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  @media (max-width: ${DESKTOP_XS}px) {
    height: 176px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const ValueOptionsContainer = styled.div`
  display: flex;
  gap: 24px;
  .guarantee-asset-checkbox {
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      gap: 2px;
      display: flex;
      align-items: center;
    }
  }
`;

export const BuyAndSellContainer = styled.div<ItemContainerProps>`
  display: flex;
  height: ${(props) => (props.type === 'modal' ? 72 : 80)}px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (max-width: ${DESKTOP_XS}px) {
    height: ${(props) => (props.type === 'modal' ? 66 : 74)}px;
  }
`;

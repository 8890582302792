import { makeCancelOrderUsecase } from 'main/factories/domain/cancel-order-usecase-factory';
import { vexterToastError, vexterToastSuccess } from 'presentation/components/micro-components/toast';
import { useCallback, useState } from 'react';

export function useCancelOrder(vexterOrderId: string) {
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);
  const onCancel = useCallback(() => {
    setIsLoadingCancel(true);
    makeCancelOrderUsecase()
      .execute(vexterOrderId)
      .then((cancelOrError) => {
        cancelOrError.fold(
          () => {
            vexterToastError({
              title: 'Opa! Algo parece errado.',
              hasClose: true,
            });
          },
          () => {
            vexterToastSuccess({
              title: 'Tudo certo!',
              hasClose: true,
            });
          }
        );
      })
      .finally(() => setIsLoadingCancel(false));
  }, [vexterOrderId]);
  return {
    onCancel,
    isLoadingCancel,
  };
}

import { Checkbox } from '@mui/material';
import { VxBilletSide } from 'presentation/components/vx-billet';
import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { VxBodyXs } from 'presentation/styleguide/typography/body/VxBody.stories';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useMediaQuery } from 'usehooks-ts';
import { CheckboxContainer } from './style';

export type VxBilletEditingCheckboxProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  useAll: boolean;
  side?: VxBilletSide;
};

export const VxBilletEditingCheckbox: React.FC<VxBilletEditingCheckboxProps> = ({ onClick, useAll, side }) => {
  const isSmall = useMediaQuery(`(max-width: ${DESKTOP_XS}px)`);
  return (
    <CheckboxContainer onClick={onClick}>
      <Checkbox
        size="small"
        style={{ color: vxAppColors.brand.primary.principal, padding: '0px', width: '16px', height: '16px' }}
        disableTouchRipple
        disableFocusRipple
        checked={useAll}
      />
      <span>
        {isSmall ? (
          <>
            <VxBodyXs
              title={side === 'buyOrBlock' ? 'Alocar' : 'Desbloquear'}
              fontWeight="medium"
              color={vxAppColors.neutral.dark}
            />
            <VxBodyXs title="quantia total" fontWeight="bold" color={vxAppColors.neutral.dark} />
          </>
        ) : (
          <>
            <VxBodySm
              title={side === 'buyOrBlock' ? 'Alocar' : 'Desbloquear'}
              fontWeight="medium"
              color={vxAppColors.neutral.dark}
            />
            <VxBodySm title="quantia total" fontWeight="bold" color={vxAppColors.neutral.dark} />
          </>
        )}
      </span>
    </CheckboxContainer>
  );
};

import { VxGridContainer, VxGridItem } from '../vx-grid';
import { VxTabs } from '../vx-tabs';
import * as Styles from './styles';

interface SubHeaderProps {
  itens: string[];
  onClick?: (parameter: string) => void;
  defaultActiveItem?: number;
}
export function SubHeader({ itens, onClick, defaultActiveItem = 0 }: SubHeaderProps) {
  return (
    <Styles.HomeSubheader>
      <VxGridContainer>
        <VxGridItem>
          <VxTabs
            defaultActiveItem={defaultActiveItem}
            items={itens}
            itemClickCallback={function (_index: number, text: string): void {
              if (onClick) onClick(text);
            }}
          />
        </VxGridItem>
      </VxGridContainer>
    </Styles.HomeSubheader>
  );
}

import { VxBodyMd, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';

type TableHeadProps = {
  isExceptionalForMobileAndSmallDesktop: boolean;
};

export function TableHead({ isExceptionalForMobileAndSmallDesktop }: TableHeadProps) {
  return (
    <thead>
      {isExceptionalForMobileAndSmallDesktop ? (
        <tr className="table__row-head">
          <th scope="col">
            <VxBodyXs title="Nome" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
          <th scope="col" className="align-center">
            <VxBodyXs title="Quantidade" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
          <th scope="col" className="align-center">
            <VxBodyXs title="Valor" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
          <th scope="col" className="align-center">
            <VxBodyXs title="Rentabilidade" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
        </tr>
      ) : (
        <tr className="table__row-head">
          <th scope="col">
            <VxBodyMd title="Nome" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
          <th scope="col" className="align-center">
            <VxBodyMd title="Quantidade" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
          <th scope="col" className="align-center">
            <VxBodyMd title="Valor" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
          <th scope="col" className="align-center">
            <VxBodyMd title="Rentabilidade" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </th>
        </tr>
      )}
    </thead>
  );
}

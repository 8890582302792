import { VXRoundedSkeleton } from 'presentation/components/micro-components/vx-skeleton/rounded/style';
import { VXSquareSkeleton } from 'presentation/components/micro-components/vx-skeleton/square/style';

import * as Styles from './styles';

function TablePositionContent() {
  return (
    <div className="content-skeleton">
      <div className="content-skeleton--first">
        <VXRoundedSkeleton size={40} skeletonColor="light" />
        <div>
          <VXSquareSkeleton width={65} height={20} skeletonColor="light" />
          <VXSquareSkeleton width={65} height={20} skeletonColor="light" />
        </div>
      </div>

      <div className="content-skeleton--second">
        <VXSquareSkeleton width={149} height={20} skeletonColor="light" />
      </div>

      <div className="content-skeleton--third">
        <VXSquareSkeleton width={75} height={33} skeletonColor="light" />
      </div>

      <div className="content-skeleton--fourth">
        <VXSquareSkeleton width={149} height={20} skeletonColor="light" />
      </div>

      <div className="content-skeleton--fifth">
        <VXSquareSkeleton width={93} height={34} skeletonColor="light" />
      </div>
    </div>
  );
}

export function TablePositionSkeleton() {
  return (
    <Styles.Container>
      <div className="header-skeleton">
        <VXSquareSkeleton width={116} height={38} skeletonColor="light" />
        <VXSquareSkeleton width={295} height={38} skeletonColor="light" />
        <VXSquareSkeleton width={310} height={38} skeletonColor="light" />
      </div>

      {[...Array(5).keys()].map((_, index) => (
        <TablePositionContent key={index} />
      ))}
    </Styles.Container>
  );
}

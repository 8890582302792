import { VxButtonTypeLg, VxButtonTypeMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useMediaQuery } from 'usehooks-ts';
import { VXIcon, VXIconsEnum } from '../vx-icon';
import * as S from './styles';

function buttonColors(type: 'primary' | 'danger' | 'success') {
  switch (type) {
    case 'danger':
      return {
        textColor: vxAppColors.feedback.error.default,
        hoverColor: vxAppColors.feedback.error.lightest,
      };
    case 'success':
      return {
        textColor: vxAppColors.feedback.success.default,
        hoverColor: vxAppColors.feedback.success.lightest,
      };
    default:
      return {
        textColor: vxAppColors.brand.tertiary.principal,
        hoverColor: vxAppColors.brand.tertiary.light,
      };
  }
}

export type VxButtonGhostProps = {
  /**
   * primary = vxAppColors.brand.tertiary.principal;
   *
   * danger = vxAppColors.feedback.error.default;
   *
   * success = vxAppColors.feedback.success.default;
   */
  type?: 'primary' | 'danger' | 'success';
  size?: 'small' | 'regular';
  onClick: () => void;
  prefixIcon?: VXIconsEnum;
  prefixIconInvertStrokeWithFill?: boolean;
  /**
   * xsm = 8px;
   *
   * sm = 16px;
   *
   * md = 24px;
   *
   * lg = 32px;
   *
   * xlg = 40px;
   *
   * xxlg = 48px;
   */
  prefixIconSize?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
  sufixIcon?: VXIconsEnum;
  sufixIconInvertStrokeWithFill?: boolean;
  /**
   * xsm = 8px;
   *
   * sm = 16px;
   *
   * md = 24px;
   *
   * lg = 32px;
   *
   * xlg = 40px;
   *
   * xxlg = 48px;
   */
  sufixIconSize?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg';
  text: string;
  width?: number;
};

export const VxButtonGhost: React.FunctionComponent<VxButtonGhostProps> = ({
  type = 'primary',
  onClick,
  size = 'regular',
  prefixIcon,
  prefixIconInvertStrokeWithFill = false,
  prefixIconSize,
  sufixIcon,
  sufixIconInvertStrokeWithFill = false,
  sufixIconSize,
  text,
  width,
}) => {
  const { hoverColor, textColor } = buttonColors(type);
  const isSmall = useMediaQuery('(max-width: 1024px)');
  return (
    <S.Button
      prefixIconInvertStrokeWithFill={prefixIconInvertStrokeWithFill}
      sufixIconInvertStrokeWithFill={sufixIconInvertStrokeWithFill}
      hoverColor={hoverColor}
      size={size}
      onClick={onClick}
      width={width}
    >
      <S.Content
        prefixIconInvertStrokeWithFill={prefixIconInvertStrokeWithFill}
        sufixIconInvertStrokeWithFill={sufixIconInvertStrokeWithFill}
        hoverColor={hoverColor}
        size={size}
        width={width}
      >
        <div className="prefix-icon-container">
          {prefixIcon && prefixIconSize && (
            <VXIcon
              icon={prefixIcon}
              size={prefixIconSize}
              color={textColor}
              invertStrokeWithFill={prefixIconInvertStrokeWithFill}
            />
          )}
        </div>
        {isSmall ? (
          <VxButtonTypeMd title={text} color={textColor} fontWeight="medium" />
        ) : (
          <VxButtonTypeLg title={text} color={textColor} fontWeight="medium" />
        )}
        <div className="sufix-icon-container">
          {sufixIcon && sufixIconSize && (
            <VXIcon
              icon={sufixIcon}
              size={sufixIconSize}
              color={textColor}
              invertStrokeWithFill={sufixIconInvertStrokeWithFill}
            />
          )}
        </div>
      </S.Content>
    </S.Button>
  );
};

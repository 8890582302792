import { HttpResponse } from './http-response';

export enum HttpMethodEnum {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
  patch = 'patch',
  head = 'head',
  options = 'options',
  trace = 'trace',
  connect = 'connect',
}

export type HttpDatasourceParams = {
  headers?: Record<string, string>;
  data?: unknown;
  method?: HttpMethodEnum;
};

export interface HttpDatasource {
  request(url: string, params?: HttpDatasourceParams): Promise<HttpResponse>;
}

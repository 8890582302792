import React from 'react';
import { HomeHeader } from '../header';
import { SideBar } from '../side-bar';
import { Container, Content } from './style';

interface AppWrapperProps {
  children?: React.ReactNode;
}
const AppWrapper: React.FunctionComponent<AppWrapperProps> = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <Content>
        <HomeHeader />
        {children}
      </Content>
    </Container>
  );
};

export { AppWrapper };

import { AssetImage } from 'presentation/components/asset-image';
import { OrderStatus } from 'presentation/pages/wallet/components/recent-orders/components/order-status';

import { OrderRowStyle } from '../style';
import { OrderDataEntity } from 'domain/entities/order-entity';
import { useCompanyName } from './../../../../../../../stores/hooks/use-company-name';
import { formatDateComplete } from 'data/utils/date-time-util';
import { useState } from 'react';
import { ExpandedContent } from './expanded-content';
import { VxBodyMd, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { goToAssetPage } from 'presentation/utils/navigate-utils';

const OrdersRow: React.FunctionComponent<{
  isFisrt: boolean;
  order: OrderDataEntity;
}> = ({ isFisrt, order }) => {
  const { companyName } = useCompanyName({ symbol: order.symbol });
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <OrderRowStyle onClick={() => setIsExpanded(!isExpanded)}>
        <div className="order-date">
          <VxBodyMd
            title={isFisrt ? formatDateComplete(new Date(order.updatedAt)) : ''}
            fontWeight="medium"
            color={vxAppColors.neutral.dark}
          />
        </div>
        <div className="order-info" onClick={() => goToAssetPage(order.symbol, navigate, dispatch)}>
          <AssetImage symbol={order.symbol} size="xs2" />
          <div>
            <VxBodySm title={order.symbol} fontWeight="bold" color={vxAppColors.brand.primary.darkest} />
            <VxBodySm title={companyName} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
          </div>
        </div>
        <div className="order-side">
          <VxBodySm title={order.side.name} fontWeight="regular" color={vxAppColors.neutral.dark} />
        </div>
        <div className="order-status">
          <OrderStatus id={order.status.id} name={order.status.name} />
        </div>

        <VXIcon
          size="sm"
          icon={isExpanded ? VXIconsEnum.arrowUp : VXIconsEnum.arrowDown}
          color={vxAppColors.neutral.darkest}
        />
      </OrderRowStyle>
      <ExpandedContent isExpanded={isExpanded} order={order} />
    </>
  );
};

export { OrdersRow };

import React from 'react';
import 'swiper/css';
import { useSwiper } from 'swiper/react';
import { Pagination, PaginationArea } from './style';

type CarouselPaginationProps = {
  activeIndex: number;
  paginationLength?: number;
  backgroundColor?: string;
  paginationInside: boolean;
};

const CarouselPagination: React.FunctionComponent<CarouselPaginationProps> = (props: CarouselPaginationProps) => {
  const swiper = useSwiper();

  const paginateList = Array.from(Array(props.paginationLength ?? 1), (_, index) => {
    return (
      <Pagination
        paginationInside={props.paginationInside}
        key={index}
        onClick={() => {
          swiper.slideTo(index);
        }}
        isActive={props.activeIndex === index}
        color={props.backgroundColor}
      />
    );
  });
  return <PaginationArea>{paginateList}</PaginationArea>;
};

export default CarouselPagination;

import dayjs from 'dayjs';
import { orderSideIdDict, OrderSideIdKey, orderStatusIdDict, OrderStatusIdKey } from 'domain/dicts';
import { OrderDataEntity } from 'domain/entities/order-entity';
import { makeLoadRecentOrdersUsecase } from 'main/factories/domain/load-recent-orders-factory';
import { vexterToastError } from 'presentation/components/micro-components/toast';
import { VxDropdownItemsType } from 'presentation/components/micro-components/vx-dropdown-filter';
import { FilterByDaysIntervalType } from 'presentation/types';
import { useCallback, useEffect, useState } from 'react';

export default function useOrders() {
  const defaultDropdownStatusOptions = () =>
    Object.keys(orderStatusIdDict).map((label) => ({
      label,
      checked: true,
    }));
  const defaultDropdownSideOptions = () =>
    Object.keys(orderSideIdDict).map((label) => ({
      label,
      checked: true,
    }));
  const [orders, setOrders] = useState<OrderDataEntity[]>([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [ordersError, setOrdersError] = useState(false);
  const [sides, setSides] = useState<VxDropdownItemsType[]>([...defaultDropdownSideOptions()]);
  const [statuses, setStatuses] = useState<VxDropdownItemsType[]>([...defaultDropdownStatusOptions()]);
  const [daysInterval, setDaysInterval] = useState<FilterByDaysIntervalType | undefined>('7 dias');
  const today = dayjs(Date.now());
  const [endAt, setEndAt] = useState<Date>(today.toDate());
  const [startAt, setStartAt] = useState<Date>(today.add(-7, 'day').toDate());
  const loadOrders = useCallback(() => {
    const status = statuses.filter((item) => item.checked).map((item) => item.label) as OrderStatusIdKey[];
    const side = sides.filter((item) => item.checked).map((item) => item.label) as OrderSideIdKey[];
    setIsLoadingOrders(true);
    setOrdersError(false);
    makeLoadRecentOrdersUsecase()
      .execute({
        endAt,
        startAt,
        sides: side,
        statuses: status,
      })
      .then((ordersOrError) => {
        ordersOrError.fold(
          () => {
            vexterToastError({ title: 'Opa! Algo parece errado.', message: 'Por favor, tente novamente mais tarde!' });
            setOrdersError(true);
          },
          (orders) => setOrders(orders)
        );
      })
      .finally(() => setIsLoadingOrders(false));
  }, [sides, statuses, daysInterval, startAt, endAt]);

  const clean = useCallback(() => {
    setDaysInterval('7 dias');
    setEndAt(today.toDate());
    setStartAt(today.add(-7, 'day').toDate());
    setSides(defaultDropdownSideOptions());
    setStatuses(defaultDropdownStatusOptions());
    loadOrders();
  }, []);

  useEffect(() => {
    if (today.diff(endAt, 'day') === 0) {
      let newDaysInterval: FilterByDaysIntervalType | undefined = undefined;
      const daysToEnd = today.diff(startAt, 'day');
      switch (daysToEnd) {
        case 7:
          newDaysInterval = '7 dias';
          break;
        case 15:
          newDaysInterval = '15 dias';
          break;
        case 30:
          newDaysInterval = '30 dias';
          break;
        case 60:
          newDaysInterval = '60 dias';
          break;
        case 90:
          newDaysInterval = '90 dias';
          break;
      }
      setDaysInterval(newDaysInterval);
    }
  }, []);

  useEffect(() => {
    if (daysInterval !== undefined) {
      let newStartAt = today.clone();
      switch (daysInterval) {
        case '7 dias':
          newStartAt = today.add(-7, 'day');
          break;
        case '15 dias':
          newStartAt = today.add(-15, 'day');
          break;
        case '30 dias':
          newStartAt = today.add(-30, 'day');
          break;
        case '60 dias':
          newStartAt = today.add(-60, 'day');
          break;
        case '90 dias':
          newStartAt = today.add(-90, 'day');
          break;
      }
      if (today.diff(endAt, 'day') !== 0) {
        setEndAt(today.toDate());
      }
      if (newStartAt.diff(startAt, 'day') !== 0) {
        setStartAt(newStartAt.toDate());
      }
    }
  }, [daysInterval]);
  useEffect(() => {
    loadOrders();
  }, []);

  return {
    orders,
    isLoadingOrders,
    ordersError,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    sides,
    setSides,
    setStatuses,
    statuses,
    daysInterval,
    setDaysInterval,
    loadOrders,
    clean,
  };
}

import { RoutesEnum } from 'config/routes/router-enum';
import json2mq from 'json2mq';
import { VxButtonGhost } from 'presentation/components/micro-components/vx-button-ghost';
import { VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { SubHeader } from 'presentation/components/sub-header';
import { VxGridContainer, VxGridItem } from 'presentation/components/vx-grid';
import { VxMargin } from 'presentation/components/vx-margin';
import { RootState } from 'presentation/stores';
import usePosition from 'presentation/stores/hooks/use-position';
import { useVexterPatrimony } from 'presentation/stores/hooks/use-vexter-patrimony';
import { setEnvironment } from 'presentation/stores/slices/settings.store';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { InvestedAmount } from '../wallet/components/Invested-amount';
import { HomeAssets } from './components/home-assets';
import { HomeBanners } from './components/home-banners';
import { HomeEmptyPositions } from './components/home-empty-positions';
import * as S from './style';
import { DESKTOP_LG } from 'presentation/components/vx-grid/helpers';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody.stories';

export default function HomePage() {
  const navigate = useNavigate();
  const settingsSlice = useSelector((state: RootState) => state.settingsSlice);
  const dispatch = useDispatch();
  const { positions, isLoading, hasPositions, rentabilitySum } = usePosition();
  const { vexterPatrimony } = useVexterPatrimony();
  const isSmallDesktop = useMediaQuery(json2mq({ minWidth: 1024, maxWidth: DESKTOP_LG }));
  const isMobile = useMediaQuery(json2mq({ minWidth: 300, maxWidth: 1023 }));
  const isMobileTitle = useMediaQuery(`(min-width: ${DESKTOP_LG}px)`);
  return (
    <>
      <SubHeader
        itens={['Spot', 'Margem']}
        onClick={(value) => {
          dispatch(setEnvironment(value === 'Spot' ? 'spot' : 'margin'));
        }}
        defaultActiveItem={settingsSlice.environment === 'spot' ? 0 : 1}
      />

      <VxGridContainer>
        <VxGridItem columns={8} md={8} sm={5}>
          <S.Nav>
            {isMobileTitle ? (
              <H5 title="Valor investido" color={vxAppColors.neutral.darkest} fontWeight="medium" />
            ) : (
              <VxBodyMd title="Valor investido" color={vxAppColors.neutral.darkest} fontWeight="medium" />
            )}

            <VxButtonGhost
              text="CARTEIRA"
              size={isSmallDesktop || isMobile ? 'small' : 'regular'}
              sufixIcon={VXIconsEnum.arrowRight}
              sufixIconSize="xsm"
              onClick={() => navigate(RoutesEnum.wallet)}
            />
          </S.Nav>
          <article>
            <div>
              {!hasPositions ? (
                <HomeEmptyPositions patrimony={vexterPatrimony?.saldoCliente ?? 0} />
              ) : (
                <InvestedAmount
                  positions={positions}
                  isLoading={isLoading}
                  hasPositions={hasPositions}
                  rentabilitySum={rentabilitySum}
                  emptyPositionComponent={<></>}
                  smallDesktop={isSmallDesktop}
                  isMobile={isMobile}
                />
              )}
              <HomeAssets />
            </div>
          </article>
        </VxGridItem>

        <VxGridItem columns={4} md={4} sm={2}>
          <aside>
            <HomeBanners />
            <VxMargin showHomeStyle={true} />
          </aside>
        </VxGridItem>
      </VxGridContainer>
    </>
  );
}

import { formatDate } from 'data/utils/date-time-util';
import { OrderDataEntity } from 'domain/entities/order-entity';
import { groupBy } from 'lodash';
import { VxBodyLg } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { OrdersRow } from './components/order-row';
import { Divider, OrdersMainContent } from './style';

const OrdersContent: React.FunctionComponent<{ orderList: OrderDataEntity[] }> = ({ orderList }) => {
  const tableTitle: string[] = ['Data', 'Ativo', 'Tipo de Ordem', 'Status'];

  orderList.forEach((order) => {
    order.formatedDate = formatDate(new Date(order.updatedAt), { showYear: false });
  });

  const formatedOrders = groupBy(orderList, 'formatedDate');

  return (
    <OrdersMainContent>
      {
        <>
          <div className="table-header">
            {tableTitle.map((text) => (
              <div className="table-titles" key={text}>
                <VxBodyLg title={text} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
              </div>
            ))}
          </div>
          <div className="table-list">
            {Object.keys(formatedOrders).map((orderList, i) => (
              <section key={`sc-${i}`}>
                <div key={i}>
                  {formatedOrders[orderList].map((order, index) => (
                    <OrdersRow key={index} isFisrt={index === 0} order={order} />
                  ))}
                </div>
                {i !== Object.keys(formatedOrders).length - 1 && <Divider key={`${i}-divider`} />}
              </section>
            ))}
          </div>
        </>
      }
    </OrdersMainContent>
  );
};

export { OrdersContent };

import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { SaveWithdrawRepository } from 'domain/repositories/save-withdraw-repository';

export interface SaveWithdrawUsecase {
  execute(amount: number): Promise<Either<Failure, void>>;
}

export class SaveWithdrawUsecaseImp implements SaveWithdrawUsecase {
  constructor(readonly repository: SaveWithdrawRepository) {
    Object.freeze(this);
  }
  execute(amount: number): Promise<Either<Failure, void>> {
    return this.repository.saveWithdraw(amount);
  }
}

import {
  LoadCompanyNameBySymbolUsecase,
  LoadCompanyNameBySymbolUsecaseImp,
} from 'domain/usecases/load-company-name-by-symbol-usecase';
import { makeCryptoLoadCompanyNameBySymbolFromHttpWithCacheFallbackRepository } from '../repositories/composite/asset-info-composite-repository-factory';

let cryptoLoadCompanyNameBySymbolUsecase: LoadCompanyNameBySymbolUsecase | null = null;

export const makeLoadCompanyNameBySymbolUsecase = (): LoadCompanyNameBySymbolUsecase => {
  if (cryptoLoadCompanyNameBySymbolUsecase === null) {
    cryptoLoadCompanyNameBySymbolUsecase = new LoadCompanyNameBySymbolUsecaseImp(
      makeCryptoLoadCompanyNameBySymbolFromHttpWithCacheFallbackRepository()
    );
  }
  return cryptoLoadCompanyNameBySymbolUsecase;
};

import { Button } from './components/button';
import * as Styles from './styles';

type VxChipsProps = {
  items: Array<string>;
  activeIndex: number;
  onClick: (item: string) => void;
};

export function VxChips(props: VxChipsProps) {
  const isActive = (index: number) => index === props.activeIndex;

  return (
    <Styles.ListContainer>
      {props.items.map((item, index) => (
        <li
          className={isActive(index) ? 'active' : ''}
          onClick={() => {
            props.onClick(item);
          }}
          key={item}
        >
          <Button isActive={isActive(index)} item={item} />
        </li>
      ))}
    </Styles.ListContainer>
  );
}

import { useEffect, useState } from 'react';
import { makeLoadExtractsUsecase } from 'main/factories/domain/load-extract-usecase-factory';
import { LoadExtractUsecaseParams } from 'domain/usecases/extract/load-extracts-usecase';
import { ExtractProps } from 'presentation/pages/profile/subpages/extract/components/extract-table';
import { OnFilterExtractParams } from 'presentation/pages/profile/subpages/extract/components/filters';
import { vexterToastError } from 'presentation/components/micro-components/toast';

export default function useExtracts(filters: OnFilterExtractParams) {
  const [extractsList, setExtractsList] = useState<ExtractProps[]>([]);
  const [loadExtracts, setLoadExtracts] = useState(true);
  const [extractsError, setExtractError] = useState(false);
  function formatDateApi(date: string) {
    const p = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
      .formatToParts(new Date(date))
      .reduce((acc: any, part) => {
        acc[part.type] = part.value;
        return acc;
      }, {});
    return `${p.month}-${p.day}-${p.year}`;
  }
  const params: LoadExtractUsecaseParams = {
    startDate: formatDateApi(filters.startDate),
    endDate: formatDateApi(filters.endDate),
    releaseType: filters.release,
  };
  useEffect(() => {
    setLoadExtracts(true);
    makeLoadExtractsUsecase()
      .execute(params)
      .then((extractOrError) => {
        if (extractOrError.isRight()) {
          setExtractError(false);
          setExtractsList(extractOrError.value);
        } else {
          vexterToastError({ title: 'Opa! Algo parece errado.', message: 'Por favor, tente novamente mais tarde!' });
          setExtractError(true);
        }

        setLoadExtracts(false);
      });
  }, [filters.endDate, filters.startDate, filters.release]);

  return {
    extractsList,
    loadExtracts,
    extractsError,
  };
}

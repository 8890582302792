import { useMediaQuery } from '@mui/material';
import { VxPositionChart } from 'presentation/components/micro-components/vx-position-chart';
import { useChartPositionHeight } from 'presentation/stores/hooks/use-chart-position-height';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import ChartSubtitle from './components/chart_subtitle';
import { ChartWithLegend, marginSubtitle, WalletChartSubtitle } from './style';

const cryptoColor: string[] = [
  vxAppColors.product.rv,
  vxAppColors.product.fundos,
  vxAppColors.product.tesouro,
  vxAppColors.product.rf,
  vxAppColors.product.clubes,
  vxAppColors.product.estruturados,
];

interface WalletChartProps {
  symbol: string;
  totalNet: number;
}
interface WalletChartResponse {
  listPosistion: WalletChartProps[];
}

type ChartSubtitleProps = {
  listPosistion: WalletChartProps[];
  isMobile: boolean;
};

const getPositionInfo = (position: WalletChartProps) => {
  return position;
};

const ChartSubtitles = (props: ChartSubtitleProps) => {
  const listPositionsModeled: WalletChartProps[] = [];

  props.listPosistion.forEach((position) => {
    listPositionsModeled.push(getPositionInfo(position));
  });

  return (
    <WalletChartSubtitle>
      {listPositionsModeled.map((position, index) => (
        <div style={marginSubtitle} key={position.symbol}>
          <ChartSubtitle
            symbol={`${position.symbol}`}
            percentage={position.totalNet}
            color={cryptoColor[index]}
            isMobile={props.isMobile}
          />
        </div>
      ))}
    </WalletChartSubtitle>
  );
};

const WalletChart = (props: WalletChartResponse) => {
  const isMobile = useMediaQuery('(max-width: 1440px)');
  const height = useChartPositionHeight();
  return (
    <ChartWithLegend>
      <VxPositionChart chartData={props.listPosistion} height={height} />
      <ChartSubtitles listPosistion={props.listPosistion} isMobile={isMobile} />
    </ChartWithLegend>
  );
};

export default WalletChart;

import { Position } from 'domain/entities/positions';

type FormatDoubleParams = {
  thousandSeparator?: string;
  decimalSeparator?: string;
  alwaysShowSignal?: boolean;
  showAsInteger?: boolean;
  minimumDecimalPlaces?: number;
  maximumFractionDigits?: number;
};

export const formatDouble = (
  value: number | null = null,
  {
    thousandSeparator = '.',
    decimalSeparator = ',',
    alwaysShowSignal = false,
    showAsInteger = false,
    minimumDecimalPlaces = 0,
    maximumFractionDigits = 0,
  }: FormatDoubleParams = {}
): string => {
  if (value === null) {
    return '--';
  }
  let result: string = value.toLocaleString('pt-BR', {
    useGrouping: thousandSeparator !== '',
    signDisplay: alwaysShowSignal ? 'always' : 'auto',
    maximumFractionDigits: maximumFractionDigits !== 0 ? maximumFractionDigits : showAsInteger ? 0 : 20,
    minimumFractionDigits: minimumDecimalPlaces,
  });
  if (decimalSeparator !== '') {
    result = result.replace(',', decimalSeparator);
  }
  if (thousandSeparator !== '') {
    result = result.replaceAll('.', thousandSeparator);
  }
  return result;
};

type FormatCurrencyParams = {
  thousandSeparator?: string;
  decimalSeparator?: string;
  alwaysShowSignal?: boolean;
  showAsInteger?: boolean;
  symbol?: string;
};

export const formatCurrency = (
  value: number | null = null,
  {
    thousandSeparator = '.',
    decimalSeparator = ',',
    alwaysShowSignal = false,
    showAsInteger = false,
    symbol = 'R$',
  }: FormatCurrencyParams = {}
): string => {
  if (value === null) {
    return `${symbol} --`;
  }
  let result = formatDouble(value, {
    thousandSeparator,
    decimalSeparator,
    showAsInteger,
    minimumDecimalPlaces: showAsInteger ? 0 : 2,
  });
  if (value < 0) {
    result = result.replaceAll('-', '').trim();
  }
  result = `${symbol} ${result}`;
  if (value < 0) {
    result = '- ' + result;
  } else if (alwaysShowSignal) {
    result = '+ ' + result;
  }
  return result;
};

type FormatPercentageParams = {
  minimumFractionDigits?: number;
  showPercentageSymbol?: boolean;
  showGainSymbol?: boolean;
};

export const formatPercentage = (
  value: number,
  { minimumFractionDigits = 2, showPercentageSymbol = true, showGainSymbol = false }: FormatPercentageParams
): string => {
  if (!value) {
    return showPercentageSymbol ? '-- %' : '--';
  }
  const option = {
    style: 'percent',
    minimumFractionDigits: minimumFractionDigits,
  };
  const formatedPercent = new Intl.NumberFormat('pt-BR', option)
    .format(value / 100)
    .replace('%', '')
    .replace('-', '- ');
  const percentWithSymbol = `${formatedPercent} %`;
  const gainSymbol = value >= 0 ? '+ ' : '';
  if (showPercentageSymbol) {
    return showGainSymbol ? `${gainSymbol}${percentWithSymbol}` : `${percentWithSymbol}`;
  }
  return showGainSymbol ? `${gainSymbol}${formatedPercent}` : `${formatedPercent}`;
};

export const unmaskMoney = (value: string, isCripto = false): number => {
  const normalizeFactionDigits = 100;

  let v = value;
  if (!v) {
    return value as any;
  }
  v = v.split('.').join('');
  v = v.split(',').join('.');
  if (isCripto) return parseFloat(v.replace(/\D/g, ''));
  return parseFloat(v.replace(/\D/g, '')) / normalizeFactionDigits;
};

export const formatNumbers = (number?: number, options: Intl.NumberFormatOptions = {}, isDolar?: boolean) => {
  if (!number && number !== 0) return 'R$ --';
  return new Intl.NumberFormat(isDolar ? 'en-US' : 'pt-BR', {
    ...options,
  }).format(number ?? 0);
};

export function getRentability(totalNet: number, avgPositionValue: number, positionValue: number) {
  const isSell = isNegative(totalNet);

  return isSell
    ? Math.abs(avgPositionValue) - Math.abs(positionValue)
    : Math.abs(positionValue) - Math.abs(avgPositionValue);
}

export function getPositionPercentage(totalNet: number, avgPositionValue: number, positionValue: number) {
  if (totalNet !== null && avgPositionValue !== null && positionValue !== null) {
    const rentability = getRentability(totalNet, avgPositionValue, positionValue);

    const isSell = isNegative(totalNet);

    const percentage = isSell ? -1 * (rentability / avgPositionValue) * 100 : (rentability / avgPositionValue) * 100;

    return percentage;
  }

  return 0;
}

export const isNegative = (value: number) => Math.sign(value) < 0;

export const isNan = (value: any) => Number.isNaN(parseFloat(String(value)));

export function getRentabilitySum(positions: Position[]) {
  let rentabilitySum = 0;

  positions.forEach((position) => {
    rentabilitySum += getRentability(position.totalNet, position.avgPositionValue, position.positionValue);
  });

  return rentabilitySum;
}

export function getAvgPositionValueSum(positions: Position[]) {
  let avgPositionValueSum = 0;
  positions.forEach((position) => {
    avgPositionValueSum += Math.abs(position.avgPositionValue);
  });
  return avgPositionValueSum;
}

export function calculatePercentageValue(positions: Position[]) {
  return (getRentabilitySum(positions) / getAvgPositionValueSum(positions)) * 100;
}

import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import settingsSlice from './slices/settings.store';
const reducer = combineReducers({
  settingsSlice,
});

const rootStore = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export { rootStore };

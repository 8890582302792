import { VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useMediaQuery } from 'usehooks-ts';
import { ChartContainer } from './style';

const COLORS = [
  vxAppColors.product.rv,
  vxAppColors.product.fundos,
  vxAppColors.product.tesouro,
  vxAppColors.product.rf,
  vxAppColors.product.clubes,
  vxAppColors.product.estruturados,
];

export type VxPositionChartProps = {
  chartData: Array<{ totalNet: number }>;
  height: number;
};

export const VxPositionChart: React.FC<VxPositionChartProps> = ({ chartData, height }) => {
  const isSmall = useMediaQuery('(max-width: 1440px)');
  const chartHeight = height - 5;
  return (
    <ChartContainer height={height}>
      <ResponsiveContainer height={chartHeight} className="layer1">
        <PieChart>
          <Pie
            data={chartData}
            innerRadius={chartHeight / 2 - 12}
            outerRadius={chartHeight / 2}
            paddingAngle={0}
            dataKey="totalNet"
            stroke="0px"
            cx="50%"
            cy="50%"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="layer3">
        <div className="layer3-inner"></div>
      </div>
      <div className="layer2">
        {isSmall ? (
          <VxBodyXs
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
            title={`${chartData.length} ativo${chartData.length > 1 ? 's' : ''}`}
          />
        ) : (
          <H5
            fontWeight="medium"
            color={vxAppColors.brand.primary.darkest}
            title={`${chartData.length} ativo${chartData.length > 1 ? 's' : ''}`}
          />
        )}
      </div>
    </ChartContainer>
  );
};

import { RoutesEnum } from 'config/routes/router-enum';
import { setCurrentRoute } from 'presentation/stores/slices/settings.store';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction } from 'redux';

export const goToAssetPage = (symbol: string, navigate: NavigateFunction, dispatch: Dispatch<AnyAction>) => {
  dispatch(setCurrentRoute(RoutesEnum.assets));
  navigate(`/ativo/${symbol}`);
};

import { VxGridContainer, VxGridItem } from '../vx-grid';
import { CurrencyInfo } from './components/currency-info';
import { PatrimonyInfo } from './components/patrimony-info';
import { ResponsiveButtons } from './components/responsive-buttons';
import * as S from './style';

export const HomeHeader = () => {
  return (
    <S.HeaderContainer>
      <VxGridContainer>
        <VxGridItem>
          <S.ContentContainer>
            <PatrimonyInfo />
            <S.ButtonsAndCurrencyContainer>
              <S.ButtonsContainer>
                <ResponsiveButtons />
              </S.ButtonsContainer>
              <CurrencyInfo />
            </S.ButtonsAndCurrencyContainer>
          </S.ContentContainer>
        </VxGridItem>
      </VxGridContainer>
    </S.HeaderContainer>
  );
};

import { LoadAssetInfoUsecase, LoadAssetInfoUsecaseImp } from 'domain/usecases/load-asset-info-usecase';
import { makeLoadAssetInfoHttpRepository } from '../repositories/http/asset-info-http-repository-factory';

let loadAssetInfoUsecase: LoadAssetInfoUsecase | null = null;

export const makeLoadAssetInfoUsecase = (): LoadAssetInfoUsecase => {
  if (loadAssetInfoUsecase === null) {
    loadAssetInfoUsecase = new LoadAssetInfoUsecaseImp(makeLoadAssetInfoHttpRepository());
  }
  return loadAssetInfoUsecase;
};

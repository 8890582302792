import { VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { ChartSubtitleBody, Container, Pointer, PointerArea } from './style';

type ChartSubtitleModel = {
  symbol: string;
  percentage: number;
  color: string;
  isMobile: boolean;
};

const ChartSubtitle = (props: ChartSubtitleModel) => {
  return (
    <ChartSubtitleBody>
      <Container>
        <PointerArea>
          <Pointer backgroundColor={props.color} isMobile={props.isMobile} />
        </PointerArea>
        {props.isMobile ? (
          <>
            <VxBodyXs title={`${props.percentage}%`} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
            <VxBodyXs title={`${props.symbol}`} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </>
        ) : (
          <>
            <VxBodySm title={`${props.percentage}%`} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
            <VxBodySm title={`${props.symbol}`} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </>
        )}
      </Container>
    </ChartSubtitleBody>
  );
};

export default ChartSubtitle;

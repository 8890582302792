import { Either, OrdersReport } from 'domain/entities';
import { Failure } from 'domain/failure/failure';
import { SaveOrdersReportRepository } from 'domain/repositories/save-orders-report-repository';

export interface SaveOrdersReportUsecase {
  execute: (params: OrdersReport) => Promise<Either<Failure, void>>;
}

export class SaveOrdersReportUsecaseImp implements SaveOrdersReportUsecase {
  constructor(readonly repository: SaveOrdersReportRepository) {
    Object.freeze(this);
  }
  execute(params: OrdersReport): Promise<Either<Failure, void>> {
    return this.repository.saveOrdersReport(params);
  }
}

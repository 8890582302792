import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VXIconsEnum } from 'presentation/components/micro-components/vx-icon';

import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody.stories';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { HomeEmptyPositionsStyle } from './style';
import homeEmptyWallet from '../../../../../assets/images/home-empty-wallet.png';
import { useBillet } from 'presentation/stores/hooks/use-billet';
import { Link } from 'react-router-dom';
import { RoutesEnum } from 'config/routes/router-enum';

export const HomeEmptyPositions: React.FunctionComponent<{ patrimony: number }> = ({ patrimony }) => {
  const { showModal } = useBillet();
  return (
    <HomeEmptyPositionsStyle>
      {patrimony !== 0 && <img src={homeEmptyWallet} />}
      <div>
        <H4
          title={patrimony !== 0 ? 'Invista agora' : 'Deposite para começar a investir'}
          color={vxAppColors.brand.primary.darkest}
          fontWeight="regular"
        />
        <VxBodySm
          title={
            patrimony !== 0
              ? 'Não deixa seu dinheiro parado'
              : 'Transfira dinheiro da sua conta corrente Genial para operar criptos pela Vexter'
          }
          color={vxAppColors.brand.primary.darkest}
          fontWeight="regular"
        />
        {patrimony !== 0 ? (
          <VxButton
            text="COMEÇAR"
            type="primary"
            size="small"
            disabled={false}
            onClick={showModal}
            sufixIcon={VXIconsEnum.arrowRight}
            sufixIconSize="sm"
            sufixIconColor={vxAppColors.neutral.white}
          />
        ) : (
          <Link to={RoutesEnum.depositWithdraw}>
            <VxButton text="DEPOSITAR" type="primary" size="small" disabled={false} onClick={() => null} />
          </Link>
        )}
      </div>
    </HomeEmptyPositionsStyle>
  );
};

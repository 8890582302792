import { useMediaQuery } from '@mui/material';

import { RoutesEnum } from 'config/routes/router-enum';
import { VxBodyMd, VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VxButtonGhost } from '../micro-components/vx-button-ghost';
import { VXIcon, VXIconsEnum } from '../micro-components/vx-icon';
import {
  ContentContainer,
  ContentMarginUsedValueContainer,
  ContentMessageContainer,
  ContentPercentAndProgressAndValuesContainer,
  ContentPercentAndProgressContainer,
  ContentValuesContainer,
  MarginContainer,
  TitleContainer,
} from './style';
import { VxMarginProgress } from './vx-margin-progress';

export type VxMarginProps = {
  shouldRedirect?: boolean;
  showHomeStyle?: boolean;
};

export const VxMargin: React.FC<VxMarginProps> = ({ shouldRedirect = true, showHomeStyle = false }) => {
  const navigate = useNavigate();
  const isSmall = useMediaQuery('(max-width: 1024px)');
  const usedMargin = 0.85;
  let usedMarginGreen = 0;
  let usedMarginYellow = 0;
  let usedMarginRed = 0;
  if (usedMargin > 0.5) {
    usedMarginGreen = 100;
    if (usedMargin > 0.8) {
      usedMarginYellow = 100;
      usedMarginRed = ((usedMargin * 100 - 80) / 20) * 100;
    } else {
      usedMarginYellow = ((usedMargin * 100 - 50) / 30) * 100;
    }
  } else {
    usedMarginGreen = ((usedMargin * 100) / 50) * 100;
  }
  let messageText = '';
  let messageColor = vxAppColors.feedback.success.default;
  if (usedMargin > 0.8) {
    messageColor = vxAppColors.feedback.error.default;
    messageText =
      'Seu nível de margem está quase no limite! Deposite mais ativos em garantia ou suas operações serão encerradas!';
  } else if (usedMargin > 0.5) {
    messageColor = vxAppColors.feedback.atention.default;
    messageText =
      'Seu nível de margem utilizada está alto! Fique atento as suas operações e considere depositar mais garantias';
  } else if (usedMargin >= 0.0) {
    messageText = 'Seu nível de margem está seguro, mantenha seus ativos em garantia para continuar assim :)';
  }
  return (
    <MarginContainer>
      <TitleContainer>
        {isSmall ? (
          <VxBodyMd title="Margem em uso" fontWeight="light" color={vxAppColors.neutral.dark} />
        ) : (
          <H5 title="Margem em uso" fontWeight="medium" color={vxAppColors.neutral.darkest} />
        )}
        {shouldRedirect && (
          <VxButtonGhost
            text="ALOCAÇÃO"
            size={isSmall ? 'small' : 'regular'}
            sufixIcon={VXIconsEnum.arrowRight}
            sufixIconSize="xsm"
            onClick={() => navigate(RoutesEnum.marginAlocation)}
          />
        )}
      </TitleContainer>
      <ContentContainer>
        <ContentPercentAndProgressAndValuesContainer>
          <ContentValuesContainer>
            <ContentPercentAndProgressContainer>
              <VxBodyMd title="0%" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
              {isSmall && showHomeStyle && (
                <VxMarginProgress
                  usedMarginGreen={usedMarginGreen}
                  usedMarginYellow={usedMarginYellow}
                  usedMarginRed={usedMarginRed}
                />
              )}
            </ContentPercentAndProgressContainer>
            <ContentMarginUsedValueContainer>
              <VxBodyMd
                title="R$ 234,20 de R$ 13.203,00"
                fontWeight="medium"
                color={vxAppColors.brand.primary.darkest}
              />
            </ContentMarginUsedValueContainer>
          </ContentValuesContainer>
          {(!isSmall || !showHomeStyle) && (
            <VxMarginProgress
              usedMarginGreen={usedMarginGreen}
              usedMarginYellow={usedMarginYellow}
              usedMarginRed={usedMarginRed}
            />
          )}
        </ContentPercentAndProgressAndValuesContainer>
        <ContentMessageContainer>
          {usedMargin > 0 && (
            <>
              <VXIcon icon={VXIconsEnum.exclamation} color={messageColor} size="md" />
              {isSmall ? (
                <VxBodyXs title={messageText} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              ) : (
                <VxBodySm title={messageText} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              )}
            </>
          )}
        </ContentMessageContainer>
      </ContentContainer>
    </MarginContainer>
  );
};

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

const OrdersMainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-left: 56px;
  margin-right: 24px;
  margin-top: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: ${vxAppColors.neutral.superLight};
  .table-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${vxAppColors.brand.secondary.lightest};
    padding: 0px 16px;
    width: 100%;
    display: flex;
    gap: 24px;
    .table-titles {
      padding: 8px 0px;
      width: 100%;
      text-align: start;

      :nth-child(3) {
        text-align: start;
      }
    }
  }

  .table-list {
    width: 100%;
  }
`;

const OrderRowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  align-self: stretch;
  width: 100%;
  .order-date {
    width: 100%;
  }
  .order-info {
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;

    > div {
      display: flex;
      gap: 4px;
      flex-direction: column;
    }
  }
  .order-side {
    width: 100%;
    margin-left: 48px;
  }
  .order-status {
    width: 100%;
  }
`;
interface ExpandedOrderdSectionType {
  isExpanded: boolean;
}
const ExpandedOrderdSection = styled.div<ExpandedOrderdSectionType>`
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: ${(props) => (props.isExpanded ? 32 : 0)}px;
  padding-top: ${(props) => (props.isExpanded ? 24 : 0)}px;
  ${(props) => (props.isExpanded ? '' : 'height:0px')};
  visibility: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};
  background-color: ${vxAppColors.background};
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  gap: ${(props) => (props.isExpanded ? 24 : 0)}px;
  div.expanded-container {
    display: flex;
    align-items: center;
    button {
      background: ${vxAppColors.feedback.error.default};
    }
  }
  ${(props) =>
    props.isExpanded
      ? `display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        align-content: space-between;`
      : ''};
  transition: ${(props) => (props.isExpanded ? '0.5s, visibility 0.8s, ' : '0.5s, visibility 0s, ')}opacity 0.5s
    ease-out;
  table {
    width: 100%;
  }
  tr {
    th {
      width: 100%;
      white-space: nowrap;
      text-align: left;
      padding-bottom: 8px;
      min-width: 200px;
      min-width: 127px;
      width: auto;
    }

    td {
      width: 1px;
      white-space: nowrap;
      text-align: left;
    }
  }
`;

const EmpatySection = styled.div`
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 64px;
  width: 100%;
  gap: 24px 40px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  .dividerText {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  background-color: ${vxAppColors.neutral.lightest};
`;

const CancelModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  div.close-button-container {
    display: flex;
    justify-content: flex-end;
    div.close-button-icon {
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 24px;
      width: 24px;
      border-radius: 24px;
      border: 0.5px solid ${vxAppColors.brand.primary.principal};
    }
  }
  div.content-container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 8px;
    align-items: center;
    gap: 40px;
    div.title-container {
      display: flex;
      flex-direction: column;
      max-width: 316px;
      text-align: center;
      gap: 8px;
    }
    div.button-container {
      gap: 16px;
      display: flex;
      div.button-cancel-container {
        button {
          background-color: ${vxAppColors.neutral.white};
        }
        span {
          color: ${vxAppColors.neutral.darkest};
        }
      }
    }
  }
`;

export { OrdersMainContent, OrderRowStyle, ExpandedOrderdSection, EmpatySection, Divider, CancelModalContainer };

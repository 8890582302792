export enum ChartIntervalEnum {
  one_minute = '1m',
  five_minutes = '5m',
  fifteen_minutes = '15m',
  thirty_minutes = '30m',
  one_hour = '1h',
  one_day = '1D',
  one_month = '1M',
  six_months = '6M',
  one_year = '12M',
}
export const getChartIntervalNumber = (interval: ChartIntervalEnum): number => {
  switch (interval) {
    case ChartIntervalEnum.one_minute:
      return 1;
    case ChartIntervalEnum.five_minutes:
      return 2;
    case ChartIntervalEnum.fifteen_minutes:
      return 3;
    case ChartIntervalEnum.thirty_minutes:
      return 4;
    case ChartIntervalEnum.one_hour:
      return 5;
    case ChartIntervalEnum.one_day:
      return 3;
    case ChartIntervalEnum.one_month:
      return 6;
    case ChartIntervalEnum.six_months:
      return 7;
    case ChartIntervalEnum.one_year:
      return 8;

    default:
      return 3;
  }
};

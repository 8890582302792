import { WsDatasource } from 'data/datasources/ws/ws-datasource';
import { AssetQuotationEntity } from 'domain/entities/asset-quotation-entity';

export type QuotationWsRepositoryMessageArgumentsParams = {
  isSubscription: boolean;
  symbol: string;
};

export type QuotationCallback = (data: AssetQuotationEntity) => void;

export type QuotationWsRepositoryListenerParams = {
  symbol: string;
  callback: QuotationCallback;
};

export abstract class QuotationWsRepository {
  constructor(readonly wsDatasource: WsDatasource) {}
  readonly subscribers: Record<string, QuotationCallback[]> = {};
  init(): void {
    this.wsDatasource.onMessage({
      message: this.listenMessage,
      callback: this.listen.bind(this),
    });
    this.wsDatasource.addOnConnectionChange(this.onConnectionChange.bind(this));
  }
  abstract subscribeMessage: string;
  abstract unsubscribeMessage: string;
  abstract listenMessage: string;
  abstract listen(data: any): void;
  abstract messageArguments(params: QuotationWsRepositoryMessageArgumentsParams): any;
  isEmpty(): boolean {
    return Object.keys(this.subscribers).length === 0;
  }
  callbacksBySymbol(symbol: string): QuotationCallback[] {
    return this.subscribers[symbol] ?? [];
  }
  async onConnectionChange(connected: boolean): Promise<void> {
    if (connected) {
      for (const symbol of Object.keys(this.subscribers)) {
        await this.wsDatasource.send({
          message: this.subscribeMessage,
          data: this.messageArguments({ isSubscription: true, symbol }),
        });
      }
    }
  }
  async add(params: QuotationWsRepositoryListenerParams): Promise<void> {
    let result: any = null;
    if (!Object.keys(this.subscribers).includes(params.symbol)) {
      result = await this.wsDatasource.send({
        message: this.subscribeMessage,
        data: this.messageArguments({ isSubscription: true, symbol: params.symbol }),
      });
    }
    const callbacks = this.subscribers[params.symbol] ?? [];
    callbacks.push(params.callback);
    this.subscribers[params.symbol] = callbacks;
    if (result) {
      this.callbacksBySymbol(params.symbol).forEach((c) => c(result));
    }
  }
  async remove(params: QuotationWsRepositoryListenerParams): Promise<void> {
    if (Object.keys(this.subscribers).includes(params.symbol)) {
      const callbacks = this.subscribers[params.symbol] ?? [];
      const indexOfCallback = callbacks.indexOf(params.callback);
      if (indexOfCallback > -1) {
        callbacks.slice(indexOfCallback, 1);
      }
      await this.wsDatasource.send({
        message: this.unsubscribeMessage,
        data: this.messageArguments({ isSubscription: false, symbol: params.symbol }),
      });
      if (callbacks.length === 0) {
        delete this.subscribers[params.symbol];
      } else {
        this.subscribers[params.symbol] = callbacks;
      }
    }
  }
}

import { AssetChartPointData } from 'domain/entities/chart-asset-data-entity';
import { LoadAssetChartUsecaseParams } from 'domain/usecases/load-asset-chart-usecase';
import { makeLoadAssetChartUsecase } from 'main/factories/domain/load-asset-chart-usecase-factory';
import { useEffect, useState } from 'react';

export type UseAssetChartParams = LoadAssetChartUsecaseParams;

export function useAssetChart({ symbol, currency, interval }: UseAssetChartParams) {
  const [chartData, setChartData] = useState<AssetChartPointData[]>([]);
  const [isLoadingChartData, setIsLoadingChartData] = useState<boolean>(true);
  const [showChart, setShowChart] = useState<boolean>(true);
  useEffect(() => {
    setIsLoadingChartData(true);
    makeLoadAssetChartUsecase()
      .execute({ symbol, interval, currency })
      .then((chartDataOrError) => {
        chartDataOrError.fold(
          () => {
            setShowChart(false);
          },
          (chartData) => {
            setChartData(chartData);
          }
        );
      })
      .catch(() => {
        setShowChart(false);
      })
      .finally(() => {
        setIsLoadingChartData(false);
      });
  }, [symbol, interval, currency]);
  return { chartData, isLoadingChartData, showChart };
}

const firebaseConfigPROD = {
  apiKey: 'AIzaSyAPn4ths6e1cTiSCij4TVWrqUYgepCtFJs',
  authDomain: 'vexter20homebrokerhml.firebaseapp.com',
  projectId: 'vexter20homebrokerhml',
  storageBucket: 'vexter20homebrokerhml.appspot.com',
  messagingSenderId: '858124587330',
  appId: '1:858124587330:web:c63692ff0ebf7e875138db',
  measurementId: 'G-LH4L30QXVL',
};

const firebaseConfigHML = {
  apiKey: 'AIzaSyAPn4ths6e1cTiSCij4TVWrqUYgepCtFJs',
  authDomain: 'vexter20homebrokerhml.firebaseapp.com',
  projectId: 'vexter20homebrokerhml',
  storageBucket: 'vexter20homebrokerhml.appspot.com',
  messagingSenderId: '858124587330',
  appId: '1:858124587330:web:c63692ff0ebf7e875138db',
  measurementId: 'G-LH4L30QXVL',
};

export const firebaseConfig = process.env.REACT_APP_ENV_NAME === 'production' ? firebaseConfigPROD : firebaseConfigHML;

import { DESKTOP_XS } from 'presentation/components/vx-grid/helpers';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { VxBilletType } from '../..';

export type TitleContainerProps = {
  type: VxBilletType;
};
export const TitleContainer = styled.div`
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${DESKTOP_XS}px) {
    height: 37px;
  }
`;

export const AssetContainer = styled.div<TitleContainerProps>`
  height: ${(props) => (props.type === 'modal' ? 95 : 103)}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: ${DESKTOP_XS}px) {
    height: 64px;
  }
`;

export const AssetIconAndSymbolAndCompanyNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AssetSymbolAndCompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  gap: 4px;
`;

export const AssetQuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ValueContainer = styled.div`
  display: flex;
  height: 67px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (max-width: 1024px) {
    height: 50px;
  }
  div {
    cursor: pointer;
  }
`;

export const CancelAndConfirmContainer = styled.div<TitleContainerProps>`
  height: 72px;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: ${(props) => (props.type === 'modal' ? 72 : 66)}px;
  }
`;

export const ButtonErrorBackground = styled.div`
  button {
    background: ${vxAppColors.feedback.error.default};
  }
`;

export const BilletConfirmationDivider = styled.hr`
  border: none;
  background-color: ${vxAppColors.neutral.lightest};
  height: 1px;
  width: auto;
  margin: 4px 24px;
`;

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
export const ExtractTableSkeletonStyle = styled.table`
  border-collapse: collapse;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  table-layout: fixed;
  thead {
    height: 40px;
    background-color: ${vxAppColors.brand.tertiary.lightest};
    tr {
      th {
        vertical-align: middle;
        text-align: left;
        padding-left: 8px;
        :nth-child(1) {
          padding-left: 16px;
        }
      }
    }
  }
  tbody {
    tr {
      height: 80px;
      border-bottom: 1px solid ${vxAppColors.neutral.lightest};
      :last-child {
        border-bottom: none;
      }
      td {
        vertical-align: middle;
        text-align: left;
        padding-left: 8px;
        :nth-child(1) {
          padding-left: 16px;
        }
      }
    }
  }
`;

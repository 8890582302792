import { Failure } from 'domain/failure/failure';

export class AssetIdEntity {
  readonly value: string;
  constructor(value?: string) {
    if (!value) {
      throw new Failure('ID deve ser informado');
    }
    this.value = value as any;
    Object.freeze(this);
  }
}

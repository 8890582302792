import { formatCurrency, formatDouble } from 'data/utils/currency-util';
import { OrderDataEntity } from 'domain/entities/order-entity';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { Modal } from 'presentation/components/modal';
import { useCancelOrder } from 'presentation/stores/hooks/use-cancel-order';

import { VxBodyMd, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useModal } from 'react-modal-hook';
import { CancelModalContainer, ExpandedOrderdSection } from '../style';

const ExpandedContent: React.FunctionComponent<{
  order: OrderDataEntity;
  isExpanded: boolean;
}> = ({ order, isExpanded }) => {
  const { onCancel, isLoadingCancel } = useCancelOrder(order.vexterOrderId);
  const [showModal, hideModal] = useModal(({ in: open }) => {
    return (
      <Modal open={open} hideModalHookCallback={hideModal} width={460} height={292}>
        <CancelModalContainer>
          <div className="close-button-container">
            <div className="close-button-icon" onClick={hideModal}>
              <VXIcon icon={VXIconsEnum.close} size="xsm" color={vxAppColors.brand.primary.principal} />
            </div>
          </div>
          <div className="content-container">
            <div className="title-container">
              <H4
                title="Tem certeza que deseja cancelar essa ordem?"
                fontWeight="light"
                color={vxAppColors.brand.primary.darkest}
              />
              <VxBodySm
                title="Esta ação não poderá ser desfeita após confirmada"
                fontWeight="regular"
                color={vxAppColors.brand.primary.darkest}
              />
            </div>
            <div className="button-container">
              <div className="button-cancel-container">
                <VxButton
                  text="CANCELAR"
                  type="primary"
                  size="small"
                  disabled={false}
                  isLoading={isLoadingCancel}
                  onClick={hideModal}
                  width={100}
                />
              </div>
              <VxButton
                text="ENCERRAR"
                type="primary"
                size="small"
                disabled={false}
                isLoading={isLoadingCancel}
                onClick={() => {
                  onCancel();
                  hideModal();
                }}
                width={101}
              />
            </div>
          </div>
        </CancelModalContainer>
      </Modal>
    );
  });
  return (
    <ExpandedOrderdSection isExpanded={isExpanded}>
      <div className="expanded-container">
        <table>
          <thead>
            <tr>
              <th scope="col">
                <VxBodyMd title="Hora" fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              </th>
              <th scope="col" className="align-left">
                <VxBodyMd title="Preço de envio" fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              </th>

              <th scope="col" className={'align-left'}>
                <VxBodyMd title="Preço de execução" fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              </th>
              <th scope="col" className={'align-left'}>
                <VxBodyMd title="Quantidade executada" fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              </th>
              <th scope="col" className={'align-left'}>
                <VxBodyMd title="Quantidade em aberto" fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
              </th>
            </tr>
            <tr>
              <td>
                <VxBodySm
                  title={`${new Date(order.updatedAt).getHours()}:${new Date(order.updatedAt).getMinutes()}`}
                  fontWeight="medium"
                  color={vxAppColors.brand.primary.darkest}
                />
              </td>
              <td>
                <VxBodySm
                  title={formatCurrency(order.sentPrice)}
                  fontWeight="medium"
                  color={vxAppColors.brand.primary.darkest}
                />
              </td>
              <td>
                <VxBodySm
                  title={formatCurrency(order.executionPrice)}
                  fontWeight="medium"
                  color={vxAppColors.brand.primary.darkest}
                />
              </td>
              <td>
                <VxBodySm
                  title={formatDouble(order.quantityExecuted, { minimumDecimalPlaces: 2, maximumFractionDigits: 8 })}
                  fontWeight="medium"
                  color={vxAppColors.brand.primary.darkest}
                />
              </td>
              <td>
                <VxBodySm
                  title={formatDouble(order.quantity, { minimumDecimalPlaces: 2, maximumFractionDigits: 8 })}
                  fontWeight="medium"
                  color={vxAppColors.brand.primary.darkest}
                />
              </td>
            </tr>
          </thead>
        </table>
        {order.status.name === 'openOrder' ||
          (order.status.name === 'partiallyFilled' && (
            <VxButton
              text="CANCELAR"
              type="primary"
              size="small"
              disabled={false}
              isLoading={isLoadingCancel}
              onClick={showModal}
            />
          ))}
      </div>
      {order.rejectReason && (
        <div>
          <VxBodyMd title="Motivo da rejeição" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          <div style={{ height: '16px' }}></div>
          <VxBodyMd title={order.rejectReason} fontWeight="light" color={vxAppColors.brand.primary.darkest} />
        </div>
      )}
    </ExpandedOrderdSection>
  );
};

export { ExpandedContent };

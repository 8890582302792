import { LoadAssetInfoFromHttpWithCacheFallbackCompositeRepository } from 'data/repositories/composites/asset-info-from-http-with-cache-fallback-composite-repository';
import { LoadCompanyNameBySymbolRepository } from 'domain/repositories/load-company-name-by-symbol-repository';
import {
  makeCacheLoadCompanyNameBySymbolRepository,
  makeCacheSaveCompanyNameBySymbolRepository,
} from '../cache/asset-info-cache-repository-factory';
import { makeLoadCompanyNameBySymbolHttpRepository } from '../http/asset-info-http-repository-factory';

export const makeCryptoLoadCompanyNameBySymbolFromHttpWithCacheFallbackRepository =
  (): LoadCompanyNameBySymbolRepository => {
    return new LoadAssetInfoFromHttpWithCacheFallbackCompositeRepository({
      httpLoadRepository: makeLoadCompanyNameBySymbolHttpRepository(),
      cacheLoadRepository: makeCacheLoadCompanyNameBySymbolRepository(),
      cacheSaveRepository: makeCacheSaveCompanyNameBySymbolRepository(),
    });
  };

import { VXRoundedSkeleton } from 'presentation/components/micro-components/vx-skeleton/rounded/style';
import { VXTextSkeleton } from 'presentation/components/micro-components/vx-skeleton/text/styles';
import React from 'react';
import { OrderSkeletonStyle } from './styles';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { VxBodyLg } from 'presentation/styleguide/typography/body/VxBody';

export const OrderSkeleton: React.FC = () => {
  const tableTitle: string[] = ['Data', 'Ativo', 'Tipo de Ordem', 'Status'];

  return (
    <OrderSkeletonStyle>
      <div className="table-header">
        {tableTitle.map((text) => (
          <div className="table-titles" key={text}>
            <VxBodyLg title={text} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
          </div>
        ))}
      </div>
      <table>
        <thead>
          <tr>
            {Array.from(Array(4).keys()).map((_, i) => (
              <th key={i}></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(7).keys()).map((_, i) => {
            return (
              <tr key={i}>
                <td>
                  <div className="quotation-info">
                    <VXTextSkeleton height={24} width={116} skeletonColor="light" />
                  </div>
                </td>
                <td>
                  <div className="asset-info">
                    <VXRoundedSkeleton size={40} skeletonColor="light" />
                    <div>
                      <VXTextSkeleton height={24} width={80} skeletonColor="light" />
                      <VXTextSkeleton height={24} width={90} skeletonColor="light" />
                    </div>
                  </div>
                </td>
                <td>
                  <VXTextSkeleton height={24} width={116} skeletonColor="light" />
                </td>
                <td>
                  <VXTextSkeleton height={24} width={200} skeletonColor="light" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </OrderSkeletonStyle>
  );
};

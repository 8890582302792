import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const ExportCardStyle = styled.div`
  background-color: ${vxAppColors.neutral.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;

  .container-export-button {
    width: 100%;
    text-align: end;
  }
  .extract-list-container {
    padding-top: 8px;
    width: 100%;
    .extract-list {
      max-height: 264px;
      overflow: auto;
      @media (max-width: 1024px) {
        min-width: 312px;
      }
      .empty-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 0px;
        gap: 24px;
        text-align: center;
      }
      .extract-container {
        padding-top: 6.5px;
        padding-bottom: 9.5px;
        display: flex;
        height: 56px;
        gap: 4px;
        align-items: center;
        border-bottom: ${vxAppColors.neutral.lightest} 1px solid;
        .extract-item {
          padding-right: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;

          i {
            cursor: pointer;
          }
          .extract-item-date {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .extract-item-dot {
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background-color: ${vxAppColors.brand.primary.principal};
            margin: 2px;
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 64px;

  span:first-child {
    text-align: center;
  }
  span:last-child {
    margin-bottom: 24px;
    display: inline-block;
    margin-top: 8px;
  }
`;

import {
  DESKTOP_FHD,
  DESKTOP_LG,
  DESKTOP_LG_GRID_WIDTH,
  DESKTOP_MD,
  DESKTOP_MD_GRID_WIDTH,
  DESKTOP_SM,
  DESKTOP_SM_USABLE_WIDTH,
  DESKTOP_XS_USABLE_WIDTH,
  getGridVw,
  GRID_GAP,
  MOBILE,
  MOBILE_USABLE_WIDTH,
} from 'presentation/components/vx-grid/helpers';
import styled from 'styled-components';

const BANNER_WIDTH_LG = DESKTOP_LG_GRID_WIDTH * 4 + GRID_GAP * 3;
const BANNER_WIDTH_MD = DESKTOP_MD_GRID_WIDTH * 4 + GRID_GAP * 3;

export const StyledHomeBannerContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
  width: ${getGridVw(504, DESKTOP_FHD)};

  @media (min-width: 1440px) and (max-width: 1919px) {
    width: ${getGridVw(BANNER_WIDTH_LG, DESKTOP_LG)};
  }

  @media (min-width: 1366px) and (max-width: 1439px) {
    width: ${getGridVw(BANNER_WIDTH_MD, DESKTOP_MD)};
  }

  @media (min-width: 1280px) and (max-width: 1365px) {
    width: ${getGridVw(DESKTOP_XS_USABLE_WIDTH - 500, DESKTOP_SM)};
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    width: ${getGridVw(DESKTOP_SM_USABLE_WIDTH - 700, DESKTOP_SM)};
  }

  @media (max-width: 1023px) {
    width: ${getGridVw(MOBILE_USABLE_WIDTH, MOBILE)};
  }
`;

export const StyledHomeBannerSkeleton = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
type StyledHomeBannerItemProps = {
  url?: string;
};
export const StyledHomeBannerItem = styled.div<StyledHomeBannerItemProps>`
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
  ${(props) => (props.url ? `background-image: url(${props.url})` : '')};
  height: 254px;
  @media (max-width: ${DESKTOP_MD}px) {
    height: 192px;
  }
`;

export const StyledHomeBannerItemText = styled.div`
  width: 218px;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  span {
    :first-child {
      margin-bottom: 8px;
    }
  }
`;

import { dictKeyByValue } from 'domain/util';

export const orderStatusIdDict = {
  'Em aberto': 1,
  'Parcialmente Executada': 2,
  Executada: 3,
  Cancelada: 5,
  Rejeitada: 8,
  Expirada: 12,
  'Cancelamento/Edição Rejeitada': 15,
} as const;
export type OrderStatusIdKey = keyof typeof orderStatusIdDict;
export const orderStatusIdValueByKey = (value: number) => dictKeyByValue(orderStatusIdDict, value);

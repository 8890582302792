import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { SaveDepositRepository } from 'domain/repositories/save-deposit-repository';

export interface SaveDepositUsecase {
  execute(amount: number): Promise<Either<Failure, void>>;
}

export class SaveDepositUsecaseImp implements SaveDepositUsecase {
  constructor(readonly repository: SaveDepositRepository) {
    Object.freeze(this);
  }
  execute(amount: number): Promise<Either<Failure, void>> {
    return this.repository.saveDeposit(amount);
  }
}

import { WsDatasource } from 'data/datasources/ws/ws-datasource';
import { removeSlashFromSymbol } from 'data/utils/symbol-util';
import { Either, left, right } from 'domain/entities/either';
import { Position } from 'domain/entities/positions';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { Failure } from 'domain/failure/failure';
import { LoadPositionsRepository } from 'domain/repositories/load-positions-repository';
import { SessionStorageEnum } from 'domain/repositories/session-storage/session-storage-enum';
import { getSessionStorage } from 'domain/repositories/session-storage/session_storage';
import { values } from 'lodash';
export class PositionWsRepository implements LoadPositionsRepository {
  positions: Array<Position> = [];
  subscribers: Array<(positions: Position[]) => void> = [];
  constructor(readonly wsDatasource: WsDatasource) {
    this.init();
  }

  async loadPositions(callback: (positions: Position[]) => void): Promise<Either<Failure, SubscriptionCancelEntity>> {
    try {
      if (this.subscribers.length === 0) {
        const result = await this.wsDatasource.send({
          message: 'Subscribe',
          data: getSessionStorage(SessionStorageEnum.account),
        });
        if (result) {
          this.positions = this.arrayConverter(result);
        }
      }
      callback(this.positions);
      if (this.subscribers.indexOf(callback) === -1) {
        this.subscribers.push(callback);
      }

      return right(
        new SubscriptionCancelEntity(() => {
          const actualSubscriberIndex = this.subscribers.indexOf(callback);
          if (actualSubscriberIndex !== -1) {
            this.subscribers.splice(actualSubscriberIndex, 1);
          }

          if (this.subscribers.length === 0) {
            this.wsDatasource.send({
              message: 'Unsubscribe',
              data: getSessionStorage(SessionStorageEnum.account),
            });
            this.positions = [];
          }
        })
      );
    } catch (error) {
      return left(new Failure('Erro ao inscrever nas posições'));
    }
  }

  async init(): Promise<void> {
    this.wsDatasource.onMessage({
      message: 'PositionData',
      callback: this.listen.bind(this),
    });
  }

  async listen(data: any) {
    const newPositions = this.positions.map((position) => {
      const symbol = removeSlashFromSymbol(data.symbol);
      if (symbol === position.symbol) {
        return {
          ...position,
          symbol,
          totalNet: data.totalNet,
          positionValue: data.positionValue,
          avgPositionValue: data.avgPositionValue,
          companyName: position.companyName,
          totalHeritageGross: position.totalHeritageGross,
        };
      }
      return position;
    });
    this.positions = newPositions;
    this.subscribers.forEach((subscriber) => {
      try {
        subscriber(newPositions);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    });
  }

  arrayConverter(result: Record<string, any>): Position[] {
    const positionsArrayConverter = values(result);
    const positions = positionsArrayConverter
      .filter((position) => position.totalNet !== 0)
      .map<Position>((position) => {
        return {
          symbol: removeSlashFromSymbol(position.symbol),
          totalNet: position.totalNet,
          positionValue: position.positionValue,
          avgPositionValue: position.avgPositionValue,
          companyName: position.companyName,
          totalHeritageGross: position.totalHeritageGross,
        } as any;
      });
    return positions;
  }
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { VxBilletType } from '../..';

export type BilletTitleProps = {
  type: VxBilletType;
};

export const BilletTitle = styled.div<BilletTitleProps>`
  height: ${(props) => (props.type === 'modal' ? 64 : 60)}px;
  padding-top: ${(props) => (props.type === 'modal' ? 0 : 12)}px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    height: ${(props) => (props.type === 'modal' ? 52 : 21)}px;
    padding-top: ${(props) => (props.type === 'modal' ? 4 : 0)}px;
    margin-top: ${(props) => (props.type === 'modal' ? 0 : 12)}px;
  }
  .guarantee-asset {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export type TextAndTooltipContainerProps = {
  shouldCenterTitle: boolean;
};
export const TextAndTooltipContainer = styled.div<TextAndTooltipContainerProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  ${(props) => (props.shouldCenterTitle ? `width: 100%; justify-content: center; margin-right: 24px;` : '')}
`;

export const BilletTitleIconContainer = styled.div<BilletTitleProps>`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border-style: solid;
  border-width: ${(props) => (props.type === 'margin' ? 0 : 1)}px;
  border-color: ${vxAppColors.brand.primary.principal};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

import { OrderDataEntity, OrdersReport } from 'domain/entities';
import { makeSaveOrdersReportUsecase } from 'main/factories/domain/save-orders-report-usecase-factory';
import { ExportCard } from 'presentation/components/export-card';
import { vexterToastError } from 'presentation/components/micro-components/toast';
import React, { useCallback } from 'react';
import * as S from './style';

function downloadCsvFile(orders: OrderDataEntity[]) {
  let csv = 'data,ativo,tipo,preco de envio, preco de execucao, quantidade executada, quantidade em aberto, status\n';
  csv += orders
    .map((order) => {
      return `${order.updatedAt},${order.symbol},${order.type.name},${order.sentPrice},${order.executionPrice},${order.quantityExecuted},${order.quantityOpen},${order.status.name}\n`;
    })
    .join('');
  const element = document.createElement('a');
  const file = new Blob([csv], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = 'Extrato Vexter.csv';
  document.body.appendChild(element);
  element.click();
}

export type OrdersExportProps = {
  orderList: OrderDataEntity[];
  orderHistoryList: OrdersReport[];
  startAt: Date;
  endAt: Date;
  reloadOrdersReport: () => void;
};

const OrdersExport: React.FC<OrdersExportProps> = ({
  orderList,
  startAt,
  endAt,
  orderHistoryList,
  reloadOrdersReport,
}) => {
  const orderListStr = JSON.stringify(orderList);
  const saveExtract = useCallback(() => {
    makeSaveOrdersReportUsecase()
      .execute({
        endAt,
        startAt,
        generatedAt: new Date(Date.now()),
        orders: orderList,
      })
      .then((historyOrError) => {
        historyOrError.fold(
          () => {
            vexterToastError({ title: 'Opa! Algo parece errado.', message: 'Por favor, tente novamente mais tarde!' });
          },
          () => reloadOrdersReport()
        );
      });
  }, [orderListStr, startAt, endAt]);
  return (
    <S.Container>
      <ExportCard
        title="Exportar ordens"
        subTitle="Serão exportadas todas as ordens do período filtrado"
        createRecordButtonText="CRIAR RELATÓRIO"
        onCreateRecord={saveExtract}
        onDownload={downloadCsvFile}
        userExportedList={orderHistoryList.map((order) => ({
          data: order.orders,
          startDate: order.startAt.toISOString(),
          endDate: order.endAt.toISOString(),
          generatedAt: order.generatedAt.toISOString(),
        }))}
        filterResult={orderList.map((order) => ({
          data: [order],
          startDate: startAt.toISOString(),
          endDate: endAt.toISOString(),
        }))}
      />
    </S.Container>
  );
};

export { OrdersExport };

import { useMediaQuery, useTheme } from '@mui/material';
import { vexterToastWarning } from 'presentation/components/micro-components/toast';
import VexterImage, { VexterImagesEnum } from 'presentation/components/micro-components/vexter-image';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { VXIconsEnum } from 'presentation/components/micro-components/vx-icon';

import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { Link } from 'react-router-dom';
import { BeginningToInvestDesktop } from './desktop';
import { BeginningToInvestMobile } from './mobile';
import * as Styles from './styles';
export function BeginningToInvestCrypto() {
  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('xl'));
  const showDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Styles.Container>
      <Styles.ContentContainer>
        <Styles.NavigationContainer>
          <H4 title="Comece a investir :)" color={vxAppColors.brand.primary.lightest} fontWeight="light" />

          <Link to="#">
            {showDesktop && (
              <VxButton
                text="COMEÇAR"
                type="secondary"
                size="regular"
                disabled={false}
                onClick={() => vexterToastWarning({ title: 'Em breve' })}
                sufixIcon={VXIconsEnum.arrowRight}
                sufixIconSize="sm"
                sufixIconColor={vxAppColors.brand.primary.darkest}
              />
            )}

            {showMobile && (
              <VxButton
                text="COMEÇAR"
                type="secondary"
                size="small"
                disabled={false}
                onClick={() => vexterToastWarning({ title: 'Em breve' })}
                sufixIcon={VXIconsEnum.arrowRight}
                sufixIconSize="sm"
                sufixIconColor={vxAppColors.brand.primary.darkest}
              />
            )}
          </Link>
        </Styles.NavigationContainer>

        <Styles.ListContainer>
          {showDesktop && <VexterImage image={VexterImagesEnum.pointingCoins} width={252} height={252} />}
          {showMobile && <VexterImage image={VexterImagesEnum.pointingCoins} width={180} height={180} />}

          <Styles.UlElement>
            {showMobile && <BeginningToInvestMobile />}
            {showDesktop && <BeginningToInvestDesktop />}
          </Styles.UlElement>
        </Styles.ListContainer>
      </Styles.ContentContainer>
    </Styles.Container>
  );
}

import usePosition from 'presentation/stores/hooks/use-position';
import { allSymbols } from 'presentation/utils/all-symbols-utils';
import { VxBilletType } from '../..';
import { BilletContainer } from '../../style';
import { VxBilletItem } from '../vx-billet-item';
import { VxBilletTitle } from '../vx-billet-title';
import { BilletSelectingAssetsList } from './style';

export type VxBilletSelectingProps = {
  onClose?: () => void;
  onSelect: (symbol: string) => void;
  showOnlyWithPosition?: boolean;
  type?: VxBilletType;
};

export const VxBilletSelecting: React.FC<VxBilletSelectingProps> = ({
  onClose,
  onSelect,
  showOnlyWithPosition = false,
  type = 'modal',
}) => {
  const { positions } = usePosition();
  const symbols = showOnlyWithPosition ? allSymbols.filter((s) => positions.some((p) => p.symbol === s)) : allSymbols;
  return (
    <BilletContainer>
      <VxBilletTitle
        type={type}
        shouldCenterTitle={type === 'margin'}
        title={type === 'margin' ? 'Selecione um ativo para alocar' : 'Selecione o ativo'}
        onClose={type === 'margin' ? undefined : onClose}
        onBack={type === 'margin' ? onClose : undefined}
      />
      <BilletSelectingAssetsList type={type}>
        {symbols.map((symbol) => (
          <VxBilletItem key={symbol} symbol={symbol} onClick={() => onSelect(symbol)} />
        ))}
      </BilletSelectingAssetsList>
    </BilletContainer>
  );
};

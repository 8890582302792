import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth, signInAnonymously } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { firebaseConfig } from '../../env/firebase';

export const initializeFirebase = () => {
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const auth = getAuth(firebaseApp);
  signInAnonymously(auth);
  getAnalytics(firebaseApp);
};

export const crashLogEvents = async (error: string) => {
  const analytics = getAnalytics();
  logEvent(analytics, error);
};

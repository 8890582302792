import { FinancialHttpRepository } from 'data/repositories/http/financial-http-repository';
import { Failure } from 'domain/failure/failure';
import { SaveWithdrawUsecase, SaveWithdrawUsecaseImp } from 'domain/usecases/save-withdraw-usecase';
import { makeCryptoHttpDatasource } from '../datasources/http/http-datasource-factory';

let saveWithdrawUsecase: SaveWithdrawUsecase | null = null;

export const makeSaveWithdrawUsecase = (): SaveWithdrawUsecase => {
  if (saveWithdrawUsecase === null) {
    const baseUrl = process.env.REACT_APP_API_CRYPTO_GATEWAY ?? '';
    if (baseUrl === '') {
      throw new Failure('Variável de ambiente ("REACT_APP_API_CRYPTO_GATEWAY") não foi encontrada');
    }
    const repository = new FinancialHttpRepository(baseUrl, makeCryptoHttpDatasource());
    saveWithdrawUsecase = new SaveWithdrawUsecaseImp(repository);
  }
  return saveWithdrawUsecase;
};

import { ExtractCacheRepository } from 'data/repositories/cache/extract-history-repository';
import { LoadExtractHistoryRepository } from 'domain/repositories/load-extract-history-repository';
import { SaveExtractHistoryRepository } from 'domain/repositories/save-extract-history-repository';
import { makeLocalStorageCacheDatasource } from 'main/factories/datasources/cache/cache-datasource-factory';

const makeExtractHistoryCacheRepository = (): ExtractCacheRepository => {
  return new ExtractCacheRepository(makeLocalStorageCacheDatasource());
};

export const makeLoadExtractHistoryFromCacheRepository = (): LoadExtractHistoryRepository => {
  return makeExtractHistoryCacheRepository();
};

export const makeSaveExtractFromCacheRepository = (): SaveExtractHistoryRepository => {
  return makeExtractHistoryCacheRepository();
};

import { FirebaseFirestoreDatasource } from 'data/datasources/firebase/firestore-datasource';
import firebase from 'firebase/compat/app';

export class FirebaseFirestoreDatasourceImp implements FirebaseFirestoreDatasource {
  async getDoc(
    collection: string,
    doc: string
  ): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
    const db = firebase.firestore().collection(collection);

    return db.doc(doc).get();
  }
  async getCollection(collection: string): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
    const db = firebase.firestore();

    return db.collection(collection).get();
  }
}

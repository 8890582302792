import axios from 'axios';
import { HttpDatasource, HttpDatasourceParams, HttpMethodEnum } from '../../data/datasources/http/http-datasource';
import { HttpResponse } from '../../data/datasources/http/http-response';
import { HttpResponseImp } from './http-response-imp';

export class AxiosHttpDatasource implements HttpDatasource {
  async request(
    url: string,
    { headers = {}, data = null, method = HttpMethodEnum.get }: HttpDatasourceParams = {}
  ): Promise<HttpResponse> {
    const result = await axios({
      url,
      headers,
      data,
      method,
    });
    return new HttpResponseImp(result.status, result.data);
  }
}

import { VXRoundedSkeleton } from 'presentation/components/micro-components/vx-skeleton/rounded/style';
import { VXSquareSkeleton } from 'presentation/components/micro-components/vx-skeleton/square/style';

import * as Styles from './styles';

export const OrderDataEntitySkeleton = () => {
  return (
    <>
      {[...Array(5).keys()].map((_, index) => (
        <Styles.OrderDataEntityContainer key={index}>
          <div className="recent-orders__info">
            <div className="recent-orders__info--container">
              <VXRoundedSkeleton size={40} skeletonColor="light" />
              <div className="recent-orders__info--text">
                <VXSquareSkeleton width={65} height={20} skeletonColor="light" />
                <VXSquareSkeleton width={65} height={20} skeletonColor="light" />
              </div>
            </div>

            <div className="recent-orders__info--status">
              <VXSquareSkeleton width={80} height={26} skeletonColor="light" />
            </div>
          </div>
        </Styles.OrderDataEntityContainer>
      ))}
    </>
  );
};

import { VxBilletType } from 'presentation/components/vx-billet';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export type BilletTitleProps = {
  type: VxBilletType;
};

export const BilletTitle = styled.div<BilletTitleProps>`
  height: ${(props) => {
    switch (props.type) {
      case 'assetProfile':
        return 82;
      case 'margin':
        return 75;
      default:
        return 64;
    }
  }}px;
  padding-top: ${(props) => (props.type === 'assetProfile' ? 6 : 0)}px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: ${(props) => (props.type === 'modal' ? 'space-between' : 'center')};
  @media (max-width: 1024px) {
    height: ${(props) => (props.type === 'modal' ? 52 : 53)}px;
  }
  .guarantee-asset {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const TextAndTooltipContainer = styled.div<BilletTitleProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.type === 'modal' ? 'start' : 'center')};
  gap: 16px;
`;

export const BilletTitleIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: ${vxAppColors.brand.primary.principal};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

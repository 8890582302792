import * as Styles from './styles';

type VxGridProps = {
  children: React.ReactNode;
  debug?: boolean;
};

export function VxGridContainer(props: VxGridProps) {
  return <Styles.GridContainer debug={props.debug}>{props.children}</Styles.GridContainer>;
}

import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { WsDatasource, WsDatasourceOnMessageParams, WsDatasourceSendParams } from 'data/datasources/ws/ws-datasource';

const sleep = (milliseconds: number) => new Promise((r) => setTimeout(r, milliseconds));

export class SignalRWsDatasource implements WsDatasource {
  private onConnectionChangeCallbacks: Array<(connected: boolean) => void> = [];
  private isConnecting = false;
  private shouldReconnect = true;
  constructor(readonly hubConnection: HubConnection) {
    hubConnection.onclose(() => {
      this.onConnectionChangeCallbacks.forEach((callback) => callback(false));
      if (this.shouldReconnect) {
        this.connect();
      }
    });
  }
  isConnected(): boolean {
    return this.hubConnection.state === HubConnectionState.Connected;
  }
  async connect(): Promise<void> {
    if (!this.isConnected() && !this.isConnecting) {
      this.isConnecting = true;
      let reconnectCount = 0;
      while (reconnectCount < 13 && !this.isConnected()) {
        try {
          await this.hubConnection.start();
        } catch (e) {
          await sleep(7000);
          reconnectCount += 1;
        }
      }
      if (this.isConnected()) {
        this.onConnectionChangeCallbacks.forEach((callback) => callback(true));
      }
      this.shouldReconnect = true;
      this.isConnecting = false;
    }
  }
  async disconnect(): Promise<void> {
    if (this.isConnected()) {
      this.shouldReconnect = false;
      try {
        await this.hubConnection.stop();
        // eslint-disable-next-line no-empty
      } catch (e) {}
      this.onConnectionChangeCallbacks.forEach((callback) => callback(false));
    }
  }
  async send(params: WsDatasourceSendParams): Promise<any> {
    await this.connect();
    if (this.isConnected()) {
      return this.hubConnection.invoke(params.message, params.data);
    }
  }
  onMessage(params: WsDatasourceOnMessageParams): void {
    this.hubConnection.on(params.message, params.callback);
  }
  addOnConnectionChange(callback: (connected: boolean) => void): void {
    this.onConnectionChangeCallbacks.push(callback);
  }
  removeOnConnectionChange(callback: (connected: boolean) => void): void {
    const indexOfCallback = this.onConnectionChangeCallbacks.indexOf(callback);
    if (indexOfCallback > -1) {
      this.onConnectionChangeCallbacks.slice(indexOfCallback, 1);
    }
  }
}

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const Dot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${vxAppColors.neutral.darkest};
  margin-right: 10px;
`;

export const DotsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
`;

import { OrdersStatusEnumId, OrderStatusEnumMessage } from 'domain/enums';
import { generateKeyValuePair, getEnumKeyByEnumValue } from 'domain/util';

import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import * as Styles from './styles';

type OrderStatusProps = {
  id: string;
  name: string;
  isExceptionalForMobileAndSmallDesktop?: boolean;
};

function getColors(status: string) {
  switch (status) {
    case OrderStatusEnumMessage.notConfirmed:
      return vxAppColors.feedback.error.default;

    case OrderStatusEnumMessage.openOrder:
      return vxAppColors.feedback.error.default;

    case OrderStatusEnumMessage.filled:
      return vxAppColors.feedback.success.default;

    case OrderStatusEnumMessage.cancelled:
      return vxAppColors.feedback.error.default;

      return vxAppColors.feedback.error.default;
    case OrderStatusEnumMessage.rejected:
      return vxAppColors.brand.secondary.light;

    default:
      return vxAppColors.neutral.medium;
  }
}

export function OrderStatus({ id, name, isExceptionalForMobileAndSmallDesktop }: OrderStatusProps) {
  const statusKey = getEnumKeyByEnumValue(OrdersStatusEnumId, id.toString());
  const message = generateKeyValuePair(OrderStatusEnumMessage);
  const color = getColors(message[statusKey] as string);

  return (
    <Styles.Container color={color}>
      {isExceptionalForMobileAndSmallDesktop ? (
        <VxBodySm title={name} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
      ) : (
        <VxBodySm title={name} fontWeight="regular" color={vxAppColors.brand.primary.darkest} />
      )}
    </Styles.Container>
  );
}

import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { CircularContainer } from './style';

const assetImageSizes: assetImageSizesType = {
  xs2: 32,
  xs: 40,
  sm: 60,
  md: 80,
  lg: 100,
};

type sizeKeys = 'xs2' | 'xs' | 'sm' | 'md' | 'lg';
type sizeValue = 32 | 40 | 60 | 80 | 100;

type assetImageSizesType = { [size in sizeKeys]: sizeValue };

interface AssetImageProps {
  symbol: string;
  /**
   * xs2 = 32px;
   *
   * xs = 40px;
   *
   * sm = 60px;
   *
   * md = 80px;
   *
   * lg = 100px;
   */
  size: sizeKeys;
}

const AssetImage: React.FunctionComponent<AssetImageProps> = ({ symbol, size }) => {
  const baseUrl = process.env.REACT_APP_BUCKET as string;
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoaded] = React.useState(true);

  function shouldShowError(isHigherThan50: boolean): boolean {
    if (isHigherThan50) {
      return isError && isLoading && assetImageSizes[size] >= 50;
    }
    return isError && isLoading && assetImageSizes[size] < 50;
  }

  return (
    <>
      <CircularContainer symbol={symbol} size={assetImageSizes[size]} isLoading={isLoading}>
        {!isError && (
          <img
            src={`${baseUrl}${symbol.toLowerCase()}.png`}
            alt={symbol.toUpperCase()}
            onError={(e) => {
              e.currentTarget.style.display = 'none';
              setIsError((state) => !state);
            }}
            onLoad={() => setIsLoaded(false)}
          />
        )}
        {shouldShowError(true) && (
          <VxBodySm color={vxAppColors.brand.primary.darkest} title={symbol.toUpperCase()} fontWeight="medium" />
        )}
        {shouldShowError(false) && <span>{symbol}</span>}
      </CircularContainer>
    </>
  );
};

export { AssetImage };

import { Either } from '../entities/either';
import { Failure } from '../failure/failure';
import { LoadCompanyNameBySymbolRepository } from '../repositories/load-company-name-by-symbol-repository';

export interface LoadCompanyNameBySymbolUsecase {
  execute(symbol: string): Promise<Either<Failure, string>>;
}

export class LoadCompanyNameBySymbolUsecaseImp implements LoadCompanyNameBySymbolUsecase {
  constructor(readonly repository: LoadCompanyNameBySymbolRepository) {
    Object.freeze(this);
  }

  async execute(symbol: string): Promise<Either<Failure, string>> {
    return this.repository.loadCompanyNameBySymbol(symbol);
  }
}

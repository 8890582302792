import { TableHead } from './components/table-head';
import { TablePositionSkeleton } from './components/table-position-skeleton';
import { TableRowDesktop } from './components/table-row/components/table-row-desktop';
import { TableRowMobile } from './components/table-row/components/table-row-mobile';
import * as Styles from './styles';
import { TablePositionProps } from './table-position-props';

export function TablePosition({
  positions,
  isLoading,
  hasError,
  hasPositions,
  isExceptionalForMobileAndSmallDesktop,
}: TablePositionProps) {
  if (isLoading) return <TablePositionSkeleton />;

  if (!hasPositions) return <></>;

  return (
    <>
      <Styles.Table>
        <TableHead isExceptionalForMobileAndSmallDesktop={isExceptionalForMobileAndSmallDesktop} />
        {hasPositions && !hasError && (
          <tbody className="table__body">
            {isExceptionalForMobileAndSmallDesktop
              ? positions.map((position, index) => <TableRowMobile position={position} key={index.toString()} />)
              : positions.map((position, index) => <TableRowDesktop position={position} key={index.toString()} />)}
          </tbody>
        )}
      </Styles.Table>
    </>
  );
}

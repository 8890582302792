import { toast } from 'react-toastify';
import { CloseButton } from './components/close-button';
import './style.scss';
import { LabelToast, TextContainer, ToastContent } from './style';

import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { VXIcon, VXIconsEnum } from '../vx-icon';
import { VxBodyLg, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';

type VexterToastParams = {
  title: string;
  message?: string;
  hideProgressBar?: boolean;
  hasClose?: boolean;
};

interface VexterToastButtonParams {
  title: string;
  message?: string;
  hideProgressBar?: boolean;
  onClick: () => void;
  label: string;
}

const closeButton = (hasClose: boolean, isWhite?: boolean) => {
  return (
    hasClose && (
      <CloseButton isBlackContent={false}>
        <VXIcon
          size="sm"
          color={isWhite ? vxAppColors.neutral.superLight : vxAppColors.neutral.darkest}
          icon={isWhite ? VXIconsEnum.close : VXIconsEnum.close}
        />
      </CloseButton>
    )
  );
};
const renderContent = (title: string, color: VxAppColor, message?: string, onClick?: () => void, label?: string) => (
  <ToastContent>
    <div>
      <TextContainer>
        <VxBodyLg title={title} fontWeight="bold" color={color} />
      </TextContainer>
      <TextContainer>{message && <VxBodySm title={message} fontWeight="light" color={color} />}</TextContainer>
    </div>

    {label && (
      <div>
        <LabelToast
          color={color}
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          <VxBodySm title={label} fontWeight="medium" color={color} />
        </LabelToast>
      </div>
    )}
  </ToastContent>
);

const vexterToastSuccess = ({ title, message, hideProgressBar = true, hasClose = true }: VexterToastParams) => {
  const msg = renderContent(title, vxAppColors.neutral.darkest, message);
  toast.success(msg, {
    position: 'top-center',
    autoClose: 5000,
    closeButton: closeButton(hasClose),
    style: {
      backgroundColor: vxAppColors.feedback.genial.lightest,
      color: vxAppColors.neutral.darkest,
      height: 80,
      width: 480,
      borderRadius: 8,
      boxShadow: 'rgba(26, 26, 26, 0.16)',
    },
    hideProgressBar: hideProgressBar,
    progressStyle: { backgroundColor: vxAppColors.neutral.superLight },
    icon: (
      <VXIcon
        size="lg"
        invertStrokeWithFill={true}
        color={vxAppColors.neutral.darkest}
        icon={VXIconsEnum.circleCheck}
      />
    ),
  });
};
const vexterToastSuccessButton = ({
  title,
  message,
  hideProgressBar = true,
  onClick,
  label,
}: VexterToastButtonParams) => {
  const msg = renderContent(title, vxAppColors.neutral.darkest, message, onClick, label);
  toast.success(msg, {
    position: 'top-center',
    autoClose: 5000,
    closeButton: closeButton(false),
    style: {
      backgroundColor: vxAppColors.feedback.genial.lightest,
      color: vxAppColors.neutral.darkest,
      height: 80,
      width: 480,
      borderRadius: 8,
      boxShadow: 'rgba(26, 26, 26, 0.16)',
    },
    hideProgressBar: hideProgressBar,
    progressStyle: { backgroundColor: vxAppColors.neutral.superLight },
    icon: <VXIcon size="md" color={vxAppColors.neutral.darkest} invertStrokeWithFill={true} icon={VXIconsEnum.check} />,
  });
};
const vexterToastError = ({ title, message, hideProgressBar = true, hasClose = true }: VexterToastParams) => {
  const msg = renderContent(title, vxAppColors.neutral.superLight, message);
  toast.error(msg, {
    position: 'top-center',
    autoClose: 5000,
    closeButton: closeButton(hasClose, true),
    style: {
      backgroundColor: vxAppColors.feedback.error.default,
      color: vxAppColors.neutral.superLight,
      height: 80,
      width: 480,
      borderRadius: 8,
      boxShadow: 'rgba(26, 26, 26, 0.16)',
    },
    hideProgressBar: hideProgressBar,
    progressStyle: { backgroundColor: vxAppColors.neutral.superLight },
    icon: (
      <VXIcon
        size="lg"
        color={vxAppColors.neutral.superLight}
        invertStrokeWithFill={true}
        icon={VXIconsEnum.circleClose}
      />
    ),
  });
};
const vexterToastErrorButton = ({
  title,
  message,
  hideProgressBar = true,
  onClick,
  label,
}: VexterToastButtonParams) => {
  const msg = renderContent(title, vxAppColors.neutral.superLight, message, onClick, label);
  toast.error(msg, {
    position: 'top-center',
    autoClose: 5000,
    closeButton: closeButton(false),
    style: {
      backgroundColor: vxAppColors.feedback.error.default,
      color: vxAppColors.neutral.superLight,
      height: 80,
      width: 480,
      borderRadius: 8,
      boxShadow: 'rgba(26, 26, 26, 0.16)',
    },
    hideProgressBar: hideProgressBar,
    progressStyle: { backgroundColor: vxAppColors.neutral.superLight },
    icon: (
      <VXIcon
        size="lg"
        color={vxAppColors.neutral.superLight}
        invertStrokeWithFill={true}
        icon={VXIconsEnum.circleClose}
      />
    ),
  });
};
const vexterToastWarning = ({ title, message, hideProgressBar = true, hasClose = true }: VexterToastParams) => {
  const msg = renderContent(title, vxAppColors.neutral.darkest, message);
  toast.warning(msg, {
    position: 'top-center',
    autoClose: 5000,
    closeButton: closeButton(hasClose),
    style: {
      backgroundColor: vxAppColors.feedback.atention.default,
      color: vxAppColors.neutral.darkest,
      height: 80,
      width: 480,
      borderRadius: 8,
      boxShadow: 'rgba(26, 26, 26, 0.16)',
    },
    hideProgressBar: hideProgressBar,
    progressStyle: { backgroundColor: vxAppColors.neutral.superLight },
    icon: <VXIcon size="lg" invertStrokeWithFill={true} color={vxAppColors.neutral.darkest} icon={VXIconsEnum.alert} />,
  });
};
const vexterToastWarningButton = ({
  title,
  message,
  hideProgressBar = true,
  onClick,
  label,
}: VexterToastButtonParams) => {
  const msg = renderContent(title, vxAppColors.neutral.darkest, message, onClick, label);
  toast.warning(msg, {
    position: 'top-center',
    autoClose: 5000,
    closeButton: closeButton(false),
    style: {
      backgroundColor: vxAppColors.feedback.atention.default,
      color: vxAppColors.neutral.darkest,
      height: 80,
      width: 480,
      borderRadius: 8,
      boxShadow: 'rgba(26, 26, 26, 0.16)',
    },
    hideProgressBar: hideProgressBar,
    progressStyle: { backgroundColor: vxAppColors.neutral.superLight },
    icon: <VXIcon size="lg" color={vxAppColors.neutral.darkest} invertStrokeWithFill={true} icon={VXIconsEnum.alert} />,
  });
};
export {
  vexterToastSuccess,
  vexterToastError,
  vexterToastWarning,
  vexterToastSuccessButton,
  vexterToastErrorButton,
  vexterToastWarningButton,
};

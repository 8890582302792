import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const SideBarStyle = styled.nav`
  background-color: ${vxAppColors.brand.primary.principal};
  width: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 32px 0px 64px;
  .navigation-buttons {
    width: 100%;
    margin-top: 32px;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
  }
  .show-mobile {
    display: none;
  }
  .show-desk {
    display: block;
  }
  @media (max-width: 1919px) {
    width: 72px;
    padding: 24px 0px 24px;
    .navigation-buttons {
      margin-top: 39px;
    }
    .navigation-buttons {
      max-width: 100%;
    }
    .show-mobile {
      display: block;
    }
    .show-desk {
      display: none;
    }
  }
`;

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

const OrderContentPage = styled.article`
  .hide-filters {
    display: flex;
    justify-content: end;
    padding: 16px 0px 0px 0px;
    margin-bottom: 24px;
  }
  .filter-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    height: 37px;
    border: none;
    background-color: transparent;
    margin: 0px;
  }

  .close-button-mobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .show-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0;
    gap: 40px;

    .filter-container {
      display: flex;
      padding: 0px;
      gap: 64px;
      width: 100%;

      &__date {
        display: flex;
        padding: 0px;
        gap: 64px;
        width: 100%;

        @media (max-width: 1023px) {
          flex-direction: column;
        }
      }

      @media (max-width: 1023px) {
        flex-direction: column;
      }
    }

    .hide-filter-mobile {
      margin-left: auto;
    }

    .actions-buttons {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-left: auto;

      .btn-filter {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
      }

      @media (min-width: 1024px) and (max-width: 1365px) {
        width: 100%;
      }
    }
  }
`;

const OrderBody = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 36px;

  width: 100%;
  @media (max-width: 1574px) {
    width: 92%;
    flex-direction: column;
  }

  aside {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 24px;

    @media (max-width: 1574px) {
      width: 111.5%;
      margin-left: 56px;
      margin-top: 24px;
    }
    > section > div {
      @media (max-width: 1574px) {
        max-width: unset;
        width: 111.5%;
      }
    }
  }
`;

const Spacer = styled.div`
  width: 1%;
  height: 200px;

  @media (max-width: 1574px) {
    display: none;
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
  background: ${vxAppColors.neutral.superLight};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
`;
export { OrderContentPage, OrderBody, Spacer };

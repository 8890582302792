import { Button } from '@mui/material';
import { vexterToastWarning } from 'presentation/components/micro-components/toast';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';

import { VxBodyMd, VxButtonTypeLg } from 'presentation/styleguide/typography/body/VxBody';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { InfoTooltip } from '../../../info-tooltip';
import * as Styles from './styles';

type TitleProps = {
  smallDesktop: boolean;
  isMobile: boolean;
  tooltipText: string;
  seeOrders: boolean;
  titleText: string;
};

export default function Title({ smallDesktop, isMobile, seeOrders, tooltipText, titleText }: TitleProps) {
  return (
    <Styles.TitleContainer>
      {smallDesktop || isMobile ? (
        <>
          <div className="title-container__with-tooltip">
            <VxBodyMd title={titleText} fontWeight="light" color={vxAppColors.neutral.dark} />
            <InfoTooltip text={tooltipText} />
          </div>
          {isMobile && seeOrders && (
            <Button variant="text" onClick={() => vexterToastWarning({ title: 'Em breve' })}>
              <VxButtonTypeLg title="VER ORDENS " color={vxAppColors.brand.tertiary.principal} fontWeight="medium" />

              <VXIcon icon={VXIconsEnum.arrowRight} size="md" color={vxAppColors.brand.tertiary.principal} />
            </Button>
          )}
        </>
      ) : (
        <div className="title-container__with-tooltip">
          <H4 title={titleText} fontWeight="light" color={vxAppColors.neutral.dark} />
          <InfoTooltip text={tooltipText} />
        </div>
      )}
    </Styles.TitleContainer>
  );
}

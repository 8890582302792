import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  margin-top: 32px;
  .align-center {
    text-align: center;
  }
  .table {
    &__row-head {
      text-align: left;
    }

    &__body {
      td {
        text-align: center;
        vertical-align: middle;

        &:first-child {
          width: 19.64%;

          span:last-child {
            text-transform: lowercase;

            &::first-letter {
              text-transform: uppercase;
            }
          }
        }

        &:nth-child(2) {
          width: 16.9%;
        }

        &:nth-child(3) {
          width: 20.5%;
        }

        &:nth-child(4) {
          width: 12.67%;
        }
      }

      tr {
        height: 80px;
        cursor: pointer;
      }

      &::before {
        content: '';
        display: block;
        height: 32px;
      }

      .tr-spacer {
        height: 16px;
      }

      &--first-info {
        cursor: pointer;
        display: flex;
        & > div {
          margin-right: 8px;
          text-align: left;
          span {
            &:first-child {
              margin-bottom: 4px;
            }
          }
        }
        div {
          display: flex;
          flex-direction: column;
          margin-left: 5px;
        }
      }

      &--numbers-info {
        display: flex;
        align-items: center;

        & > div {
          display: flex;
          flex-direction: column;
          text-align: right;
        }

        & button {
          width: 86px;
          height: 40px;
          margin-left: 40px;
        }
      }

      &--numbers {
        min-width: 180px;

        span {
          width: fit-content;
          min-width: 107px;

          &:first-child {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
`;

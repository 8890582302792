export function getGridPercentage(propertieWidth: number, usableWidth: number) {
  const percentage = Math.abs((propertieWidth / usableWidth) * 100);
  return `${percentage.toString()}%`;
}

export function getGridVw(propertieWidth: number, usableWidth: number) {
  const percentage = Math.abs((propertieWidth / usableWidth) * 100);
  return `${percentage.toString()}vw`;
}

export function checkProps(genericColumn?: number, especificColumn?: number) {
  if (especificColumn) {
    return especificColumn;
  }
  return genericColumn;
}

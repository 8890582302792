import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export type ChartContainerProps = {
  height: number;
};

export const ChartContainer = styled.div<ChartContainerProps>`
  max-width: ${(props) => props.height}px;
  min-width: 102px;
  max-height: 162px;
  min-height: 102px;
  width: 100%;
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  .layer1 {
    width: 100%;
  }
  .layer2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: -100%;
  }
  .layer3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: -100%;
    div.layer3-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      box-shadow: inset 0px 0px 12px rgba(54, 77, 205, 0.15), inset -9px -9px 12px rgba(255, 255, 255, 0.15);
      background: ${vxAppColors.neutral.white};
      width: ${(props) => props.height}px;
      height: ${(props) => props.height}px;
    }
  }
`;

import * as Styles from './styles';
import { H4 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';

export default function OrderDataEntityError() {
  return (
    <Styles.Container>
      <H4 title="Você ainda não possui ordens" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
      <VxBodyMd
        title="Ao realizar operações elas serão exibidas aqui"
        fontWeight="regular"
        color={vxAppColors.brand.primary.darkest}
      />
    </Styles.Container>
  );
}

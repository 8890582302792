import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';
import { InputFontSizeType, InputStatus } from '.';

const getMainColor = ({ value, disabled, status }: ContainerProps): VxAppColor => {
  if (disabled === true) {
    return vxAppColors.neutral.light;
  }
  if (status === 'success') {
    return vxAppColors.feedback.success.default;
  }
  if (status === 'error') {
    return vxAppColors.feedback.error.default;
  }
  return value === '' ? vxAppColors.neutral.darkest : vxAppColors.graph.principal;
};

const getFocusedColor = ({ status }: ContainerProps): VxAppColor => {
  if (status === 'success') {
    return vxAppColors.feedback.success.default;
  }
  if (status === 'error') {
    return vxAppColors.feedback.error.default;
  }
  return vxAppColors.brand.tertiary.principal;
};

type ContainerProps = {
  value: string;
  disabled: boolean;
  status: InputStatus;
  fontSize: InputFontSizeType;
};

export const InputContainer = styled.div<ContainerProps>`
  .MuiFormLabel-root {
    color: ${getMainColor};
    font-family: 'Source Sans Pro';
    font-style: ${({ value, disabled }) => (value === '' || disabled ? 'italic' : 'normal')};
    font-size: ${({ value, fontSize }) => (fontSize === 'small' ? 12 : value === '' ? 16 : 14)}px;
    line-height: ${({ value, fontSize }) => (fontSize === 'small' ? 18 : value === '' ? 24 : 21)}px;
    font-weight: 400;
    &.Mui-focused {
      color: ${getFocusedColor};
      font-style: normal;
      font-size: ${({ fontSize }) => (fontSize === 'small' ? 12 : 14)}px;
      line-height: ${({ fontSize }) => (fontSize === 'small' ? 18 : 21)}px;
      font-weight: 400;
    }
  }
  .MuiInput-input {
    font-family: 'Source Sans Pro';
    color: ${vxAppColors.brand.primary.darkest};
    font-size: ${({ fontSize }) => (fontSize === 'small' ? 12 : 14)}px;
    line-height: ${({ fontSize }) => (fontSize === 'small' ? 18 : 21)}px;
    font-weight: 400;
  }
  .MuiInput-underline {
    color: ${getMainColor};
    &.Mui-focused {
      border-bottom: 1px solid ${getFocusedColor};
      :after {
        border-bottom: 1px solid ${getFocusedColor};
      }
    }
    &.Mui-disabled::before {
      border-bottom-style: solid;
      color: ${vxAppColors.neutral.light};
    }
    &.MuiInput-root:not(.Mui-disabled):before,
    &.MuiInputBase-root:not(.Mui-disabled):before {
      border-bottom: 1px solid ${getFocusedColor};
    }
    &.MuiInput-root:not(.Mui-disabled):after,
    &.MuiInputBase-root:not(.Mui-disabled):after {
      border-bottom: 1px solid ${getFocusedColor};
    }
  }
  .MuiFormHelperText-root {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    color: ${getMainColor};
    font-size: ${({ value, disabled }) => (value === '' && !disabled ? 12 : 10)}px;
    line-height: ${({ value, disabled }) => (value === '' && !disabled ? 28 : 15)}px;
    &.Mui-focused {
      font-weight: 400;
      font-family: 'Source Sans Pro';
      color: ${getFocusedColor};
      font-style: normal;
      font-size: 10px;
      line-height: 15px;
    }
  }
`;

export const HelperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import { VxBodyMd, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { useMediaQuery } from 'usehooks-ts';
import * as Styles from './styles';

export function TableHead() {
  const isSmall = useMediaQuery('(max-width: 1024px)');
  return (
    <Styles.Thead>
      <tr>
        {['Nome', 'Quantidade', 'Valor', ''].map((title, i) => (
          <th key={i}>
            {isSmall ? (
              <VxBodyXs title={title} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
            ) : (
              <VxBodyMd title={title} fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
            )}
          </th>
        ))}
      </tr>
    </Styles.Thead>
  );
}

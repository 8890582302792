import { WsDatasource } from 'data/datasources/ws/ws-datasource';
import { CryptoQuotation } from 'data/models/crypto-quotation-response-model';
import { AssetQuotationEntity } from 'domain/entities/asset-quotation-entity';
import { AssetSymbolEntity } from 'domain/entities/asset-symbol-entity';
import { Either, left, right } from 'domain/entities/either';
import { SubscriptionCancelEntity } from 'domain/entities/subscription-cancel-entity';
import { Failure } from 'domain/failure/failure';
import {
  ListenQuotationBySymbolRepository,
  ListenQuotationBySymbolRepositoryParams,
} from 'domain/repositories/listen-quotation-by-symbol-repository';
import { QuotationWsRepository, QuotationWsRepositoryMessageArgumentsParams } from './quotation-ws-repository';

export class CryptoQuotationWsRepository extends QuotationWsRepository implements ListenQuotationBySymbolRepository {
  constructor(wsDatasource: WsDatasource) {
    super(wsDatasource);
    this.init();
    Object.freeze(this);
  }
  override subscribeMessage = 'SubscribeCrypto';
  override unsubscribeMessage = 'UnsubscribeCrypto';
  override listenMessage = 'CryptoQuotes';
  override listen(data: any): void {
    data = JSON.parse(data);
    const isDataValid = data !== null && data !== undefined && (data.Symbol ?? '') !== '';
    if (!isDataValid) return;
    const result = CryptoQuotation.fromJson(data);
    const quotation: AssetQuotationEntity = {
      symbol: new AssetSymbolEntity(result.symbol),
      lastTrade: result.lastTrade,
      dailyOscillation: result.dailyOscillation,
      status: result.status,
      bookInfoBuy: result.bookInfoBuy,
      bookInfoSell: result.bookInfoSell,
      marginValueSell: result.marginValueSell,
    };
    const callbacks = this.callbacksBySymbol(quotation.symbol.value);
    for (const callback of callbacks) {
      try {
        callback(quotation);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }
  override messageArguments(params: QuotationWsRepositoryMessageArgumentsParams): any {
    return {
      CurrencyId: 'BRL',
      Symbol: params.symbol,
    };
  }
  async quotationBySymbol(
    params: ListenQuotationBySymbolRepositoryParams
  ): Promise<Either<Failure, SubscriptionCancelEntity>> {
    try {
      await this.add(params);
      return right(
        new SubscriptionCancelEntity(async () => {
          await this.remove(params);
        })
      );
    } catch (e) {
      return left(new Failure(`Erro ao inscrever na cotação (símbolo: "${params.symbol}")`));
    }
  }
}

import { Modal } from 'presentation/components/modal';
import { VxBillet, VxBilletPhase, VxBilletSide, VxBilletType } from 'presentation/components/vx-billet';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useMediaQuery } from 'usehooks-ts';

export type UseBilletParams =
  | {
      type?: 'modal';
    }
  | {
      type?: VxBilletType;
      symbol?: string;
      value?: number;
      quantity?: number;
    }
  | {
      type?: VxBilletType;
      symbol?: string;
      value?: number;
      quantity?: number;
      side?: VxBilletSide;
    };

export function useBillet(params?: UseBilletParams) {
  let phase: VxBilletPhase = 'editing';
  if (params === undefined) {
    phase = 'selecting';
  } else if (params.type !== 'margin' && 'side' in params) {
    phase = 'confirmation';
  }
  let side: VxBilletSide | undefined = undefined;
  if (params && 'side' in params) {
    side = params.side;
  }
  let symbol: string | undefined = undefined;
  if (params && 'symbol' in params) {
    symbol = params.symbol;
  }
  const [showModal, hideModal] = useModal(({ in: open }) => {
    const [height, setHeight] = useState<number>(535);
    const isSmall = useMediaQuery('(max-width: 1024px)');
    return (
      <Modal
        open={open}
        hideModalHookCallback={hideModal}
        width={params?.type === 'margin' && side === 'sellOrRelease' ? 504 : isSmall ? 400 : 464}
        height={height}
      >
        <VxBillet
          hideModal={hideModal}
          setHeight={setHeight}
          phase={phase}
          symbol={symbol}
          type={params?.type ?? 'modal'}
          side={side}
        />
      </Modal>
    );
  });
  return {
    showModal,
  };
}

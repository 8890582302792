import { Either } from 'domain/entities/either';
import { ExtractHistoryCache } from 'domain/entities/extract-entity';
import { Failure } from 'domain/failure/failure';
import { SaveExtractHistoryRepository } from 'domain/repositories/save-extract-history-repository';
import { ExtractProps } from 'presentation/pages/profile/subpages/extract/components/extract-table';

export interface SaveExtractHistoryCacheUsecase {
  execute(
    extract: ExtractProps[],
    filterStartDate: string,
    filterEndDate: string,
    generatedAtDate: string
  ): Promise<Either<Failure, ExtractHistoryCache[]>>;
}

export class SaveExtractHistoryCacheUsecaseImpl implements SaveExtractHistoryCacheUsecase {
  constructor(readonly repository: SaveExtractHistoryRepository) {
    Object.freeze(this);
  }
  async execute(
    extract: ExtractProps[],
    filterStartDate: string,
    filterEndDate: string,
    generatedAtDate: string
  ): Promise<Either<Failure, ExtractHistoryCache[]>> {
    return this.repository.saveHistory(extract, filterStartDate, filterEndDate, generatedAtDate);
  }
}

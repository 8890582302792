import styled from 'styled-components';

export const Container = styled.section`
  word-wrap: unset;

  .invested-amount__numbers--info {
    word-wrap: unset;
    white-space: nowrap;

    span {
      white-space: nowrap;
    }
  }
`;

import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`;

import { BannerDataEntity } from 'domain/entities/banner-data-entity';
import { Either } from 'domain/entities/either';
import { Failure } from 'domain/failure/failure';
import { LoadBannersRepository } from 'domain/repositories/load-banners-repository';

export interface LoadBannersUsecase {
  execute(): Promise<Either<Failure, BannerDataEntity[]>>;
}

export class LoadBannersUsecaseImp implements LoadBannersUsecase {
  constructor(readonly repository: LoadBannersRepository) {
    Object.freeze(this);
  }
  execute(): Promise<Either<Failure, BannerDataEntity[]>> {
    return this.repository.loadBanners();
  }
}

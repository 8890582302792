import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const CardContainerArea = styled.div`
  display: flex;
  flex-direction: column;
  background: ${vxAppColors.neutral.superLight};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow-wrap: anywhere;
`;

import { Either } from 'domain/entities';
import { Failure } from 'domain/failure/failure';
import { CancelOrderRepository } from 'domain/repositories/cancel-order-repository';

export interface CancelOrderUsecase {
  execute: (orderId: string) => Promise<Either<Failure, void>>;
}

export class CancelOrderUsecaseImp implements CancelOrderUsecase {
  constructor(readonly repository: CancelOrderRepository) {
    Object.freeze(this);
  }
  execute(orderId: string): Promise<Either<Failure, void>> {
    return this.repository.cancelOrder(orderId);
  }
}

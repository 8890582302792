import { useMediaQuery } from '@mui/material';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';
import { useAppDispatch, useAppSelector } from 'presentation/stores/hooks';
import { toggleShowInfo } from 'presentation/stores/slices/settings.store';
import { VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import * as S from './styles';

export function HideButton() {
  const isMobile = useMediaQuery('(max-width: 1366px)');

  const dispatch = useAppDispatch();
  const { showInfo } = useAppSelector((state) => state.settingsSlice);

  return (
    <S.HideContainer onClick={() => dispatch(toggleShowInfo())}>
      {isMobile ? (
        <>
          <VxBodyXs
            title={!showInfo ? 'Exibir valores' : 'Esconder valores'}
            fontWeight="light"
            color={vxAppColors.neutral.darkest}
            htmlTag="span"
          />
          <S.HideIconContainer showing={showInfo}>
            <VXIcon
              icon={showInfo ? VXIconsEnum.olho : VXIconsEnum.olhoFechado}
              size="msm"
              invertStrokeWithFill={true}
              color={vxAppColors.neutral.superLight}
            />
          </S.HideIconContainer>
        </>
      ) : (
        <>
          <VxBodySm
            title={!showInfo ? 'Exibir valores' : 'Esconder valores'}
            fontWeight="light"
            color={vxAppColors.neutral.darkest}
            htmlTag="span"
          />
          <S.HideIconContainer showing={showInfo}>
            <VXIcon
              icon={showInfo ? VXIconsEnum.olho : VXIconsEnum.olhoFechado}
              size="sm"
              invertStrokeWithFill={true}
              color={vxAppColors.neutral.superLight}
            />
          </S.HideIconContainer>
        </>
      )}
    </S.HideContainer>
  );
}

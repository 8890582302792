import { ComponentStory, Meta } from '@storybook/react';

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import {
  VxBodyLg as BodyLg,
  VxBodyMd as BodyMd,
  VxBodySm as BodySm,
  VxBodyXs as BodyXs,
  VxButtonTypeLg as ButtonType,
  VxPlaceholder as Placeholder,
} from './VxBody';

type BodyContainerProps = {
  children: JSX.Element[] | JSX.Element;
  title?: string;
};
const BodyContainer = (props: BodyContainerProps) => <>{props.children}</>;

export default {
  title: 'Body',
  component: BodyContainer,
  argTypes: {
    fontWeight: {
      options: ['light', 'regular', 'medium', 'bold'].filter((x) => typeof x === 'string'),
      control: { type: 'select' },
    },
  },
} as Meta;

const VxBodyLGTemplate: ComponentStory<typeof BodyLg> = (args) => <BodyLg {...args} />;

export const VxBodyLg = VxBodyLGTemplate.bind({});

VxBodyLg.args = {
  title: 'BodyLg - Source Sans Pro (18px)',
  color: vxAppColors.brand.primary.principal,
};

const VxBodyMdTemplate: ComponentStory<typeof BodyMd> = (args) => <BodyMd {...args} />;

export const VxBodyMd = VxBodyMdTemplate.bind({});

VxBodyMd.args = {
  title: 'BodyMd - Source Sans Pro (18px)',
  color: vxAppColors.brand.primary.principal,
};

const VxBodySmTemplate: ComponentStory<typeof BodySm> = (args) => <BodySm {...args} />;

export const VxBodySm = VxBodySmTemplate.bind({});

VxBodySm.args = {
  title: 'BodyMd - Source Sans Pro (18px)',
  color: vxAppColors.brand.primary.principal,
};

const VxBodyXsTemplate: ComponentStory<typeof BodyXs> = (args) => <BodyXs {...args} />;

export const VxBodyXs = VxBodyXsTemplate.bind({});

VxBodyXs.args = {
  title: 'BodyMd - Source Sans Pro (18px)',
  color: vxAppColors.brand.primary.principal,
};

const VxButtonTypeTemplate: ComponentStory<typeof ButtonType> = (args) => <ButtonType {...args} />;

export const VxButtonType = VxButtonTypeTemplate.bind({});

VxButtonType.args = {
  title: 'BodyMd - Source Sans Pro (18px)',
  color: vxAppColors.brand.primary.principal,
};

const VxPlaceholderTemplate: ComponentStory<typeof Placeholder> = (args) => <Placeholder {...args} />;

export const VxPlaceholder = VxPlaceholderTemplate.bind({});

VxPlaceholder.args = {
  title: 'BodyMd - Source Sans Pro (18px)',
  color: vxAppColors.brand.primary.principal,
};

export const AllBodyText = () => (
  <BodyContainer>
    <BodyLg title="BodyLg - Source Sans Pro" color={vxAppColors.brand.primary.principal} fontWeight="light" />
    <BodyMd title="BodyMd - Source Sans Pro" color={vxAppColors.brand.primary.principal} fontWeight="light" />
    <BodySm title="BodySm - Source Sans Pro" color={vxAppColors.brand.primary.principal} fontWeight="light" />
    <BodyXs title="BodyXs - Source Sans Pro" color={vxAppColors.brand.primary.principal} fontWeight="light" />
    <ButtonType title="ButtonType - Source Sans Pro" color={vxAppColors.brand.primary.principal} fontWeight="light" />
    <Placeholder title="Placeholder - Source Sans Pro" color={vxAppColors.brand.primary.principal} fontWeight="light" />
  </BodyContainer>
);

import { formatNumbers } from 'data/utils/currency-util';
import { VXIcon, VXIconsEnum } from 'presentation/components/micro-components/vx-icon';

import { VxBodyMd, VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { VxAppColor, vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import * as S from './styles';

export interface ExtractOperations {
  text: string;
  value: number;
}

export interface ExtractProps {
  date: string;
  operations: Array<ExtractOperations>;
}

type ExtractTableType = {
  extractList: ExtractProps[];
};

const ExtractTable: React.FunctionComponent<ExtractTableType> = ({ extractList }) => {
  const dark = vxAppColors.neutral.darkest;
  const formatOptions = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 };

  function formatOperationType(operation: string) {
    switch (operation.toLocaleLowerCase()) {
      case 'deposit':
        return 'Depósito';
      case 'withdraw':
        return 'Saque';
      case 'buy':
        return 'Compra de Ativo';
      case 'sell':
        return 'Venda de Ativo';
      case 'tax':
        return 'Cobrança de taxa';
      case 'exchangecrypto':
        return 'Negociação Cripto';

      default:
        return '--';
    }
  }

  function renderOperationIcon(text: string): VXIconsEnum {
    switch (text.toLocaleLowerCase()) {
      case 'deposit':
        return VXIconsEnum.deposit;
      case 'withdraw':
        return VXIconsEnum.withdraw;
      case 'tax':
        return VXIconsEnum.tax;
      case 'exchangecrypto':
        return VXIconsEnum.coin;

      default:
        return VXIconsEnum.tax;
    }
  }

  function monetaryColor(value: number): VxAppColor {
    if (value > 0) {
      return vxAppColors.feedback.success.default;
    }
    return vxAppColors.feedback.error.default;
  }

  return (
    <S.ExtractTableStyle>
      <thead>
        <tr>
          {['Data', 'Operação', 'Valor'].map((title, i) => (
            <th key={i}>
              <VxBodyMd title={title} fontWeight="medium" color={dark} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {extractList.map((extract, i) => {
          return (
            <tr key={i}>
              <td className={extract.operations.length > 1 ? 'date-column' : ''}>
                <VxBodySm title={extract.date} fontWeight="medium" color={dark} />
              </td>
              <td>
                {extract.operations.map((operation, i) => {
                  return (
                    <div key={i} className="operation-row">
                      <VXIcon icon={renderOperationIcon(operation.text)} size="md" color={dark} />
                      <VxBodySm title={formatOperationType(operation.text)} fontWeight="regular" color={dark} />
                    </div>
                  );
                })}
              </td>
              <td>
                {extract.operations.map((operation, i) => {
                  return (
                    <div key={i} className="value-column">
                      <VxBodySm
                        key={i}
                        title={formatNumbers(operation.value, formatOptions)}
                        fontWeight="medium"
                        color={monetaryColor(operation.value)}
                      />
                    </div>
                  );
                })}
              </td>
            </tr>
          );
        })}
      </tbody>
    </S.ExtractTableStyle>
  );
};

export { ExtractTable };

import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import styled from 'styled-components';

export const ExtractFilterStyle = styled.nav`
  background-color: ${vxAppColors.background};
  .filter-hide {
    display: flex;
    justify-content: end;
    padding: 16px 0px 16px 56px;

    button {
      div {
        padding-right: 0px;
      }
    }
  }
  > div.filter-show-desk {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 64px;
    padding: 24px 48px 24px 56px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    background-color: ${vxAppColors.neutral.white};
    margin-bottom: 24px;
    .date-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > div {
        display: flex;
        gap: 36px;
      }
    }
    .period-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .release-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .filters-btn-container {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-left: auto;
      gap: 3px;
      > div {
        display: flex;
        gap: 16px;
      }
    }
    margin-bottom: 24px;
  }

  > div.filter-show-responsive {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px;
    padding: 24px 48px 24px 56px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    background-color: ${vxAppColors.neutral.white};
    margin-bottom: 24px;
    .container-left {
      margin-left: auto;
      display: flex;
      gap: 16px;
    }
    .date-period-container {
      display: flex;
      gap: 64px;
      @media (max-width: 1295px) {
        flex-direction: column;
        gap: 32px;
      }
      .date-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > div {
          display: flex;
          gap: 36px;
        }
      }
      .period-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .release-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    margin-bottom: 24px;
  }
`;

import styled from 'styled-components';

export const HomeEmptyPositionsStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 505px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 22.5px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
  }
`;

import { RoutesEnum } from 'config/routes/router-enum';
import { VxButton } from 'presentation/components/micro-components/vx-button';
import { setCurrentRoute } from 'presentation/stores/slices/settings.store';
import { VxBodySm } from 'presentation/styleguide/typography/body/VxBody';
import { H5 } from 'presentation/styleguide/typography/headline/VxHeadline';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
const EmptyOrders: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <S.EmptyOrdersContainer>
      <H5 title="Você ainda não fez nenhuma ordem" fontWeight="medium" color={vxAppColors.brand.primary.darkest} />
      <VxBodySm
        title="Quando fizer, ela aparecerá aqui"
        fontWeight="regular"
        color={vxAppColors.brand.primary.darkest}
      />

      <VxButton
        type="primary"
        disabled={false}
        size="small"
        width={162}
        onClick={() => {
          navigate(RoutesEnum.home);
          dispatch(setCurrentRoute(RoutesEnum.home));
        }}
        text="NEGOCIAR"
      />
    </S.EmptyOrdersContainer>
  );
};

export { EmptyOrders };

export const GRID_GAP = 24;
export const GRID_FHD_COLUMNS = 12;
export const GRID_XS_COLUMNS = 8;
export const GRID_MOBILE_COLUMNS = 6;

export const MARGIN_XL_DESKTOP = 56;
export const MARGIN_LG_DESKTOP = 40;
export const MARGIN_MOBILE = 32;

export const DESKTOP_SIDEBAR_WIDTH = 256;
export const MOBILE_SIDEBAR_WIDTH = 72;

export const DESKTOP_FHD_GRID_WIDTH = 108;
export const DESKTOP_LG_GRID_WIDTH = 84;
export const DESKTOP_MD_GRID_WIDTH = 80;
export const DESKTOP_SM_GRID_WIDTH = 72;
export const DESKTOP_XS_GRID_WIDTH = 88;
export const MOBILE_GRID_WIDTH = 84;

export const DESKTOP_FHD = 1920;
export const DESKTOP_LG = 1440;
export const DESKTOP_MD = 1366;
export const DESKTOP_SM = 1280;
export const DESKTOP_XS = 1024;
export const MOBILE = 768;

export const DESKTOP_FHD_USABLE_WIDTH = DESKTOP_FHD_GRID_WIDTH * GRID_FHD_COLUMNS + GRID_GAP * (GRID_FHD_COLUMNS - 1);
export const DESKTOP_LG_USABLE_WIDTH = DESKTOP_LG_GRID_WIDTH * GRID_FHD_COLUMNS + GRID_GAP * (GRID_FHD_COLUMNS - 1);
export const DESKTOP_MD_USABLE_WIDTH = DESKTOP_MD_GRID_WIDTH * GRID_FHD_COLUMNS + GRID_GAP * (GRID_FHD_COLUMNS - 1);
export const DESKTOP_SM_USABLE_WIDTH = DESKTOP_SM_GRID_WIDTH * GRID_FHD_COLUMNS + GRID_GAP * (GRID_FHD_COLUMNS - 1);
export const DESKTOP_XS_USABLE_WIDTH = DESKTOP_XS_GRID_WIDTH * GRID_XS_COLUMNS + GRID_GAP * (GRID_XS_COLUMNS - 1);
export const MOBILE_USABLE_WIDTH = MOBILE_GRID_WIDTH * GRID_MOBILE_COLUMNS + GRID_GAP * (GRID_MOBILE_COLUMNS - 1);

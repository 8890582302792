import { formatDouble } from 'data/utils/currency-util';
import { AssetChartPointEntity } from 'domain/entities';
import AssetChart from 'presentation/components/charts/asset_chart';
import { RootState } from 'presentation/stores';
import { useBillet } from 'presentation/stores/hooks/use-billet';
import { useCompanyName } from 'presentation/stores/hooks/use-company-name';
import { useQuotation } from 'presentation/stores/hooks/use-quotation';

import { VxBodyMd } from 'presentation/styleguide/typography/body/VxBody';
import { vxAppColors } from 'presentation/styleguide/vx-colors-style';
import { vxOscillationColor } from 'presentation/utils/color-utils';
import { goToAssetPage } from 'presentation/utils/navigate-utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { AssetImage } from '../asset-image';
import { VxButton } from '../micro-components/vx-button';
import { AssetItemStyle } from './style';
import { VxBodySm, VxBodyXs } from 'presentation/styleguide/typography/body/VxBody.stories';
import { DESKTOP_LG } from '../vx-grid/helpers';

interface AssetItemProps {
  symbol: string;
  miniChartData: AssetChartPointEntity[];
}

export const AssetItem: React.FC<AssetItemProps> = ({ symbol, miniChartData }) => {
  const { companyName } = useCompanyName({ symbol });
  const breakpoint = useMediaQuery('(min-width: 1550px)');
  const { lastTrade, dailyOscillation, marginValueSell } = useQuotation({ symbol });
  const { showModal } = useBillet({ symbol });
  const settingsSlice = useSelector((state: RootState) => state.settingsSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(min-width: ${DESKTOP_LG}px)`);
  return (
    <AssetItemStyle>
      <td>
        <div
          onClick={() => {
            if (symbol !== 'BRL' && symbol !== 'USD') {
              goToAssetPage(symbol, navigate, dispatch);
            }
          }}
          className="asset-info"
        >
          <AssetImage size="xs" symbol={symbol} />
          <div>
            <div className="asset-margin-info">
              {isMobile ? (
                <VxBodySm title={symbol} color={vxAppColors.brand.primary.dark} fontWeight="bold" />
              ) : (
                <VxBodyXs title={symbol} color={vxAppColors.brand.primary.dark} fontWeight="bold" />
              )}
              {settingsSlice.environment === 'margin' && (marginValueSell ?? 0) > 0 && (
                <>
                  <div className="asset-margin-dot" />
                  <VxBodyMd title={`${marginValueSell}x`} color={vxAppColors.neutral.dark} fontWeight="bold" />
                </>
              )}
            </div>
            {isMobile ? (
              <VxBodySm title={companyName} color={vxAppColors.brand.primary.dark} fontWeight="regular" />
            ) : (
              <VxBodyXs title={companyName} color={vxAppColors.brand.primary.dark} fontWeight="regular" />
            )}
          </div>
        </div>
      </td>
      <td>
        <div>
          {isMobile ? (
            <VxBodyMd
              title={formatDouble(lastTrade, { minimumDecimalPlaces: 2, maximumFractionDigits: 2 })}
              color={vxAppColors.brand.primary.dark}
              fontWeight="medium"
            />
          ) : (
            <VxBodyXs
              title={formatDouble(lastTrade, { minimumDecimalPlaces: 2, maximumFractionDigits: 2 })}
              color={vxAppColors.brand.primary.dark}
              fontWeight="medium"
            />
          )}

          {!breakpoint &&
            (isMobile ? (
              <VxBodyMd
                title={`${formatDouble(dailyOscillation, { alwaysShowSignal: true, minimumDecimalPlaces: 2 })}%`}
                color={vxOscillationColor(dailyOscillation)}
                fontWeight="medium"
              />
            ) : (
              <VxBodyXs
                title={`${formatDouble(dailyOscillation, { alwaysShowSignal: true, minimumDecimalPlaces: 2 })}%`}
                color={vxOscillationColor(dailyOscillation)}
                fontWeight="medium"
              />
            ))}
        </div>
      </td>
      {breakpoint && (
        <td>
          <div className="mini-chart-container">
            <AssetChart
              chartPositions={miniChartData.map((data) => ({
                assetPrice: data.value,
                date: data.when,
              }))}
              variation={dailyOscillation}
            />
          </div>
        </td>
      )}

      {breakpoint && (
        <td>
          <VxBodyMd
            title={`${formatDouble(dailyOscillation, { alwaysShowSignal: true, minimumDecimalPlaces: 2 })}%`}
            color={vxOscillationColor(dailyOscillation)}
            fontWeight="medium"
          />
        </td>
      )}
      <td>
        <VxButton type="callToAction" disabled={false} size="small" width={86} onClick={showModal} text="NEGOCIAR" />
      </td>
    </AssetItemStyle>
  );
};
